.tournament-team-replace-picker {
	width: 100%;
	height: 120px;
	overflow-y: auto;
}

.tournament-team-replace-team {
	height: 40px;
	line-height: 40px;
	cursor: pointer;
	padding: 0 8px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	&:nth-child(odd) {
		background-color: #f2f2f2;
	}

	&:nth-child(even) {
		background-color: #f8f8f8;
	}

	&.selected {
		background-color: $primary-hover-color;
	}
}

;@import "sass-embedded-legacy-load-done:121";