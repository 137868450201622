.admin-tournament-search-input {
	position: relative;
	top: 3px;
}

.admin-player-ladder-country-flag {
	margin-right: 8px;

	&.inactive {
		opacity: 0.6;
	}
}

;@import "sass-embedded-legacy-load-done:86";