@import '~bulma/sass/utilities/initial-variables';
$widescreen: 1300px;
$fullhd: 1780px;
@import '~bulma/sass/utilities/functions';
@import '~bulma/sass/utilities/derived-variables';
@import '~bulma/sass/utilities/mixins';
@import '~bulma/sass/utilities/controls';
@import '~bulma/sass/utilities/extends';

@import '~bulma/sass/helpers/float';
@import '~bulma/sass/helpers/spacing';
@import '~bulma/sass/helpers/typography';
@import '~bulma/sass/helpers/visibility';

.is-justify-content-center {
	justify-content: center !important;
}
.is-align-content-start {
	align-content: start !important;
}
.has-text-success {
	color: hsl(153, 53%, 53%);
}
.has-text-danger {
	color: hsl(348, 86%, 61%);
}

@import '~bulma/sass/grid/columns';

@include mobile() {
	.columns {
		margin: 0;
	}
}

;@import "sass-embedded-legacy-load-done:319";