body {
	margin: 0;
}

input {
	padding: 0px 5px;
	border: 0;
}

a {
	color: #fff;
	text-decoration: none;
	background-color: transparent;
}

a:active,
a:hover {
	outline: 0;
}

ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

audio,
canvas,
progress,
video {
	display: inline-block;
	vertical-align: baseline;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

img {
	border: 0;
}

button,
input,
select,
textarea {
	font: inherit;
	margin: 0;
}

button {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

input {
	line-height: normal;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}

textarea {
	overflow: auto;
}

button::-moz-focus-inner {
	border: 0;
	padding: 0;
}
