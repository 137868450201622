.match-ready-popover {
	width: 400px;
	max-width: 100%;

	.match-description {
		color: #fff;
		margin-bottom: 20px;

		opacity: 0.6;
		text-align: right;
	}
}

.match-ready-users {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.match-ready-user {
	float: left;
	width: 60px;
	height: 60px;
	margin-bottom: 10px;
	background-image: url(../img/gathers/user_notready.png);
	background-position: 50% 50%;
	border: 2px solid #fff;
	transition: all 0.15s linear;

	&.ready {
		background-image: url(../img/gathers/user_ready.png);
		border: 2px solid #7cb600;
	}

	&.declined {
		border: 2px solid #b60000;
	}
}

.match-ready-timer {
	margin-top: 10px;
	font-size: 40px;
	color: #00739e;
}

.match-ready-btn {
	width: 48%;
	margin-left: 0px !important;
}
.match-ready-btn:first-child {
	float: left;
	background-color: #5ec76c;
}
.match-ready-btn:last-child {
	float: right;
	background-color: #e4454b;
}

;@import "sass-embedded-legacy-load-done:113";