@include mobile() {
	@import 'helpers/table';

	.is-hidden-mobile {
		display: none !important;
	}

	.columns.no-gap-mobile {
		margin-left: 0;
		margin-right: 0;
		margin-top: 0;

		&:last-child {
			margin-bottom: 0;
		}

		&:not(:last-child) {
			margin-bottom: 0;
		}

		> .column {
			padding: 0;
		}
	}
}

@include from(1200px) {
	.inner-wrapper {
		margin: 0px auto;
		position: relative;
		padding-left: $content-padding-horizontal;
		padding-right: $content-padding-horizontal;
	}
}

.is-fullwidth {
	width: 100%;
}

.is-halfwidth {
	width: 49%;
}

.center-content {
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.child-spacing > * {
	margin-left: 5px;
	margin-right: 5px;
	display: inline-block;
}

.stick-right {
	display: flex;
	width: 100%;
	justify-content: flex-end;
}

th.collapsing td.collapsing {
	width: 1%;
	white-space: nowrap;
}

.is-fullhd-only {
	display: none !important;

	@include fullhd() {
		display: unset !important;
	}
}
.is-visible-touch-only {
	display: unset;

	@include desktop() {
		display: none !important;
	}
}

.is-invisible {
	visibility: hidden;
}

.is-uppercase {
	text-transform: uppercase;
}

.is-capitalize {
	text-transform: capitalize;
}
.is-space-around {
	justify-content: space-around;
}
.no-wrap-inline-block {
	white-space: nowrap;
	display: inline-block;
}
.link {
	color: $button-color;
	cursor: pointer;
}

;@import "sass-embedded-legacy-load-done:13";