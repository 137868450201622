@import 'src/esportal-ui/styles/colors';

.item {
	cursor: pointer;
	padding: 0;
	font-size: 14px;
	font-weight: normal;

	i {
		width: 46px;
		margin: 0;
		text-align: center;
		transition: all 0.3s;
		will-change: transform;
		color: $navigation-icon-color;
	}

	div {
		font-size: 14px;
		font-weight: normal;
	}

	&:hover {
		i {
			color: $text-color-blue;
		}
	}

	:global(.expanded) & {
		i:first-of-type {
			color: $text-color-blue;
		}
	}
}

.body {
	// animation
	height: auto;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.3s linear;
	will-change: max-height;

	// styles
	background-color: $navigation-submenu-background;

	li {
		font-weight: 300;

		&:first-of-type {
			margin-top: 5px;
		}

		&:last-of-type {
			margin-bottom: 5px;
		}
	}

	:global(.expanded) & {
		max-height: 200px;
	}
}

.chevron {
	:global(.expanded) & {
		transform: rotate(180deg);
	}
}

.subItemNavLink {
	color: $text-color-grey;
	display: inline-block;
	width: 100%;
	transition: all 0.3s;
	padding: 12px 0 12px 46px;
	font-size: 12px;

	&:hover {
		color: $text-color-blue;
		box-shadow: inset 6px 0 0 $text-color-blue;
	}
}

.activeNavLink {
	color: $text-color-blue;
	box-shadow: inset 6px 0 0 $text-color-blue;
}

.newsUnreadCount {
	min-width: 20px;
	min-height: 20px;
	margin-left: 10px;
	text-align: center;
	line-height: 20px;
	background-color: #f02849;
	border-radius: 50%;
	font-size: 10px;
	color: #fef4f6;
	font-weight: 600;
}
