#adyen-payment {
	overflow: auto;
	width: 582px;

	@media only screen and (max-width: 605px) {
		width: 100%;
	}

	.checkout-service-agreement {
		margin-bottom: 15px;
		font-weight: normal;

		@media only screen and (max-width: 605px) {
			font-size: 11px;
			font-weight: 300;
			text-align: center;
		}
	}

	.adyen-checkout__payment-method {
		background: #191a24;
		border: 0;
		border-radius: 0;
		margin: 0;

		&:first-child {
			border-radius: 4px 4px 0 0;
		}

		&:not(:last-child) {
			border-bottom: 2px solid #232530;
		}

		&:last-child {
			border-radius: 0 0 4px 4px;
		}
	}

	.adyen-checkout__payment-method--selected {
		background: #1c1e29;
		padding-bottom: 1px;
	}

	.adyen-checkout__payment-method__header__title {
		color: #fff;
	}

	.adyen-checkout__label__text {
		color: #fff;
		font-size: 13px;
	}

	.adyen-checkout__error-text {
		font-size: 12px;
	}

	.adyen-checkout__input {
		background: #14151d;
		border: 1px solid #2d2f3b;
		border-radius: 4px;
		color: #fff;
		font-size: 13px;

		&::placeholder {
			color: #40454e;
		}
	}

	.adyen-checkout__status {
		background-color: #1c1e29;
		color: #fff;
		font-size: 15px;
		border: 0;
		border-radius: 4px;

		&--success {
			img {
				filter: invert(1) hue-rotate(199deg) saturate(2);
			}
		}

		&--error {
			img {
				filter: invert(1) hue-rotate(190.5deg) saturate(7) brightness(0.75);
			}
		}
	}

	.adyen-checkout__threeds2__challenge {
		background-color: #e3e3e3;
		margin: 0px auto;
		border-radius: 4px;
	}
}

.adyen-payment-premium {
	.adyen-checkout__button--pay {
		background: transparent linear-gradient(90deg, #ff8400 0%, #ff6600 100%) 0% 0% no-repeat padding-box;

		&:hover {
			background: transparent linear-gradient(90deg, #ff6600 0%, #ff8400 100%) 0% 0% no-repeat padding-box;
		}
	}
}

.adyen-payment-prime {
	.adyen-checkout__button--pay {
		background: transparent linear-gradient(90deg, #8522ff 0%, #4b00a7 100%) 0% 0% no-repeat padding-box;

		&:hover {
			background: transparent linear-gradient(90deg, #4b00a7 0%, #8522ff 100%) 0% 0% no-repeat padding-box;
		}
	}
}

;@import "sass-embedded-legacy-load-done:101";