$page-color: #dc0f03;

.wk-landing-root {
	background-color: #14151d;

	.bullet-points {
		margin: 60px 15% 0;

		.bullet-points-item {
			&:before {
				background-color: $page-color;
			}
		}

		@media only screen and (max-width: 510px) {
			margin: 20px 20px 0;
		}
	}

	.picture-links {
		width: 100%;

		&-container {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			padding: 0 20px;
		}

		&-item {
			max-width: 25%;
			padding: 0 5px;

			@media only screen and (max-width: 710px) {
				padding: 20px 5px;
				max-width: 50%;
			}
		}

		&-link {
			display: block;
			width: 220px;
			max-width: 100%;
			padding: 7px 0;
			margin: 0 auto;
			line-height: 20px;
			font-size: 13px;
			font-weight: 400;
			text-align: center;
			color: #fff;
			background-color: $page-color;
			border-radius: 4px;
			transition: all 0.3s ease;
			cursor: pointer;

			&:hover {
				background-color: lighten($page-color, 10);
			}
		}

		&-picture {
			width: 100%;
		}

		@media only screen and (max-width: 510px) {
			&-container {
				display: block;
			}

			&-item {
				width: 100%;
				max-width: 100%;
				padding: 15px 0;
			}
		}
	}

	.simple-banner {
		margin-top: 100px;
	}

	.tournaments-list {
		padding: 40px 50px;
	}
}
