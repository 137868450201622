.text-xs {
	font-size: 0.75rem;
	line-height: 1rem;
}
.font-light {
	font-weight: 300;
}
.text-white {
	color: #fff;
}

;@import "sass-embedded-legacy-load-done:15";