.banner {
	max-width: 100%;
	padding: 0;
}

.challenges-banner {
	display: flex;
	justify-content: space-between;
	background-image: url(../img/banners/challenges_header.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 75% 50%;

	.text {
		padding: 3rem 0.75rem;
		padding-top: 5rem;
		font-weight: 500;

		.title {
			.fa-flag {
				color: $primary-color;
				margin-right: 0.5em;
			}
		}
	}

	.subtext {
		margin-top: 15px;
	}

	@include mobile() {
		margin-bottom: 1em;

		.text {
			padding: 1rem 0.75rem;
			padding-top: 2rem;
		}
		.title {
			font-size: 20px;
		}
		.subtext {
			font-size: 14px;
			color: $grey-text-color;
			margin-top: 5px;
		}
	}

	@include tablet() {
		.title {
			font-size: 24px;
		}
		.subtext {
			font-size: 12px;
		}

		.text {
			padding: 1rem 5px;
			padding-top: 2rem;
		}
		.sponsored {
			margin-top: 0.5em;
		}
	}

	@include desktop() {
		.title {
			font-size: 28px;
		}
		.subtext {
			font-size: 16px;
		}
		.text {
			padding: 3rem 0.75rem;
			padding-top: 5rem;
		}
	}

	@include widescreen() {
		.title {
			font-size: 36px;
		}
		.subtext {
			font-size: 19px;
		}
	}

	@include from(1200px) {
		width: calc(100% + #{$content-padding-horizontal});
	}

	&.subregion-poland {
		background-image: url(../img/banners/challenges_pl.png);
	}
}

.desktop-list {
	display: flex;
	height: 280px;
	flex-direction: column;
	flex-wrap: wrap;
	width: 100%;
}

.challenge-standings-table-wrapper {
	width: 100%;
	overflow: auto;

	.content-heading {
		font-size: 19px;
		padding: 4px 0px;
		text-align: left;
		user-select: none;
	}
}
.challenge-standings-table {
	white-space: nowrap;

	> thead {
		background-color: $table-header-background-color;
	}
	th:first-child,
	th:last-child {
		border-color: $button-color;
	}

	th,
	td {
		text-align: left;
	}
	th:last-child,
	td:last-child {
		text-align: right;
	}

	.fa-caret-down,
	.fa-caret-up {
		display: inline-block;
		width: 20px;
		font-size: 18px;
		text-align: center;
	}
	.active {
		color: $button-color;
	}
	.table-extended-parent {
		padding: 0 0;
	}
}

.challenges-notes {
	list-style-type: disc;
}

.expanded-challenge-standings-table {
	background-color: rgba(49, 51, 60, 0.19);

	tr:last-child {
		border-bottom: none;
	}
}

.challenges-last-months-winners {
	overflow: auto;
	text-align: left;
	width: 100%;
	@include tablet() {
		padding-left: 8px;
		padding-right: 8px;
	}
	@include widescreen() {
		padding-left: 0;
		padding-right: 0;
	}

	.challenges {
		width: 100%;
		margin-bottom: 5px;
	}
	.famfamfam-flag {
		margin-right: 6px;
	}

	table {
		white-space: nowrap;

		.challenges-last-months-winners-nav {
			background-color: #20212d;
			font-weight: 400;
			text-transform: uppercase;
			display: inline-flex;
			justify-content: flex-start !important;
			width: 100%;

			border-top-left-radius: 4px;
			border-top-right-radius: 4px;

			&.region-filter {
				div:first-child.selected {
					border-top-left-radius: 4px;
				}

				@include mobile() {
					display: flex;
					justify-content: left;
				}
			}

			&.game-achievements-sorting {
				margin-top: 2px;

				.game-achievements-sorting-item {
					flex-grow: 1;
					text-transform: capitalize;
				}

				.game-achievements-sorting-item-left {
					border-top-left-radius: 4px;
				}

				.game-achievements-sorting-item-right {
					border-top-right-radius: 4px;
				}
			}

			@include mobile() {
				text-align: left;
			}

			@include tablet() {
				display: flex;
				justify-content: center;
				text-transform: none;

				.filter-label {
					display: none;
				}
			}

			div {
				color: $grey-text-color;
				font-size: 12px;
				padding: 12px 24px;
				text-transform: uppercase;

				@include mobile() {
					padding: 12px 14px;
				}

				@include tablet() {
					font-size: 14px;
					padding: 5px 8px;
				}

				@include widescreen() {
					white-space: nowrap;
				}

				.famfamfam-flag {
					padding: 0px 8px;
				}
			}

			.selected {
				background-color: #191a24;
				color: $primary-color;
				padding: 13px;
				font-size: 10px;
				text-transform: uppercase;
			}

			.clickable {
				text-transform: uppercase;
				font-size: 10px;
				padding: 13px;
			}
			.fa-caret-left,
			.fa-caret-right {
				font-size: 14px;
			}
			.disabled {
				color: $dark-grey-text-color;
				padding: 13px;
			}
		}

		.challenges-last-months-winners-row {
			position: relative;

			.table-index {
				padding-left: 22px;
				padding-right: 21px;
			}

			.table-user-info {
				text-align: left;

				.table-avatar {
					margin-left: 9px;
				}

				.table-username {
					padding-left: 8px;
				}
			}

			.table-rank-flag {
				width: 125px;
			}

			.table-sorting-parameter {
				padding-right: 40px;
			}
		}

		.top-players-sorting-parameter {
			padding-right: 19px;
		}
	}
}

;@import "sass-embedded-legacy-load-done:78";