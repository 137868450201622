.region {
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

.region-select {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;

	.region {
		cursor: pointer;
		width: 128px;
		height: 128px;
		position: relative;
	}
	.region.disabled {
		cursor: not-allowed;
	}
	.region.active {
		cursor: auto;
	}
}
.region-title {
	position: absolute;
	bottom: 0;
	text-align: center;
	left: 0;
	right: 0;
}

.region-flag {
	width: 29px;
	height: 20px;
	background-size: cover;
}

.region-flag0 {
	background-image: url(../img/flags/region_europe.svg);
}
.region-flag1 {
	background-image: url(../img/flags/region_southamerica.svg);
}
.region-flag2 {
	background-image: url(../img/flags/region_cis.svg);
}

;@import "sass-embedded-legacy-load-done:57";