.ranking-page {
	max-width: 1200px;
}

.ranking-header {
	font-size: 18px;
}

.ranking-filter-rank {
	display: inline-block;
	margin-top: 8px;
	margin-bottom: 8px;
	margin-left: 25px;
	width: 75px;
	background-size: 75px 75px;
	background-position: 50% 0%;
	background-repeat: no-repeat;
	vertical-align: middle;
	opacity: 0.3;
	cursor: pointer;
	font-size: 14px;
	padding-top: 75px;
	text-align: center;
	transition: opacity 0.25s ease-in-out;
}
.ranking-filter-rank.active,
.ranking-filter-country.active {
	opacity: 1;
}

.ranking-filter-country {
	margin: 0 6px;
	opacity: 0.3;
	transition: opacity 0.25s ease-in-out;
	cursor: pointer;
}

.ranking-filter-checkbox {
	display: flex;
	align-items: center;
	padding-bottom: 5px;

	input {
		margin-right: 5px;
	}
}

.ranking-find-player-form {
	position: relative;
}
#ranking-find-player-input {
	background-color: #ebebeb;
	width: 100%;
	height: 38px;
	font-size: 14px;
}
#ranking-find-player-button {
	position: absolute;
	right: 10px;
	line-height: 38px;
	font-size: 16px;
	background-color: transparent;
	border: 0;
	padding: 0;
	margin: 0;
	color: $button-color;
	cursor: pointer;
}
.ranking-region-flag {
	width: 17px;
	height: 12px;
	background-size: cover;

	&.inactive {
		opacity: 0.6;
	}
}
.ranking-flag-list {
	display: inline-block;
	margin-left: 8px;
}

.ranking-table {
	td:nth-child(2) {
		text-align: unset;
	}
}

@include mobile() {
	.ranking-heading {
		display: flex;
		flex-wrap: wrap;
	}
	.ranking-filter-rank-column {
		order: 3;
	}
	.ranking-filter-rank {
		width: 25%;
		margin: 0;
	}
	.ranking-find-player {
		order: 2;
	}

	.ranking-table {
		th,
		td {
			&:nth-child(n + 4) {
				display: none;
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:76";