.drops-content {
	width: 100%;
	padding: 80px 0px 80px 0px;
	background-image: url(../img/backgrounds/dropback.png);
	background-repeat: no-repeat;
	background-position: 50% 120px;
	background-size: 100%;
	background-color: #06040a;
	background-attachment: fixed;

	.columns {
		justify-content: center;
	}
	.drops-half-column {
		max-width: 600px;
	}
	.drops-full-column {
		margin: auto auto 0;
		max-width: 1200px;
	}
	.drops-premium-selling-point-section {
		background: linear-gradient(
			0deg,
			transparent 0%,
			transparent 20%,
			#21254433 20%,
			#21254433 80%,
			transparent 80%,
			transparent 100%
		);
		.drops-premium-selling-point-image {
			content: url(../img/drops/pouplanding.png);
			width: 100%;
		}
	}
	.column-text {
		font-weight: 400;
		line-height: 24px;
		max-width: 420px;
		text-align: start;
		margin-top: 16px;
		margin-bottom: 16px;
		@include mobile() {
			margin: auto;
			max-width: 380px;
		}
	}
	.premium-button-container {
		display: flex;
		margin: auto;
		max-width: 250px;
		.button {
			&.premium {
				margin-top: 16px;
				font-size: 13px;
				padding: 1px 16px;
				@include mobile() {
					margin: auto;
					margin-top: 25px;
					width: 100%;
					text-align: center;
				}
			}
		}
	}
	.drops-premium-drops-image {
		max-width: 380px;
		@include mobile() {
			display: flex;
			margin: auto;
		}
	}
	.latest-super-drops-only-carousel {
		.latest-drops-carousel {
			.latest-drops-carousel-content {
				height: 209px;
			}

			.latest-drop {
				&.super-drop {
					height: 209px;
				}

				.drop-category-text {
					display: none;
				}
			}

			.match-link {
				background-color: #20212d;
				color: $important-color;
				line-height: 34px;
			}

			.home-carousel-button {
				height: 209px;
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:107";