.friend {
	height: 50px;
	background-color: #eee;
	overflow: hidden;
	display: flex;
	justify-content: space-between;
}
.profile-friend {
	display: flex;
	align-items: center;
	height: 50px;
	background-color: #1c1c1c;
	overflow: hidden;
	justify-content: space-between;

	.friend-list-username {
		flex-grow: 1;
	}
}
.profile-friend:nth-child(odd) {
	background-color: #191a23;
	border-bottom: 1px solid #24242d;
}
.profile-friend:nth-child(even) {
	background-color: #15151c;
	border-bottom: 1px solid #24242d;
}

.friend-column {
	display: flex;
	align-items: center;

	&.col-friend {
		min-width: calc(100% - 190px);
		height: 50px;
		float: left;
	}
	&.col-chat {
		width: 30px;
		height: 50px;
	}
	&.col-invite-status {
		max-width: 138px;
		padding-left: 5px;
		justify-content: center;
		align-items: center;

		.status {
			font-size: 12px;
			cursor: default;

			&.invited {
				color: orange;
			}
			&.in-party {
				color: limegreen;
			}
		}
		a.status {
			cursor: pointer;
		}
		.button {
			margin-left: 5px;
		}
	}
	&.col-other-stuff {
		margin-right: 2em;
	}
	.online-status-indicator {
		height: 8px;
		width: 8px;
		margin-left: 8px;
	}
}
.friend {
	background-color: #1c1c1c;
}
.friend:nth-child(even) {
	background-color: #14151d;
}
.friend:nth-child(odd) {
	background-color: #1a1b24;
}
.friend-avatar {
	flex-shrink: 0;
	margin: 0 10px;
	height: 26px;
	width: 26px;
	border: 2px solid $primary-color;
}

.friend-country {
	margin-right: 10px;
}
.friend-list-country {
	margin-left: 10px;
}
.friend-country-container {
	display: block;
	float: left;
	margin-left: 10px;
	line-height: 50px;
}
.friend-list-username {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.friend-request {
	cursor: pointer;
	border: 2px solid #3f4151;
	text-align: center;
	font-size: 12px;
	padding: 0px 15px;
	border-radius: 4px;
	height: 30px;
	line-height: 26px;
	color: #fff;
	white-space: nowrap;
}
.friend-request:hover {
	background-color: $primary-color;
	border-color: $primary-color;
}
.friend-request.disabled:hover {
	cursor: default;
	background-color: transparent;
	border-color: #3f4151;
}

.friend-admin-symbol {
	margin-left: 4px;
}
.friend-prime-symbol {
	margin-left: 4px;
}
.friend-invite-mm-tipsy {
	.Tipsy {
		left: 10px;
	}
}
.friend-open-chat {
	position: relative;
	vertical-align: top;
	display: inline-block;
	cursor: pointer;
	height: 30px;
	width: 30px;
	line-height: 30px;
	text-align: center;
	color: $primary-hover-color;
	font-size: 18px;
}
.friend-chat-unseen-indicator {
	position: absolute;
	width: 12px;
	height: 12px;
	top: 2px;
	left: 1px;
	background-color: $primary-color;
	border-radius: 50%;
}

.friend-button {
	vertical-align: top;
	display: inline-block;
	min-height: auto;
	height: 20px;
	line-height: 20px;
	font-size: 12px;
	font-weight: 400;
	border-radius: 10px;

	&.play {
		width: 53px;
		margin: 15px auto;
	}

	&.join {
		padding: 0;
		margin: 15px 0;
	}

	&.disabled {
		filter: grayscale(1);
		opacity: 0.5;
	}

	&.gather-group-invite {
		padding: 0 8px;
		margin: 15px 0;

		&.kick {
			background-color: #da0041;
		}
	}
	&.gather-group-invite.invite {
		font-size: 8px;
	}
}
.chat-icon {
	display: inline-block;
	width: 20px;
	height: 20px;
	background-image: url(../img/misc/chat.svg);
	background-repeat: no-repeat;
	background-position-y: center;
	vertical-align: middle;
}

.pre-invite-button {
	@media (max-width: $right-side-wide-breakpoint) {
		display: none;
	}
}

.plus-icon {
	display: inline-block;
	width: 20px;
	height: 20px;
	background-image: url(../img/misc/plus.svg);
	background-repeat: no-repeat;
	background-position-y: center;
}

.invite-1v1-button-holder {
	height: 100%;
	display: flex;
	align-items: center;
}

.invite-friend-1v1-button {
	width: 35px;
	height: 35px;
	cursor: pointer;

	&.invited {
		color: $premium-color;

		&:hover {
			border-color: $premium-color;
		}
	}
}

;@import "sass-embedded-legacy-load-done:29";