.subscription-plans {
	&_list-greyed {
		opacity: 0.5;
		pointer-events: none;
	}
	&_header {
		display: flex;
		justify-content: space-between;
	}

	&_archived {
		text-transform: none;
		user-select: none;
	}
}

;@import "sass-embedded-legacy-load-done:85";