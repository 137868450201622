.support-page {
	max-width: 1200px;
}

.support-ticket-popover {
	width: 500px;
	max-width: 100%;

	label {
		margin: 6px 0;
		display: inline-block;
	}

	input,
	textarea {
		width: 100%;
	}

	input[type='submit'] {
		margin-top: 6px;
	}
}
.support-ticket-chat {
	float: left;
	width: 100%;
	.box-header {
		background-color: #21273d;
		font-size: 12px;
		text-transform: none;
		text-align: left;

		.button {
			padding: 0px 5px;
			height: 25px;
			line-height: 25px;
			min-height: 25px;
			font-size: 12px;
		}
	}
	.box-content {
		height: 200px;
		padding: 0px 0px;
		overflow: hidden;
		border-top: 1px solid #ddd;
	}
}
.support-ticket-chat-messages {
	height: 164px;
	overflow: auto;
	padding: 0px 10px;
}
.support-ticket-chat-message {
	margin: 5px 0px;

	.player-card-wrapper {
		font-weight: 500;
	}
}
.support-ticket-chat-input-bar {
	border-top: 1px solid #ddd;
	height: 36px;
	overflow: hidden;
}
.support-ticket-chat-input-toggle,
.support-ticket-chat-input-field,
.support-ticket-chat-input-button {
	display: inline-block;
}
.support-ticket-chat-input-toggle:hover {
	background-color: $primary-hover-color;
}
.support-ticket-chat-input-field {
	width: calc(100% - 100px);
	background-color: rgba(25, 25, 25, 0) !important;
}
.support-ticket-chat-input-field,
.support-ticket-chat-input-field:focus {
	border: 0;
}
.support-ticket-chat-input-button {
	width: 100px;
}

.admin-support-tickets-country-filter .famfamfam-flag {
	cursor: pointer;
	margin-top: 13px;

	&:first-child {
		margin-left: 0.5rem;
	}

	&:not(:last-child) {
		margin-right: 0.5rem;
	}
}

;@import "sass-embedded-legacy-load-done:111";