.map0 {
	background-image: url(../img/gathers/maps/maps_random.png);
}
.map1 {
	background-image: url(../img/gathers/maps/maps_de_dust2.png);
}
.map2 {
	background-image: url(../img/gathers/maps/maps_de_inferno.png);
}
.map3 {
	background-image: url(../img/gathers/maps/maps_de_nuke.png);
}
.map4 {
	background-image: url(../img/gathers/maps/maps_de_overpass.png);
}
.map5 {
	background-image: url(../img/gathers/maps/maps_de_mirage.png);
}
.map6 {
	background-image: url(../img/gathers/maps/maps_de_cache.png);
}
.map7 {
	background-image: url(../img/gathers/maps/maps_de_cobblestone.png);
}
.map8 {
	background-image: url(../img/gathers/maps/maps_de_train.png);
}
.map9 {
	background-image: url(../img/gathers/maps/maps_de_tuscan.png);
}
.map10 {
	background-image: url(../img/gathers/maps/maps_de_season.png);
}
.map11 {
	background-image: url(../img/gathers/maps/maps_de_santorini.png);
}
.map12 {
	background-image: url(../img/gathers/maps/maps_de_aztec.png);
}
.map13 {
	background-image: url(../img/gathers/maps/maps_de_dust.png);
}
.map14 {
	background-image: url(../img/gathers/maps/maps_de_fire.png);
}
.map15 {
	background-image: url(../img/gathers/maps/maps_de_mill.png);
}
.map16 {
	background-image: url(../img/gathers/maps/maps_de_prodigy.png);
}
.map17 {
	background-image: url(../img/gathers/maps/maps_de_piranesi.png);
}
.map18 {
	background-image: url(../img/gathers/maps/maps_de_vertigo.png);
}
.map19 {
	background-image: url(../img/gathers/maps/maps_de_dust2_old.png);
}
.map20 {
	background-image: url(../img/gathers/maps/maps_de_inferno_old.png);
}
.map21 {
	background-image: url(../img/gathers/maps/maps_de_nuke_old.png);
}
.map22 {
	background-image: url(../img/gathers/maps/maps_de_cobblestone_old.png);
}
.map23 {
	background-image: url(../img/gathers/maps/maps_de_train_old.png);
}
.map24 {
	background-image: url(../img/gathers/maps/maps_cs_office.png);
}
.map25 {
	background-image: url(../img/gathers/maps/maps_cs_assault16.png);
}
.map26 {
	background-image: url(../img/gathers/maps/maps_cs_italy.png);
}
.map27 {
	background-image: url(../img/gathers/maps/maps_cs_insertion.png);
}
.map28 {
	background-image: url(../img/gathers/maps/maps_cs_agency.png);
}
.map29 {
	background-image: url(../img/gathers/maps/maps_cs_militia.png);
}
.map30 {
	background-image: url(../img/gathers/maps/maps_cs_estate16.png);
}
.map31 {
	background-image: url(../img/gathers/maps/maps_de_subzero.png);
}
.map32 {
	background-image: url(../img/gathers/maps/maps_de_cache_new.png);
}
.map33 {
	background-image: url(../img/gathers/maps/maps_aim_map_nocancer.png);
}
.map34 {
	background-image: url(../img/gathers/maps/maps_ancient.png);
}
.map35 {
	background-image: url(../img/gathers/maps/maps_dp_wingman.png);
}
.map36 {
	background-image: url(../img/gathers/maps/maps_aim_esportal.png);
}
.map37 {
	background-image: url(../img/gathers/maps/maps_aim_monster.png);
}
.map38 {
	background-image: url(../img/gathers/maps/maps_aim_astralis.png);
}
.map39 {
	background-image: url(../img/gathers/maps/maps_rbf_digital_archives.png);
}
.map40 {
	background-image: url(../img/gathers/maps/maps_rbf_control_room.png);
}
.map41 {
	background-image: url(../img/gathers/maps/maps_rbf_city_palace.png);
}
.map42 {
	background-image: url(../img/gathers/maps/maps_rbf_sky_platform.png);
}
.map43 {
	background-image: url(../img/gathers/maps/maps_rbf_wing5_lobby.png);
}
.map44 {
	background-image: url(../img/gathers/maps/maps_rbf_wing_dex.png);
}
.map45 {
	background-image: url(../img/gathers/maps/maps_rbf_wing_flux.png);
}
.map46 {
	background-image: url(../img/gathers/maps/maps_rbf_wing_t3l-p0rt.png);
}
.map47 {
	background-image: url(../img/gathers/maps/maps_de_shortnuke.png);
}
.map48 {
	background-image: url(../img/gathers/maps/maps_omen_amd_esportal_wingman.png);
}
.map49 {
	background-image: url(../img/gathers/maps/maps_de_cache_old.png);
}
.map50 {
	background-image: url(../img/gathers/maps/maps_aim_redline.png);
}
.map51 {
	background-image: url(../img/gathers/maps/maps_aim_usp.png);
}
.map52 {
	background-image: url(../img/gathers/maps/maps_aim_deagle.png);
}
.map53 {
	background-image: url(../img/gathers/maps/maps_aim_ak47.png);
}
.map54 {
	background-image: url(../img/gathers/maps/maps_aim_awp_india.png);
}
.map55 {
	background-image: url(../img/gathers/maps/maps_de_corsair_inferno_wingman.png);
}
.map56 {
	background-image: url(../img/gathers/maps/maps_de_mirage_ce.png);
}
.map57 {
	background-image: url(../img/gathers/maps/maps_aim_ak47_eanp.png);
}
.map58 {
	background-image: url(../img/gathers/maps/maps_awp_wk.png);
}
.map59 {
	background-image: url(../img/gathers/maps/maps_aim_pistol_wk.png);
}
.map60 {
	background-image: url(../img/gathers/maps/maps_aim_map_wk.png);
}
.map61 {
	background-image: url(../img/gathers/maps/maps_aim_map_monster_awp.png);
}
.map62 {
	background-image: url(../img/gathers/maps/maps_aim_map_monster_skate.png);
}

;@import "sass-embedded-legacy-load-done:55";