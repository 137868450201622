#copy-link {
	$medium: 500;
	$normal: 400;
	$light: 300;

	display: flex;
	justify-content: space-between;
	align-items: center;
	min-width: 300px;

	margin: 30px 0 0 0;
	background-color: #14151d;
	border: 2px solid #2d2f3b;
	border-radius: 4px;

	.share-icon {
		margin: 0 10px;
		width: 20px;
		height: 20px;
	}

	.ref-link {
		display: inline-block;
		font-size: 20px;
		padding: 20px 10px;
		color: #13aceb;
		height: 30px;
		margin: 0;
		background-color: transparent;
		border: none;
		flex-grow: 1;
	}

	.copy-button {
		min-height: 30px;
		font-size: 14px;
		font-weight: 500;
		cursor: pointer;
		display: flex;
		align-items: center;

		i {
			font-size: 20px;
			color: white;
			text-decoration: none;
			margin: 0 10px;
			font-size: 20px;
			cursor: pointer;

			transition: color 0.2s;
		}
	}

	.copy-button-clicked {
		i {
			color: #13aceb;
		}
	}
}
