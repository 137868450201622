.player-card-wrapper {
	position: relative;
	display: inline-block;
	line-height: 17px;
	vertical-align: middle;
}

.player-card {
	position: absolute;
	line-height: 12px;
	z-index: 9999;
	width: 460px;
	background-color: #fff;
	box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.5);
}

.player-card-wrapper.player-card-fixed {
	position: static;
	> .player-card {
		position: fixed;
		z-index: 9999;
	}
}

.player-card-head {
	background-color: #0d0f1b;
	height: 60px;
	color: $primary-color;
	position: relative;
	text-align: left;

	.avatar {
		float: left;
		margin: 9px 14px;
		width: 40px !important;
		height: 40px !important;
		border: 2px solid #13aceb;
		border-width: 2px !important;
	}

	.right {
		float: right;
		height: 50px;
	}

	.icon20 {
		margin-left: 5px !important;
	}
}

.player-card-head.prime {
	.circle {
		border-color: #fff !important;
	}
}
.player-card-head.premium {
	.circle {
		border-color: #fff !important;
	}
}
.player-card-username {
	float: left;
	font-size: 18px;
	font-weight: 400;
	line-height: 60px;
	max-width: 140px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.player-card-rank-icon {
	float: right;
	width: 42px;
	height: 42px;
	background-size: 42px 42px;
	background-repeat: no-repeat;
	margin: 9px 18px;
}
.player-card-skill-icon {
	float: right;
	height: 18px;
	background-size: cover;
	background-repeat: no-repeat;
	margin: 16px 6px;

	&.skill0 {
		width: 37.69px;
	}
	&.skill1 {
		width: 50.06px;
	}
	&.skill2 {
		width: 36px;
	}
}
.player-card-online-status {
	line-height: 60px;
	margin: 25px 5px;
}

.player-card-country {
	position: relative;
	margin-left: 5px;
	vertical-align: middle;
}

.player-card-online-status.online1 {
	cursor: pointer;
}

.player-card-body {
	background-color: #232632;
	position: relative;
	height: 220px;
}
.player-card-loading {
	text-align: center;
	margin-top: 20px;
	line-height: 220px;
	height: 220px;
}

.player-card-results {
	position: absolute;
	top: 45px;
	left: 5px;
	text-align: center;
	font-size: 14px;
	width: 60%;
}

.player-card-top-row {
	display: flex;
	justify-content: space-around;
	margin-top: 20px;

	.player-card-label,
	.player-card-value {
		font-size: 15px;
	}
}

.player-card-favorite-weapon {
	left: 300px;
	top: 35px;
	width: 110px;
	position: absolute;
	text-align: center;
}

.player-card-lower-bottom {
	background-color: #232632;
	width: 100%;
	height: 40%;
	position: absolute;
	bottom: 10px;
	left: 0;
	padding-left: 10px;
	text-align: left;
}

.player-card-label {
	color: #000;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 600;
	vertical-align: middle;

	.fas {
		font-size: 15px;
		line-height: 10px;
		margin-right: 10px;
		vertical-align: middle;
	}
}

.player-card-value {
	line-height: 12px;
	font-size: 13px;
	font-weight: 700;
	vertical-align: middle;
	margin-left: 4px;
}

.player-card-actions {
	position: absolute;
	top: 24px;
	right: -32px;
	width: 50%;
	height: 100%;
	color: #fff;
}
.player-card-action {
	display: inline-block;
	background-color: $button-color;
	width: 50px;
	height: 50px;
	text-align: center;
	line-height: 50px;
	font-size: 18px;
	margin-right: 10px;
	border-radius: 50%;
}
.player-card-action.prime {
	background-color: $prime-color;
}
.player-card-action.premium {
	background-color: $premium-color;
}
.player-card-action:hover {
	background-color: $button-hover-color;
}
.player-card-action.disabled {
	cursor: default;
	background-color: #333;
}

.player-card-favorite {
	margin-top: 0px;
	display: inline-block;
	width: 98px;
	height: 68px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

;@import "sass-embedded-legacy-load-done:28";