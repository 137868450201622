.matchmaking-elo-ranking-root {
	width: 100%;
	padding: 0;
	margin: 0;

	.body {
		width: 100%;

		.rank-info {
			font-size: 15px;
		}

		.ranks {
			position: relative;
			display: flex;
			justify-content: space-between;
			padding: 0 50px;
			width: 100%;

			.line {
				top: 75%;
				left: 50%;
				transform: translate(-50%);
				width: 72%;
				height: 3px;
				position: absolute;
				z-index: 0;
			}

			.backdrop {
				position: absolute;
				background: #292a38 0 0 no-repeat padding-box;
				border-radius: 4px;
				width: 340px;
				height: 135px;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -52%);
				z-index: 0;

				&.left {
					width: 220px;
					top: 50%;
					left: 6%;
					transform: translate(0%, -52%);

					&.left-3 {
						width: 324px;
					}
				}

				&.right {
					width: 220px;
					top: 50%;
					left: auto;
					right: 6%;
					transform: translate(0, -52%);

					&.right-3 {
						width: 324px;
					}
				}
			}

			.rank-item {
				max-width: 79px;
				min-width: 79px;
				display: flex;
				flex-direction: column;
				align-items: center;
				z-index: 1;

				.transparent {
					opacity: 0.3;
				}

				img {
					max-width: 100%;
				}

				p {
					line-height: 10px;
					font-size: 10px;
					text-align: center;
					background-color: #3a3b4b;
					color: #c6cfde;
					width: 80%;
					padding: 5px 10px;
					border-radius: 10px;
				}

				&.empty {
					visibility: hidden;
				}
			}
		}
	}
}
