.theme-premium {
	.top-bar-below-item.active,
	.top-bar-below-item:hover {
		box-shadow: inset 0 -5px 0 $premium-hover-color;
		color: #fff;
	}
	.top-bar-volume {
		color: $premium-hover-color;
	}
	.top-bar-caret {
		color: $premium-hover-color;
	}
	.top-bar-dropdown-icon {
		color: $premium-hover-color;
	}
	.top-bar-notifications-dropdown::before {
		border-bottom-color: $premium-hover-color;
	}
	.top-bar-notifications-content {
		border-top-color: $premium-hover-color;
	}
	.top-bar-search-result {
		&.hover,
		&:hover {
			background-color: $premium-hover-color;
			color: #fff;
		}
	}

	.top-bar-matchmaking {
		&:hover {
			box-shadow: inset 0 -5px 0 $premium-hover-color;
		}

		span {
			color: $premium-hover-color;
		}

		&.active {
			background-color: $premium-hover-color;
			span {
				color: #fff;
			}
		}
	}

	.top-bar-below-item > i {
		color: $premium-hover-color;
	}
	.top-bar-below-icon.icon-ranking {
		background-image: url(../img/menu/ranking_premium.png);
	}

	@include mobile() {
		.top-bar-dropdown .top-bar-item.active {
			box-shadow: inset 0 -5px 0 $premium-hover-color;
		}
	}
	#left-side-navigation {
		.main-menu .item {
			.fas {
				color: $premium-hover-color;
			}
			.icon-ladders,
			.icon-public-servers {
				filter: brightness(0) saturate(100%) invert(52%) sepia(86%) saturate(1135%) hue-rotate(344deg)
					brightness(101%) contrast(101%);
			}
		}
		.main-menu .expanded {
			> ul li {
				&:hover {
					a {
						color: $premium-hover-color;
						box-shadow: inset 6px 0 0 $premium-hover-color;
					}
				}

				a.active-nav-link {
					color: $premium-hover-color;
					box-shadow: inset 6px 0 0 $premium-hover-color;
				}
			}
		}
		.item {
			&.active,
			&:hover {
				color: $premium-hover-color;
				box-shadow: inset 6px 0 0 $premium-hover-color;

				.fas {
					color: $premium-hover-color;
				}
				.icon-ranking {
					filter: brightness(0) saturate(100%) invert(52%) sepia(86%) saturate(1135%) hue-rotate(344deg)
						brightness(101%) contrast(101%);
				}
			}
		}
	}
}

.premium-color {
	color: $premium-color;
}

.premium {
	.avatar,
	&.avatar,
	&.friends-user-avatar,
	&.stats-bar-user-avatar,
	&.top-bar-avatar,
	&.activity-icon,
	&.stats-bar,
	.friends-user-avatar {
		border-color: $premium-color !important;
	}
	&.button:not([disabled]) {
		background-color: $premium-color !important;
	}
	&.stats-bar-games-ratio-bar,
	&.box-header {
		background-color: $premium-color !important;
	}
}

.button-premium {
	&.button-two-tone {
		background: linear-gradient(to bottom, #ffb200 50%, #ff7000 50%) !important;
	}
	&.button-gradient {
		background: linear-gradient(#ffb200, #ff7000);
	}
}

.invite-friends-table > tbody {
	tr.premium .friends-user-avatar {
		border-color: #fff !important;
	}

	tr.premium {
		&,
		.kick-button,
		.player-card-wrapper {
			color: #fff !important;
		}
	}
	tr.premium:nth-child(odd) {
		&,
		.kick-button {
			background-color: $premium-hover-color !important;
		}
	}

	tr.premium:nth-child(even) {
		&,
		.kick-button {
			background-color: $premium-color-dark !important;
		}
	}
}

.premium-popover {
	padding: 0;
	width: 100%;
	max-width: 630px;
	color: #fff;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1) !important;
	border: 0 !important;

	> .popover-content {
		padding: 0;
	}
}

.premium-popover-close {
	position: absolute;
	top: 14px;
	right: 0px;
	width: 20px;
	text-align: center;
	line-height: 20px;
	color: #fff;
	cursor: pointer;
}

.premium-popover-image {
	position: relative;
	background-image: url(../img/premium/popover.png);
	background-position: 50% 50%;
	background-size: 350px 246px;
	background-repeat: no-repeat;
	height: 300px;
	width: 100%;
	max-width: 600px;
	margin: 0px auto;
}

.premium-popover-label {
	background-color: #ff8601;
	padding: 25px 10px;
	font-size: 26px;
}
.premium-popover-friends {
	background-color: rgba(1, 5, 19, 0.4);
	padding: 20px 10px 25px 10px;
	overflow: hidden;
}
.premium-popover-friends-label {
	text-transform: uppercase;
	font-weight: 700;
	font-size: 13px;
	margin-bottom: 10px;
}
.premium-popover-friend {
	width: 120px;
	height: 105px;
	margin: 10px 0px;

	.avatar {
		width: 75px;
		height: 75px;
		border: 3px solid $premium-color;
	}
	.username {
		color: $primary-hover-color;
		font-size: 14px;
		font-weight: 500;
	}
}

.match-drop-super {
	background-image: url(../img/premium/match_drop_super.png);
	background-repeat: no-repeat;
}
.match-drop-logo {
	background-image: url(../img/premium/match_drop_logo.svg);
	background-repeat: no-repeat;
}
.match-drop-logo-top {
	background-image: url(../img/premium/match_drop_logo_top.svg);
	background-repeat: no-repeat;
}
.match-drop-logo-left {
	background-image: url(../img/premium/match_drop_super.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.premium-function-popover {
	padding: 0px;
	width: 843px;

	.popover-content {
		padding: 0px;
	}

	.premium-function-popover-header {
		padding: 0px 15px;
		font-weight: 700;
		text-align: left;
		height: 46px;
		line-height: 46px;

		.fas {
			color: $premium-color;
		}
	}
	.premium-function-popover-content {
		background-color: #191a24;
		overflow: auto;

		.premium-function-popover-left {
			float: left;
			width: 60%;

			.premium-function-popover-logo {
				margin: 40px auto;
				background-image: url(../img/premium/premium_function_popover.png);
				width: 430px;
				height: 87px;
			}

			.premium-function-popover-feature {
				font-weight: 700;
				font-size: 18px;
			}
			.premium-function-popover-description {
				font-weight: 300;
				font-size: 14px;
				color: #aaa;
			}
			.button-premium {
				border-radius: 0px;
				padding: 3px 50px;
				margin: 15px 0px;
				font-weight: 700;
			}
			.premium-function-popover-close {
				cursor: pointer;
				margin: 0px 0px;
				font-size: 14px;
				font-weight: 700;
			}
		}
		.premium-function-popover-right {
			background-image: url(../img/premium/background_s1.jpg);
			background-position: 60% 20%;
			background-size: 960px 539.5px;
			float: right;
			width: 40%;
			height: 381px;
			text-align: left;
			padding: 50px 50px 0px 50px;

			.fas {
				color: $premium-color;
			}
			.premium-function-popover-right-more {
				font-weight: 700;
				color: $premium-color;
				margin-left: 18px;
			}
		}
	}
}
.premium-info-popover {
	width: 460px;
	padding: 0px;

	.popover-content {
		padding: 0px;
	}

	.premium-info-popover-header {
		padding: 0px 15px;
		font-weight: 700;
		text-align: left;
		height: 46px;
		background-color: #0e0e0e;
		line-height: 46px;

		.fas {
			color: $premium-color;
		}
	}
	.premium-info-popover-content {
		background-color: #141414;
		overflow: auto;
		padding: 5px 10px;

		.premium-info-popover-logo {
			margin: 15px auto;
			background-image: url(../img/premium/premium_function_popover.png);
			background-size: contain;
			width: 240px;
			height: 50px;
		}

		.premium-info-popover-title {
			color: #f78709;
			font-weight: 700;
			font-size: 18px;
		}
		.premium-info-popover-description {
			font-weight: 700;
			font-size: 14px;
			color: #eee;
		}
		.button-premium {
			border-radius: 0px;
			padding: 3px 50px;
			margin: 15px 0px;
			font-weight: 700;
		}
	}
}

.match-drop-popover {
	padding: 0px;
	width: 900px;

	.popover-content {
		padding: 0px;
	}

	.match-drop-popover-content {
		background-image: url(../img/premium/background_s1.jpg);
		background-repeat: no-repeat;
		background-position: 50% 20%;
		background-size: 1152px 647px;
		overflow: auto;

		.match-drop-popover-left {
			float: left;
			width: 45%;
			margin: 55px 0px;

			.match-drop-logo-top {
				margin: 0px auto 40px auto;
				width: 245px;
				height: 100px;
			}
			.match-drop-popover-title {
				font-weight: 500;
				font-size: 32px;
			}
			.match-drop-popover-description {
				font-weight: 400;
				padding: 0px 80px;
				font-size: 18px;
			}
			.match-drop-popover-description-premium {
				color: #fe7203;
			}
			.button-premium {
				border-radius: 0px;
				padding: 3px 50px;
				font-weight: 700;
			}
			.button-close {
				display: inline-block;
				cursor: pointer;
				margin-top: 25px;
				text-transform: uppercase;
				font-size: 12px;
				font-weight: 700;
			}

			.match-drop-popover-elgiganten-title {
				padding: 0px 20px;
				font-weight: 500;
				font-size: 22px;
				color: $premium-color;
			}
			.match-drop-popover-elgiganten-description {
				font-weight: 400;
				padding: 15px 80px;
				font-size: 18px;
			}
			.match-drop-popover-elgiganten-powered-by {
				line-height: 35px;
				font-weight: 400;
				font-size: 10px;
				text-transform: uppercase;
			}
			.match-drop-popover-elgiganten-powered-by-logo {
				margin-left: 10px;
				vertical-align: top;
				display: inline-block;
				background-image: url(../img/promotions/elgiganten.svg);
				width: 150px;
				height: 35px;
			}
		}
		.match-drop-popover-right {
			float: right;
			width: 55%;
			height: 381px;
			text-align: left;
			padding: 50px 50px 0px 50px;
			background-color: rgba(0, 0, 0, 0.25);
		}
		.match-drop-popover-user {
			margin-top: 15px;
			font-size: 18px;
			text-align: center;

			.avatar {
				vertical-align: middle;
				width: 34px;
				height: 34px;
				border: 2px solid $primary-color;
				margin-right: 5px;
			}
		}
		.match-drop-popover-item-image {
			margin-top: 15px;
			width: 100%;
			height: 230px;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: 50% 50%;
		}
		.match-drop-popover-item-name {
			text-align: center;
			font-size: 18px;
			margin: 10px 0px;
		}
		.match-drop-popover-item-description {
			background-color: #d64e4e;
			border-radius: 7px;
			text-align: center;
			color: #000;
			line-height: 25px;
			height: 25px;
			padding: 0px 15px;
			max-width: 230px;
			margin: 0px auto;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}

;@import "sass-embedded-legacy-load-done:140";