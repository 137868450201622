.rank-popover {
	width: 450px;
	max-width: 100%;
	padding: 0;

	.popover-content {
		padding: 0 0 15px 0;
	}
}

.rank-popover-info {
	margin-top: 25px;
}
.rank-popover-info .big-rank {
	width: 310px;
	max-width: 100%;
	height: 240px;
	margin: 0 auto;
	background-size: 310px 240px;
}

.rank-popover .rank-list {
	height: 60px;
	margin-top: 20px;
	background-color: #ddd;

	.rank {
		width: 50px;
		height: 38.71px;
		position: relative;
		top: 12px;
		margin: 0 6px;
		background-size: 50px 38.71px;
		display: inline-block;
	}
}
.rank-marker {
	position: absolute;
	height: 60px;

	.top {
		width: 16px;
		height: 16px;
		background-image: url(../img/misc/marker_top.png);
	}

	.bottom {
		position: relative;
		width: 16px;
		height: 16px;
		top: 28px;
		background-image: url(../img/misc/marker_bottom.png);
	}
}

;@import "sass-embedded-legacy-load-done:115";