.report-popover {
	width: 400px;
	max-width: 100%;

	.relevant-rounds-wrapper {
		display: flex;
		justify-content: space-between;

		padding: 10px 0;
		align-items: center;

		border: 2px solid #2d2f3b;
		border-radius: 5px;

		background-color: $content-background-color;

		.relevant-rounds-first,
		.relevant-rounds-last {
			flex: 0 0 3ch;
		}
		.relevant-rounds-selector {
			flex: 1 1 auto;
			padding: 5px;

			.rc-slider-handle {
				background-color: $primary-color;
				border-color: $primary-color;
			}

			/* unselected entities */
			.rc-slider-rail {
				background-color: transparent;
			}
			.rc-slider-dot {
				background-color: #333;
				border-color: #333;
			}

			/* selected entities */
			.rc-slider-track,
			.rc-slider-dot-active {
				background-color: $primary-color;
			}
			.rc-slider-dot-active {
				border-color: $primary-color;
			}
		}
		.relevant-rounds-last {
		}
	}
}

;@import "sass-embedded-legacy-load-done:114";