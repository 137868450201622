.onboarding-popover {
	width: 650px;
	box-shadow: 3px 3px 6px #0000007a;
	border-radius: 5px;
	padding: 0;

	.popover-content {
		padding-bottom: 0;
	}

	.onboarding-popover-content {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0;
		height: 100%;

		.close-button {
			position: absolute;
			right: 1em;
			top: 0;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			border: 1px solid $dark-grey-text-color;
			color: $grey-text-color;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}

		.logo {
			background-image: url(../img/logo.png);
			width: 108px;
			height: 42px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}

		.header {
			font-size: 22px;
			margin-top: 1em;
			margin-bottom: 1em;
		}

		.client-screen,
		.email-screen {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		.rank-screen {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		.client-screen,
		.email-screen {
			padding-bottom: 2em;
		}

		.text {
			color: #aeb6c4;
			width: 70%;
			font-weight: 400;

			.email,
			.client {
				color: #ffffff;
				font-weight: 500;
			}
		}

		.width-container {
			width: 80%;

			.waiting {
				background-color: #292a38;
				border-radius: 4px;
				width: 100%;
				padding: 1em;
				margin-bottom: 2em;

				.dots {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: 1em;

					.dot {
						background-color: #4a4d5e;
						height: 18px;
						width: 18px;
						border-radius: 50%;
						margin: 7px;
						transition: background-color 0.5s ease;

						&.colored {
							background-color: $primary-color;
						}
					}
				}

				.waiting-text {
					color: $primary-color;
					font-size: 19px;
					margin: 5px;
				}

				.check-email {
					color: #aeb6c4;
					font-weight: 400;
				}
			}

			.email-resend {
				background-color: #292a38;
				border-radius: 4px;
				width: 100%;

				.resend-open {
					width: 100%;
					cursor: pointer;
					padding: 1em;

					.fas {
						margin-left: 1em;
					}

					&.open {
						border-bottom: 2px solid #20212d;
					}
				}
				.check-email {
					color: #aeb6c4;
					font-weight: 400;
					padding: 1em;
				}
				.form {
					width: 240px;
					display: flex;
					flex-direction: column;
					align-content: center;
					align-items: center;
					justify-content: center;
					margin: auto;

					.button {
						width: 100%;
						margin-top: 1em;
						margin-bottom: 2em;
						background-color: #1b1d26;
						font-size: 14px;
					}
				}
			}
		}

		.footer-text {
			color: #aeb6c4;
			font-weight: 400;
			font-size: 13px;
		}

		.installing {
			background-color: #292a38;
			border-radius: 4px;
			display: flex;
			padding: 1.5em 1em;
			width: 90%;
			justify-content: space-around;

			.step {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 25%;

				.fas {
					width: 60px;
					height: 60px;
					border-radius: 50%;
					background-color: #434860;
					color: $primary-color;
					font-size: 30px;
					line-height: 60px;
				}
				.name {
					font-size: 20px;
					margin-top: 0.75em;
					margin-bottom: 0.5em;
				}
				.description {
					color: #aeb6c4;
					font-weight: 400;
					font-size: 13px;

					.emphasis {
						color: #ffffff;
						font-weight: 500;
					}
				}
			}
		}
		.ranks {
			width: 100%;
			background-color: #1c1d27;
			padding: 1em;

			.rank-images {
				position: relative;
				display: flex;
				padding-top: 1em;

				.rank {
					padding-top: 1em;
					margin-left: 0.5em;
					margin-right: 0.5em;
					z-index: 2;

					img {
						max-width: 100%;
					}
					&.mute {
						margin-right: 2em;
						margin-left: 2em;
						img {
							//using filter instead of opacity so the line won't be visible behind the rank images
							filter: saturate(39%) brightness(50%);
						}
					}

					.rank-name {
						background: #3a3b4b;
						border-radius: 44px;
						font-weight: 400;
						padding-top: 3px;
						padding-bottom: 3px;
						font-size: 13px;
						margin-top: 5px;
					}
				}
				.preliminary-ranks-background {
					position: absolute;
					background-color: #292a38;
					border-radius: 4px;
					top: 60%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 55%;
					height: 95%;
					z-index: 0;
				}
				.line {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 65%;
					transform: translate(-50%, -50%);
					background: transparent linear-gradient(90deg, #bb881c 0%, #1d9420 13%, #0d47c3 100%);
					height: 1px;
					z-index: 1;
				}
			}
			.text {
				margin: auto;
				margin-top: 2em;
				margin-bottom: 1em;
			}
		}
		.rank-buttons {
			width: 100%;
			background-color: #292a38;
			border-radius: 0 0 5px 5px;
			display: flex;
			justify-content: space-evenly;
			padding: 1em;

			.fas {
				margin-left: 1em;
			}
		}
		.user-sign-up-source {
			padding-bottom: 2em;
			border-radius: 5px;
		}
	}
}

;@import "sass-embedded-legacy-load-done:132";