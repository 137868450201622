.stats-bar {
	width: 100%;
	height: 70px;
	line-height: 70px;
	position: relative;
	background-color: #2a2a33 !important;
}
.stats-bar-item {
	display: inline-block;
	position: relative;
	padding: 0px 10px;
	font-size: 16px;
	float: left;
}

.stats-bar-user {
	width: 170px;
	height: 100%;
	background-color: #1a1d25;
}
.stats-bar-username {
	font-weight: 500;
	font-size: 15px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.stats-bar-user-avatar {
	float: left;
	width: 32px;
	height: 32px;
	background-size: cover;
	background-repeat: no-repeat;
	border: 2px solid $button-color;
	border-radius: 50%;
	margin-right: 10px;
	margin-top: 19px;
}

.stats-bar-rank {
	width: 120px;
	height: 100%;
	background-color: #1a1d25;
}
.stats-bar-rank-number {
	font-weight: 500;
	white-space: nowrap;
}
.stats-bar-rank-icon {
	float: left;
	width: 42px;
	height: 42px;
	background-size: 42px 42px;
	background-repeat: no-repeat;
	margin-right: 10px;
	margin-top: 14px;
}

.stats-bar-games-played {
	width: 120px;
	height: 100%;
}
.stats-bar-games-played-number {
	height: 25px;
	font-size: 25px;
	line-height: 55px;
	text-align: center;
}
.stats-bar-games-played-label {
	font-size: 12px;
	line-height: 40px;
	text-align: center;
}

.stats-bar-games-ratio {
	width: calc(100% - 580px);
	height: 100%;
	max-width: 400px;
	@include mobile() {
		width: 100% !important;
		padding-left: 12px;
		padding-right: 12px;
	}
}
.stats-bar-games-ratio-text {
	padding: 0 5px;
	width: 50px;
	text-align: left;
	font-weight: 500;

	> .percentage {
		height: 25px;
		line-height: 60px;
	}

	> .label {
		height: 45px;
		font-size: 12px;
		line-height: 40px;
	}
}
.stats-bar-games-ratio-bar-container {
	width: calc(100% - 120px);
	height: 30px;
	padding: 0;
	margin: 21px 10px;
	background-color: #ccc;
	border-radius: 4px;

	> .stats-bar-games-ratio-bar {
		height: 100%;
		background-color: $button-color;
		border-radius: 4px 0 0 4px;
	}
}

.stats-bar-dropped {
	width: 145px;
	padding: 0 12px;
	float: right;
}
.stats-bar-dropped-icon {
	float: left;
	width: 32px;
	height: 32px;
	background-image: url(../img/gathers/running-icon-on-transparent-background-hi.png);
	background-size: 32px 32px;
	background-repeat: no-repeat;
	margin-right: 10px;
	margin-top: 19px;
}
.stats-bar-dropped-percentage {
	height: 25px;
	line-height: 55px;
	text-align: center;
	padding: 0 5px;
	font-size: 25px;
	font-weight: 500;
}
.stats-bar-dropped-label {
	line-height: 40px;
	text-align: center;
	font-size: 12px;
}
.stats-bar-dropped-text {
	display: block;
}

;@import "sass-embedded-legacy-load-done:36";