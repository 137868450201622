.latest-results-row-head {
	padding: 20px 0px 8px 0px;
}

.latest-results-title {
	color: $grey-text-color;
	font-size: 14px;
	text-align: left;
	line-height: 20px;
}

.latest-results-info {
	font-size: 12px;
	text-align: left;
	width: 100%;
	line-height: 20px;
	.date {
		color: $dark-grey-text-color;
		text-transform: uppercase;
	}
	.view-summary {
		margin-left: 26px;
		text-transform: uppercase;
		font-weight: 700;
	}
}

.latest-results-teams {
	line-height: 50px;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: flex;
	border-radius: 6px;
	@include mobile() {
		flex-direction: column;
	}

	.divider {
		background-color: #21222d;
		width: 3px;
		height: 50px;
		@include mobile() {
			background-color: transparent;
			width: 100%;
			height: 3px;
		}
	}

	.latest-results-team {
		display: inline-flex;
		flex: 1;
		overflow: hidden;
		vertical-align: bottom;
		background-color: #21222d;
		border-radius: 6px 0 0 6px;
		padding: 0 0 0 10px;
		@include mobile() {
			width: 100%;
			border-radius: 6px;
		}
		.latest-results-team-name {
			display: inline-block;
			vertical-align: bottom;
			text-overflow: ellipsis;
			overflow: hidden;
			margin-left: 8px;
			margin-right: auto;
			padding: 0 10px;
			&.right {
				text-align: right;
				margin-left: auto;
				margin-right: 8px;
				@include mobile() {
					text-align: left;
					margin-left: 8px;
					margin-right: 4px;
				}
			}
		}
		&.right {
			text-align: right;
			border-radius: 0 6px 6px 0;
			padding: 0 10px 0 0;
			@include mobile() {
				text-align: left;
				border-radius: 6px;
				padding: 0 0 0 10px;
			}
		}

		.latest-results-score {
			min-width: 28px;
			text-align: center;
			background-color: #1a1b25;
			line-height: 50px;
			@include mobile() {
				width: 53px;
				margin-left: auto;
				border-radius: 0 6px 6px 0;
			}
		}

		> .winner {
			color: #99be68;
		}

		> .loser {
			color: #c2c3c7;
		}
	}
}

.tournaments-latest-results-wide-row {
	display: flex;
	flex-direction: row;
	border-bottom: 1px solid $dark-grey-border;
	background-color: #161720;
	min-height: 4em;

	&:nth-child(even) {
		background-color: #191a24;
	}

	@include mobile() {
		flex-direction: column;
	}

	.latest-results-wide-row-head {
		width: 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include mobile() {
			align-items: center;
			padding-top: 1em;
			width: 100%;
		}

		.latest-results-wide-title {
			color: #fff;
			font-size: 14px;
		}

		.latest-results-wide-info {
			font-size: 12px;
			margin-top: 2px;
			.date {
				color: $dark-grey-text-color;
				margin-right: 1em;
			}
		}
	}
	.latest-results-wide-teams {
		width: 50%;
		display: flex;
		justify-content: center;

		@include mobile() {
			width: 100%;
		}

		.latest-results-wide-team {
			display: flex;
			align-items: center;
			margin-right: 2em;
			width: 45%;
			overflow: hidden;
			text-overflow: ellipsis;
			justify-content: right;

			&.right {
				justify-content: left;
				margin-left: 2em;
			}

			.latest-results-wide-team-name {
				width: 100%;
				overflow: hidden;
				text-align: right;

				&.right {
					text-align: left;
				}
			}
			.famfamfam-flag {
				margin: 0 0.5em;
			}
		}
		.latest-results-wide-scores {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 1em 0;
			min-width: 5em;
			background-color: #1d1e29;
			width: 10%;

			.divider {
				margin: 0 2px;
			}

			> .winner {
				color: #99be68;
			}

			> .loser {
				color: #c2c3c7;
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:62";