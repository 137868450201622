/* Main container */
.plp {
	margin: 0;
	margin-bottom: -1em;
	flex-grow: 1;
	color: #fff;
}
.plp-inner-wrapper {
	max-width: 1200px;
	margin: 0px auto;
	position: relative;
}
/* Core */
.plp h1,
.plp h2,
.plp h3 {
	clear: both;
}
.plp h1 {
	font-size: 45px;
	padding-top: 0px;
	text-align: center;
	width: 100%;
	text-transform: uppercase;
}
.plp h2 {
	font-size: 35px;
	margin: 0;
	text-align: center;
	text-transform: none;
	line-height: 60px;
	padding-right: 16px;
}
.plp h3 {
	font-size: 18px;
	padding-top: 5px;
	margin-bottom: 10px;
	clear: both;
	text-align: left;
	width: 100%;
}
.plp strong {
	color: inherit;
}
.plp small {
	font-weight: 200;
	font-size: 0.7em;
}
/* Dashes */
.plp-dashes {
	padding-right: 10px;
	font-weight: bold;
}
/* text colors */
.plp-pink {
	color: #8522ff;
}
/* Buttons */
.plp-button {
	min-width: 200px;
	font-weight: bold;
	font-size: 13px;
	padding: 6px 15px;
	border-radius: 4px;
	cursor: pointer;
}
.plp-button-buy-now {
	font-weight: bold;
	text-transform: uppercase;
	font-size: 14px;
	padding: 15px 36px;
	border-radius: 8px;
	color: #fff;
	background-color: #8522ff;
	cursor: pointer;
}
.plp-button.pink {
	color: #fff;
	background-color: #7919e6;
}
.plp-button.pink:hover {
	background-color: #6816c7;
}
.plp-button.blue {
	color: #fff;
	background-color: #13aceb;
}
.plp-button.blue:hover {
	background-color: #0076a3;
}
/* text alignment */
.plp-text-center {
	text-align: center;
}
.plp-text-left {
	text-align: left;
}
.plp-text-right {
	text-align: right;
}
/* section content container */
.plp-content-container {
	max-width: 1200px;
	display: block;
	margin: 0 auto;
}
.plp-content-container p {
	text-align: left;
}
/* list and block */
.plp-content-list {
	display: flex;
	flex-direction: column;

	.plp-content-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $dark-grey-border;
		line-height: 3em;

		&:last-child {
			border-bottom: 0;
		}

		.fas.fa-check {
			padding-right: 1em;
			display: block;
		}
	}
}
table.plp-content-list {
	td {
		text-align: left;
	}
}

.plp-check.fas {
	display: none;
}
/* logos */
.plp-logo {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	clear: both;
	margin: 0 auto;
}
.plp-logo {
	min-width: 273px;
	width: 273px;
	height: 75px;
	margin-bottom: 50px;

	&.premium {
		width: 324px;
		min-width: 324px;
		height: 75px;
	}

	&.no-margin {
		margin-bottom: 0;
	}
}
.plp-logo.black {
	background-image: url(../img/prime/logo_black.png);
}
.plp-logo.white {
	background-image: url(../img/prime/logo_white.png);
}
.plp-logo.purple {
	background-image: url(../img/prime/logo_purple.png);
}
.plp-logo-adyen {
	min-width: 150px;
	width: 150px;
	height: 58px;
	display: block;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-image: url(../img/prime/logo_adyen.png);
	clear: both;
	margin: 10px auto;
}
.plp-login {
	height: 35px;
	width: 255px;
	min-width: 255px;
	display: block;
	margin: 15px auto;
}
/* video wrapper */
.plp-video-wrapper {
	position: relative;
}
.plp-video-wrapper iframe {
	width: 640px;
}

.plp-premium {
	/* new */
	img {
		width: 100%;
		max-width: 100%;
	}
	.columns {
		padding: 30px 0px;
	}
	.column {
		font-weight: 400;
		font-size: 17px;
	}
	.column-title {
		color: #d0904e;
		font-size: 30px;
	}
	.column-text {
		line-height: 34px;
	}

	ul {
		padding: 10px 0px;

		li {
			position: relative;
			margin-left: 30px;
			padding: 5px 0px;
		}
		li::before {
			position: absolute;
			left: -25px;
			top: 50%;
			margin-top: -6px;
			background-color: $premium-color;
			width: 12px;
			height: 12px;
			content: ' ';
		}

		&.plp-premium-prime-list {
			overflow: hidden;

			li {
				float: left;
				width: 40%;
			}
			li::before {
				background-color: $prime-color;
			}
		}
	}

	.plp-premium-section-1 {
		background-position: 50% 0%;
		background-repeat: no-repeat;
		background-color: #100f1b;
		background-image: url(../img/premium/background_s1.jpg);
	}
	.plp-premium-section-2 {
		background-repeat: no-repeat;
		background-color: #05060a;
		background-position: 50% 0%;
		background-image: url(../img/premium/landing/dropback.jpg);
	}
	.plp-premium-section-3 {
		background-color: #0e0f1b;
	}
	.plp-premium-section-4 {
		background-color: #141523;
		line-height: 115px;
		text-align: center;

		h2 {
			display: inline-block;
			vertical-align: middle;
			width: auto;
		}
		.button {
			margin-left: 35px;
			min-width: 160px;
			font-size: 18px;
			font-weight: 700;
			height: 50px;
			padding: 0px 30px;
			line-height: 50px;
			display: inline-block;
		}
	}
	.plp-premium-section-5 {
		padding: 50px 0px 20px 0px;
		background-color: #0e0f1b;

		.column-title {
			color: #fff;
			font-weight: 400;
		}
	}
	.plp-premium-section-5p5 {
		width: 100%;
		padding: 50px 15px 100px 15px;
		text-align: center;
		font-weight: 500 !important;
	}
	.plp-premium-section-6 {
		padding: 30px 0px;
		background-color: #141523;

		.button {
			height: 50px;
			line-height: 50px;
			min-width: 270px;
			padding: 0px 30px;
			font-weight: 700;
			font-size: 18px;
			margin-bottom: 20px;
		}
	}

	.plp-premium-title {
		padding: 30px 10px 0;
		font-size: 28px;
		text-align: center;
		font-weight: 100;
		.premium-color {
			font-weight: 400;
		}

		.premium-logo {
			margin: 15px auto 30px;
			background-image: url(../img/premium/premium_function_popover.png);
			background-size: contain;
			width: 290px;
			height: 60px;
		}
	}

	.plp-offer-box {
		display: inline-block;
		min-width: 370px;
		max-width: 90%;
		padding: 20px 40px;
		background-color: rgba(0, 0, 0, 0.3);

		strong,
		small {
			font-weight: 400;
		}
		h2 {
			font-weight: 500;
			font-size: 40px;
		}
	}
	h3 {
		font-size: 30px;
		text-transform: uppercase;
	}

	.plp-button {
		color: #fff;
	}

	/* old */
	.plp-pink {
		color: #ffaa41;
	}

	.plp-section-2 {
		background-color: $premium-hover-color;
	}
	.plp-section-3 {
		background-color: $premium-color;
	}
	.plp-section-4 {
		background-color: #ffaa41;
	}

	.plp-logo.white {
		background-image: url(../img/premium/logo_white.png);
	}
}

/* section 1 */
.plp-section-1 {
	width: 100%;
	background-color: #0a0428;
	padding: 60px 15px 40px 15px;
	background-image: url(../img/prime/background_s1.jpg);
	background-position: center top;
	background-repeat: no-repeat;
	background-size: 1920px auto;
	text-align: center;
}
.plp-heading {
	font-size: 28px;
	font-weight: 400;
	padding-top: 0;
	text-align: center;
	width: 100%;

	.esportal-logo {
		background-image: url(../img/prime/logo_white_purple.png);
		min-width: 222px;
		width: 222px;
		height: 59px;
		margin: 0 auto;
		margin-bottom: 1em;
	}
}
.plp-section-1p5 {
	width: 100%;
	padding: 50px 15px 20px 15px;
	text-align: left;
}
/* Section 2 */
.plp-section-2 {
	width: 100%;
	padding: 50px 15px 100px 15px;
	text-align: center;

	.is-multiline {
		align-items: center;

		.plp-video-wrapper {
			margin-top: 30px;
		}
	}
}
.plp-section-2 p,
.plp-section-3 p {
	font-size: 16px;
}

.plp-section-2 ul,
.plp-section-3 ul {
	list-style-type: disc;
}
.plp-section-2 li,
.plp-section-3 li {
	margin-left: 20px;
	margin-bottom: 10px;
}
/* Section 3 */
.plp-section-3 {
	width: 100%;
	background-color: #270c65;
	padding: 50px 15px 0px 15px;
	text-align: center;
}
/* Section 4 */
.plp-section-4 {
	width: 100%;
	background-color: #150047;
	padding: 40px 15px 30px 15px;
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	.plp-heading {
		padding: 2em 0;
	}
}
.plp-section-4p5 {
	width: 100%;
	padding: 50px 15px 100px 15px;
	text-align: center;
}
/* Section 5 */
.plp-section-5 {
	width: 100%;
	padding: 50px 15px 100px 15px;
	text-align: center;
}
.plp-image {
	width: 100%;
}
#plp-gather-image {
	content: url(../img/premium/landing/gathers.png);

	&.gathers-sv {
		content: url(../img/premium/landing/gathers.png);
	}

	&.gathers-pl {
		content: url(../img/premium/landing/gathers.png);
	}

	&.gathers-de {
		content: url(../img/premium/landing/gathers.png);
	}

	&.gathers-ua {
		content: url(../img/premium/landing/gathers_ua.png);
	}

	&.gathers-ru {
		content: url(../img/premium/landing/gathers_ru.png);
	}

	&.gathers-pl {
		content: url(../img/premium/landing/gathers_pl.png);
	}
}

#plp-stats-image {
	content: url(../img/premium/landing/stats.png);

	&.stats-sv {
		content: url(../img/premium/landing/stats.png);
	}

	&.stats-pl {
		content: url(../img/premium/landing/stats.png);
	}

	&.stats-de {
		content: url(../img/premium/landing/stats.png);
	}

	&.stats-ua {
		content: url(../img/premium/landing/stats_ua.png);
	}

	&.stats-ru {
		content: url(../img/premium/landing/stats_ru.png);
	}

	&.stats-pl {
		content: url(../img/premium/landing/stats_pl.png);
	}
}

#plp-drops-image {
	content: url(../img/premium/landing/drops.png);

	&.drops-sv {
		content: url(../img/premium/landing/drops.png);
	}

	&.drops-pl {
		content: url(../img/premium/landing/drops.png);
	}

	&.drops-de {
		content: url(../img/premium/landing/drops.png);
	}

	&.drops-ua {
		content: url(../img/premium/landing/drops_ua.png);
	}

	&.drops-ru {
		content: url(../img/premium/landing/drops_ru.png);
	}
}

.plp {
	.comparison-table {
		table-layout: fixed;
		width: 100%;
		color: $grey-text-color;
		td {
			white-space: pre;
		}
		&,
		th,
		td {
			border: 2px solid #0f0f1b;
			white-space: pre-wrap;
		}
		tr:nth-child(even) {
			background-color: #1b1d2a;
		}
		tbody > tr:nth-child(odd) {
			background-color: #161825;
		}

		th:last-child,
		td:last-child {
			text-align: center;
		}

		td:nth-child(2) {
			color: $primary-color;
		}
		td:nth-child(3) {
			color: $prime-color;
		}
		td:last-child {
			color: $premium-color;
		}
		td:first-child {
			width: 40%;
			padding-left: 14px;
		}
		thead {
			td:not(:first-child) {
				padding: 0;
				background-color: #1e202c;
				position: relative;
				background-clip: padding-box;
			}
		}

		.comparison-table-compare-packages {
			color: $premium-color;
			font-size: 22px;
			font-weight: 200;
			margin-left: 14px;
			&.prime {
				color: #fff;
				text-transform: uppercase;
				font-weight: 600;
			}
			.prime-slashes {
				color: $prime-color;
				margin-right: 4px;
				letter-spacing: 2px;
			}

			@include mobile() {
				margin-left: 0;
			}
		}
		.comparison-table-compare-packages-description {
			line-height: 24px;
			margin-left: 14px;

			@include mobile() {
				margin-left: 0;
			}
		}
		.comparison-table-section-heading {
			color: #fff !important;
			background-color: #282c39 !important;
		}
		.comparison-table-header-title {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			white-space: nowrap;
			color: #fff;
			background-color: #292937;
		}
		.comparison-table-price {
			margin-top: 3em;
			color: #fff;
		}
		.comparison-table-basic-price {
			background-color: #1e202c;
			line-height: 62px;
			font-size: 20px;
		}
		.comparison-table-offer-period {
			color: #fff;
			font-size: 12px !important;
			line-height: 12px !important;
		}
		.comparison-table-prime-offer-price {
			display: flex;
			justify-content: center;
			font-size: 12px !important;
			line-height: 20px !important;
		}
		.comparison-table-premium-offer-price {
			display: flex;
			justify-content: center;
			font-size: 12px !important;
			line-height: 20px !important;
		}
		.comparison-table-prime-offer-price-value {
			font-size: 20px !important;
			line-height: 28px !important;
			padding-top: 16px;
		}
		.comparison-table-premium-offer-price-value {
			font-size: 20px !important;
			line-height: 28px !important;
			padding-top: 16px;
		}
		.comparison-table-upgrade-button {
			min-width: 78px;
			padding: 8px 20px;
			font-weight: 500;
			font-size: 12px;
			border-radius: 4px;
			color: #fff;
			display: block;
			margin-top: 1em;
			line-height: initial;

			@include mobile() {
				min-width: 40px;
				padding: 8px 8px;
				font-size: 11px;
			}

			@include tablet-only() {
				min-width: 40px;
				padding: 8px 9px;
				font-size: 12px;
			}

			&.prime {
				background-color: $prime-color;
			}

			&.premium {
				background-color: $premium-color;
			}
		}
		.comparison-table-info-tipsy {
			float: right;
			padding-right: 12px;
			color: #373a49;
		}
	}
	.mobile-full-width {
		@include mobile() {
			width: 100%;
		}
	}
}
.notice {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-align: left;
	background-color: #750008;
	margin-bottom: 0.75rem;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	padding: 20px 35px;
	font-weight: 500;

	@include fullhd() {
		padding-right: 96px;
	}

	.underlined-text {
		text-decoration: underline;
		font-weight: 500;
	}

	&.prime {
		margin-bottom: 0px;
	}

	.notice-icon {
		display: block;
		font-size: 23px;
		margin-right: 15px;
	}
}

;@import "sass-embedded-legacy-load-done:137";