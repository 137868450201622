.create-team-header {
	height: 45px;
	color: #fff;
	font-size: 14px;
	text-align: start;
	line-height: 45px;
}

.create-team-past-teams {
	margin-top: 15px;
}

.create-team-form {
	display: block;
	background-color: #171a22;
	padding: 3rem 1.5rem;

	.logo-upload {
		width: 170px;
		height: 170px;
		border-radius: 100%;
		cursor: pointer;
		transition: 0.2s;
		color: #5a5a5a;
		border-color: #5a5a5a;
		background-size: cover;
		background-position: 50% 50%;
		margin-left: 20px;
		&.no-preview {
			border: 1px dashed;
		}

		&:hover {
			border-color: #000;
			color: #000;
		}

		// Hide the label when not hovering if there's a preview
		&:not(.no-preview) {
			color: #aaaaaa;

			.center-content {
				visibility: hidden;
			}

			&:hover {
				.center-content {
					visibility: visible;
					background-color: rgba(0, 0, 0, 0.7);
					color: #fff;
				}
			}
		}

		.center-content {
			width: 100%;
			height: 100%;
			border-radius: 100%;
			flex-direction: column;
		}

		.fa-image {
			font-size: 45px;
		}

		.description {
			line-height: 26px;
			text-transform: uppercase;
			color: $primary-color;
		}
	}

	.logo-input {
		display: none;
	}

	.inputs-column {
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	.field {
		width: 100%;

		&:not(:first-child) {
			margin-top: 0.5rem;
		}

		.label {
			display: block;
			line-height: 30px;
			font-weight: 600;
			color: $grey-text-color;
		}

		.control {
			display: block;
			width: 100%;
		}
	}

	.fields {
		margin-top: 0.5rem;

		@include tablet() {
			display: flex;
			width: 100%;
			justify-content: space-between;

			> .field {
				width: 45%;
				margin-top: 0;
			}
		}
	}

	.extra-info {
		margin-top: 20px;
	}

	.create-button {
		display: block;
		margin-top: 25px;
		margin-right: auto;
		height: 45px;
		padding: 0 20px;
	}
}

;@import "sass-embedded-legacy-load-done:91";