.activity {
	margin-top: 12px;
	margin-bottom: 12px;
	color: #fff;
	display: flex;
	align-items: center;
}
.activity-icon {
	vertical-align: middle;
	display: inline-block;
	margin-right: 32px;
	margin-left: 0px;
	width: 58px;
	min-width: 58px;
	height: 58px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;

	&.avatar {
		border: 2px solid $button-color;
	}

	@include mobile() {
		margin-left: 10px;
	}
}
.activity-box {
	position: relative;
	vertical-align: middle;
	display: inline-block;
	flex-grow: 1;
	min-height: 58px;
	padding: 15px 15px;
	background-color: #1f212d;
	font-weight: 500;
	border-radius: 10px;

	&::before {
		position: absolute;
		content: ' ';
		top: 50%;
		right: 100%;
		margin-top: -17px;
		width: 17px;
		height: 17px;
		border: 17px solid transparent;
		border-right-color: #1f212d;
	}

	&.premium {
		border-color: #fc8100;
		border-width: 1px;

		&::before {
			border-right-color: #fc8100;
		}

		.activity-title {
			color: #fc8100;
			padding-left: 45px;
			background-image: url(../img/premium/match_drop_logo.svg);
			background-repeat: no-repeat;
			background-size: 36px 20px;
			background-position: 0% 50%;
		}
	}

	@include mobile() {
		width: calc(100% - 115px);
	}

	.activity-match-link {
		margin-top: 1em;
	}
}
.activity-title {
	color: $primary-color;
	font-size: 16px;
}
.activity-time {
	float: right;
	display: flex;
	flex-direction: column;
	font-size: 13px;
	color: #8c95a4;
}
.activity-message {
	padding-top: 5px;
	font-size: 15px;
	white-space: pre-line;
	word-wrap: break-word;
}

;@import "sass-embedded-legacy-load-done:31";