.signupEmailVerified {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	h1 {
		margin-bottom: 16px;
	}

	h2 {
		margin-top: 0;
	}
}

.signupEmailVerifiedIcon {
	width: 100%;
	height: 84px;
	font-size: 84px;
	color: #13aceb;
	text-align: center;
}

.signupEmailVerfiedRedirectLabel {
	margin-top: 32px;
	font-size: 12px;
	font-weight: normal;
	text-align: center;

	> a {
		color: #13aceb;
	}
}
