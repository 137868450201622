.rank-silver {
	background-image: url(../img/ranking/silver.png);
}
.rank-gold-1 {
	background-image: url(../img/ranking/gold1.png);
	&.disabled {
		filter: grayscale(100%) brightness(100%);
	}
}
.rank-gold-2 {
	background-image: url(../img/ranking/gold2.png);
	&.disabled {
		filter: grayscale(100%) brightness(100%);
	}
}
.rank-veteran-1 {
	background-image: url(../img/ranking/veteran1.png);
	&.disabled {
		filter: grayscale(100%) brightness(100%);
	}
}
.rank-veteran-2 {
	background-image: url(../img/ranking/veteran2.png);
	&.disabled {
		filter: grayscale(100%) brightness(100%);
	}
}
.rank-master-1 {
	background-image: url(../img/ranking/master1.png);
	&.disabled {
		filter: grayscale(100%) brightness(250%);
	}
}
.rank-master-2 {
	background-image: url(../img/ranking/master2.png);
	&.disabled {
		filter: grayscale(100%) brightness(250%);
	}
}
.rank-elite-1 {
	background-image: url(../img/ranking/elite1.png);
	&.disabled {
		filter: grayscale(100%) brightness(200%);
	}
}
.rank-elite-2 {
	background-image: url(../img/ranking/elite2.png);
	&.disabled {
		filter: grayscale(100%) brightness(200%);
	}
}
.rank-pro-1 {
	background-image: url(../img/ranking/pro1.png);
	&.disabled {
		filter: grayscale(100%) brightness(200%);
	}
}
.rank-pro-2 {
	background-image: url(../img/ranking/pro2.png);
	&.disabled {
		filter: grayscale(100%) brightness(200%);
	}
}
.rank-legend {
	background-image: url(../img/ranking/legend.png);
	&.disabled {
		filter: grayscale(100%) brightness(200%);
	}
}
.rank-inactive {
	background-image: url(../img/ranking/inactive.png);
}

;@import "sass-embedded-legacy-load-done:56";