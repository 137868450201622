#consent-manager {
	background-color: rgba(0, 0, 0, 0.15);
	bottom: 0;
	left: 50%;
	margin-left: -600px;
	max-width: 1200px;
	max-width: 100%;
	position: fixed;
	z-index: 3000;
}
@media (max-width: 1200px) {
	#consent-manager {
		left: 0;
		width: 100%;
		margin-left: 0px;
	}
}
@include mobile() {
	.consent .button {
		margin-top: 10px;
	}
}
.consent {
	background-color: #fff;
	border: 1px solid #ccc;
	width: 100%;
	font-size: 12px;
	padding: 13px 20px;

	.button {
		width: 100%;
		height: 30px;
		font-weight: 900;
		font-size: 14px;
		line-height: 21px;
		min-height: 21px;
		padding: 5px 15px;
	}
}

;@import "sass-embedded-legacy-load-done:25";