.user-rewind-2021-popover {
	margin: auto;

	.popover-content {
		padding: 0;
		margin-bottom: 1.5rem;
	}

	width: 920px;
	position: relative;
	border-radius: 4px;

	@include mobile() {
		width: 95%;
		padding: 5px;
		max-height: 600px;
		overflow-y: scroll;
	}

	.small-margin {
		margin: 5px 0px;
	}

	.avatar {
		border: 3px solid #00a9ea;

		&.premium {
			border-color: $premium-color;
		}
		&.prime {
			border-color: $prime-color;
		}
	}

	.title {
		text-align: center;
		font-weight: 400;
	}

	.faded {
		opacity: 0.8;
	}

	.highlight {
		color: #ff8020;
		font-weight: 700;
	}

	.popover-close-button {
		width: 200px;

		@include mobile() {
			width: 130px;
		}
	}
}

.user-rewind-2021-popover .header {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 30px;
	font-weight: 400;

	margin: 1rem 0;

	@include mobile() {
		font-size: 20px;
	}

	.avatar {
		width: 35px;
		height: 35px;
		margin-right: 0.6rem;
	}

	.player-name {
		margin-right: 0.2em;
	}

	@include mobile() {
		font-size: 20px;

		.avatar {
			width: 20px;
			height: 20px;
			margin-right: 0.3rem;
		}
	}
}

.user-rewind-2021-popover .subheader {
	font-size: 20px;
	font-weight: 400;

	margin-bottom: 1rem;

	@include mobile() {
		font-size: 14px;
		margin-bottom: 0.5rem;
	}
}

.user-rewind-2021-popover .content {
	display: flex;
	justify-content: space-between;

	@include mobile() {
		flex-direction: column;
	}

	.left {
		width: 50%;

		display: flex;
		flex-direction: column;

		@include mobile() {
			width: 100%;
		}

		.friends {
			background-color: #222530;
			border-radius: 4px;
			padding: 1rem;

			display: flex;
			flex-direction: column;
			font-weight: 400;

			margin-bottom: 0.7rem;

			.friends-title {
				font-size: 18px;
				text-align: left;
				margin-bottom: 0.8rem;
			}

			.avatar {
				width: 50px;
				height: 50px;
				margin-right: 0.5rem;
			}

			.friend-container {
				display: flex;
				align-items: center;

				.name {
					font-weight: 400;
					text-align: left;
					font-size: 20px;
					color: #fff;
				}

				&:not(:last-child) {
					margin-bottom: 0.7rem;
				}
			}

			@include mobile() {
				.avatar {
					width: 30px;
					height: 30px;
					margin-right: 0.5rem;
				}
				.faded {
					font-size: 12px;
				}

				.friend-container {
					.name {
						font-weight: 400;
						text-align: left;
						font-size: 16px;
					}

					&:not(:last-child) {
						margin-bottom: 0.5rem;
					}
				}
			}
		}

		.favorite-weapon-container {
			display: flex;
			flex-direction: column;
			align-items: center;

			background-color: #222530;
			border-radius: 4px;
			padding: 1rem;

			.weapon-stats {
				font-size: 20px;
				font-weight: 400;
				margin-bottom: 1rem;
			}

			.favorite-weapon-icon {
				background-size: contain;
				width: 100px;
				height: 75px;
				background-repeat: no-repeat;
			}

			@include mobile() {
				flex-direction: row;
				padding: 0.5rem;

				.weapon-stats {
					font-size: 16px;
					font-weight: 400;
					margin-bottom: 0.5rem;
				}

				.favorite-weapon-icon {
					background-size: contain;
					width: 60px;
					height: 40px;
					background-repeat: no-repeat;
				}
			}
		}
	}

	.right {
		width: 45%;

		.maps-dominated {
			.title {
				padding: 1rem;
				font-size: 22px;
				font-weight: 400;
				margin-bottom: 1rem;
			}
			.map-list {
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				align-items: center;
				flex: 1;

				.map {
					font-weight: 400;

					&:not(:last-child) {
						margin-bottom: 1rem;
					}

					.map-name {
						font-size: 20px;
					}
					.map-image {
						width: 180px;
						height: 120px;
						background-repeat: no-repeat;
						background-size: contain;
						background-position: 50% 50%;
					}
				}
			}
		}

		@include mobile() {
			width: 100%;

			.maps-dominated {
				.title {
					padding: 0.5rem;
					font-size: 16px;
					margin-bottom: 0.5rem;
				}
				.map-list {
					display: flex;

					justify-content: center;
					align-items: center;
					flex: 1;

					.map {
						display: flex;

						flex-direction: column;
						align-items: center;
						font-weight: 400;
						margin-right: 0.3rem;

						&:not(:last-child) {
							margin-bottom: 0;
						}

						.map-name {
							font-size: 14px;
						}

						.faded {
							font-size: 12px;
						}
					}
				}
			}
		}
	}
}

.user-rewind-2021-popover .played-matches-container {
	padding: 1rem;
	font-size: 22px;
	font-weight: 400;
	margin-bottom: 1rem;

	@include mobile() {
		padding: 0.5rem;
		font-size: 18px;
		font-weight: 400;
		margin-bottom: 0.5rem;
	}
}

;@import "sass-embedded-legacy-load-done:134";