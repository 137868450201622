.button {
	background-color: $button-color;
	padding: 0px 10px;
	cursor: pointer;
	text-align: center;
	min-height: 35px;
	transition: background 0.25s;
	border: 0;
	color: #fff;
	background: linear-gradient(90deg, $primary-hover-color, #007ce9);
	border-radius: 4px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-transform: none;
	font-size: 13px;
	font-weight: 600;
	line-height: normal;

	&:hover {
		background: linear-gradient(90deg, #007ce9, $primary-hover-color);
	}
	&.prime {
		background: linear-gradient(90deg, #8522ff, #3900bf);

		&:hover:not([disabled]) {
			background: linear-gradient(90deg, #3900bf, #8522ff);
		}
	}

	&.premium {
		background: linear-gradient(90deg, #ff8400, #ff4e00);

		&:hover:not([disabled]) {
			background: linear-gradient(90deg, #ff4e00, #ff8400);
		}
	}

	&.rounded {
		border-radius: 17px;
	}
	&.wide {
		width: 100%;
		max-width: 140px;
	}

	> * {
		display: inline-block;
		margin: 0px 2px;
	}

	> .icon-prime {
		margin-top: -2px;
	}
}

.button-verified {
	background-color: $button-color;
	padding: 0px 10px;
	cursor: pointer;
	text-align: center;
	min-height: 35px;
	transition: background 0.25s;
	border: 0;
	color: #fff;
	background: transparent linear-gradient(90deg, #a3dc00 0%, #31b900 100%) 0% 0% no-repeat padding-box;
	border-radius: 4px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-transform: none;
	font-size: 13px;
	font-weight: 600;
	line-height: normal;

	&:hover {
		background: transparent linear-gradient(270deg, #a3dc00 0%, #31b900 100%) 0% 0% no-repeat
			padding-box;
	}

	&.wide {
		width: 100%;
		max-width: 140px;
	}
}

.button-solid {
	background: $primary-color;
}

.button-dark {
	background-color: #1e1e25;
	transition: background-color 0.25s;
	color: $primary-color;
}

.button-dark:hover,
.button-dark:focus {
	background-color: $secondary-hover-color !important;
}

.button:hover,
.button:focus {
	background-color: $primary-hover-color;
}

.button-gradient {
	background: linear-gradient($primary-hover-color, #007cbb);
	transition: box-shadow 0.15s linear;
	border-radius: 4px;
}

.button-gradient:hover {
	box-shadow: 0px 0px 7px 0px #aaa;
}

.button.disabled,
.button:disabled {
	cursor: auto;
	background: #b7b7b7;
}

.button-gradient.disabled,
.button-gradient:disabled {
	background: linear-gradient(#202020, #282828);
}

.button-gradient.disabled:hover {
	box-shadow: none;
}

.button-square {
	border-radius: 0px;
}

.button-small {
	height: 20px;
	line-height: 20px;
	min-height: 20px;
	font-size: 12px;
}

.button-twitch {
	border: 1px solid #5a3e8e;
	background-color: #6c2498;
	transition: box-shadow 0.15s linear;
}

.button-twitch:hover {
	box-shadow: 0px 0px 7px 0px #aaa;
	background-color: #8b23b9;
}

.button-twitter {
	border: 1px solid #14171a;
	background: #1da1f2;
	text-transform: none;
	transition: box-shadow 0.15s linear;
}

.button-twitter:hover {
	box-shadow: 0px 0px 7px 0px #aaa;
}

.button-lemondogs {
	border: 1px solid #422e00;
	background-color: #ecb213;
	transition: box-shadow 0.15s linear;
}

.button-lemondogs:hover {
	box-shadow: 0px 0px 7px 0px #aaa;
	background-color: #ffc00e;
}

.button-join {
	background-color: #f26522;
}

.button-two-tone {
	background: linear-gradient(to bottom, #13aceb 50%, #0b95cd 50%);
}
.button-two-tone.disabled,
.button-two-tone:disabled {
	background: linear-gradient(to bottom, #b7b7b7 50%, #aaa 50%) !important;
	border-color: #202020 !important;
}

.button-ready-check {
	background-color: #739900;

	&:hover {
		background-color: #739900;
	}
}

.button-danger {
	background: #790000;
}
.button-danger:hover,
.button-danger:focus {
	background: #990000;
}

.button-transparent {
	background-color: transparent;
	&:hover,
	&:focus {
		background-color: rgba(0, 0, 0, 0.2);
	}
}

.button-new-style {
	background: transparent;
	border: 2px solid #3f4151;
	text-align: center;
	font-size: 12px;
	padding: 0px 15px;
	border-radius: 4px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	white-space: nowrap;
	text-transform: none;
	font-weight: 600;

	&.primary-color {
		color: $primary-color;

		&:hover {
			color: #fff;
		}
	}
	&.premium-color {
		color: $premium-color;
		border-color: $premium-color;

		&:hover {
			color: $premium-hover-color;
			border-color: $premium-hover-color;
		}
	}
}
.button-new-style:focus {
	background: transparent;
}
.button-new-style:hover {
	background: transparent;
	color: $primary-color;
	border-color: $primary-color;
}
.button-new-style.disabled:hover {
	cursor: default;
	background-color: transparent;
	border-color: #3f4151;
}

.button-new-style-rounded {
	border: 2px solid #3f4151;
	background: transparent;
	color: #fff;
	border-radius: 50px;
	transition: all 0.25s;

	&.big {
		height: 42px;
		line-height: 38px;
	}
	&.thin {
		border-width: 1px;
		line-height: 40px;
	}
	&.primary-color {
		color: $primary-color;
		border-color: $primary-color;

		&:hover {
			color: #fff;
		}
	}
	&.prime-color {
		color: $prime-color;
		border-color: $prime-color;

		&:hover {
			color: #fff;
		}
	}
	&.premium-color {
		color: $premium-color;
		border-color: $premium-color;

		&:hover {
			color: #fff;
			background-color: $premium-color;
		}
	}
}

.button-flex {
	display: flex !important;
	justify-content: center;
	align-items: center;
	line-height: normal !important;

	> * {
		display: inline-block;
		margin: 0px 2px;
	}

	> .icon-prime {
		margin-top: -2px;
	}

	&.inline-flex {
		display: inline-flex !important;
	}
}

;@import "sass-embedded-legacy-load-done:17";