.verifyEmailRoot {
	h1 {
		margin: 0 0 36px 0;
	}
}

.skipButton {
	width: 200px;
	margin-top: 32px;
}

.resendContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	opacity: 0;
	visibility: hidden;

	transition:
		opacity 0.2s linear 0s,
		visibility 0s linear 0.2s;
}

.resendContainerShow {
	opacity: 1;
	visibility: visible;
	transition:
		opacity 0.2s linear 0s,
		visibility 0s linear 0s;
}
