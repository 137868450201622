.user-profile {
	color: #181922;
	margin-bottom: 1rem;
	background-color: #191a23;
	border-radius: 15px;

	@include mobile() {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 2em;
		margin-bottom: 0;
	}

	.invite-1v1-tipsy-root {
		width: 100%;
	}
}

.user-profile-premium-banner {
	background-color: rgba(222, 118, 32, 0.8);
	color: #fff;
	height: 50px;
	width: 100%;
	line-height: 50px;
	padding: 0px 25px;
	font-size: 25px;
	border-radius: 4px 4px 0 0;

	@include mobile() {
		border-radius: 0;
	}

	.icon-premium {
		float: right;
		margin-top: 5px;
	}
}

.user-profile-prime-banner {
	background-color: rgba(87, 0, 162, 0.8);
	color: #fff;
	height: 50px;
	width: 100%;
	line-height: 50px;
	padding: 0px 25px;
	font-size: 25px;
	border-radius: 4px 4px 0 0;

	@include mobile() {
		border-radius: 0;
	}

	.icon-prime {
		float: right;
		margin-top: 5px;
	}
}

.user-profile-basic-info {
	background-color: #222330;
	padding: 20px 0px 0px 0px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;

	@include mobile() {
		width: 100%;
		display: flex;
		flex-direction: column;
		// iOS flex-direction: column fix
		display: -webkit-box;
		display: -ms-flexbox;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
}
.user-profile-info-mobile-friend-button {
	position: absolute;
	width: 50px;
	height: 50px;
	background: $button-color;
	top: 12px;
	left: 3%;
	font-size: 20px;
	line-height: 50px;
}

.user-profile-basic-stats {
	background-color: #191a23;
	padding-top: 8px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

.user-profile-basic-stats-tablet {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	.user-profile-lls-lws-container {
		width: 65%;
		height: 100%;
	}
	.user-profile-results {
		height: auto;
		margin-top: 0px;
	}
	.user-profile-thumbs {
		width: 35%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		font-size: 22px;
		color: $primary-color !important;
		line-height: 22px;
		.section {
			width: 50%;
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			> .fas {
				color: $grey-text-color !important;
				margin: 4px 8px 4px 4px;
			}

			.mvps {
				font-size: 22px !important;
				margin: 4px 4px 4px 8px;
			}
		}
	}
}

.user-profile-basic-stats-mobile {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	.user-profile-lls-lws-container {
		width: 100%;
		height: 100%;
	}
	.user-profile-thumbs {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		font-size: 22px;
		color: $primary-color !important;
		line-height: 22px;
		justify-content: space-between;
		margin-top: 12px;
		.section {
			width: 32%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			background-color: $card-background-color;
			text-align: center;
			min-width: initial;
			justify-content: center;
			height: 76px;
			padding-top: 0;
			padding: 12px;
			> .fas {
				color: $grey-text-color !important;
				margin: 4px 8px 4px 4px;
			}

			.mvps {
				font-size: 22px !important;
				margin: 4px 4px 4px 8px;
			}
		}
	}
}

.user-profile-avatar {
	width: 100px;
	height: 100px;
	border: 4px solid #cfcfcf;
	position: relative;
	margin-bottom: 10px;

	@include tablet() {
		width: 240px;
		height: 240px;
		margin-top: 15px;
		margin-left: calc((100% - 240px) / 2);
	}

	div,
	span {
		@include mobile() {
			display: none;
		}
	}
}
// .user-profile-avatar-rank {
// 	position: absolute;
// 	top: 100%;
// 	left: 50%;
// 	margin-top: -37px;
// 	margin-left: -47.14px;
// 	width: 94.29px;
// 	height: 73px;
// 	z-index: 1;
// 	background-size: contain;
// 	background-repeat: no-repeat;
// 	transition: transform 0.25s ease-in-out;
// }
// .user-profile-avatar-rank:hover {
// 	transform: scale(1.75) translateY(-15px);
// }
.user-profile-favorite-weapon,
.user-profile-favorite-map,
.user-profile-steam-link,
.user-profile-twitch-link,
.user-profile-region,
.user-profile-lemondogs-link,
.user-profile-twitter-link {
	position: absolute;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 39px;
	height: 39px;
	border: 4px solid $primary-color;
	bottom: -5px;
	background-color: #fff;

	.prime & {
		border-color: $prime-color !important;
	}
	.premium & {
		border-color: $premium-color !important;
	}
}
.user-profile-favorite-weapon {
	width: 56px;
	height: 56px;
	left: 0;
	bottom: 10px;
	background-color: #7d8f9f;
	background-size: contain;
	box-shadow: inset 0px 0px 30px 2px #4f5c6a;
}
.user-profile-favorite-map {
	width: 56px;
	height: 56px;
	right: 0;
	bottom: 10px;
	background-size: cover;
}
.user-profile-steam-link {
	bottom: auto;
	top: 18px;
	left: 6px;
	background-size: 28px;
	background-color: #000;
	background-image: url(../img/misc/steam-logo.png);
}
.user-profile-twitch-link {
	bottom: auto;
	top: -11px;
	left: 39px;
	background-size: contain;
	background-color: #5a3e8e;
	color: #fff;
	font-size: 20px;
	text-align: center;
	line-height: 52px;
	width: 40px;
	height: 40px;

	.fa-twitch {
		position: relative;
		top: -8px;
	}
}
.user-profile-twitter-link {
	bottom: auto;
	top: 4px;
	right: 24px;
	background-size: contain;
	background-color: #1da1f2;
	color: #fff;
	font-size: 20px;
	text-align: center;
	line-height: 52px;
	width: 40px;
	height: 40px;

	.fa-twitter {
		position: relative;
		top: -8px;
	}
}

.user-profile-region {
	background-size: cover;
	top: -25px;
	left: 81px;
	z-index: 1;
	width: 40px;
	height: 40px;
}

.user-profile-lemondogs-link {
	right: -26px;
	bottom: 74px;
	width: 56px;
	height: 56px;
	background-image: url(../img/lemondogs_head.png);
	background-size: 52px;
	background-position-x: -7px;
	background-position-y: 6px;
	background-color: #fcd200;
}

.user-profile-username {
	margin: 12px 0px;
	text-align: center;
	font-weight: 500;
	font-size: 18px;

	.caret-username-history {
		user-select: none;
		display: inline-block;
		padding-top: 10px;
		top: 2px;
		position: relative;
		font-size: 19px;
		margin-left: 2px;
	}
}
.user-profile-country {
	display: inline-block;
	margin-right: 4px;
}

.user-profile-action-buttons {
	width: 100%;
	padding: 8px 0;
	text-align: center;

	.button {
		margin: auto auto 8px;
		width: 80%;
		display: flex;
	}

	.button:last-child {
		margin-bottom: 0;
	}

	.invite-1v1-challenge-button {
		margin-bottom: 0;
	}
}

.user-profile-thumbs {
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	color: $primary-hover-color;
	padding: 12px;
	padding-top: 0px;
	line-height: 30px;

	> .fas {
		color: #000;
		font-size: 26px;
		margin: 0px 5px 0px 15px;
	}

	> .mvps {
		margin-left: 5px;
		font-size: 16px;
		color: #313232;
		font-weight: 500;
	}
}

.user-profile-results {
	position: relative;
	margin-top: 20px;
	margin-bottom: 20px;
	height: 90px;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	color: #000;
	width: initial;

	@include mobile() {
		width: 100%;
	}
}
/*.user-profile-lws, .user-profile-lls {
	position: absolute;
	top: 30px;
	width: 60px;
	font-size: 11px;
	font-weight: 500
}
.user-profile-lws>.fa-trophy {
	color: #2877a4
}
.user-profile-lls>.fa-trophy {
	color: #b7d5e3
}
.user-profile-lls {
	right: 0;
}
.user-profile-wins, .user-profile-losses, .user-profile-win-streak, .user-profile-lose-streak {
	position: absolute;
	width: calc(50% - 60px)
}
.user-profile-wins, .user-profile-losses {
	top: 30px;
	height: 29px;
	line-height: 29px;
	font-weight: 500
}
.user-profile-win-streak, .user-profile-lose-streak {
	top: 59px;
	font-size: 10px;
	height: 19px;
	line-height: 19px;
	font-weight: 400
}
.user-profile-wins {
	left: 60px;
	background-color: #5ec76c
}
.user-profile-losses {
	right: 60px;
	background-color: #e4454b
}
.user-profile-win-streak {
	left: 60px;
	background-color: #63d487
}
.user-profile-lose-streak {
	right: 60px;
	background-color: #e96373
}
*/
.user-profile-friend-list {
	margin-bottom: 15px;
}

.user-profile-friend-list-header {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	color: #fff;
	min-height: 42px;
	font-size: 18px;
	font-weight: 600;
	padding: 2px 8px;

	.flex-grow {
		margin-right: 5px;
	}
	.friend-request {
		margin: 5px 0px;
	}
}
.user-profile-friend-list-body {
	max-height: 368px;
	overflow: auto;
	border-radius: 15px;

	.friend-request {
		margin-right: 10px;
	}
}
.user-profile-friend-list-empty {
	text-align: center;
	height: 40px;
	line-height: 40px;

	@include tablet() {
		background-color: $content-background-color;
	}
}

.user-profile-blocked-list {
	margin-bottom: 0px;
}

.user-profile-blocked-list-body {
	max-height: 368px;
	overflow: auto;
	display: flex;
	flex-direction: column;

	@include mobile() {
		display: flex;
		flex-wrap: wrap;
		max-height: none;

		.blocked {
			background-color: #eee;

			&:nth-child(4n),
			&:nth-child(4n + 1) {
				background-color: #fff;
			}
		}
	}
}
.user-profile-blocked-list-empty {
	text-align: center;
	height: 40px;
	line-height: 40px;

	@include tablet() {
		background-color: $content-background-color;
	}
}

.user-profile-rank-rating {
	display: inline-flex;
	height: 64px;
	font-size: 16px;
	font-weight: 500;
	margin-top: 5px;
	margin-bottom: 5px;
	vertical-align: top;
	text-transform: uppercase;
	flex-shrink: 0;
	flex-wrap: wrap;
	width: 50%;
	justify-content: space-between;

	@include mobile() {
		margin: 0;
		font-size: 15px;
		width: 100%;
		height: auto;
	}

	@include tablet() {
		margin-top: 0px;
		margin-bottom: 15px;
		width: 100%;
		justify-content: flex-start;
	}

	.section {
		display: inline-flex;
		align-items: center;
		justify-content: flex-start;
		margin-right: 16px;

		&.is-center {
			justify-content: center;
			width: 30%;
		}

		&.is-right {
			justify-content: flex-start;
			margin-left: 0px;
			margin-right: 0px;
			width: 18%;
		}

		.label {
			margin-right: 8px;
		}

		@include tablet-only() {
			&.is-center {
				justify-content: center;
			}

			&.is-right {
				justify-content: flex-end;
				margin-left: auto;
				margin-right: 0px;
			}

			.label {
				margin-right: 8px;
			}
		}

		@include mobile() {
			justify-content: center;
			align-content: center;
			align-items: center;
			padding-right: 0px;
			padding-bottom: 20px;
			flex-direction: row;
			margin-right: 0px;
			width: 41%;
			flex-wrap: nowrap;
			.label {
				padding-right: 8px;
			}
			&.is-center {
				justify-content: center;
			}

			&.is-right {
				width: 18%;
			}
		}
	}
}

.user-profile-rank {
	display: inline-block;
	vertical-align: middle;
	height: 53px;
	width: 53px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.user-profile-skill {
	display: inline-block;
	vertical-align: middle;
	height: 64px;
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;

	&.skill0 {
		width: 79px;
	}
	&.skill1 {
		width: 106px;
	}
	&.skill2 {
		width: 63px;
	}

	@include mobile() {
		height: 40px;

		&.skill0 {
			width: 49px;
		}

		&.skill1 {
			width: 66px;
		}

		&.skill2 {
			width: 39px;
		}
	}
}

.user-profile-rating {
	@include mobile() {
		line-height: 40px;
		padding-right: 8px;
	}
}

.user-profile-level {
	width: 41px;
	height: 40px;
	background-size: cover;
}

.user-profile-stats-summary-stats-section {
	display: flex;
	flex-wrap: wrap;

	@include mobile() {
		margin-bottom: 16px;
	}
}
.user-profile-stats-boxes {
	display: flex;
	justify-content: space-between;
	width: 70%;

	@include mobile() {
		flex-wrap: wrap;
		width: 100%;
	}

	@include tablet-only() {
		flex-wrap: wrap;
		width: 100%;
	}
}
.user-profile-stats-box {
	border-radius: 4px;
	padding: 15px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 24%;
	min-width: 90px;
	background-color: $secondary-color;
	color: #fff;

	@include tablet-only() {
		flex-wrap: wrap;
		height: 116px;
		width: 24%;
	}

	@include mobile() {
		min-width: initial;
		justify-content: center;
		height: 76px;
		width: 100%;
		padding: 20px;
	}
}
.user-profile-stats-box-icon {
	width: 41px;
	height: 40px;
	background-size: cover;
	background-repeat: no-repeat;
	margin-right: 10px;
	margin-bottom: 5px;

	@include mobile() {
		width: 50%;
		height: 45px;
		background-size: 45px 45px;
		background-position-x: 50%;
	}
}
.user-profile-stats-box-value {
	font-size: 22px;
	font-weight: 500;
	word-wrap: break-word;
	color: $primary-color;
	text-align: center;

	@include mobile() {
		width: 50%;
		font-size: 23px;
	}

	.label {
		text-transform: uppercase;
		font-weight: 700;
		font-size: 10px;
		color: #fff;
	}
}

.user-profile-stats-boxes.mobile {
	grid-template-columns: 49% 49%;
	grid-template-rows: 1fr 1fr;
	display: grid;
	gap: 12px;
	justify-items: center;
	align-items: center;
	@include mobile() {
		padding: 12px;
		padding-bottom: 0px;
	}
}

.user-profile-view-all-buttons {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 29%;
	padding-left: 8px;
	@include tablet-only() {
		flex-direction: row;
		height: auto;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-top: 8px;
	}
	@include mobile() {
		flex-direction: row;
		height: auto;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-top: 8px;
	}
}

.user-profile-view-all-button {
	width: 100%;
	min-width: 120px;

	& + .button {
		margin-left: 0;
		margin-top: 10px;
	}

	.label {
		font-size: 11px;
	}

	@include mobile() {
		margin: 0;
		width: 100%;

		&:first-child {
			margin-right: 10px;
		}
	}

	@include tablet-only() {
		margin-top: 0px !important;
		width: 49%;

		&:first-child {
			margin-right: 10px;
		}
	}
}

.user-profile-heading {
	margin-top: 10px;
	font-size: 20px;
	margin-bottom: 15px;

	@include mobile() {
		margin-bottom: 0;
		padding: 0.3rem 0.5rem;
		color: #fff;
	}
}

.user-profile-activity-list {
	position: relative;
}
.user-profile-activity-list-stripe {
	position: absolute;
	width: 12px;
	top: 0;
	bottom: 84px;
	left: 30px;
	background-image: url(../img/timeline.png);
	z-index: -1;

	@include mobile() {
		left: 40px;
	}
}

.user-profile-old-usernames {
	z-index: 10;
	width: 300px;
	margin: 16px auto;
	margin-bottom: 16px;
	border-collapse: collapse;
	box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.5);
	background-color: #1f202d;
	position: absolute;
	transition: 250ms opacity;
	top: 15px;
	table-layout: fixed;

	&.hidden {
		opacity: 0;
		pointer-events: none;
	}

	> .heading {
		text-align: center;
		font-size: 16px;
		font-weight: 500;
		color: #fff;
		background-color: #1f202d;
		padding: 8px;
	}

	.table-container {
		display: block;
		max-height: 40vh;
		overflow-y: auto;
	}

	th {
		text-align: left;
		color: #fff;
		background-color: #252635;
		font-weight: 500;
	}

	tr,
	td {
		text-align: left;
		background-color: #252635;
		border-bottom: 1px solid $dark-grey-border;
		font-weight: 400;
		padding-left: 8px;
	}

	@include mobile() {
		width: 250px;
		left: -125px;
	}
}

.user-profile-level-container {
	padding: 16px;
	background-color: $content-background-color;
	margin-bottom: 16px;
}
.user-profile-level-list {
	text-align: center;
	margin: auto;

	@include mobile() {
		display: flex;
		flex-wrap: wrap;
	}
}
.user-profile-level-list-entry {
	display: inline-block;
	text-align: center;
	margin-left: 7px;
	width: calc(10% - 7px); // (10 levels in a row) - margin

	@include mobile() {
		width: 20%;
		margin-left: 0;
	}

	.entry-level {
		width: 52px;
		height: 49px;
		margin: 0 auto 10px auto;
		background-size: cover;

		&.locked {
			filter: grayscale(1) opacity(0.5);
		}
	}

	.entry-missions {
		text-align: center;
		width: 46px;
		height: 14px;
		margin: 0 auto;
		border-radius: 8px;
	}

	.entry-mission {
		display: inline-block;
		vertical-align: top;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: #c0c0c0;
		margin-top: 3px;
		margin-left: 5px;

		&.completed {
			background-color: #fece00;
		}
	}

	.entry-mission:first-child {
		margin-left: 0;
	}
}
.user-profile-mission-title {
	font-weight: 500;
	margin-bottom: 8px;
}
.user-profile-mission-container {
	vertical-align: top;
	display: inline-block;
	width: 100%;

	&.reward {
		width: calc(100% - 140px);
	}
}
.user-profile-mission {
	width: 100%;
	height: 30px;
	margin-bottom: 2px;
	font-size: 14px;
	line-height: 30px;
	border-bottom: 1px solid #292a34;
}
.user-profile-mission-text {
	vertical-align: top;
	display: inline-block;
	width: calc(100% - 34px);
	height: 100%;
	margin-right: 4px;
}
.user-profile-mission-label {
	display: inline-block;
	width: 82px;
	padding-left: 10px;
	font-weight: 500;
}
.user-profile-mission-completed {
	vertical-align: top;
	display: inline-block;
	width: 30px;
	height: 100%;
	font-size: 18px;
	text-align: center;
}
.user-profile-level-reward {
	vertical-align: top;
	display: inline-block;
	margin-left: 10px;
	width: 130px;
	font-size: 12px;
	text-align: center;

	@include mobile() {
		width: 100%;
		margin-left: 0;
	}
}
.user-profile-level-reward-trophy {
	font-size: 40px;
	color: $gold-color;
}
.user-profile-level-reward-title {
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 500;
	margin: 4px 0;
}
.user-profile-level-tipsy-content {
	min-width: 236px;
	padding: 14px;
	text-align: left;
}
.user-profile-level-tipsy {
	min-width: 236px;
}
.user-profile-tipsy-mission-text {
	padding-bottom: 12px;

	&.completed {
		color: $gold-color;
	}
}
.user-profile-tipsy-mission-text:last-child {
	padding-bottom: 0;
}
.user-profile-tipsy-reward {
	padding: 0 12px;
	height: 34px;
}
.user-profile-tipsy-reward-icon {
	font-size: 34px;
	float: left;
	margin-right: 4px;
	color: $gold-color;
}
.user-profile-tipsy-reward-text {
	float: right;
	width: 75%;

	&.completed {
		color: $gold-color;
	}
}
.user-profile-tournament-achievements-header {
	width: 100%;
	height: 44px;
	color: #fff;
	font-size: 18px;
	text-align: center;
	line-height: 44px;
	background-color: $primary-color;
	margin-bottom: 5px;
}

.user-profile-ban-info {
	background-color: #c30000;
	color: #fff;
	text-transform: uppercase;
	padding: 12px 12px;
	font-weight: 600;
}

.user-profile-smurf-info {
	background-color: #ffdd00;
	color: black;
	text-transform: uppercase;
	padding: 12px 12px;
	font-weight: 600;

	.Tipsy-inlineblock-wrapper {
		text-transform: none;

		.fa-question-circle {
			color: black;
			margin-left: 1em;
			font-size: 16px;
		}
	}

	&.admin {
		color: #fff;
		text-transform: none;
		background-color: #13aceb;

		.link {
			color: #fff;
			text-decoration: underline;
		}
	}
}

.user-stats-latest-matches {
	td {
		border-top: none !important;
		border-left: none !important;
		border-right: none !important;
		border-bottom: 1px solid $dark-grey-border !important;
		border-color: $dark-grey-border !important;
	}

	th {
		background-color: #01051390;
		color: $grey-text-color;
	}

	color: $grey-text-color;

	.star-gather-match {
		color: $premium-color;
	}
	.verified-gather-match {
		color: #b4e900;
	}
}

.user-stats-view-latest-match {
	min-width: 54px;
	min-height: 26px;
	line-height: 26px;
	padding: 0 4px;
	font-size: 12px;
}

.user-profile-team-info-header {
	color: #fff;
	line-height: 20px;
	font-size: 13px;
	font-weight: 600;
	margin-top: 24px;
	text-transform: uppercase;
}

.user-profile-team-info > .content {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: column;
	padding-top: 28px;
	padding-bottom: 28px;
	margin-left: 4px;
	margin-right: 4px;
	border-radius: 4px;

	.team-info-logo {
		width: 90px;
		height: 90px;
		border-radius: 50%;
		border: 3px solid $primary-color;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.team-info-name {
		margin-bottom: 24px;
		font-size: 16px;
		color: $primary-color;
	}

	.team-info-no-team {
		font-size: 18px;
		margin-bottom: 15px;
	}

	.button {
		min-width: 80%;
	}
}

.user-profile-nel-data {
	margin: 16px 0;
	text-align: center;

	.nel-logo {
		display: inline-block;
		width: 35px;
		height: 35px;
		vertical-align: middle;
		background-image: url(../img/nel/nel_logo.png);
		background-size: 100% 100%;
	}

	.region-name {
		margin-left: 10px;
		font-weight: 700;
		font-size: 17px;
		vertical-align: middle;
	}
}

.user-profile-profile-column {
	height: auto !important;
}
.user-profile-friends-column {
	height: auto !important;
}

;@import "sass-embedded-legacy-load-done:73";