// This files main concern is NEL-specific styling.

.scrollable-wrapper {
	overflow: auto;
	padding: 0 1px;

	.scrollable-container {
		min-width: 820px;

		@include mobile() {
			min-width: 750px;
		}
	}
}

.nel-content {
	width: 100%;
	background-color: #14151d;
	text-align: center;

	.nel-content-inner {
		margin: auto;
	}

	.inner-wrapper {
		min-height: 600px;

		@include fullhd() {
			min-height: 1100px;
		}

		@media (max-width: 1000px) {
			min-height: auto;
		}
	}
	.center-column {
		@include tablet() {
			width: 100%;
		}
	}
	.nel-landing-banner {
		padding-top: 31%;
		position: relative;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		background-image: url(../img/nel/banner_landing.jpg);

		&.nord {
			background-image: url(../img/nel/banner_landing.jpg);
		}

		&.ost {
			background-image: url(../img/nel/banner_landing.jpg);
		}

		&.vast {
			background-image: url(../img/nel/banner_landing.jpg);
		}

		&.syd {
			background-image: url(../img/nel/banner_landing.jpg);
		}
	}
	.nel-omen-banner-holder {
		width: 100%;
		display: flex;
		justify-content: center;

		.nel-omen-banner {
			width: 740px;
			height: 100px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			background-image: url(../img/nel/banner.jpg);

			@include mobile() {
				width: 370px;
				height: 50px;
			}
		}
	}

	.nel-logged-out-right-banner {
		background-image: url(../img/nel/banner_secsgo.png);
		padding-top: 240%;
		background-size: cover;
		width: 100%;
		display: block;
	}
	.nel-icons-container {
		position: absolute;
		right: 10px;
		top: 10px;

		display: flex;
		flex-direction: row-reverse;

		@include mobile() {
			right: 10px;
			top: 0px;
			flex-direction: column;
		}
	}
	.nel-icon {
		display: inline-block;
		margin: 10px;
		padding: 10px;

		font-size: 20px;
		width: 44px;
		height: 44px;
		border-radius: 50%;
		text-align: center;
		cursor: pointer;
		background-color: $button-color;
		color: #ffffff;

		@include mobile() {
			width: 30px;
			height: 30px;
			margin: 2px;
			padding: 5px;
			font-size: 18px;
		}
	}
	.nel-icon:hover {
		background-color: $button-hover-color;
	}
	.nel-latest-news {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.entry {
			width: 32%;
			@include mobile() {
				width: 100%;
				margin: 0px 15px;
				&:not(:last-child) {
					margin-bottom: 24px;
				}
			}
		}
	}
	.nel-team-box {
		padding: 7px;
		text-align: center;
		font-size: 12px;
		background-color: #f5f5f5;
		border: #dddddd 2px solid;
		color: #0076a3;
		padding: 40px 0px;
	}
	.nel-team-box-no-team {
		line-height: 30px;
	}
	.nel-team-box-signed-in {
		line-height: 25px;
	}
	.nel-team-box-obs {
		font-size: 50px;
	}

	.nel-team-box-shield {
		font-size: 65px;
		margin-bottom: 10px;
		color: #0076a3;
	}
	.nel-team-box-logo {
		display: inline-block;
		background-size: cover;
		background-repeat: no-repeat;

		width: 30px;
		height: 30px;

		vertical-align: middle;
		margin-right: 8px;
	}
	.nel-team-box-team-name {
		font-size: 14px;
		font-weight: 500;
	}
	.nel-team-box-region-name {
		font-size: 20px;
		text-transform: uppercase;
	}
	.nel-right-heading {
		width: 100%;
		font-size: 15px;
		text-align: center;
		padding: 10px;
		font-weight: 500;
		background-color: $primary-color;
		color: #fff;
	}
	.nel-center-heading {
		font-size: 16px;
		font-weight: 400;
		padding-left: 5px;
	}
	.nel-team-box-condition {
		text-align: left;
		display: block;
		color: #f26522;
		margin: 0px 20px;
	}
	.nel-team-box-conditions {
		text-align: left;
		i {
			margin-right: 3px;
		}
	}
	.nel-team-box-city-name {
		display: inline-block;
		margin-bottom: 10px;
	}
	.nel-sign-up-popup {
		z-index: 20;
		$popup-width: 400px;
		$popup-height: 280px;

		position: fixed;
		text-align: center;
		width: $popup-width;
		height: $popup-height;
		line-height: 30px;

		top: 50%;
		left: 50%;

		margin-left: calc($popup-width / -2);
		margin-top: calc($popup-height / -2);

		background: #ffffff;
	}
	.nel-sign-up-popup-head {
		line-height: 40px;
		text-transform: uppercase;
		background-color: #0076a3;
		color: #ffffff;
	}
	.nel-sign-up-popup-body {
		border: 2px #dddddd solid;
		border-top: 0px;
		height: 100%;
		background-color: #ffffff;
	}
	.nel-sign-up-popup-cancel {
		cursor: pointer;
		color: #ff2d2d;
	}
	.nel-sign-up-popup-sign-up {
		display: inline-block;
		background-color: $button-color;
		color: #ffffff;
		padding: 5px 20px 5px 20px;
		margin: 25px 0px 10px 0px;
		cursor: pointer;
		font-weight: 500;
		text-transform: uppercase;
	}
	.nel-sign-up-popup-sign-up:hover {
		background-color: $button-hover-color;
	}
	.nel-region-tab-parent {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
	}
	.nel-region-tab {
		width: 20%;
		padding: 25px;
		font-size: 20px;
		text-align: center;
		background-color: #031d28;
		color: $button-color;

		@include mobile() {
			width: 100%;
			font-size: 18px;
			padding: 10px;
			text-align: left;
		}

		&.active {
			background-color: $primary-color;
			color: #ffffff;
		}
	}
	.nel-region-tab-selector {
		text-transform: uppercase;

		.fas {
			margin-left: 20px;
			font-size: 15px;
		}
	}
	.nel-region-subtab.active {
		color: $button-color;
	}
	.nel-region-subtab {
		cursor: pointer;
		display: inline-block;
		padding: 0px 40px 0px 40px;
		height: 70%;
		vertical-align: middle;
		color: #ffffff;
	}
	.nel-region-innner-subtab {
		vertical-align: top;
		line-height: 40px;
	}
	.nel-region-subtab:not(:first-child) {
		border-left: 1.99px #46ccff solid;
		margin-right: 40px;
		padding-right: 0px;
	}

	.nel-region-subtabs {
		display: inline-block;
		background-color: #006991;
		line-height: 40px;
		min-height: 40px;
	}
	.nel-teams-players {
		tr {
			height: 12px;
			width: 100%;
		}
		th {
			background-color: #0d729e;
			text-transform: uppercase;
			line-height: 12px;
			font-size: 12px;
			padding: 5px;
		}
	}
	.nel-teams-stats {
		overflow: auto;
	}
	.nel-teams-misc-names {
		color: $button-color;
	}
	.nel-teams-average-rating-value {
		color: $button-color;
	}
	.nel-qualifier-sign-up-container {
		text-align: center;
		padding: 15px;
		background-color: #006991;
		color: #fff;

		.button {
			margin-left: 15px;
			padding: 0 40px;
		}
	}
	.nel-teams-player-favorite {
		vertical-align: middle;
		margin-top: 0px;
		display: inline-block;
		width: 39px;
		height: 25px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% 50%;
	}
	.nel-table-view-tournament {
		padding: 0 35px;
		line-height: 25px;
		min-height: 25px;

		&.sign-up {
			background-color: #f26522;

			&:hover,
			&:focus {
				background-color: #c4490f;
			}
		}
	}
	.nel-detailed-qualifiers-list {
		tr {
			line-height: 28px;
		}
		tbody > tr:not(:last-child) {
			border-bottom: 30px solid transparent;
		}
	}
}

// NEL-qualifier list specific things.
.nel-qualifier-list {
	th {
		background-color: #006991;
		color: #fff;
	}
}
.nel-qualifier-list-logo {
	display: inline-block;
	background-size: cover;
	background-repeat: no-repeat;

	width: 30px;
	height: 30px;

	vertical-align: middle;
	margin-right: 8px;
}

.nel-teams-entry {
	border: #292a34 solid 1px;
	td:first-child {
		word-break: break-all;
		line-height: 1.2;
	}
}
.nel-teams-entry:nth-child(odd) {
	background-color: #191a24b3;
}
.nel-status-col {
	text-transform: capitalize;
}
.nel-accepted {
	color: #96ea00;
}
.nel-pending {
	color: #a61228;
}

.nel-teams-toggle-col .nel-teams-toggle {
	font-size: 15px;
	color: $button-color;
	cursor: pointer;
}
.nel-teams-expanded {
	padding: 0px;
	background-color: $secondary-color;
}
.nel-teams-stats {
	padding: 2px;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.nel-lws-lls {
	float: left;
}
.nel-teams-data-value {
	font-size: 22px;
}
.nel-teams-data {
	font-weight: 500;
	margin: 15px;
	text-align: center;
	font-size: 16px;
	line-height: 28px;
}
.nel-teams-players {
	border-bottom: 5px #ffffff solid;
	border: 0px;
	thead {
		border-left: 2px solid #c9c9c9;
		border-right: 2px solid #c9c9c9;
	}
}
.nel-teams-player {
	border: 2px #c9c9c9 solid;
}

.nel-tournament-list-nel-logo {
	background-image: url(../img/nel/nel_logo.png);
	background-size: cover;
	width: 20px;
}

;@import "sass-embedded-legacy-load-done:60";