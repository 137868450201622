.gather-group-dialog {
	position: absolute;
	bottom: 100%;
	right: 0;
	width: 260px;
	background-color: #fff;
}
.gather-group-dialog.host {
	width: 352px;

	.gather-group-user {
		width: calc(100% - 166px);
	}
}
.gather-group-dialog-header {
	text-align: left;
	height: 50px;
	line-height: 50px;
	color: #fff;
	padding: 0 8px;
	background-color: $primary-hover-color;
	text-transform: uppercase;
	cursor: pointer;
}

.gather-group-chat-bar-entry-title {
	height: 40px;
	line-height: 40px;
	background-color: #0077a2;
	text-align: center;

	&.active {
		width: 365px;
	}

	.chat-bar-entry {
		height: 0;
	}

	.gather-group-chat-message {
		min-height: 50px;
		margin: 12px 0;
		text-align: left;

		.avatar {
			float: left;
		}
		.gather-group-chat-message-content {
			display: flex;
			flex-direction: column;
			color: $grey-text-color;

			.user-chat-message-timestamp {
				margin-left: 5px;
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:49";