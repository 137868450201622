.deactivation-popover {
	&.popover {
		width: 50%;
		padding: 0;
		max-width: 800px;
	}
	@include mobile() {
		&.popover {
			width: 100%;
			max-width: unset;
		}
	}
	.popover-content {
		padding: 0;
		font-size: 13px;
		font-weight: 400;
	}
	button {
		font-size: 12px;
		max-width: 250px;

		&.wide {
			padding-right: 2em;
			padding-left: 2em;
			max-width: fit-content;
		}

		&.button-transparent {
			color: $primary-color;
			background: transparent !important;
			margin-top: 1em;
		}
	}

	.main-content {
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.reasons {
			background-color: #1f202b;
			flex-grow: 1;
			padding: 2em;

			.fa-frown {
				font-size: 80px;
				color: $primary-color;
			}
			.text {
				color: $grey-text-color;
				line-height: 32px;
			}
			select,
			textarea {
				min-width: 350px;
				width: 80%;
				margin-top: 1em;
			}
		}
		.buttons {
			background-color: #171820;
			height: 8em;
			display: flex;
			flex-direction: column;
			padding: 1em;
			align-items: center;

			.deactivation-popover--abort {
				border-radius: 2px;
			}
		}
	}
	.things-to-think-about {
		background-image: url(../img/backgrounds/dark.png);
		background-repeat: no-repeat;
		background-position: 50% 0;
		background-color: #14151d;
		display: flex;
		flex-direction: column;
		justify-content: center;

		h2 {
			text-align: left;

			&.left-margin {
				margin-left: 1em;
			}
		}
		.usp-wrapper .usp {
			height: 30px;
			width: 5em;
		}
		button {
			max-width: initial;
		}
		table.plp-content-list tr td {
			height: 42px;
		}
	}
	.prime {
		.main-content .reasons .fa-frown {
			color: $prime-color;
		}
		.things-to-think-about {
			background-position: 70% 50%;
			background-repeat: no-repeat;
			background-image: url(../img/prime/background_narrow.jpg);
			padding-right: 2em;
			padding-left: 2em;

			.plp-block {
				height: 6px;
				width: 6px;
			}
		}
		button {
			&.deactivation-popover--abort {
				@extend .prime;
			}
			&.button-transparent {
				color: $prime-color;
			}
		}
		.esportal-logo {
			background-image: url(../img/prime/logo_white_purple.png);
			min-width: 222px;
			width: 222px;
			height: 59px;
		}
	}
	.premium {
		.main-content .reasons .fa-frown {
			color: $premium-color;
		}
		.things-to-think-about {
			background-size: cover;
			background-position: 68% -45px;
			background-repeat: no-repeat;
			background-image: url(../img/premium/background_s1.jpg);
			background-color: #0f0f1b;
			padding-right: 2em;
			padding-left: 2em;

			.plp-block {
				height: 6px;
				width: 6px;
				background-color: $premium-color;
			}
		}
		button {
			&.deactivation-popover--abort {
				@extend .premium;
			}
			&.button-transparent {
				color: $premium-color;
			}
		}
		.esportal-logo {
			width: 100%;
			height: 50px;
			background-image: url(../img/premium/premium_function_popover.png);
			background-repeat: no-repeat;
			background-size: contain;
		}
	}

	.notice {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		text-align: left;
		background-color: #750008;
		margin-bottom: 0.75rem;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		padding: 20px 35px;
		font-weight: 500;

		@include fullhd() {
			padding-right: 96px;
		}

		.underlined-text {
			text-decoration: underline;
			font-weight: 500;
		}

		.notice-icon {
			display: block;
			font-size: 23px;
			margin-right: 15px;
		}
	}
}

;@import "sass-embedded-legacy-load-done:127";