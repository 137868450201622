// TOOD: Fix styles to fit both user-stats and user-profile pages! Remove .user-stats-page-only class
.user-stats-page-only {
	.no-stats-available {
		.column {
			text-align: center;
		}

		.no-stats-username {
			font-size: 24px;
			font-weight: 400;
		}
	}

	.content-heading {
		font-size: 16px;
	}

	.header-column {
		@include tablet() {
			width: 200px;
			padding: 0;
			padding-bottom: 0.75rem;
		}
	}

	.games-summary-column {
		@include tablet() {
			display: flex;
			flex-direction: column;
			padding: 0.75rem 2rem;
		}
	}

	.user-summary-column {
		@include tablet() {
			flex-grow: 1;
			width: auto;
			padding: 0;
			padding-bottom: 0.75rem;
		}
	}

	.header {
		@include mobile() {
			/* #content */
			margin-top: -10px;
			padding: 1em;
			display: flex;
		}

		.user-avatar {
			width: 200px;
			max-width: 100%;
			height: 200px;
			background-size: cover;
			border-radius: 50%;
			border: 4px solid #cfcfcf;

			@include mobile() {
				max-width: 80px;
				max-height: 80px;
			}
		}

		.label {
			display: flex;
			justify-content: center;
			flex-direction: column;
			margin-left: 1rem;
			font-size: 1.5rem;

			.name {
				color: $primary-hover-color;
			}

			.description {
				font-size: 0.8rem;
				font-weight: 500;
				text-transform: uppercase;
			}
		}

		.go-back {
			margin-left: auto;
			display: flex;
			align-items: center;
		}
	}

	.games-summary {
		display: flex;
		flex-wrap: wrap;
		flex-shrink: 0;

		@include mobile() {
			display: block;
			flex-wrap: initial;
			flex-shrink: initial;
			.is-right {
				margin-left: auto;
				margin-right: auto;
				width: 100%;
			}
		}

		.is-left {
			display: flex;
		}

		.is-right {
			margin-left: auto;
		}

		.rank-data {
			display: flex;
			align-items: center;
			margin-right: 3em;

			.rank {
				display: inline-block;
				width: 75.16px;
				height: 55px;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: 0 50%;
			}

			.rank-elo {
				font-size: 30px;
				font-weight: 500;
			}
		}

		.stats-bar-games-ratio {
			display: flex;
			align-items: center;
			min-width: 180px;
			height: 64px;
			margin-top: 5px;
			margin-bottom: 5px;
			background-color: transparent;
			max-width: 100%;
			flex-grow: 1;
		}
	}

	.user-stats-boxes {
		flex: 1;
		min-height: 90px;
		display: inline-flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;

		@include tablet() {
			padding-top: 1rem;
		}

		.user-profile-stats-box {
			border-radius: 4px;
			height: 90px;
			width: 18%;
			min-width: 130px;
			margin-top: 10px;

			@include tablet() {
				.user-profile-stats-box-icon {
					width: 44px;
				}
			}
		}
	}
	.user-profile-stats-box {
		@include mobile() {
			width: 45%;
			margin-top: 10px;
		}
	}

	.stats-boxes {
		display: grid;
		grid-template-columns: 50% 50%;
		grid-template-rows: 1fr 1fr 1fr;
		padding: 0;
		padding-top: 1em;

		@include mobile() {
			display: grid;
			grid-template-columns: 50% 50%;
			grid-template-rows: 1fr 1fr 1fr;
		}

		@include tablet() {
			display: inline-flex;
			justify-content: space-around;
		}

		.stat-box {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			background-color: $secondary-color;
			color: #fff;
			text-align: center;
			margin: 4px;
			padding: 12px;
			padding-top: 22px;
			padding-bottom: 22px;
			min-width: 80px;

			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}

			@include mobile() {
				height: 150px;
				margin: 1%;
			}

			@include desktop() {
				width: 16%;
			}
			@include tablet() {
				width: 96%;
			}
			.value {
				font-size: 30px;
				color: $primary-color;
			}

			.label {
				font-weight: 500;
				font-size: 12px;
			}
		}
	}

	.user-stats-performance-stats-column {
		align-items: stretch;
	}

	.clutch-stats {
		margin-top: 3px;
		height: 100%;
		color: $grey-text-color;
		flex-direction: column;

		.clutch-table-column {
			flex-grow: 2;
		}
		.clutch-table {
			margin-top: 3px;
			tr {
				line-height: 55px;
			}
		}

		.user-stats-premium-only {
			height: calc(100% - 35px);
		}

		.clutch-stats-stats-boxes {
			display: flex;
			justify-content: space-between;
			margin-top: 5px;

			@include mobile() {
				flex-wrap: wrap;
				width: 100%;
			}

			.user-profile-stats-box {
				width: 49%;
				@include mobile() {
					width: 100%;
				}
			}
		}
	}
	.advanced-weapon-stats {
		margin-top: 3px;
	}
	.user-stats-premium-only {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 0, 0, 0.6);

		.user-stats-premium-only-content {
			text-align: center;
			font-weight: 500;
			font-size: 16px;
			padding: 40px 0px 8px 0px;
		}

		img {
			width: 320px;
			max-width: 70%;
			margin-bottom: 25px;
		}
		.button {
			margin-top: 30px;
			width: 300px;
			max-width: 55%;
		}
	}
	.performance-stats {
		.performance-stats-wrapper {
			display: flex;
			flex-wrap: wrap;
			min-height: 542px;
			@include mobile() {
				display: block;
			}
			.performance-stats-types {
				width: 30%;
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				@include mobile() {
					width: 100%;
					flex-wrap: nowrap;
				}
				@include widescreen() {
					width: 100%;
					flex-wrap: nowrap;
				}
				.performance-stats-header {
					flex-grow: 1;
					display: flex;
					padding: 0 20px;
					height: 50px;

					.performance-stats-header-icon {
						width: 32px;
						display: block;
					}
					.performance-stats-header-name {
						display: flex;
						align-items: center;
						text-transform: uppercase;
						font-size: 1.08rem;
						padding: 0 10px;
					}
				}
				.performance-stats-types-only {
					display: flex;
					flex-direction: column;
					flex-wrap: wrap;
					height: calc(100% - 50px);
					background-color: #191922;

					@include mobile() {
						width: 100%;
						flex-direction: row;
						flex-wrap: nowrap;
					}
					@include widescreen() {
						width: 100%;
						flex-direction: row;
						flex-wrap: nowrap;
					}
					.performance-stats-type {
						cursor: pointer;
						flex-grow: 1;
						display: flex;
						justify-content: space-between;
						padding: 0 20px;
						font-size: 1.05rem;
						border-top: 1px solid #252525;
						@include mobile() {
							padding: 10px;
							font-size: 0.8rem;
							border: none;
							text-align: center;
						}
						@include widescreen() {
							padding: 20px;
							font-size: 0.8rem;
							border-top: none;
							border-right: 1px solid #252525;
							text-align: center;
							justify-content: center;

							&:last-child {
								border: none;
							}
						}
						.performance-stats-type-name,
						.performance-stats-type-icon {
							display: flex;
							align-items: center;
						}
						.performance-stats-type-icon {
							@include mobile() {
								display: none;
							}
							@include widescreen() {
								display: none;
							}
						}
					}
					.performance-stats-type-active,
					.performance-stats-type:hover {
						color: #01a9ea;
						@include mobile() {
							background-color: #0c0c0c;
						}
						@include widescreen() {
							background-color: #0c0c0c;
						}
					}
				}
			}
			.performance-stats-chart {
				width: 70%;
				background-color: #101116;
				padding: 30px;
				@include mobile() {
					width: 100%;
					padding: 10px;
				}
				@include widescreen() {
					width: 100%;
					padding: 10px;
				}
			}
		}
	}

	.user-stats-favorite-chart {
		margin-bottom: 8px;
		color: $grey-text-color;
		min-width: 200px;
		width: 100%;

		.overflowed {
			width: 50%;
			position: relative;

			&:before {
				content: '&nbsp;';
				visibility: hidden;
			}

			span {
				position: absolute;
				left: 0;
				right: 0;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
	.user-stats-favorites-stats {
		flex-direction: row;
	}

	.advanced-weapon-chart {
		background-color: #101116 !important;
	}
	.user-profile-basic-info {
		border-radius: 0;
		padding: 1em;
	}
	.user-profile-rank-rating {
		@include tablet() {
			justify-content: space-between;
		}
	}
	.user-profile-stats-summary-stats-section {
		width: 100%;
	}
}

.user-stats-page {
	.no-stats-available {
		.column {
			text-align: center;
		}

		.no-stats-username {
			font-size: 24px;
			font-weight: 400;
		}
	}

	.content-heading {
		font-size: 22px;
	}

	.header-column {
		@include tablet() {
			width: 200px;
			padding: 0;
			padding-bottom: 0.75rem;
		}
	}

	.games-summary-column {
		@include tablet() {
			display: flex;
			flex-direction: column;
			padding: 0.75rem 2rem;
		}
	}

	.user-summary-column {
		@include tablet() {
			flex-grow: 1;
			width: auto;
			padding: 0;
			padding-bottom: 0.75rem;
		}
	}

	.header {
		@include mobile() {
			/* #content */
			margin-top: -10px;
			padding: 1em;
			display: flex;
		}

		.user-avatar {
			width: 200px;
			max-width: 100%;
			height: 200px;
			background-size: cover;
			border-radius: 50%;
			border: 4px solid #cfcfcf;

			@include mobile() {
				max-width: 80px;
				max-height: 80px;
			}
		}

		.label {
			display: flex;
			justify-content: center;
			flex-direction: column;
			margin-left: 1rem;
			font-size: 1.5rem;

			.name {
				color: $primary-hover-color;
			}

			.description {
				font-size: 0.8rem;
				font-weight: 500;
				text-transform: uppercase;
			}
		}

		.go-back {
			margin-left: auto;
			display: flex;
			align-items: center;
		}
	}

	.games-summary {
		display: flex;
		flex-wrap: wrap;
		flex-shrink: 0;
		justify-content: left;

		@include mobile() {
			display: initial;
			flex-wrap: initial;
			flex-shrink: initial;
			.is-right {
				margin-left: auto;
				margin-right: auto;
				width: 100%;
			}
		}

		@include desktop() {
			justify-content: center;
		}
		@include fullhd() {
			justify-content: left;
		}

		.is-left {
			display: flex;
		}

		.is-right {
			margin-left: auto;
		}

		.rank-data {
			display: flex;
			align-items: center;

			.rank {
				display: inline-block;
				width: 35px;
				height: 30px;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: 0 50%;
				margin-right: 10px;

				@include fullhd() {
					width: 55px;
					height: 40px;
				}
			}

			.rank-elo {
				font-size: 16px;
				font-weight: 500;

				@include fullhd() {
					font-size: 22px;
				}
			}
		}

		.stats-bar-games-ratio {
			display: flex;
			align-items: center;
			width: 280px;
			height: 64px;
			margin-top: 5px;
			margin-bottom: 5px;
			background-color: transparent;

			@include mobile() {
				height: 45px;
				margin: 0;
			}
		}
	}

	.user-stats-boxes {
		flex: 1;
		min-height: 90px;
		display: inline-flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;

		@include tablet() {
			padding-top: 1rem;
		}

		.user-profile-stats-box {
			height: 90px;
			width: 18%;
			min-width: 130px;

			@include tablet() {
				.user-profile-stats-box-icon {
					width: 44px;
				}
			}
		}
	}

	.misc-stats {
		display: inline-flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		color: $grey-text-color;
		justify-self: center;
		width: 100%;

		.column {
			padding: 0.2rem;
		}

		.box {
			display: flex;
			align-items: center;
			height: 100%;
			background-color: #f4f4f4;
			min-height: 50px;

			&.is-center-except-mobile {
				justify-content: center;
				@include mobile() {
					justify-content: left;
					padding-left: 2em;
				}
			}

			&.games-played-touch {
				background-color: transparent;
				text-transform: uppercase;

				@include mobile() {
					width: 100%;
					min-height: 20px;

					.content {
						width: 100%;
						display: flex;
						justify-content: space-evenly;
						align-items: center;

						.drop-ratio-label {
							font-size: 10px;
						}
					}
				}
			}

			@include mobile() {
				justify-content: center;
			}

			&.is-half {
				width: 48%;
				@include widescreen() {
					width: 49%;
				}
			}

			.fas {
				font-size: 20px;

				&.fa-trophy {
					font-size: 30px;
				}

				@include mobile() {
					font-size: 15px;
					margin-right: 20px;

					&.fa-trophy {
						font-size: 22px;
					}
				}
			}

			.stats-bar-dropped-icon {
				margin-top: 0;
				color: $grey-text-color;

				@include mobile() {
					&.mobile {
						width: 20px;
						height: 20px;
						background-size: 20px 20px;
						margin-right: 27px;
					}
				}
			}

			.content {
				display: block;

				&.is-pushed {
					padding-left: 0.5rem;
				}

				.statistic {
					font-size: 20px;
					font-weight: 500;
					color: $button-color;

					@include mobile() {
						font-size: 15px;
					}
				}

				.drop-ratio-label {
					font-size: 14px;
					font-weight: 500;
					color: #fff;
				}

				.small {
					font-weight: 500;
					color: #fff;
				}
			}
		}
	}

	.stats-boxes {
		display: grid;
		grid-template-columns: 50% 50%;
		grid-template-rows: 1fr 1fr 1fr;

		@include mobile() {
			padding: 20px;
		}

		@include tablet() {
			padding: 20px;
		}

		@include desktop() {
			display: inline-flex;
			justify-content: space-around;
			padding: 8px;
		}
		@include widescreen() {
			display: grid;
			grid-template-columns: 50% 50%;
			grid-template-rows: 1fr 1fr 1fr;
		}
		.stat-box {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			background-color: #202029;
			color: #fff;
			text-align: center;
			margin: 4px;
			padding: 12px;
			padding-top: 22px;
			padding-bottom: 22px;
			min-width: 80px;

			@include mobile() {
				height: 150px;
				margin: 1%;
			}

			@include desktop() {
				width: 16%;
			}
			@include tablet() {
				width: 96%;
			}
			.value {
				font-size: 30px;
				color: $primary-color;
			}

			.label {
				font-weight: 500;
				font-size: 12px;
			}
		}
	}

	.user-stats-performance-stats-column {
		align-items: stretch;
	}

	.clutch-stats {
		margin-top: 3px;
		height: 100%;
		color: $grey-text-color;

		.clutch-table {
			margin-top: 3px;
			tr {
				line-height: 55px;
			}
		}

		.user-stats-premium-only {
			height: calc(100% - 35px);
		}

		.clutch-stats-stats-boxes {
			display: flex;
			justify-content: space-between;
			margin-top: 5px;

			@include mobile() {
				flex-wrap: wrap;
				width: 100%;
			}

			.user-profile-stats-box {
				width: 49%;
				@include mobile() {
					width: 100%;
				}
			}
		}
	}
	.advanced-weapon-stats {
		margin-top: 3px;
	}
	.user-stats-premium-only {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 0, 0, 0.6);

		.user-stats-premium-only-content {
			text-align: center;
			font-weight: 500;
			font-size: 16px;
			padding: 40px 0px 8px 0px;
		}

		img {
			width: 320px;
			max-width: 70%;
			margin-bottom: 25px;
		}
		.button {
			margin-top: 30px;
			width: 300px;
			max-width: 55%;
		}
	}
	.performance-stats {
		.performance-stats-wrapper {
			display: flex;
			flex-wrap: wrap;
			min-height: 542px;
			@include mobile() {
				display: block;
			}
			.performance-stats-types {
				width: 30%;
				background-color: #191922;
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				@include mobile() {
					width: 100%;
					flex-wrap: nowrap;
				}
				.performance-stats-header {
					flex-grow: 1;
					display: flex;
					padding: 0 20px;
					height: 50px;
					@include mobile() {
						display: none;
					}
					.performance-stats-header-icon {
						width: 32px;
						display: block;
					}
					.performance-stats-header-name {
						display: flex;
						align-items: center;
						text-transform: uppercase;
						font-size: 1.08rem;
						padding: 0 10px;
					}
				}
				.performance-stats-types-only {
					display: flex;
					flex-direction: column;
					flex-wrap: wrap;
					height: calc(100% - 50px);

					@include mobile() {
						width: 100%;
						flex-direction: row;
						flex-wrap: nowrap;
					}

					.performance-stats-type {
						cursor: pointer;
						flex-grow: 1;
						display: flex;
						justify-content: space-between;
						padding: 0 20px;
						font-size: 1.05rem;
						border-top: 1px solid #252525;
						@include mobile() {
							padding: 10px;
							font-size: 0.8rem;
							border: none;
							text-align: center;
						}

						.performance-stats-type-name,
						.performance-stats-type-icon {
							display: flex;
							align-items: center;
						}

						.performance-stats-type-icon {
							@include mobile() {
								display: none;
							}
						}
					}

					.performance-stats-type-active,
					.performance-stats-type:hover {
						color: #01a9ea;
						@include mobile() {
							background-color: #0c0c0c;
						}
					}
				}
			}
			.performance-stats-chart {
				width: 70%;
				background-color: #101116;
				padding: 30px;
				@include mobile() {
					width: 100%;
					padding: 10px;
				}
			}
		}
	}

	.user-stats-favorite-chart {
		margin-bottom: 8px;
		color: $grey-text-color;
		min-width: 200px;
	}
	.user-stats-favorites-stats {
		flex-direction: row;
	}

	.advanced-weapon-chart {
		background-color: #101116 !important;
	}
	.user-profile-basic-info {
		border-radius: 0;
		padding: 1em;
	}
}

.previous-next-buttons {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	font-size: 14px;

	.button-dark {
		background-color: transparent;
	}

	.button-dark.disabled {
		background-color: transparent !important;
		color: $grey-text-color !important;
	}
}

.compare-stats-page {
	.user-avatar {
		width: 100%;
		padding-bottom: 100%;
		background-size: cover;
		border-radius: 50%;
		border: 4px solid #cfcfcf;
	}

	.games-summary {
		.games-played {
			font-size: 16px;

			@include mobile() {
				margin-left: 0;
				text-align: center;
			}

			.number {
				font-size: 24px;
				font-weight: 500;
				color: $button-color;
			}
		}

		.stats-bar-games-ratio {
			margin-top: -15px;
			width: 100%;
			background-color: transparent !important;
		}
	}

	.misc-stats {
		.box {
			background-color: $secondary-color;
			.fas {
				font-size: 20px !important;
			}
			.stats-bar-dropped-icon {
				background-position: 0% 50%;
				width: 35px;
				height: 35px;
				margin-right: 10px;
				background-size: contain;
			}
			.content .statistic {
				font-size: 22px;
				font-weight: 600;
			}
		}
	}

	.user-stats-latest-matches {
		.button {
			width: 10em;
		}
	}

	.clutch-table-together td {
		text-align: center;
	}

	.clutch-reverse {
		.clutch-stats {
			flex-direction: row-reverse;
		}
	}

	.clutch-stats {
		flex-wrap: wrap;

		@include tablet() {
			flex-wrap: wrap-reverse;
		}
		@include desktop() {
			flex-wrap: nowrap;
		}

		.clutch-table-column {
			@include mobile() {
				display: none;
			}
			@include desktop() {
				flex-basis: 50%;
			}
			@include widescreen() {
				flex-basis: 60%;
			}
			@include fullhd() {
				flex-basis: 70%;
			}
		}
		.clutch-stats-stats-boxes {
			flex-wrap: wrap;

			@include desktop() {
				flex-basis: 50%;
			}
			@include widescreen() {
				flex-basis: 40%;
			}
			@include fullhd() {
				flex-basis: 30%;
			}

			.user-profile-stats-box {
				height: auto;
				margin-bottom: 1em;
				width: 100%;
				text-align: center;

				@include mobile() {
					flex-direction: column;
					min-height: 120px;
				}
				@include tablet() {
					min-height: 90px;
				}
				@include desktop() {
					text-align: unset;
					padding: 2em 0;
				}

				.user-profile-stats-box-icon {
					margin-right: 10px;

					@include mobile() {
						margin-right: 0;
					}
					@include tablet() {
						width: 40px;
						height: 40px;
					}
					@include desktop() {
						width: 75px;
						height: 75px;
					}
				}

				.user-profile-stats-box-value {
					@include mobile() {
						font-size: 15px;
						max-width: 90%;
						width: 90%;
					}
					@include tablet() {
						font-size: 15px;
						max-width: 80%;
					}
					@include desktop() {
						font-size: 34px;
						max-width: 60%;
					}

					.label {
						font-size: 14px;
						@include mobile() {
							margin-left: 5px;
							display: inline;
							font-size: 12px;
						}
						@include tablet() {
							margin-left: 5px;
							display: inline;
						}
						@include desktop() {
							margin-left: 0;
							display: block;
						}
					}

					.date {
						display: inline-block;
						@include mobile() {
							vertical-align: baseline;
						}
						@include tablet() {
							vertical-align: top;
						}

						@include desktop() {
							vertical-align: top;
						}
					}
				}
			}
		}
	}

	.user-profile-basic-info {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.basic-stats > .column:first-child {
		@include desktop() {
			border-right: 1px solid #353841;
		}
	}
	.basic-stats {
		.user-profile-username {
			font-size: 22px;
			text-align: left;
			@include desktop() {
				text-align: center;
			}
		}

		.player-basic-stats {
			flex-wrap: wrap;

			.tablet-name-rank {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				align-content: center;
			}
			.reverse {
				flex-direction: row-reverse;
			}
		}

		.mobile-avatar {
			.column,
			.user-profile {
				padding: 0;
			}
		}
		.reverse {
			flex-direction: row-reverse;
		}
	}
	.columns.basic-stats.mobile {
		.columns:last-child {
			margin-bottom: 0;
		}
	}

	.compare-column {
		margin: auto;
	}

	.compare-boxes {
		margin-bottom: 30px;
		.compare-box {
			display: flex;
			margin-bottom: 4px;
			height: 45px;
			.compare-box-value,
			.compare-box-title {
				flex: 1;
				display: flex;
				align-items: center;
				padding: 15px 0 15px 15px;
				font-weight: 500;

				@include desktop() {
					padding: 15px 0 15px 35px;
				}
			}
			.compare-box-title {
				text-transform: uppercase;
				background-color: $secondary-color;
				font-size: 0.7rem;

				@include desktop() {
					font-size: 0.9rem;
				}

				&.compare-other {
					justify-content: flex-end;
					padding: 15px 15px 15px 0;

					@include desktop() {
						padding: 15px 35px 15px 0;
					}
				}
			}
			.compare-box-value {
				background-color: #101117;
				font-size: 0.8rem;

				@include desktop() {
					font-size: 1rem;
				}
				&.compare-self {
					justify-content: flex-end;
					padding: 15px 15px 15px 0;

					@include desktop() {
						padding: 15px 45px 15px 0;
					}
				}
			}
			.compare-box-vs {
				text-align: center;
				font-weight: 500;
				margin: 7px 5px 6px;
				width: 38px;
				line-height: 32px;
				height: 32px;
				background-color: #03a9e7;
				text-transform: uppercase;

				&.compare-box-vs-orange {
					background-color: $premium-color;
				}
				@include desktop() {
					width: 51px;
				}
			}
			.compare-box-active {
				color: #049acd;
			}
		}

		.compare-box-mobile {
			display: flex;
			justify-content: space-around;
			text-transform: uppercase;
			background-color: $secondary-color;
			margin-bottom: 4px;
			height: 40px;
			align-items: center;
			font-weight: 600;

			.compare-box-active {
				color: #049acd;
			}
			.compare-box-value {
				width: 25%;
				text-align: center;
				font-size: 0.8rem;
			}
			.compare-box-title {
				width: 50%;
				text-align: center;
				font-size: 0.7rem;
			}
		}
	}
	.together-boxes {
		display: flex;
		margin: 0 -5px;
		.together-box {
			flex: 1;
			text-align: center;
			background-color: $secondary-color;
			margin: 5px;
			padding: 10px;
			&:nth-child(even) {
				background-color: $secondary-inactive-color;
			}
			.together-box-value {
				color: #029cd7;
				font-size: 3rem;

				@include mobile() {
					font-size: 1.5rem;
				}
			}
			.together-box-title {
				text-transform: uppercase;
				font-weight: bold;

				@include mobile() {
					font-size: 0.6rem;
				}
			}
		}
	}

	.advanced-weapon-chart {
		background-color: #101116 !important;
		padding: 20px 30px;
	}

	.advanced-weapon-stats,
	.favorite-weapons,
	.favorite-maps {
		th:last-child,
		td:last-child {
			text-align: center;
		}

		@include mobile() {
			th:first-child {
				color: transparent;
			}
		}
		@include tablet() {
			th:first-child {
				color: transparent;
			}
		}
		@include desktop() {
			th:first-child {
				color: unset;
			}
		}
	}

	.advanced-weapon-stats,
	.favorite-weapons {
		@include mobile() {
			.table-weapon {
				display: none;
			}
		}
		@include tablet() {
			.table-weapon {
				display: none;
			}
		}
		@include desktop() {
			.table-weapon {
				display: inline-block;
			}
		}
	}
	.favorite-maps {
		@include mobile() {
			.table-map {
				display: none;
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:74";