.prime-popover {
	padding: 0;
	width: 100%;
	max-width: 630px;
	color: #fff;
	background-color: #fff !important;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1) !important;
	border: 0 !important;

	> .popover-content {
		padding: 0;
	}
}
.prime-popover-image {
	position: relative;
	background-image: url(../img/prime/stars.png);
	background-position: 50% 50%;
	height: 300px;
	width: 100%;
	max-width: 600px;
	margin: 0px auto;
}
.prime-popover-close {
	position: absolute;
	top: 14px;
	right: 0px;
	width: 20px;
	text-align: center;
	line-height: 20px;
	color: #000;
	cursor: pointer;
}
.prime-popover-label {
	background-color: $prime-hover-color;
	padding: 25px 10px;
	font-size: 26px;
}
.prime-popover-friends {
	background-color: #400090;
	padding: 20px 10px 25px 10px;
	overflow: hidden;
}
.prime-popover-friends-label {
	text-transform: uppercase;
	font-weight: 700;
	font-size: 13px;
	margin-bottom: 10px;
}
.prime-popover-friend {
	width: 120px;
	height: 105px;
	margin: 10px 0px;

	.avatar {
		width: 75px;
		height: 75px;
		border: 3px solid $prime-hover-color;
	}
	.username {
		color: $primary-hover-color;
		font-size: 14px;
		font-weight: 500;
	}
}

;@import "sass-embedded-legacy-load-done:122";