.signupProgressBarContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 284px;
	height: 42px;
	margin-bottom: 60px;
}

.signupProgressBarStep {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 28px;
	font-size: 11px;
	font-weight: normal;
	white-space: nowrap;
}

.signupProgressBarNode {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 28px;
	height: 28px;
	margin-bottom: 7px;
	border-radius: 50%;
	background-color: #13aceb;
	color: white;
	font-size: 12px;
	font-weight: medium;
}

.signupProgressBarNodeDisabled {
	background-color: #1f2154;
}

.signupProgressBar {
	position: relative;
	align-self: start;
	top: 9.5px;
	width: 100%;
	height: 4px;
	background-color: #1f2154;

	> div {
		height: 100%;
		background-color: #13aceb;

		transition: width 0.5s ease-out;
	}
}
