#refer-friend-root {
	$medium: 500;
	$normal: 400;
	$light: 300;

	p {
		line-height: unset;
	}

	background-image: url(../img/referfriend/referrals.png);
	background-size: contain;
	background-repeat: no-repeat;

	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 50px 128px;

	@media (max-width: 768px) {
		padding: 50px 10px;
	}

	.header-root {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		ul {
			max-width: 430px;
		}
	}

	.step {
		position: relative;
		display: flex;
		background-color: #1b1d26;
		border-radius: 4px;
		width: 450px;
		align-items: center;
		margin: 10px;
		padding: 24px;

		&:last-child {
			.line {
				border: none;
			}
		}

		.line {
			position: absolute;
			height: 18px;
			width: 2px;
			border: 1px dashed #13aceb;
			bottom: -18px;
		}

		.icon {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 16px;

			i {
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 30px;
				border-radius: 200px;
				background-color: #13aceb;
				padding: 20px;
				color: #1b1d26;
				width: 50px;
				height: 50px;
			}
		}

		.info {
			h3 {
				color: #13aceb;
				font-size: 18px;
				font-weight: 600;
				margin: 0;
			}

			p {
				margin-top: 5px;
				color: #fff;
				font-size: 15px;
				font-weight: 400;
			}
		}
	}

	.link-section {
		display: flex;
		width: 100%;
		justify-content: center;

		input {
			margin-right: 20px;
		}
	}

	.header-1 {
		font-size: 64px;
		letter-spacing: 0.13px;
		text-align: center;
		font-weight: $medium;
		margin-bottom: 12px;
	}

	.header-small {
		font-size: 32px;
		letter-spacing: 0.13px;
		color: #ffffff;
		margin-top: 0;
		text-align: center;
		font-weight: $normal;
	}

	.header-blue-2 {
		font-size: 24px;
		font-weight: $normal;
		letter-spacing: 0.08px;
		color: #fff;
		text-align: center;
		margin-top: 60px;
		margin-bottom: 32px;
	}

	.header-blue-3 {
		font-size: 18px;
		color: #fff;
		font-weight: $medium;
		text-align: center;
	}

	.tabs {
		display: flex;

		p {
			background-color: #11121a;
			padding: 13px 25px;
			border-radius: 4px 4px 0 0;
			margin: 0;
			cursor: pointer;

			&.active {
				background-color: #20212d;
				cursor: initial;
				color: #13aceb;
			}
		}
	}

	.rules-root {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.rules-conditions {
		font-size: 11px;
		font-weight: $light;
		text-align: center;
		margin: 30px 0;
	}

	.rules {
		margin-top: 100px;
		position: relative;
		max-width: 896px;

		button {
			color: #00a9e9;
			border-color: #00a9e9;
			cursor: pointer;
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translateX(-50%);
		}

		.overlay {
			position: absolute;
			width: 100%;
			height: 100%;
			background: linear-gradient(rgba(255, 255, 255, 0), #14151d);
		}
		.expanded {
			height: unset;
			overflow: auto;
		}

		ul {
			height: 260px;
			overflow: hidden;

			li {
				margin: 20px 0;
				display: flex;
				font-size: 16px;
				line-height: 150%;
				font-weight: $light;
				color: #ffffff;
			}
		}
	}

	.leaderboard {
		display: flex;
		width: 100%;
		flex-direction: column;
		margin: 0 0 80px 0;
		max-width: 600px;

		.tabs {
			display: flex;
			font-size: 13px;
			font-weight: $medium;
			justify-content: flex-start;

			width: 100%;

			.selected {
				color: #13aceb;
				padding: 20px;
				background-color: #20212d;
				border-radius: 4px 4px 0 0;
			}

			.not-selected {
				color: #c0c6d1;
				padding: 20px;
				background-color: #11121a;
				border-radius: 4px 4px 0 0;
				cursor: pointer;
			}
		}

		.header {
			display: flex;
			width: 100%;
			background-color: #20212d;
			font-size: 12px;
			font-weight: $normal;
			letter-spacing: 0.16px;

			p {
				line-height: unset;
				margin: 0;
				padding: 10px;
			}

			.id {
				width: 10%;
				margin-left: 20px;
			}

			.name {
				width: 25%;
			}

			.referrals {
				width: 70%;
				text-align: right;
				margin-right: 20px;
			}
		}

		.leaderboard-table {
			.player-info {
				display: flex;
				align-items: center;

				.id {
					padding: 0 30px;
					width: 5%;
				}

				.flag {
					padding: 0 20px;
					width: 5%;
				}

				.photo {
					padding: 0 10px;
					width: 10%;
				}

				.name {
					width: 30%;
				}

				.referrals {
					text-align: right;
					padding: 0 30px;
					width: 50%;
				}

				&:nth-child(2n + 1) {
					background-color: #191a24;
					border-bottom: 1px solid #2a2c3a;
					border-top: 1px solid #2a2c3a;
				}

				&:nth-child(1) {
					border-top: none;
				}
			}
		}
	}
}
