.unclaimed-elo-popover {
	background-color: #151515;
	padding: 0;
	width: 700px;
	height: 330px;
	border: 0;

	.popover-content {
		padding: 0;
	}

	.unclaimed-elo-header-outer {
		width: 100%;
		padding: 10px;
		background-color: #0e0e0e;
	}
	.unclaimed-elo-header {
		width: 100%;
		height: 45px;
		background-repeat: no-repeat;
		background-image: url(../img/premium/premium_function_popover.png);
		background-size: contain;
		background-position: 0px 0px;
	}
	.unclaimed-elo-content {
		width: 100%;
		display: flex;
		height: 270px;
	}
	.unclaimed-elo-estimated-gain {
		width: 250px;
		background-repeat: no-repeat;
		background-image: url(../img/premium/background_s1.jpg);
		display: flex;
		flex-direction: column;
		justify-content: center;

		background-size: 700px;
		background-position-x: -80px;
		background-position-y: -62px;
	}

	.unclaimed-elo-value {
		color: $important-color;
		font-size: 75px;
		font-weight: 400;
	}
	.unclaimed-elo-value-desc {
		display: inline-block;
		border-radius: 6px;
		padding: 7px;
		font-size: 14px;
		color: #ea4f01;
		background-color: #07070d;
		text-transform: uppercase;
		font-weight: 900;
		font-size: 12px;
		position: relative;
		top: -7px;
	}

	.unclaimed-elo-details {
		color: #b0b0b0;
		background-color: #151515;
		width: 75%;
		margin: 0 auto;
		padding: 24px 0;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;

		.unclaimed-elo-i18n {
			color: #ea4f01;
		}

		h1 {
			font-size: 23px;
			margin: 0;
			padding: 0;
			color: $important-color;
		}

		button.upgrade-premium {
			font-size: 14px;
			padding: 5px 50px;
			margin-bottom: 5px;
			background: rgb(255, 173, 0);
			background: linear-gradient(
				180deg,
				rgba(255, 173, 0, 1) 0%,
				rgba(254, 164, 0, 1) 25%,
				rgba(254, 146, 0, 1) 50%,
				rgba(254, 131, 0, 1) 75%
			);
			&:hover {
				background: linear-gradient(
					180deg,
					rgba(255, 193, 0, 1) 0%,
					rgba(254, 184, 0, 1) 25%,
					rgba(254, 166, 0, 1) 50%,
					rgba(254, 151, 0, 1) 75%
				);
			}
		}
		button.decline {
			font-size: 12px;
			color: #b0b0b0 !important;
			background-color: transparent !important;
			margin: 0;
			&:hover {
				color: white !important;
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:124";