$level-1-color: #13aceb;
$level-1-header-color: #ffffff;
$level-1-description-color: #cfd1de;

$level-5-color: #8522ff;
$level-5-header-color: #ffffff;
$level-5-description-color: #cfd1de;

$level-10-color: #ffc000;
$level-10-header-color: #ffffff;
$level-10-description-color: #cfd1de;

$level-premium-color: #ff8400;
$level-premium-header-color: #ffffff;
$level-premium-description-color: #cfd1de;

@function get-level-colors($level) {
	@if $level < 5 {
		@return $level-1-color, $level-1-header-color, $level-1-description-color;
	} @else if ($level < 10) {
		@return $level-5-color, $level-5-header-color, $level-5-description-color;
	} @else if ($level < 11) {
		@return $level-10-color, $level-10-header-color, $level-10-description-color;
	} @else {
		@return $level-premium-color, $level-premium-header-color, $level-premium-description-color;
	}
}

$mission-1-level-1-url: '../img/levels/mission_icons/icon_mission_1_level_1.png';
$mission-2-level-1-url: '../img/levels/mission_icons/icon_mission_2_level_1.png';
$mission-3-level-1-url: '../img/levels/mission_icons/icon_mission_3_level_1.png';

$mission-1-level-2-url: '../img/levels/mission_icons/icon_mission_1_level_2.png';
$mission-2-level-2-url: '../img/levels/mission_icons/icon_mission_2_level_2.png';
$mission-3-level-2-url: '../img/levels/mission_icons/icon_mission_3_level_2.png';

$mission-1-level-3-url: '../img/levels/mission_icons/icon_mission_1_level_3.png';
$mission-2-level-3-url: '../img/levels/mission_icons/icon_mission_2_level_3.png';
$mission-3-level-3-url: '../img/levels/mission_icons/icon_mission_3_level_3.png';

$mission-1-level-4-url: '../img/levels/mission_icons/icon_mission_1_level_4.png';
$mission-2-level-4-url: '../img/levels/mission_icons/icon_mission_2_level_4.png';
$mission-3-level-4-url: '../img/levels/mission_icons/icon_mission_3_level_4.png';

@function get-level-icon($level) {
	@if $level < 5 {
		@return $mission-1-level-1-url, $mission-2-level-1-url, $mission-3-level-1-url;
	} @else if ($level < 10) {
		@return $mission-1-level-2-url, $mission-2-level-2-url, $mission-3-level-2-url;
	} @else if ($level < 11) {
		@return $mission-1-level-3-url, $mission-2-level-3-url, $mission-3-level-3-url;
	} @else {
		@return $mission-1-level-4-url, $mission-2-level-4-url, $mission-3-level-4-url;
	}
}

@for $i from 1 through 20 {
	.level#{$i} {
		background-image: url(../img/levels/level_#{$i}.png);
	}
}

.levels-page .content-heading {
	font-size: 26px;
}

.levels-page .levels-title {
	color: #9294a1;
}

.levels-page .level-info {
	margin: 1em 0;
	background-color: #171821;
	height: 142px;
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;

	@include mobile() {
		height: 340px;
		margin-bottom: 1em;
	}
}

.levels-page .level-info-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex-direction: row;
	background-color: #20212d;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;

	.level-image {
		width: 109px;
		height: 103px;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.level-name {
		margin-top: 1em;
		padding: 0.2em 1.5em;
		border-radius: 500px;
		border: 2px solid;
		color: #fff;
		text-transform: uppercase;

		@for $i from 1 through 20 {
			&.level-#{$i} {
				$color: get-level-colors($i);
				background-color: nth($color, 1);
				border-color: nth($color, 2);
			}
		}
	}

	.level-stats {
		display: flex;
		flex-direction: column;
		margin: 0 0.1em;
		height: 100px;
		justify-content: space-evenly;

		@for $i from 1 through 20 {
			&.level-#{$i} {
				$color: get-level-colors($i);

				.stat-item {
					display: flex;
					align-items: center;

					.stat-item-info {
						display: flex;
						flex-direction: column;
						margin-left: 15px;
					}

					.stat-item-icon-wrapper {
						width: 29px;
					}

					.stat-icon {
						font-size: 1.5em;
						color: nth($color, 1);
					}

					.amount {
						color: nth($color, 1);
						font-size: 18px;
					}

					.description {
						font-size: 13px;
						color: nth($color, 3);
					}
				}
			}
		}
	}
}

.levels-page .level-info .level-data {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	border-bottom-right-radius: 4px;
	border-top-right-radius: 4px;

	&.is-logged-out {
		.missions-wrapper {
			width: 100%;

			.level-stats {
				.stat {
					width: 100%;

					&.stat-players {
						justify-content: center;
					}

					&.stat-friends {
						display: none;
					}
				}
			}
		}
	}
}

.levels-page .level-info .level-data .missions-wrapper {
	height: 100%;
	width: 100%;

	display: flex;
	justify-content: space-around;

	.mission {
		display: flex;
		width: 33%;

		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;

		.icon-wrapper {
			height: 80px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		@for $i from 1 through 20 {
			&.level-#{$i} {
				$color: get-level-colors($i);
				$icons: get-level-icon($i);

				.mission-icon {
					display: block;
					flex: none;
					width: 55px;
					height: 55px;
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;

					&.mission-1 {
						background-image: url(nth($icons, 1));
					}

					&.mission-2 {
						background-image: url(nth($icons, 2));
					}

					&.mission-3 {
						background-image: url(nth($icons, 3));
					}

					&.completed {
						opacity: 0.4;
					}
				}
			}
		}

		.mission-completed-sign {
			position: absolute;
			width: 30px;
			height: 30px;
			margin-top: -50px;
			margin-left: 00px;
			text-align: center;
			line-height: 30px;
			font-size: 18px;
			color: #14151d;

			border-radius: 50%;

			@for $i from 1 through 20 {
				&.level-#{$i} {
					$color: get-level-colors($i);
					background-color: nth($color, 1);
				}
			}
		}

		.mission-description {
			padding: 0em 0.5em;
			min-height: 40px;
			display: flex;
			flex-direction: column;
			align-items: center;

			@for $i from 1 through 20 {
				&.mission-description-#{$i} {
					$color: get-level-colors($i);

					.mission-title,
					.mission-task {
						display: block;
						text-align: center;
					}

					.mission-title {
						color: nth($color, 2);
						font-size: 14px;
					}

					.mission-task {
						color: nth($color, 3);
						font-size: 12px;
					}
				}
			}
		}
	}
}

.levels-page .level-info .level-data .level-progress-indicator {
	width: 100%;
	height: 142px;
	background-color: #20212d;
	border-bottom-right-radius: 4px;
	border-top-right-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;

	.level-progress-indicator-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		width: 100%;
		height: 100px;
	}

	.mission-progress {
		font-size: 30px;
	}
	.indicator-bars {
		width: 132px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.indicator-bar {
			width: 42px;
			height: 6px;
			background-color: #404254;
			border-radius: 4px;

			@for $i from 1 through 20 {
				&.indicator-bar-completed-#{$i} {
					$color: get-level-colors($i);

					background-color: nth($color, 1);
				}
			}
		}
	}

	.level-status {
		font-size: 13px;
	}
}

;@import "sass-embedded-legacy-load-done:59";