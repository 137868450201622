.top-bars-container {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 3000;
	color: #fff;
	width: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-touch-callout: none;
	user-select: none;
}

.top-bar {
	background-color: $secondary-color;
	height: $top-bar-height;
	border-bottom: 1px solid #161720;

	#right-side-info {
		position: absolute;
		top: $top-bar-height;
		right: 0;
	}
}

.top-bar-above {
	background-color: $secondary-color;
	border-bottom: 2px solid #14151d;

	.top-bar-menu {
		height: $top-bar-above-height;
		justify-content: space-between;
		padding-right: 1em;

		@include mobile() {
			padding-right: 0;
			justify-content: center;
		}
	}
}

.top-bar-logo {
	background-image: url(../img/logo.png);
	width: 125px;
	height: 70px;
	background-size: cover;
	background-repeat: no-repeat;
}

.top-bar-item.top-logo-wrapper {
	display: flex;
	justify-content: flex-start;
	padding-right: 1em;
	padding-left: 16px;

	@include widescreen() {
		min-width: 240px;
		padding-right: 0;
		padding-left: 40px;
	}

	@include fullhd() {
		min-width: 240px;
		padding-right: 0;
	}
}

.top-bar-upgrade {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 189px;
	height: 100%;
	background: #1e202e 0% 0%;

	&__title {
		color: #ff8400;
		font-size: 13px;
		line-height: 18px;
		margin-top: 14px;
	}

	&__button {
		cursor: pointer;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		background: transparent linear-gradient(90deg, #ff8600 0%, #ffca00 100%) 0% 0%;
		outline: none;
		border: none;
		height: 29px;
		width: 100%;
		margin-top: 9px;
		color: #1b1d26;
		font-size: 12px;
		line-height: 16px;
		font-weight: 900;
		text-transform: uppercase;
	}
}

.top-bar-matchmaking {
	height: $top-bar-height;
	padding-left: 1.1em;
	padding-right: 1.1em;
	display: flex;
	font-size: 16px;
	text-align: center;
	line-height: 1;
	color: white;
	transition: all 0.15s linear;

	@include fullhd() {
		padding-left: 1.3em;
		padding-right: 1.3em;
	}

	&:hover {
		box-shadow: inset 0 -5px 0 $primary-hover-color;
	}

	&.has-label {
		padding-top: calc(1em);
	}

	span {
		color: $primary-hover-color;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 12px;
		letter-spacing: 1px;
		line-height: 24px;
	}

	&.active {
		background-color: $primary-hover-color;

		span {
			color: #fff;
		}
	}
}

.top-bar .inner-div {
	width: 100%;
	height: 100%;
	padding-left: 20px;
	padding-right: 15px;

	@include widescreen() {
		padding-left: 0;
	}
	@include fullhd() {
		padding-left: 0;
	}
}

.top-bar-login {
	height: 35px;
	width: 210px;
	min-width: 210px;
}

.steam-login-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@include mobile() {
		height: 35px;
	}

	.button {
		width: 100%;
	}

	.steam-icon {
		background-repeat: no-repeat;
		background-image: url(../img/steam.svg);
		background-position: center;
		width: 40px;
		height: 20px;
	}

	.steam-association-notice {
		color: #8a8e90;
		font-size: 10px;
	}
}

.top-bar-hamburger {
	@include hamburger($top-bar-height);
	width: 30px;

	&.active {
		color: #13aceb;
	}

	> span {
		width: 24px;
		height: 2px;
		left: calc(50% - 12px);

		&:nth-child(1) {
			top: calc(50% - 8px);
		}
		&:nth-child(2) {
			top: calc(50% - 1px);
		}
		&:nth-child(3) {
			top: calc(50% + 6px);
		}
	}
}

.top-bar-menu,
.top-bar-left,
.top-bar-right {
	display: flex;
	align-items: stretch;
}

.top-bar-menu {
	height: 100%;
	flex-grow: 1;
	flex-shrink: 0;
	font-weight: 400;

	.top-bar-item.create-account,
	.top-bar-item.go-home {
		margin-right: 1em;
	}
}

.go-home {
	.button {
		width: 210px;
	}
}
.top-bar-left {
	justify-content: flex-start;
	margin-right: auto;
}

.top-bar-right {
	justify-content: flex-end;
	margin-left: auto;
}

.top-bar-item,
.top-bar-right-item,
.top-bar-below-item {
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1.5;
	flex-grow: 0;
	flex-shrink: 0;
}

.top-bar-item {
	.fa-chevron-down {
		color: $primary-color;
		margin-left: 8px;

		&.premium {
			color: $premium-hover-color;
		}

		&.prime {
			color: $prime-color;
		}
	}
}

.top-bar-below-item {
	font-size: 14px;
	height: 50px;
	padding: 0 1rem;
	color: white;
	transition: all 0.15s linear;

	> i {
		color: $primary-hover-color;
		margin-right: 5px;
		font-size: 17px;
	}

	@include fullhd() {
		padding: 0 1.5rem;
	}

	@include desktop-only() {
		padding: 0 0.7rem;
	}
}
.top-bar-below-item-prime i {
	color: $prime-color !important;
}

.top-bar-below-item.active,
.top-bar-below-item:hover {
	box-shadow: inset 0 -5px 0 $primary-hover-color;
	color: $primary-hover-color;
}

.top-bar-icon-wrapper {
	width: 36px;
	height: 36px;
	background-color: #282a36;
	border-radius: 50%;
	margin: 0 0.4em;
}

.top-bar-notification-type {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 36px;
	height: 36px;
	cursor: pointer;
	background-position: center;
	position: relative;

	// Allow solid, regular and brand icons.
	&.fas,
	&.fab,
	&.far {
		color: #6e7181;
		font-size: 16px;

		&.is-active {
			color: $primary-color;
		}
	}
}

.top-bar-unread-count {
	position: absolute;
	min-width: 18px;
	min-height: 18px;
	top: -5px;
	right: -5px;
	background-color: #f02849;
	border-radius: 50%;
	font-size: 10px;
	color: #fef4f6;
	font-weight: 600;
	display: flex;
	justify-content: center;
	align-items: center;
}

.top-bar-search-icon {
	background-size: 26px 26px;
	background-repeat: no-repeat;
	filter: grayscale(100%) brightness(200%);
	cursor: pointer;

	&.is-active {
		filter: none;
	}
}

.top-bar-action-icon,
.top-bar-message-icon,
.top-bar-activity-icon {
	background-repeat: no-repeat;
	background-position: center;

	&.is-active {
		filter: none;
	}
}

.top-bar-action-icon,
.top-bar-message-icon,
.top-bar-activity-icon,
.top-bar-search-icon {
	background-size: 18px 18px;
}

.top-bar-volume {
	font-size: 22px;
	cursor: pointer;
	width: 34px;
	height: 34px;
	color: #6e7181;
}
.top-bar-notification-center {
	font-size: 22px;
	color: #6e7181;
}

.top-bar-profile {
	padding-right: 1.5em;
	padding-left: 1em;
	position: relative;

	.top-bar-caret {
		display: none;
	}
	@include desktop() {
		.top-bar-caret {
			display: unset;
			padding-right: 0.5em;
		}
	}
}

.top-bar-profile-opener {
	cursor: pointer;
}

.top-bar-avatar {
	width: 40px;
	height: 40px;
	background-repeat: no-repeat;
	background-size: cover;
	border: 2px solid $primary-hover-color;
	border-radius: 50%;
}

.top-bar-user {
	white-space: nowrap;
	font-weight: normal;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 15px;
	display: flex;
	align-items: center;

	@media (max-width: 1464px) {
		display: none;
	}
}

.top-bar-caret {
	font-size: 21px;
	padding-left: 0.5em;
	color: $primary-hover-color;
}

.top-bar-action-icon {
	background-image: url(../img/menu/requests.png);
}

.top-bar-message-icon {
	background-image: url(../img/menu/chat.svg);
}

.top-bar-activity-icon {
	background-image: url(../img/menu/activity.png);
}

.top-bar-below-icon {
	width: 25px;
	height: 25px;
	background-position: 0px -2px;
	background-size: cover;
}

.top-bar-dropdown-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	width: 40px;
	height: 30px;
	background-size: 26px 26px;
	background-repeat: no-repeat;
	background-position: center;
	color: $primary-hover-color;
	font-size: 14px;
}

.top-bar-dropdown {
	display: none;
	min-width: 210px;
	position: absolute;
	top: 68px;
	right: 0;
	z-index: 100;
	background-color: $notifications-background-color;
	padding: 1em 10px;
	border-radius: 15px;
	cursor: auto;

	&.is-active {
		display: block;
	}

	.top-bar-item {
		justify-content: flex-start;
		font-weight: 400;
		cursor: pointer;
		color: #e2e6ef;
		height: 35px;

		&:hover,
		&.active {
			background-color: rgba(27, 29, 38, 0.6);

			transition: background-color 0.2s;
			border-radius: 5px;
		}
	}

	hr {
		border: 0;
		height: 1px;
		background-color: #2d2d38;
	}
}
.top-bar-notifications-dropdown {
	right: 0;
}
.top-bar-notifications-dropdown,
.top-bar-search-dropdown {
	display: none;
	position: absolute;
	width: 320px;
	max-width: 100%;
	top: $top-bar-height;
	z-index: 500;

	@include mobile() {
		margin-left: 120px;
	}

	&.is-active {
		display: block;
	}
}

.top-bar-notifications-content {
	max-height: 300px;
	overflow: auto;
	line-height: 100px;
	text-align: center;
	background-color: $notifications-background-color;
	border-color: $notifications-background-color;
	box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.4);
	color: #fff;
	border-radius: 15px;

	&.is-search-dropdown {
		line-height: normal;
	}

	.no-notifications-text {
		display: flex;
		flex-direction: column;
		align-items: center;
		line-height: 20px;
		padding: 2em;

		.top-bar-action-icon {
			background-size: 40px 40px;
			height: 40px;
			width: 40px;
		}
		.title {
			font-weight: 600;
			margin-top: 20px;
			margin-bottom: 10px;
			font-size: 14px;
			text-transform: capitalize;
		}
		.subtext {
			font-size: 10px;
		}
	}
	&.chatpopup {
		line-height: normal;
		text-align: left;
		background-color: #191a24;
	}
}

.top-bar-search-field {
	width: 100%;
}

.top-bar-search-result {
	display: block;
	width: 100%;
	height: 45px;
	line-height: 45px;
	text-align: left;
	color: #000;
	padding-left: 10px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-top: none;
	cursor: pointer;
	overflow: hidden;

	> .avatar {
		margin-right: 10px;
		border: 2px solid $button-color;
	}

	> .username {
		display: inline-block;
		font-weight: 500;
		width: 200px;
		white-space: nowrap;
	}

	&.hover,
	&:hover {
		background-color: $primary-hover-color;
	}

	&:active {
		color: $grey-text-color;
		background-color: $secondary-hover-color;
	}
}
.top-bar-hamburger-menu {
	display: none;

	&.is-active {
		display: block;
	}

	#left-side-navigation {
		position: absolute;
		width: 100%;

		#left-side-navigation-content {
			position: relative;
			max-height: calc(100vh - #{$top-bar-height});
			width: 100%;
			top: 0;
			overflow: auto;

			.left-side-menu {
				padding-top: 0;
			}
		}
	}
}
.top-bar-menu {
	.hamburger {
		padding-top: 10px;
	}
	.top-bar-mobile-search {
		.fa-search {
			color: #6e7181;
			&.is-active {
				color: $primary-color;
			}
		}
	}
}

@include mobile() {
	.top-bar-menu {
		.top-bar-left {
			width: 100%;
		}
	}
}

@include mobile() {
	.top-bar-login {
		height: 26px;
		width: 135px;
		background-size: cover;
	}

	.top-bar-volume {
		font-size: 20px;
		margin-right: 0.5em;
	}

	.top-bar-dropdown .top-bar-item,
	.top-bar-below-item {
		font-size: 12px;
		width: 100%;
		flex-direction: row;
	}

	.top-bar-dropdown .top-bar-item.active {
		background-color: transparent;
		box-shadow: inset 0 -5px 0 $primary-hover-color;
	}

	.top-bar-below-icon {
		width: 25px;
		height: 25px;
	}

	.top-bar-dropdown-icon {
		width: 25px;
		height: 25px;
		background-size: 25px 25px;
		padding-right: 32px;
	}

	.top-bar-dropdown {
		position: fixed;
		height: 480px;
		width: 100%;
		background-color: #1b1d25;
		overflow-x: auto;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;

		&::-webkit-scrollbar {
			display: none;
		}

		&.is-active {
			display: flex;
			flex-direction: column;
		}

		.top-bar-item {
			justify-content: flex-start;
			padding-left: 32px;
			height: 60px;
			font-weight: 550;
			font-size: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			border-bottom: 1px solid $dark-grey-border;
		}
	}
	.on-the-left-touch {
		margin-right: auto;
	}
	.top-bar-avatar {
		width: 25px;
		height: 25px;
	}
}

@include tablet() {
	.top-bar-below-icon {
		&.icon-ranking {
			margin-right: 5px;
		}
	}
	.top-bar-right-item {
		padding-top: 0;
	}
	.top-bar-avatar {
		width: 25px;
		height: 25px;
	}
	.top-bar-volume {
		display: none;
	}
	.on-the-left-touch {
		margin-right: auto;
	}
}

@include desktop() {
	.top-bar-volume {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.on-the-left-touch {
		margin-right: unset;
	}
}

@include widescreen() {
	.top-bar-avatar {
		width: 35px;
		height: 35px;
	}
}

.prime-header-button {
	background: linear-gradient(0deg, rgba($prime-color, 0.1) 0%, rgba($prime-color, 0.01) 100%);
	color: $prime-color;
	align-items: center;
	padding-top: 10px;
	font-size: 14px;
	justify-content: center;

	&.active {
		color: #fff;
	}

	.fa-star {
		font-size: 18px;
		margin-right: 0.5em;
		padding-bottom: 4px;
	}

	@include widescreen() {
		width: 7em;
	}
	@include fullhd() {
		width: 9em;
	}
}
.premium-header-button {
	max-width: 320px;
	background: linear-gradient(
		0deg,
		rgba($premium-color-dark, 0.2) 0%,
		rgba($premium-color-dark, 0.01) 100%
	);
	color: #ffa400;
	white-space: nowrap;
	align-items: center;
	padding-top: 10px;
	font-size: 14px;
	justify-content: center;

	.icon-premium {
		width: 25px;
		height: 25px;
		margin-right: 8px;
	}

	&.active {
		color: white;
	}
}

.secsgo-header-button {
	background-color: #15385f;
	color: #fed143;
}
.secsgo-header-logo {
	background-repeat: no-repeat;
	background-size: contain;
	margin: 15px auto auto auto;
	background-position: 0px 0px;
	min-width: 45px;
	line-height: 37px;
	padding-left: 50px;
	height: 37px;
}

.drpepper-header-button {
	color: white;
	background: linear-gradient(0deg, #d31931 0%, #5f0a36 100%);
	&.active {
		background: unset;
		background-color: $primary-hover-color;
	}
}
.drpepper-header-logo {
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	float: left;
	margin: -26px auto auto auto;
	width: 35px;
	height: 55px;
}
.getinpro-header-button {
	color: white;
	background-image: url(../img/getinpro/nav-bg.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #9500c7;
	transition: unset;
	&.active {
		background: unset;
		background-color: $primary-hover-color;
	}
}
.getinpro-header-logo {
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	float: left;
	margin: -2px 5px auto auto;
	width: 32px;
	height: 20px;
}
.omenbyhp-header-button {
	color: white;
	background: #620d22;
	&.active {
		background: unset;
		background-color: $primary-hover-color;
	}
}
.omenbyhp-header-logo {
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	margin: -30px -10px auto -10px;
	width: 120px;
	height: 70px;
}
.telenor-header-button {
	color: white;
	background: #0c0d21;
	&.active {
		background: unset;
		background-color: $primary-hover-color;
	}
}
.telenor-header-logo {
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	margin: -40px auto auto auto;
	width: 90px;
	height: 90px;
}
.tarczynski-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/tarczynski/tarczynski_topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 95px;
	height: 70px;
}
.midsummer-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/midsummer/midsummer_topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.oneblade-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/oneblade/topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 170px;
	height: 70px;
}
.leo-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/leovegas/topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.gaminate-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/gaminate/topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.shark-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/shark/shark_topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 200px;
	height: 70px;
}
.fragbite-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/fragbite/fragbite_topbar.png);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 175px;
	height: 70px;
}
.pucko-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/pucko/puckomondays_topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.ggbet-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/landing/ggbet/ggbet_topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.doritos-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/doritos/doritos_topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 152px;
	height: 70px;
}
.omen-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/omenopen/omennew_topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.mimi-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/mimi/topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.yoggi-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/yoggi/topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.ambush-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/ambush/topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 175px;
	height: 70px;
}

.deltaco-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/deltaco/topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.komplett-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/komplett/komplettcsgo_topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.flashe-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/flashe/flashegaming_topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.maxgaming-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/maxgaming/maxgaming_topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.max-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/maxgaming/maxgamingsteelseries_topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.elisa-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/elisa/topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 175px;
	height: 70px;
}
.christmas-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/elisa/topbar_row.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
	&.fi {
		background-image: url(../img/elisa/topbar.png);
	}
}
.nordic-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/nordic/topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}

.gcs-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/gcs/gcs_header_button.jpg);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 126px;
	height: 70px;
}

.asiimov-header-button {
	color: white;
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/asiimov/topbar_asiimov.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.astralis-header-button {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../js/components/landing/astralis/assets/astralis_topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.dragonlore-header-button {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/dragonLore/dragonlore_top.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.havu-header-button {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/havu/landing/topbar_havu.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.ggscupsummer2021-header-button {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/landing/gcscupsummer2021/topbar_gcs.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.heaton-howl-header-button {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/heatonhowl/heatonhowl_topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.volt-header-button {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/landing/volt/topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.honoris-header-button {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/landing/honoris/topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.kniferaffle-header-button,
.giveaway-header-button,
.megamarz-header-button,
.izako-header-button {
	vertical-align: middle;
	display: inline-block;
	background-size: 100px 60px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.megamarz-header-button {
	background-image: url(../img/topbar/megamarz.png);
	background-size: unset;
}
.kniferaffle-header-button {
	background-image: url(../img/landing/kniferaffle/topbar.png);
}
.giveaway-header-button {
	background-image: url(../img/landing/giveawayPoland/topbar.png);
}
.izako-header-button {
	background-image: url(../js/components/landing/izakoDragonLore/assets/upper_banner.png);
	background-size: unset;
}
.ago-header-button {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/landing/ago/topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}
.relaunch-header-button {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/relaunch/topbar.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}

.scou7-header-button {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/topbar/scou77.png);
	background-size: contain;
	background-repeat: no-repeat;
	width: 140px;
	height: 70px;
}

.referral-games-header-button {
	display: flex;
	height: $top-bar-height;
	align-items: center;
	margin-top: 10px;

	.icon-refer-friend {
		width: 39px;
		height: 39px;
		margin: 0 5px;
	}
	.referral-games-text {
		.title {
			font-size: 13px;
			line-height: 15px;
			color: #00d555;
		}
		.subtext {
			font-size: 10px;
			color: #fff;
		}
		.referral-progressbar {
			display: flex;
			padding-top: 2px;

			.bar {
				display: block;
				width: 20%;
				height: 6px;
				margin-right: 2px;
				background-color: darkgrey;

				&:last-child {
					margin-right: 0;
				}
				&.colored {
					background-color: #00d555;
				}
			}
		}
	}
}

@media (max-width: 1920px) {
	.top-bar-below-left {
		.prime-header-button,
		.premium-header-button {
			display: block !important;
		}
	}
}

.top-bar-search-wrapper {
	display: flex;
	justify-content: flex-start;
	padding-left: 16px;
	padding-right: 32px;

	.top-bar-search {
		position: relative;
		width: 100%;
		max-width: 256px;
		padding-top: 10px;

		.top-bar-search-field {
			padding-right: 30px;
			border: 1px solid #252732;
			padding-top: 9px;
			padding-bottom: 9px;
			background-color: #14151d;
			color: #c0c6d1;
		}

		.fa-search {
			position: absolute;
			top: 22px;
			right: 12px;
			color: #5e6169;
			font-size: 12px;
		}
	}
}
.top-bar-player-counter {
	.text {
		display: flex;
		flex-direction: column;
	}
	.fa-caret-right {
		padding: 0 1em;
		font-size: 24px;
	}
}
.mobile-search {
	position: absolute;
	top: calc(#{$top-bar-above-height} + #{$top-bar-height});
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: $content-background-color;

	.top-bar-search-dropdown {
		margin-left: 0;
		position: relative;
		top: 0;
		width: 100%;
		max-width: 100%;

		.top-bar-notifications-content {
			border-radius: 0;
			max-height: unset;

			.top-bar-search-result {
				.search-result-type {
					float: right;
					padding-right: 20px;
					font-weight: 300;
					font-size: 8px;
				}
			}
		}
	}
}

.logout-top-bar-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 70px;
	background-color: #21232d;
	padding: 0 15px;

	@include mobile() {
		justify-content: space-evenly;
	}
}

.logout-top-bar-item {
	background-color: #21232d;

	display: flex;
	align-items: center;
	height: 70px;

	justify-content: flex-start;

	&.right-side {
		justify-content: flex-end;

		@include mobile() {
			justify-content: center;
			flex: 1 1 auto;
		}
	}
}

.kandis-kampen-top-bar-counter-holder {
	height: 70px;
	padding: 10px 20px;

	.kandis-kampen-top-bar-counter {
		display: flex;
		align-items: center;
		height: 100%;

		.logo {
			background-image: url(../img/kandiskampen/logo_topbar.png);
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;

			width: 46px;
			height: 50px;
		}

		.top-bar-counter-holder {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-left: 10px;

			.counter-holder {
				display: flex;
				justify-content: center;
				width: 100%;
				align-items: center;
				font-weight: 900;

				.counter-number {
					width: 25px;
					height: 30px;
					background-color: #2e313e;
					border-radius: 3px;
					color: #e32a1d;
					font-size: 20px;
					margin-right: 5px;

					display: flex;
					justify-content: center;
					align-items: center;
				}

				.counter-currency {
					color: #fff;
					font-size: 14px;
				}
			}

			.link {
				margin-top: 5px;
				color: #e32a1d;
				font-weight: 900;
				font-size: 13px;
			}
		}
	}
}

.header-premium-offer {
	margin-left: 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 220px;

	.price-holder {
		margin-top: -4px;
	}

	span {
		text-transform: unset;
		font-weight: 400;
		font-size: 13px;
		line-height: unset;
		color: #ffa400 !important;
		letter-spacing: 0.5px;
		-webkit-font-smoothing: auto;

		&.price {
			color: #fff !important;
			font-size: 10px;
			letter-spacing: 0.5px;
		}
	}
}

.no-notifications {
	.empty-notifications-suggested-friends {
		line-height: normal;
		background-color: $table-header-background-color;
		padding-left: 1em;
		padding-right: 1em;

		.suggested-friends-header {
			color: #c6cfde;
			font-weight: 500;
			padding-top: 0.5em;
			padding-bottom: 0.5em;
		}

		.suggested-friend {
			display: flex;
			align-items: center;
			height: 50px;
			overflow: hidden;
			justify-content: space-between;
			border-bottom: 1px solid #2d2f3b;
			transition: all 0.5s;

			&.hidden {
				height: 0;
				margin: 0;
				padding: 0;
				overflow: hidden;
				border-bottom: 0;
			}
		}
		.suggested-friend:last-child {
			border-bottom: 0;
		}

		.username {
			display: flex;
			align-items: center;

			.friend-avatar {
				margin: 0;
				margin-right: 10px;
			}
			.friend-list-username {
				display: flex;
				flex-direction: column;
				text-align: left;

				.friend-source {
					color: #c6cfde;
					font-size: 13px;
				}
			}
		}
		.add-friend {
			width: 20px;
			height: 20px;

			.fa-user-plus {
				font-size: 15px;
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:45";