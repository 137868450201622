.frag {
	clear: both;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 13px;
	position: sticky;
	top: calc(#{$top-bar-height} + #{$top-bar-above-height} + 10px);

	.frag-text {
		display: inline-block;
		color: #777;
		text-align: right;
		width: 100%;
		font-size: 12px;
		line-height: 20px;
	}

	&.frag-bottom {
		margin-top: 10px;
	}
	&.frag-left {
		margin-right: 30px;
	}
	&.frag-right {
		margin-left: 30px;
	}
	&.static {
		position: static;
	}
}

;@import "sass-embedded-legacy-load-done:16";