@import '../constants';

.messaging {
	position: absolute;
	bottom: 0;
	right: 20px;
	width: 330px;
	flex-direction: column;
	box-shadow: 0px 3px 6px #00000042;

	display: none;

	@include desktop() {
		display: flex;
	}

	.header {
		border-radius: 4px 4px 0 0;
		background-color: #20212d;
		min-height: 46px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #fff;
		padding: 15px 14px;
		cursor: pointer;

		@keyframes headerPulse {
			0% {
				background-color: #20212d;
			}
			50% {
				background-color: $primary-color;
			}

			100% {
				background-color: #20212d;
			}
		}

		&.highlight {
			animation-name: headerPulse;
			animation-duration: 0.5s;
		}

		.unseen-indicator {
			display: inline-block;
			margin-left: 5px;
			width: 9px;
			height: 9px;
			background-color: #20212d;
			border-radius: 50%;
			transition: background-color 0.5s ease;

			&.highlight {
				background-color: $primary-color;
			}
		}

		i.fa-chevron-down {
			color: #838a97;
			cursor: pointer;

			transition: transform 0.2s;

			@keyframes chevronPulse {
				0% {
					color: #838a97;
				}

				50% {
					color: #ffffff;
				}

				100% {
					color: #838a97;
				}
			}

			&.highlight {
				animation: chevronPulse;
				animation-duration: 0.5s;
			}
		}

		i.closed {
			transform: rotateX(180deg);
		}
	}

	.activeChatHeader {
		border-radius: 4px 4px 0 0;
		background-color: #20212d;
		min-height: 46px;
		width: 100%;
		display: flex;
		align-items: center;
		color: #fff;
		justify-content: space-between;
		align-items: center;
		padding: 0 14px 0 0;
		cursor: pointer;

		.return-button {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			height: 46px;
			background-color: transparent;
			border: none;
			padding: 0 16px 0 14px;
			cursor: pointer;

			&:focus {
				outline: none;
			}

			.fa-arrow-left {
				position: relative;
				display: flex;
				align-items: center;
				color: #ffffff;

				.unseen-indicator {
					position: absolute;
					width: 5px;
					height: 5px;
					top: -3px;
					right: -3px;
					background-color: $primary-color;
					border-radius: 50%;
				}
			}
		}

		.names {
			display: flex;
			align-items: center;
			max-width: 220px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			span {
				display: flex;
				align-items: center;
				height: 100%;
			}

			a {
				color: #ffffff;
				height: 100%;
				line-height: 46px;
			}

			.online-status-indicator {
				vertical-align: unset;
				margin-left: 6px;
			}
		}

		.freespace {
			cursor: pointer;
			flex-grow: 1;
			height: 100%;
			min-height: 45px;
		}

		.buttons {
			position: relative;
		}

		.options-button {
			height: 46px;
			background-color: transparent;
			border: none;
			cursor: pointer;
			padding: 0 8px;

			&:focus {
				outline: none;
			}

			.fa-ellipsis-v {
				cursor: pointer;
				margin: 0 5px;
				color: #ffffff;
			}
		}

		.ellipsisMenu {
			position: absolute;
			border-radius: 4px;
			background-color: #262734;
			box-shadow: 2px 2px 4px #00000029;
			top: 23px;
			right: 100%;
			z-index: 1;
			white-space: nowrap;

			p {
				padding: 5px 10px;
				font-size: 12px;
				cursor: pointer;

				&:hover {
					background-color: #51526b;
					border-radius: inherit;
				}
			}
		}

		.fa-chevron-down {
			color: #838a97;
			cursor: pointer;
		}
	}

	.messaging-container {
		height: 0;
		transition: height 0.2s;
		background-color: #191a24;

		&.active {
			height: 350px;
		}

		.body {
			position: relative;
			height: 100%;
			overflow: auto;
			color: #fff;
			background-color: #191a24;

			&::-webkit-scrollbar-track {
				padding: 0;
				margin: 0;
			}

			&::-webkit-scrollbar {
				background-color: transparent;
				width: 15px;
			}

			&::-webkit-scrollbar-thumb {
				background-color: transparent;
				border-radius: 8px;
			}
		}

		.body:hover {
			&::-webkit-scrollbar-thumb {
				background-color: $scrollbar-thumb-color;
			}
			&::-webkit-scrollbar-thumb:hover {
				background-color: $scrollbar-thumb-hover-color;
			}
		}

		.chatTitle {
			margin: 12px 0 4px 14px;
		}

		.no-chats {
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			color: $dark-grey-text-color;
			font-weight: 300;
			width: 100%;
			height: 100%;
			padding: 0 60px;
		}
	}
}

.chatPreview {
	height: 53px;
	display: flex;
	align-items: center;
	padding: 8px 14px;
	width: 100%;
	cursor: pointer;

	p {
		margin: 0;
		line-height: unset;
	}

	&:hover {
		background-color: #1d1f2a;
	}

	.info {
		display: flex;
		flex-direction: column;
		margin-left: 12px;

		.username {
			font-size: 14px;
			color: #fff;
			font-weight: 500;
			margin-bottom: 2px;

			.online-status-indicator {
				vertical-align: unset;
			}
		}

		.message {
			margin-left: auto;
			font-size: 12px;
			color: #838a97;
			overflow-x: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 150px;
		}
	}

	.time {
		align-self: flex-start;
		margin-left: auto;
		margin-top: 4px;
		font-size: 10px;
		color: #838a97;
	}

	.preview-avatars-container {
		position: relative;
		width: 36px;
		height: 36px;
		margin: 0;

		.user-chat-message-avatar {
			position: absolute;
			top: 0;
			width: 36px;
			height: 36px;
			margin: 0;

			&:first-child {
				left: 0px;
				z-index: 1;
			}

			&:nth-child(2) {
				left: 4px;
			}
		}

		.unseen-indicator {
			position: absolute;
			z-index: 2;
			width: 9px;
			height: 9px;
			top: 0;
			left: 0;
			background-color: $primary-color;
			border-radius: 50%;
		}
	}
}

;@import "sass-embedded-legacy-load-done:141";