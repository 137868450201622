.bullet-points {
	max-width: 800px;
	margin: 0 auto;

	&-item {
		position: relative;
		display: block;
		margin-top: 24px;
		padding-left: 54px;
		padding-right: 30px;
		font-size: 16px;
		line-height: 23px;
		font-weight: 300;
		color: #fff;

		&:before {
			position: absolute;
			left: 30px;
			top: 8px;
			content: '';
			width: 7px;
			height: 7px;
			background-color: #ed9521;
		}
	}
}
