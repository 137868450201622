body.theme-dark:not(.disable-theme-dark) {
	input[type='radio'] {
		appearance: none;
		height: 14px;
		width: 14px;
		margin: 2px;
		margin-right: 5px;
		padding: 0;
		border-radius: 50%;
		border: 2px solid #46475e;
		cursor: pointer;
	}

	input[type='radio']:hover {
		background-color: #313136;
	}

	//on press
	input[type='radio']:active {
		background-color: #12568c;
	}

	input[type='radio']:checked {
		content: ' ';
		border: 2px solid #2196f3;
		background-image: url('data:image/svg+xml;utf8,<svg fill="%2320212D" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6" stroke="black" stroke-width="5" fill="%232196f3"/></svg>');
		background-repeat: no-repeat;
		background-position: center;
	}
}

;@import "sass-embedded-legacy-load-done:18";