.signUpVideoBackground {
	position: absolute;
	width: 100vw;
	height: 100vh;
	object-fit: cover;
	opacity: 0.2;
	-webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
	mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

.signUpBackgroundOverlay {
	position: absolute;
	width: 100vw;
	height: 100vh;
	object-fit: cover;
	background: linear-gradient(180deg, #b500b5 5%, #0000ff 50%, #00a2ff2c 90%, #14151d);
	opacity: 0.2;
}

.signupPageLeft {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 50%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.699);
	overflow: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	button {
		width: 256px;
	}

	input {
		width: 256px;
	}

	@media (max-width: 1000px) {
		width: 100%;
		float: none;
		right: 0;
	}
}

.signupPageContent {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 32px;
}

.signupPageContentTop {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 20px 30px 0 30px;

	div {
		&:nth-child(2) {
			margin-top: 8px;
			select {
				width: 128px;
			}
		}
	}
}

.signupPageLogo {
	background-image: url(../../../img/logo.png);
	width: 125px;
	height: 70px;
	background-size: cover;
	background-repeat: no-repeat;
}
