@import '../styles/colors';
@import '../styles/fonts';

.es-text-h1 {
	font-family: #{$font-family};
	font-weight: $font-weight-medium;
	font-size: 32px;
}

.es-text-h2 {
	font-family: #{$font-family};
	font-weight: $font-weight-medium;
	font-size: 24px;
}

.es-text-h3 {
	font-family: #{$font-family};
	font-weight: $font-weight-medium;
	font-size: 18px;
}

.es-text-body {
	font-family: #{$font-family};
	font-weight: $font-weight-normal;
	font-size: 14px;
}

.es-text-button {
	font-family: #{$font-family};
	font-weight: $font-weight-medium;
	font-size: 13px;
}

.es-text-link {
	font-family: #{$font-family};
	font-weight: $font-weight-medium;
	font-size: 14px;
}

.es-text-color-grey {
	color: $text-color-grey;
}
.es-text-color-white {
	color: $text-color-white;
}
.es-text-color-blue {
	color: $text-color-blue;
}

.es-text-align-left {
	text-align: left;
}
.es-text-align-center {
	text-align: center;
}
.es-text-align-right {
	text-align: right;
}
