.gather-content {
	max-width: 1400px;
	margin: auto auto 0;
}
.gather-top-info {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: -15px;
	margin-bottom: 2px;
}
.gather-name {
	font-size: 28px;
	overflow-wrap: break-word;
	padding: 12px;
	font-weight: 400;

	.fa-star {
		color: #cb0;
		margin-right: 5px;
	}
}
.gather-map {
	margin-left: auto;
	margin-right: 32px;
	color: $grey-text-color;
	font-size: 16px;
	font-weight: 400;
}
.gather-server-location {
	color: $grey-text-color;
	font-size: 16px;
	font-weight: 400;
	margin-right: 8px;
}
.gather-server-location-label {
	margin-right: 20px;
}
.gather-country-flag {
	display: inline-block;
	width: 20px;
	height: 15px;
	background-size: cover;
	position: relative;
	top: 1px;
}
.gather-title-label {
	color: $primary-hover-color;
}
.gather-microphone {
	position: relative;
	display: inline-block;
	margin-left: 4px;
	margin-right: 4px;
	top: -3px;
	background-size: cover;
	filter: invert(1);
}
.gather-state-icon {
	position: relative;
	display: inline-block;
	background-size: cover;
	width: 32px;
	height: 32px;
	margin-left: 4px;
	margin-right: 4px;
}
.gather-team-damage {
	background-image: url(../img/gathers/team-damage.png);
}
.gather-unranked {
	background-image: url(../img/gathers/unranked.png);
}
.gather-ranked {
	background-image: url(../img/gathers/ranked.png);
}

.gather-header {
	position: relative;
	height: 48px;
}
.gather-header-fragleague {
	max-width: 1297px;
	width: 100%;
	height: 130px;
	margin: 0px auto 20px auto;
	background-image: url(../img/banners/fragleague.png);
	background-position: 50% 50%;
}

.star-gather-header {
	position: relative;
	background-image: url(../img/gathers/stargather.jpg);
	background-position: 50% 30%;
	background-size: cover;
	height: 95px;

	.star-gather-label {
		position: absolute;
		left: 20px;
		top: 30px;
		font-size: 21px;
		font-weight: 500;
		line-height: 40px;
		color: #f98a00;

		.icon-star-gather {
			margin-top: -10px;
			margin-right: 10px;
		}
	}

	.star-gather-drop-label {
		position: absolute;
		right: 15px;
		top: 10px;
		color: #fff;
		font-size: 14px;
		font-weight: 700;
	}

	.gather-title-label {
		color: #fc7e06;
	}
}

.sponsored-gather-drop-label {
	position: relative;
	padding-right: 15px;
	color: #fff;
	font-size: 14px;
	font-weight: 700;
}

.verified-gather-header {
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-image: url(../img/gathers/verifiedgather.jpg);
	background-position: 50% 30%;
	background-size: cover;
	padding: 0 20px;

	.verified-gather-label {
		font-size: 18px;
		font-weight: 500;
		line-height: 40px;
		color: #abff00;

		.icon-verified {
			margin-right: 10px;
		}
	}

	.verified-gather-drop-label {
		color: #fff;
		font-size: 14px;
		font-weight: 700;
	}

	.gather-title-label {
		color: #fc7e06;
	}
}

.gather-header-container {
	@include from(1200px) {
		margin-top: 0px !important;
	}
	@include desktop() {
		margin-top: 0px !important;
	}
	@include widescreen() {
		margin-top: 0px !important;
	}
}

.gather-header-container + .match-summary-page-header {
	margin-top: -12px !important;
}

.gather-title + .gather-header-container {
	margin-top: 0px !important;
}

.gather-header-container + .match-lobby-page-header {
	margin-top: -12px !important;
}

.gather-change-map-button {
	vertical-align: top;
	position: relative;
	min-height: 20px;
	height: 20px;
	line-height: 20px;
	font-size: 10px;
	padding-left: 4px;
	padding-right: 4px;
	margin-left: 8px;
	top: 2px;
}
.gather-map-selector-container {
	width: 100%;
	min-height: 163px;
	margin: 16px 0;
	padding: 8px 26px;
}

.gather-map-selector-actions {
	height: 50px;
	width: 100%;
}
.gather-map-selector-section {
	margin-bottom: 32px;
}
.gather-map-selector-category {
	text-transform: uppercase;
	font-size: 11px;
	margin-bottom: 8px;
}

#gather-map-selector {
	margin: 0 0 4px 0;
}
.gather-creator {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 10px;

	@include mobile() {
		text-align: center;
	}
}
.gather-creator-user {
	padding-top: 10px;
	padding-bottom: 10px;
	margin: auto;

	@include mobile() {
		margin: 10px 0;
	}
}
.gather-creator-user-avatar {
	display: inline-block;
	width: 45px;
	height: 45px;
	border: 2px solid $primary-hover-color;
	margin-right: 8px;
}
.gather-creator-user-label {
	font-size: 10px;
	text-transform: uppercase;
	color: #000;
	text-align: left;
}
.gather-creator-username {
	vertical-align: bottom;
	display: inline-block;
	color: $primary-hover-color;
	font-size: 20px;
	height: 45px;
}
.gather-creator-stats {
	color: $grey-text-color;
	font-size: 16px;
	display: flex;
	flex-direction: column;
	margin: auto;
	padding: 12px;
}
.gather-creator-stats-columns {
	padding: 10px 0 10px 10px;
}
.gather-creator-stats-column {
	vertical-align: top;
	display: inline-block;
	width: 49%;
}
.gather-creator-stats-row {
	margin-top: 8px;
}
.gather-creator-stat {
	line-height: 28px;

	&:first-child {
		margin-bottom: 10px;
	}
}
.gather-creator-last-column-stat {
	@include tablet() {
		text-align: center;
	}

	.value {
		color: $primary-hover-color;
	}

	.stat-icon {
		color: $primary-hover-color;
	}
}
.gather-creator-stat-value {
	float: left;
	margin-right: 6px;
	color: $primary-hover-color;
}
.gather-creator-stat-label {
	text-align: left;
}
.gather-creator-stat-single-line {
	display: inline-block;
	margin-right: 14px;
	white-space: nowrap;

	&:last-child {
		margin-right: 0;
	}
}
.gather-title-status {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.gather-status {
	line-height: 28px;
	text-align: center;
	color: #aee32d;
	font-size: 18px;
	font-weight: 500;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #262836;
	height: 110px;
	width: 100%;
}
.gather-status-icon {
	position: relative;
	display: inline-block;
	height: 34px;
	line-height: 34px;
	margin-top: 4px;
	margin-bottom: 4px;
	margin-right: 8px;
	font-size: 24px;

	&.fa-balance-scale {
		margin-right: 8px;
	}

	&.playing {
		width: 40px;
		height: 40px;
		background-image: url(../img/gathers/playing.png);
		background-size: cover;
		background-repeat: no-repeat;
		filter: invert(1);
	}
}
.gather-button {
	line-height: 45px;
	border-radius: 8px;
}
.gather-button.cancel {
	background-color: #aa000b;
}
.gather-status-label {
	vertical-align: top;
	display: inline-block;
}
.gather-status-buttons {
	display: inline-block;
	width: 114px;
}
.gather-status-buttons.admin {
	position: relative;
	top: 12px;
}
.gather-state-button {
	min-height: 30px;
	line-height: 30px;
}
.gather-caret {
	position: relative;
	top: 2px;
	font-size: 20px;

	&.fa-caret-right {
		margin-left: 6px;
	}
	&.fa-caret-left {
		margin-right: 6px;
	}
}
.gather-description {
	display: inline-block;
	line-height: 30px;
	color: $primary-hover-color;
	margin: 0 10px;
	font-weight: bold;
	font-size: 15px;
}

.gather-table {
	margin: 15px 0;

	.content-heading {
		display: inline-block;
		margin-right: 2em;
		.fas {
			color: $primary-color;
			margin-right: 10px;
		}
	}

	.gather-table-filter-wrapper {
		display: inline-block;
	}
}

.gather-table-header {
	width: 100%;
	height: 40px;
	padding-left: 20px;
	line-height: 40px;
	font-size: 16px;
	text-transform: uppercase;
}
.table-head-spacer {
	width: 1em;
	height: 1em;
	display: inline-block;
}
.gather-table-name-top {
	display: flex;
	flex-direction: column;
}
.gather-player-column {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.gather-player-row {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.gather-table-filter {
	font-weight: 400;
	text-transform: uppercase;
	display: inline-flex;
	justify-content: flex-start !important;
	text-transform: uppercase;

	@include mobile() {
		text-align: left;
	}

	@include tablet() {
		display: flex;
		justify-content: center;

		.filter-label {
			display: none;
		}
	}

	@include mobile() {
		display: flex;
		justify-content: left;
	}

	.clickable,
	.selected {
		color: #fff;
		font-size: 16px;
		padding: 12px 24px;

		@include mobile() {
			padding: 12px 14px;
		}

		@include tablet() {
			font-size: 14px;
			padding: 5px 12px;
		}

		@include widescreen() {
			white-space: nowrap;
		}

		.famfamfam-flag {
			padding: 0px 8px;
		}
	}

	.select-all {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}
	.selected {
		color: #0077a2;
	}
	.clickable {
		color: $grey-text-color;
		text-transform: uppercase;
	}
}
.gather-droprate {
	position: relative;
	display: inline-block;
	width: 32px;
	height: 24px;
	top: 8px;
	margin-right: 2px;
	background-size: cover;
	filter: invert(1);
}
.gather-table-droprate {
	line-height: normal;
}
.gather-table-drop-values {
	font-size: 12px;
	text-transform: uppercase;
	white-space: nowrap;
}
.gather-no-waiting-players {
	width: 100%;
	height: 40px;
	line-height: 40px;
	text-align: center;
	background-color: #eee;
}

.gather-picked {
	float: right;
	width: 100%;
}
.gather-picked-average-rating {
	vertical-align: top;
	display: inline-block;
	width: 320px;

	.average-rating {
		display: inline-block;
		font-size: 14px;
		margin-left: 16px;
	}
}
.gather-picked-empty-row {
	width: 100%;
	height: 40px;
	padding: 7px 16px;
	border-bottom: 1px solid $dark-grey-border;

	&.odd {
		background-color: #191a24b3;
	}

	&.even {
		background-color: rgba(25, 25, 25, 0);
	}
}
.gather-picked-empty {
	height: 26px;
	width: 100%;
	border: 1px dashed #555;
}
.gather-mute-cell,
.gather-pick-cell,
.gather-remove-cell {
	line-height: 40px;
	font-size: 22px;

	button {
		background: none;
		border: none;
		font-size: 1em;
		color: #88d307;
		padding: 0;
	}

	&.disabled button,
	&.disabled i {
		color: #b7b7b7;
	}
}

.gather-pick-cell,
.gather-remove-cell {
	position: sticky;
	right: 0;
	background-color: #21232c;
}

.gather-mute-cell button {
	color: #000;
}

.gather-info {
	height: 100%;
	background-color: #20222e;
}
.column.left {
	padding-left: 0;
}
.column.right {
	padding-right: 0;
}
.column.middle {
	flex: 1.4 1 auto;
	align-items: stretch;
	display: flex;
	flex-direction: column;
}

.gather-picking {
	@include mobile() {
		width: 100%;
		flex-direction: column;
	}
}
.gather-left {
	width: 49%;

	@include mobile() {
		width: 100%;
	}
}
.gather-right {
	width: 49%;

	@include mobile() {
		width: 100%;
	}
}
.gather-middle {
	text-align: center;
	width: 4%;
	min-width: 5px;
	padding-top: 166px;

	@include mobile() {
		display: none;
	}

	&.picking {
		width: 2%;
	}

	&.balancing {
		min-width: 22px;
	}
}

.gather-remaining-players {
	padding-top: 6px;
}

.gather-picked-count {
	color: $button-color;
	padding: 4px;
}

.gather-right {
	@include mobile() {
		margin-top: 10px;
	}
}

.gather-chat {
	float: left;
	width: 100%;
	margin-top: 22px;

	.box-content {
		height: 220px;
		padding: 0px 0px;
		overflow: hidden;
		border: none;
	}
}
.gather-chat-input-field {
	width: 100%;
	height: 100%;
	margin-left: 10px;
	margin-right: 10px;

	&:disabled {
		background-color: rgb(233, 233, 233);
	}
}
.gather-chat.no-input .box-content {
	height: 165px;
}

.gather-chat-input-field,
.gather-chat-input-field:focus {
	border: 0;
}

.gathers-player-table {
	width: 100%;
	white-space: nowrap;
}
.gathers-player-table tr {
	border: none !important;
	&.accepted td {
		background-color: #94da81;
	}

	&.declined td {
		background-color: #ce4040;
	}
}
.gathers-player-table.creator {
	.gather-players-username {
		max-width: 76px;
	}
}
.gather-players-left-swap-column {
	padding-right: 0;
	input {
		vertical-align: middle;
	}
}
.gather-players-right-swap-column {
	padding-left: 0;
	input {
		vertical-align: middle;
	}
}
.gather-players-group-column {
	padding: 0;
}
.gather-players-rating-column-cell {
	white-space: nowrap;
}
.gather-players-ping-column {
	padding-left: 0;
}

.gather-players-username {
	max-width: 160px;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 16px;

	&.small {
		max-width: 40px;
	}
}
.create-gather-player-column {
	.famfamfam-flag {
		vertical-align: middle;
		margin-right: 10px;
	}
	.table-avatar {
		margin-right: 10px;
	}
	.online-status-indicator {
		display: none;
	}
}

.gathers-player-table th {
	white-space: nowrap;
}
.gathers-player-table thead {
	background-color: $table-header-background-color;
}
.gathers-player-table + .gather-table-header {
	margin-top: 22px;
}

.gather-balance-button {
	background: none;
	border: none;
	font-size: 22px;
	color: #a2ff00;
	cursor: pointer;

	i {
		display: block;
	}
	&.disabled {
		cursor: default;
		color: #b7b7b7;
	}
}

.gather-ready-check-status {
	width: 100%;
	height: 220px;
	background-color: $content-background-color;
	text-align: center;
	padding: 22px;
	margin-top: 22px;
}
.gather-ready-check-time {
	font-size: 46px;
}
.gather-ready-check-label {
	line-height: 54px;
}

.icon-gather-group {
	width: 14px;
	height: 14px;
	background-size: cover;
	background-image: url(../img/misc/gather_group.png);
	filter: brightness(0.6);
}
.icon-droprate {
	width: 30px;
	height: 30px;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url(../img/gathers/lobby/droprate_icon.png);
	vertical-align: top;
	display: inline-block;
}

.gather-join {
	width: 100%;
	margin-bottom: 12px;
	background-color: #1d1e29;
	display: flex;
	align-items: center;
	padding: 1em;
	flex-wrap: wrap;

	> div {
		flex: 1;
	}

	.fa-caret-right {
		padding: 1em;
		font-size: 28px;
	}
}
.gather-join-left {
	vertical-align: top;
	display: flex;
	align-items: center;
	padding: 8px;
}
.gather-join-left-title {
	margin-bottom: 5px;
	text-align: right;
}
.gather-join-group-selection {
	vertical-align: top;
	display: inline-block;
	width: 100px;
	height: 100px;
	margin: 10px;
	text-align: center;
	background-color: #212230;

	&.selected {
		border: 1px solid $primary-hover-color !important;
	}
}
.gather-join-right {
	display: inline-block;
	padding-right: 12px;
}
.gather-microphone-option {
	margin: 16px 0;
	display: flex;
	align-items: center;
}
.gather-microphone-radio-button {
	width: auto;
	margin-right: 8px;
}
.gather-join-button {
	width: 100%;
	max-width: 10em;
	min-height: 31px;
	line-height: 31px;
	background: linear-gradient(to bottom, #88ce00 50%, #6da600 50%);
	border: 0 !important;
}

.gather-join-solo-icon {
	width: 52px;
	height: 52px;
	margin: 12px auto 4px auto;
	background-size: cover;
	background-image: url(../img/menu/solo.png);
}
.gather-join-group-icon {
	width: 52px;
	height: 52px;
	margin: 12px auto 4px auto;
	background-size: cover;
	background-image: url(../img/menu/team.png);
}

.gather-creator-row td {
	background-color: #91ddff;
}

.gather-player-list-player {
	text-align: left;
	font-size: 14px;
}

.gather-group-friends-invite {
	padding: 8px 10px;
	background-color: $content-background-color;
	text-align: right;
}
.gather-group-friends-nav {
	width: 100%;
	height: 48px;
	text-align: left;
	padding-bottom: 8px;

	.button {
		min-height: 31px;
		height: 31px;
		line-height: 31px;
	}
}

.gather-group-friends-invite-button {
	width: 152px;
	margin-left: 10px;
}

.gather-group-friends-cancel-button {
	display: inline-block;
	width: auto;
	height: 31px;
	max-height: 31px;
	min-height: 31px;
	line-height: 31px;
	border: none !important;
	border-radius: 0;
}

.gather-balancing-info-box {
	width: 100%;
	background-color: $card-background-color;
	padding: 24px 20px;
	margin-top: 22px;
}
.gather-balancing-info-box-label {
	text-align: center;
	text-transform: uppercase;
	color: $primary-hover-color;
	font-size: 12px;
	margin-top: 16px;
}
.gather-swap-average-rank-container {
	margin: 8px 80px;
}
.gather-swap-average-rank {
	vertical-align: top;
	display: inline-block;
	width: 100px;
	height: 100px;
	border: 1px solid #ddd;
	margin: 10px;
	text-align: center;

	&.new-elo {
		height: 130px;
	}
}
.swap-average-rank {
	width: 78px;
	height: 60px;
	background-size: cover;
	margin: 8px auto;
	text-align: center;
	font-size: 24px;
}
.swap-new-elo-label {
	font-size: 11px;
	text-transform: uppercase;
	color: $primary-hover-color;
}
.gather-swap-button-container {
	width: 100%;
	text-align: center;
	margin-top: 20px;
}

.gather-state-text {
	text-transform: uppercase;
}

.gather-user {
	float: left;
}

.gather-username {
	display: inline-block;
	line-height: 50px;
}

.gather-info-div {
	align-items: center;
	display: flex;
}

.gather-swap-rules > li:before {
	content: ' ';
	display: inline-block;
	height: 1px;
	width: 1px;
	vertical-align: middle;
	border: 3px solid $primary-hover-color;
	margin-right: 8px;
}
.gather-swap-rules {
	margin: 8px 0px 20px 20px;
}
.gather-swap-rules > li {
	margin-bottom: 8px;
}
.gather-table thead tr {
	height: 40px;
	line-height: 40px;
}
.gather-filter-countries {
	display: flex;
	padding: 0;
	background-color: #1c1e28;
	border-radius: 5px;
}
.gather-control {
	align-items: center;
	display: flex;
	justify-content: space-between;
	background-color: #1d1e29;

	.gather-info-div {
		padding: 1em;
		flex-shrink: 0;
	}
	.gather-state-buttons {
		padding: 0 1em;
		display: flex;
		justify-content: space-between;
		flex: 1;
		align-items: center;
	}
}

.sponsored-gather-page-header {
	background-image: url(../img/gathers/sponsored/sponsored-gather-background.png);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.sponsored-gather-summary-header {
	background-image: url(../img/gathers/sponsored/sponsored-gather-background.png) !important;
}

.sponsored-gather-img-wrapper-summary {
	width: 250px;
	height: 120px;
	position: absolute;
	padding-left: 17px;

	.elgiganten-img {
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/gathers/sponsored/elgiganten.png);
		width: 100%;
		height: 100%;
	}

	.corsair-img {
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/gathers/sponsored/corsair_gather5.png);
		width: 100%;
		height: 100%;
		margin-top: -14px;
	}

	.corsair-tutsi-img {
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/gathers/sponsored/corsair_gather4.png);
		width: 100%;
		height: 100%;
		margin-top: -14px;
	}

	.nipwwf2-img {
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/gathers/sponsored/sponsored_gather_nipwwf2.png);
		width: 100%;
		height: 100%;
		margin-top: -12px;
	}

	.disturbed-img {
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/gathers/sponsored/sponsored_gather_havu2.png);
		width: 100%;
		height: 100%;
		margin-top: -12px;
	}

	.silver-snipers-img {
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/gathers/sponsored/sponsored_gather_silversnipers2.png);
		width: 100%;
		height: 100%;
		margin-top: -12px;
	}

	.get_right-img {
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/gathers/sponsored/drop_lenovogetright.png);
		width: 100%;
		height: 100%;
		margin-top: -12px;
	}

	.msi-img {
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/gathers/sponsored/sponsored_msi.png);
		width: 100%;
		height: 100%;
		margin-top: -12px;
	}

	.pucko-img {
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/gathers/sponsored/pucko.png);
		width: 100%;
		height: 100%;
		margin-top: -12px;
	}

	.pucko-keyboard-img {
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/gathers/sponsored/sponsored_gather_puckokeyboard.png);
		width: 100%;
		height: 100%;
		margin-top: -12px;
	}
}

.sponsored-gather-img-wrapper {
	width: 200px;
	height: 120px;
	position: absolute;
	padding-left: 17px;
	margin-bottom: 24px;

	.elgiganten-img {
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/gathers/sponsored/elgiganten.png);
		width: 100%;
		height: 100%;
	}

	.corsair-img {
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/gathers/sponsored/corsair_gather5.png);
		width: 100%;
		height: 100%;
	}

	.corsair-tutsi-img {
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/gathers/sponsored/corsair_gather4.png);
		width: 100%;
		height: 100%;
		top: -24px;
	}

	.nipwwf2-img {
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/gathers/sponsored/sponsored_gather_nipwwf2.png);
		width: 100%;
		height: 100%;
		margin-top: 11px;
	}

	.get_right-img {
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/gathers/sponsored/drop_lenovogetright.png);
		width: 100%;
		height: 100%;
		margin-top: 11px;
	}

	.disturbed-img {
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/gathers/sponsored/sponsored_gather_havu2.png);
		width: 100%;
		height: 100%;
		margin-top: 11px;
	}

	.silver-snipers-img {
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/gathers/sponsored/sponsored_gather_silversnipers2.png);
		width: 100%;
		height: 100%;
		margin-top: 11px;
	}

	.msi-img {
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/gathers/sponsored/sponsored_msi.png);
		width: 100%;
		height: 100%;
		margin-top: 11px;
	}

	.pucko-img {
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/gathers/sponsored/pucko.png);
		width: 100%;
		height: 100%;
		margin-top: 11px;
	}

	.pucko-keyboard-img {
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/gathers/sponsored/sponsored_gather_puckokeyboard.png);
		width: 100%;
		height: 100%;
		margin-top: 11px;
	}
}

.sponsored-gather {
	margin-top: 10px;
	background-image: url(../img/gathers/sponsored/sponsored-gather-background.png);

	.sponsored-gather-header {
		height: 36px !important;
		background: rgba(1, 5, 19, 0.38);
		display: table-row;
	}

	.sponsored-gather-body {
		height: 120px;
		display: table-row;

		.sponsored-gather-creator {
			width: 21%;
		}

		.sponsored-gather-wrapper {
			position: relative;
			width: 35%;
			display: table-cell;

			@media (max-width: 600px) {
				display: none;
			}

			.image-holder {
				width: 100%;
				display: flex;
				justify-content: center;
				@media (max-width: 767px) {
					display: block;
				}

				.elgiganten-img {
					background-repeat: no-repeat;
					background-size: contain;
					background-image: url(../img/gathers/sponsored/elgiganten.png);
					width: 290px;
					height: 150px;
					position: absolute;
					top: -24px;
					@media (max-width: 767px) {
						width: 190px;
						height: 100px;
						top: -12px;
					}
				}

				.corsair-img {
					background-repeat: no-repeat;
					background-size: contain;
					background-image: url(../img/gathers/sponsored/corsair_gather5.png);
					width: 290px;
					height: 150px;
					position: absolute;
					top: -32px;
					@media (max-width: 767px) {
						width: 190px;
						height: 100px;
						top: -16px;
					}
				}

				.corsair-tutsi-img {
					background-repeat: no-repeat;
					background-size: contain;
					background-image: url(../img/gathers/sponsored/corsair_gather4.png);
					width: 290px;
					height: 150px;
					position: absolute;
					top: -32px;
					@media (max-width: 767px) {
						width: 190px;
						height: 100px;
						top: -16px;
					}
				}

				.nipwwf2-img {
					background-repeat: no-repeat;
					background-size: contain;
					background-image: url(../img/gathers/sponsored/sponsored_gather_nipwwf2.png);
					width: 290px;
					height: 150px;
					position: absolute;
					top: -30px;
					@media (max-width: 767px) {
						width: 190px;
						height: 100px;
						top: 20px;
					}
				}

				.get_right-img {
					background-repeat: no-repeat;
					background-size: contain;
					background-image: url(../img/gathers/sponsored/drop_lenovogetright.png);
					width: 290px;
					height: 150px;
					position: absolute;
					top: -30px;
					@media (max-width: 767px) {
						width: 190px;
						height: 100px;
						top: 20px;
					}
				}

				.silver-snipers-img {
					background-repeat: no-repeat;
					background-size: contain;
					background-image: url(../img/gathers/sponsored/sponsored_gather_silversnipers2.png);
					width: 290px;
					height: 150px;
					position: absolute;
					top: -30px;
					@media (max-width: 767px) {
						width: 190px;
						height: 100px;
						top: 20px;
					}
				}

				.disturbed-img {
					background-repeat: no-repeat;
					background-size: contain;
					background-image: url(../img/gathers/sponsored/sponsored_gather_havu2.png);
					width: 290px;
					height: 150px;
					position: absolute;
					top: -30px;
					@media (max-width: 767px) {
						width: 190px;
						height: 100px;
						top: 20px;
					}
				}

				.msi-img {
					background-repeat: no-repeat;
					background-size: contain;
					background-image: url(../img/gathers/sponsored/sponsored_msi.png);
					width: 290px;
					height: 150px;
					position: absolute;
					top: -30px;
					@media (max-width: 767px) {
						width: 190px;
						height: 100px;
						top: 20px;
					}
				}

				.pucko-img {
					background-repeat: no-repeat;
					background-size: contain;
					background-image: url(../img/gathers/sponsored/pucko.png);
					width: 290px;
					height: 150px;
					position: absolute;
					top: -30px;
					@media (max-width: 767px) {
						width: 190px;
						height: 100px;
						top: 20px;
					}
				}

				.pucko-keyboard-img {
					background-repeat: no-repeat;
					background-size: contain;
					background-image: url(../img/gathers/sponsored/sponsored_gather_puckokeyboard.png);
					width: 290px;
					height: 150px;
					position: absolute;
					top: -30px;
					@media (max-width: 767px) {
						width: 190px;
						height: 100px;
						top: 20px;
					}
				}
			}
		}

		.lobby-button {
			width: 14%;
		}
	}
}

.sponsored-gather-top-row {
	height: 36px !important;
	display: table-row;
	background-color: rgba(1, 5, 19, 0.38);
	background-image: url(../img/gathers/sponsored/sponsored-gather-background.png);
}

.sponsored-gather-bottom-row {
	height: 120px;
	display: table-row;
	background-image: url(../img/gathers/sponsored/sponsored-gather-background.png);
}

.sponsored-gather-name {
	padding-left: 228px;
}

.pucko-true {
	background-image: url(../img/gathers/sponsored/pucko_bg.png) !important;
}

;@import "sass-embedded-legacy-load-done:88";