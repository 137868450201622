.news-images-container {
	height: 240px;
	overflow: auto;
	margin-bottom: 8px;
}

.news-image {
	cursor: pointer;
	height: 72px;
	border: 1px $primary-color solid;
	border-radius: 6px;
	margin-bottom: 8px;
}
.news-image-thumbnail {
	float: left;
	height: 64px;
	max-width: 100px;
	margin: 4px;
}
.news-image-name {
	line-height: 72px;
}

;@import "sass-embedded-legacy-load-done:118";