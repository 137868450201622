.acceptRulesContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-left: 16px;
	margin-right: 16px;

	h1 {
		margin-bottom: 32px;
	}

	> div {
		&:nth-child(4) {
			margin-bottom: 32px;
		}
	}

	> button {
		width: 200px;
	}
}

.acceptRulesList {
	margin-bottom: 36px;
}

.acceptRulesAffirmationLabel {
	margin-bottom: 30px;
	font-weight: normal;
	line-height: 150%;
	max-width: 384px;
}
