.flagbar-container {
	width: 28px;
	height: 20px;
	position: relative;
	right: 10px;
	margin-left: 10px;
}

.flagbar {
	position: absolute;
	height: 20px;

	left: 0;
	top: 0;
	background-color: $secondary-color;
	overflow: hidden;

	.famfamfam-flag {
		margin: 4px 6px;
		cursor: pointer;
	}
}

.flagbar--show {
	box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 10px;
	overflow: visible;
	height: auto;
}

;@import "sass-embedded-legacy-load-done:51";