.quick-gather {
	width: 600px;
	padding: 0;

	.popover-title {
		height: 3em;
		line-height: 3em;
		background-color: $content-background-color;
		text-transform: capitalize;
		color: #fff;
	}
	.popover-content {
		padding: 20px 28px;
	}

	.create-gather-quick {
		text-align: left;

		.create-gather-complete-setup {
			text-align: center;
		}
		.player-card-wrapper {
			vertical-align: baseline;
		}
	}

	.create-gather-name-input {
		width: 100%;
		margin-left: 0;
	}
	select {
		width: 100%;
	}
	.collapsable-title {
		display: flex;
		align-items: center;
		cursor: pointer;
		margin: 0.5em 0;

		.fas {
			font-size: 20px;
			margin-left: 1em;
		}
	}
	.checkbox-label {
		display: flex;
		align-items: center;
		margin: 0.5em 0;

		.input-label {
			margin-left: 0.5em;
		}
	}

	.gathers-player-table {
		height: 150px;
		overflow: auto;

		.create-gather-friends-table > tbody {
			max-height: 130px;
		}

		.create-gather-pick-column {
			height: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.create-gather-complete-setup {
		margin-left: 0;
	}
	.create-gather-invite-friends {
		width: 100%;
	}
}

;@import "sass-embedded-legacy-load-done:125";