.landing-page {
	background-color: #000;
	margin: 0;
	margin-bottom: -1em;
	flex-grow: 1;
	color: #fff;
	font-family: 'Robofan Spark';

	&-wrapper {
		width: 100%;
	}
}

// /* SECTION 1 */
.section-1 {
	width: 100%;
	background-color: #000;
	padding: 50px 15px 30px 15px;
	background-position: center top;
	background-repeat: no-repeat;
	text-align: center;
}

.landing-content-container {
	max-width: 1200px;
	display: inline-block;
}

.landing-title-section {
	margin-top: 80px;

	h1 {
		font-size: 52px;
		text-align: center;
		width: 100%;
		text-transform: uppercase;
		margin-block-start: 0;
		margin-block-end: 0;
	}

	h3 {
		text-transform: uppercase;
	}
}

.landing-bullets {
	margin: auto;
}

.landing-content-list {
	line-height: 42px;
	font-size: 16px;
	width: 100%;
	text-align: left;
	font-weight: 100;

	a {
		color: #fff;
	}
}

.landing-block {
	float: left;
	background-color: #fff;
	width: 10px;
	height: 10px;
	margin-right: 20px;
	margin-top: 15px;
	margin-bottom: 15px;
	clear: left;
}

.landing-logo {
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	clear: both;
	margin: 0 auto;
}

.landing-big-logo {
	width: 600px;
	height: 500px;
	margin: 50px auto 0 auto;

	@media (max-width: 800px) {
		width: 400px;
	}
}

.landing-arrow {
	margin: 50px auto;
	width: 0;
	height: 0;
	border-left: 60px solid transparent;
	border-right: 60px solid transparent;
	border-top: 60px solid #fff;
}

.landing-esportal-logo {
	width: 372px;
	height: 145px;
	background-size: contain;
	margin: 30px auto 20px auto;
	// background-image: url(../img/omenopen/esportal_logo.png);
}

.landing-section-2 {
	width: 100%;
	background-color: #000;
	padding: 30px 15px;
	text-align: center;
}

.landing-terms {
	color: #fff;
	text-align: center;
	padding-bottom: 20px;

	a {
		color: #fff;
	}
}

.landing-table {
	margin: 15px auto 0 auto;
	width: 270px;
	background-color: transparent;
	color: #fff;
	font-size: 16px;
}

.landing-table tr {
	background-color: transparent;
	margin-bottom: 4px;
}

.landing-table a {
	color: #fff;
	padding: 10px 15px;
	border-radius: 4px;
}

.landing-table .landing-text-right {
	text-align: right;
	padding-right: 15px;
}

.landing-table .landing-text-left {
	text-align: left;
}

.landing-info-button {
	margin-top: 60px;
}

.landing-button {
	padding: 11px 37px;
	border-radius: 4px;
	background-color: #ef7400;
	color: #fff;
	cursor: pointer;
}

.text-black {
	color: #000 !important;
}

.landing-general-banner,
.block-general-banner,
.simple-banner {
	width: 100%;
}

.large-title {
	margin: 0 auto;
	padding: 100px 0 50px;
	max-width: 650px;
	font-size: 45px;
	text-align: center;
	color: #fff;
}

.block-title {
	margin: 0 auto;
	padding: 0 10px 50px;
	max-width: 480px;
	font-size: 35px;
	text-align: center;
	color: #fff;
	font-weight: 500;

	@media only screen and (max-width: 800px) {
		padding-bottom: 35px;
		font-size: 28px;
	}
}

@media screen and (min-width: 1800px) {
	.landing-title-section {
		margin-top: 250px;
	}
}

@media screen and (min-width: 2000px) {
	.landing-title-section {
		margin-top: 450px;
	}
}

@media screen and (min-width: 2200px) {
	.landing-title-section {
		margin-top: 600px;
	}
}

@media screen and (min-width: 2600px) {
	.landing-title-section {
		margin-top: 700px;
	}
}

@media screen and (min-width: 2800px) {
	.landing-title-section {
		margin-top: 900px;
	}
}

@media screen and (max-width: 768px) {
	.landing-title-section {
		margin-top: -200px;
	}
}

/* PRIZE-LANDING*/
.prize-landing-root {
	$main_color: #f5444e;
	$second_color: #28df9d;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 0 0 100px;
	width: 100%;
	text-align: center;

	ul {
		text-align: left;

		li {
			font-size: 18px;
			font-weight: 400;
			margin: 10px 0;

			&:before {
				content: ' ■ ';
				color: #fff;
				margin-right: 10px;
				font-size: 15px;
			}
		}
	}

	h1 {
		line-height: 62px;
		font-size: 62px;
		font-weight: 500;
		color: #fff;
		margin: 0 0 50px;
		font-family: 'Bebas Neue', sans-serif;
	}

	h2 {
		font-size: 32px;
		font-weight: 600;
		color: #fff;
		margin: 44px 0 10px;
		font-family: 'TradeGothic LT', sans-serif;
	}

	h3 {
		font-size: 39px;
		font-weight: 500;
		color: #fff;
		letter-spacing: 0.43px;
		font-family: 'Bebas Neue', sans-serif;

		&:nth-child(1) {
			margin: 0;
		}
	}

	.small-info {
		margin-bottom: 24px;
	}

	p {
		text-align: center;
		font-size: 14px;
		margin: 0;

		a {
			color: $main_color;
			text-decoration: none;
		}
	}

	.bullet-points {
		padding: 0 20px;
	}

	.prize-item {
		width: 100%;
		margin: 6px 0;

		.image {
			font-size: 0;
		}

		.link-wrapper {
			.link {
				display: inline-block;
				white-space: nowrap;
			}
		}
	}

	.prizes-list {
		margin-top: 50px;
		padding: 0 20px;
	}

	.prize-item {
		display: flex;
		background-color: #191a27;
		border-radius: 4px;
		margin: 6px;
		// width: 90%;

		.image {
			@media (max-width: 768px) {
				width: 100%;
			}

			img {
				max-width: 100%;
			}
		}

		.info-right {
			width: 40%;
			@media (max-width: 768px) {
				width: 100%;
			}
			.date {
				color: $main_color;
				font-size: 20px;
				text-align: center;
			}

			.teams {
				text-align: center;
				font-size: 14px;
			}
		}

		.info {
			@media (max-width: 768px) {
				width: 100%;
				flex-wrap: wrap;
				height: 250px;
			}

			padding: 0 30px;
			width: 100%;

			.info-wrapper {
				width: 100%;
			}

			display: flex;
			justify-content: space-between;
			align-items: center;

			p {
				text-align: left;
			}

			.title {
				font-size: 20px;
				margin-bottom: 10px;
			}

			.prize-value {
				display: flex;
				width: 100%;
				font-size: 14px;

				.p1 {
					margin-right: 8px;
				}

				.p2 {
					margin-right: 8px;
				}
			}

			.prize {
				color: $main_color;
			}

			.link-wrapper {
				margin-top: 10px;
				@media (max-width: 768px) {
					margin-top: 30px;
				}

				.link {
					background-color: $main_color;
					color: #14151d;
					font-size: 13px;
					font-weight: 500;
					border-radius: 4px;
					padding: 9px 24px;

					&:hover {
						background-color: #ffdd00;
					}
				}
			}
		}

		&.prize-item {
			&-aimking {
				.info .prize,
				.info-right .date {
					color: $main_color;
				}

				.info .link-wrapper .link {
					background-color: $main_color;
				}
			}

			&-aimapprentice {
				.info .prize,
				.info-right .date {
					color: $second_color;
				}

				.info .link-wrapper .link {
					background-color: $second_color;
				}
			}
		}

		@media (max-width: 768px) {
			flex-wrap: wrap;
			width: 90%;
			max-width: 450px;
			margin: 16px;
			border-radius: 0;

			.info {
				height: auto;
				padding: 20px;

				&-right {
					margin-top: 20px;
				}

				.link-wrapper {
					margin-top: 20px;
				}
			}
		}
	}
}

/* Ladder table responsive and additional styles */
.participants-table {
	.participants-table-heading {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.named-data-block {
			margin-right: 40px;

			&:last-child {
				margin-right: 0;
			}
		}

		.participants-side {
			display: flex;
			align-items: center;
		}

		.sign-up-button,
		.button {
			display: inline-block;
			padding: 15px 30px;
			border: none;
			color: #fff;
			font-size: 14px;
			text-transform: uppercase;
			background: #58c09c;
			border-radius: 3px;
			transition: background 0.3s ease;
			cursor: pointer;

			&:hover {
				background: #73dbb7 !important;
			}
		}

		.participants-table-dates {
			display: flex;
		}
	}

	.table-wrapper {
		margin-bottom: 40px;
	}

	@media only screen and (max-width: 1730px) {
		.participants-table-heading {
			display: block;
		}
	}

	@media only screen and (max-width: 920px) {
		.table-wrapper {
			width: 100%;
			overflow: auto;

			.table-striped {
				width: 920px;
			}
		}
	}
}
