.table-avatar {
	vertical-align: middle;
	width: 25px;
	height: 25px;
	border: 2px solid $primary-color;
	margin-right: 5px;
}
.table-rank {
	vertical-align: middle;
	display: inline-block;
	width: 40px;
	height: 40px;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	margin: 0px 8px;

	&.lock {
		opacity: 0.7;
	}
}
.table-rank-icon {
	position: relative;
}
.table-rank-lock {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -60%);
	opacity: 0.85;
	width: 10px;
}
.table-skill {
	vertical-align: middle;
	display: inline-block;
	height: 25px;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;

	&.skill0 {
		width: 40px;
	}
	&.skill1 {
		width: 70px;
	}
	&.skill2 {
		width: 55px;
	}
}
.table-weapon {
	vertical-align: middle;
	display: inline-block;
	width: 50px;
	height: 50px;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;

	@include desktop() {
		margin-right: 10px;
	}
	@include widescreen() {
		margin-right: 20px;
	}
}
.table-map {
	vertical-align: middle;
	display: inline-block;
	width: 45px;
	height: 45px;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;

	@include tablet() {
		margin-right: 10px;
	}
	@include widescreen() {
		margin-right: 20px;
	}
}
.table-head-toggle {
	display: inline-block;
	vertical-align: middle;
	background-color: #008dc2;
	cursor: pointer;
	padding: 0px 10px;
	margin: 0px 5px;
	height: 26px;
	line-height: 26px;
	border-radius: 15px;
}
.table-level {
	vertical-align: middle;
	display: inline-block;
	width: 32px;
	height: 30px;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}
.table-header-microphone {
	margin: 0 auto;
}
.table-microphone {
	width: 28px;
	height: 28px;
	background-size: cover;
	margin: auto;
	filter: invert(1);
}

table {
	width: 100%;
	font-size: 14px;
	border-spacing: 0px 0px;
	border-collapse: collapse;

	@include tablet() {
		font-size: 13px;
	}
	@include widescreen() {
		font-size: 14px;
	}
}
th,
td {
	text-align: center;
}
th:last-child,
td:last-child {
	text-align: right;
}
th:first-child,
td:first-child {
	text-align: left;

	&.table-index {
		text-align: right;
	}
}
th {
	color: $grey-text-color;
}
tr {
	height: 48px;
	line-height: 48px;
}
th,
td {
	padding: 0 6px;

	select {
		line-height: normal;
	}
}

.table-striped {
	tbody > tr {
		border-bottom: 1px solid $dark-grey-border;
	}
	th:first-child {
		border-left: 1px solid $primary-color;
	}
	th:last-child {
		border-right: 1px solid $primary-color;
	}
	&.table-button-color {
		th {
			border-bottom: 1px solid #75cff1;
		}
		th:first-child {
			border-left: 1px solid $button-color;
		}
		th:last-child {
			border-right: 1px solid $button-color;
		}
	}
	td {
		border-bottom: 1px solid #e6e6e6;
	}
	td:first-child {
		border-left: 1px solid #e6e6e6;
	}
	td:last-child {
		border-right: 1px solid #e6e6e6;
	}
}
.table-bordered {
	td {
		border-left: 1px solid #e3f0f5;
		border-bottom: 1px solid #e3f0f5;
	}
	td:last-child {
		border-right: 1px solid #e3f0f5;
	}
}
.table-separated {
	border-collapse: separate;
	border-spacing: 0px 2px;

	td {
		line-height: 52px;
		height: 52px;
		& > .breakable-text {
			line-height: 20px;
		}
	}
	thead {
		background-color: $table-header-background-color;
	}
}
.table-condensed {
	tr {
		line-height: normal;
		td,
		th {
			padding: 6px;
		}
	}
}
.table-button-color {
	th {
		background-color: $button-color;
	}
}
.table-left-aligned {
	td,
	th {
		text-align: left;
	}
}
.table-cell-button {
	line-height: 40px;
	font-size: 22px;
	cursor: pointer;

	button {
		cursor: pointer;
		background: none;
		border: none;
		font-size: 1em;
		color: #88d307;
	}

	&.disabled button,
	&.disabled i {
		color: #b7b7b7;
	}
}
.table-cell-button {
	line-height: 40px;
	font-size: 22px;
	cursor: pointer;

	button {
		cursor: pointer;
		background: none;
		border: none;
		font-size: 1em;
		color: #88d307;
	}

	&.disabled button,
	&.disabled i {
		color: #b7b7b7;
	}
}
.table-cell-button {
	line-height: 40px;
	font-size: 22px;
	cursor: pointer;

	button {
		cursor: pointer;
		background: none;
		border: none;
		font-size: 1em;
		color: #88d307;
	}

	&.disabled button,
	&.disabled i {
		color: #b7b7b7;
	}
}
.table-cell-button {
	line-height: 40px;
	font-size: 22px;
	cursor: pointer;

	button {
		cursor: pointer;
		background: none;
		border: none;
		font-size: 1em;
		color: #88d307;
	}

	&.disabled button,
	&.disabled i {
		color: #b7b7b7;
	}
}
.table-cell-button {
	line-height: 40px;
	font-size: 22px;
	cursor: pointer;

	button {
		cursor: pointer;
		background: none;
		border: none;
		font-size: 1em;
		color: #88d307;
	}

	&.disabled button,
	&.disabled i {
		color: #b7b7b7;
	}
}
.table-cell-button {
	line-height: 40px;
	font-size: 22px;
	cursor: pointer;

	button {
		cursor: pointer;
		background: none;
		border: none;
		font-size: 1em;
		color: #88d307;
	}

	&.disabled button,
	&.disabled i {
		color: #b7b7b7;
	}
}
.table-cell-button {
	line-height: 40px;
	font-size: 22px;
	cursor: pointer;

	button {
		cursor: pointer;
		background: none;
		border: none;
		font-size: 1em;
		color: #88d307;
	}

	&.disabled button,
	&.disabled i {
		color: #b7b7b7;
	}
}
.table-cell-button {
	line-height: 40px;
	font-size: 22px;
	cursor: pointer;

	button {
		cursor: pointer;
		background: none;
		border: none;
		font-size: 1em;
		color: #88d307;
	}

	&.disabled button,
	&.disabled i {
		color: #b7b7b7;
	}
}
.table-cell-button {
	line-height: 40px;
	font-size: 22px;
	cursor: pointer;

	button {
		cursor: pointer;
		background: none;
		border: none;
		font-size: 1em;
		color: #88d307;
	}

	&.disabled button,
	&.disabled i {
		color: #b7b7b7;
	}
}

.admin-ban-info {
	padding-left: 5px;
	padding-right: 5px;
}
.table-index {
	width: 30px;
	padding-left: 15px;
}

;@import "sass-embedded-legacy-load-done:23";