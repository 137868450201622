.live-games {
	.match-status {
		line-height: normal;
	}
	.match-slots {
		font-size: 1.5em;
		line-height: 28px;
	}

	.country-flag-cell {
		vertical-align: bottom;
	}
	.twitch-icon {
		position: relative;
		display: inline-block;
		width: 24px;
		height: 24px;
		top: 7px;
		left: -6px;
		background-size: 24px 24px;
		background-image: url(../img/misc/twitch_views.png);
		background-repeat: no-repeat;
	}
	.rank-icon {
		position: relative;
		display: inline-block;
		width: 41.33px;
		height: 32px;
		top: 10px;
		background-size: 41.33px 32px;
		background-repeat: no-repeat;
	}
	.button {
		min-width: 78px;
		min-height: 30px;
		line-height: 30px;

		&.join {
			background-color: #f26522;
		}
	}
	.join-button:hover,
	.join-button:focus {
		background-color: #c4490f;
	}
	.content-footer {
		.button {
			padding: 7px;
			min-width: 90px;
			@include mobile() {
				min-width: 40%;
				padding: 9px;
				font-size: 19px;
			}
		}
	}

	.prime-match {
		color: #8522ff;
	}
}
.friends-column,
.gather-friends-column {
	.fa-user-friends {
		color: #39a7e0;
	}
}

.live-games-twitch-icon {
	position: relative;
	display: inline-block;
	width: 16px;
	height: 16px;
	top: 4px;
	left: -2px;
	background-size: cover;
	background-image: url(../img/misc/twitch_views.png);
	background-repeat: no-repeat;
}
.live-games-rank-icon {
	position: relative;
	display: inline-block;
	width: 42px;
	height: 42px;
	top: 4px;
	background-size: 42px 42px;
	background-repeat: no-repeat;
}
.live-games-button {
	min-width: 78px;
	min-height: 30px;
	line-height: 30px;
	padding: 0 10px;
	font-size: 12px;
	width: 100%;

	color: #ffffff;
	border-color: #3f4151;
	background: transparent;

	&:hover {
		color: $primary-hover-color;
		border-color: $primary-hover-color;
		background: transparent;
	}

	&.join,
	&.open-lobby {
		color: #ffffff;
		border: none;
		font-size: 12px;
		padding: 0;
		width: 100%;
		display: block;
		background: transparent linear-gradient(90deg, $primary-hover-color, #007ce9) 0% 0% no-repeat
			padding-box;
		&:hover {
			background: transparent linear-gradient(90deg, #007ce9 0%, $primary-hover-color 100%) 0% 0%
				no-repeat padding-box;
		}
	}
}
.live-games-join-button:hover,
.live-games-join-button:focus {
	background-color: $important-hover-color;
}
.live-games-live-label {
	line-height: normal;
	text-transform: uppercase;
}
.live-games-started {
	line-height: normal;
	font-size: 11px;
	text-transform: uppercase;
}
.live-games-type {
	line-height: normal !important;
}
.live-games-gather-name {
	max-width: 190px;
	color: $primary-hover-color;
	font-size: 11px;
	font-weight: 700;
	text-transform: uppercase;
	text-overflow: ellipsis;
	overflow: hidden;
}
.gather-table {
	@extend %dropdown-table;
	.table-separated {
		border-collapse: collapse;

		th,
		td {
			border: none;
		}
	}
	tr:not(.star-gather-top-row):not(.sponsored-gather-body) {
		height: 45px;
		line-height: 45px;
	}

	th {
		font-size: 13px;

		&:last-child {
			text-align: center;
		}
	}
}
.gather-country-column {
	width: 59px;
}
.gather-creator-column {
	text-align: left;
	width: 180px;
	border-right: 1px solid #2f313c;

	@include mobile() {
		width: auto;
	}
}
.gather-creator-name {
	display: inline-block;
	max-width: 100%;
	overflow: hidden;
	height: 15px;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.gather-elo-column {
	max-width: 120px;

	.gather-average-elo {
		font-size: 14px;
		color: #fff;
	}
}
.gather-name-column {
	max-width: 120px;
	text-align: left;
}
.gather-friends-column {
	max-width: 50px;
}
.gather-twitch-column {
	max-width: 62px;
	font-size: 11px;
}
.gather-twitch-column-icon {
	display: inline-block;
}

.gather-table-name-top {
	vertical-align: top;
	line-height: normal;
}
.gather-table-name {
	max-width: 175px;
	vertical-align: top;
	display: inline-block;
	line-height: 20px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 13px;

	.fa-star {
		color: #cb0;
		margin-right: 5px;
	}
}
.gather-table-name-bottom {
	line-height: 17px;
	width: 120px;
}
.gather-average-elo {
	font-size: 11px;
	color: #ffffff;
	text-transform: uppercase;
}
.gather-auto-accept {
	color: #739900;
	font-size: 10px;
	font-weight: 700;
	text-transform: uppercase;
}
.gather-table-microphone {
	vertical-align: top;
	display: inline-block;
	width: 20px;
	height: 20px;
	margin-right: 4px;
	background-size: cover;
	filter: invert(1);
}

.gather-player-column {
	position: relative;
}
.gather-player-rank-column {
	width: 30px;
}
.gather-player-rank {
	vertical-align: middle;
}

.star-gather-top-row {
	border-bottom-color: transparent;
	background-image: url(../img/gathers/stargather.jpg);
	background-size: cover;
	background-position: 50% 32.5%;
	height: 30px !important;
	line-height: 30px;

	td {
		height: 30px;
		line-height: 30px;
		font-size: 12px;
		font-weight: 700;
		background-color: rgba(0, 0, 0, 0.5);
	}

	.star-gather-top-row-left {
		float: left;
	}
	.star-gather-top-row-right {
		float: right;
	}
}
.star-gather {
	border-top-color: transparent;
	background-image: url(../img/gathers/stargather.jpg);
	background-size: cover;
	background-position: 50% 44%;

	.gather-average-elo {
		color: #ff6600;
	}
	.friends-column,
	.gather-friends-column {
		.fa-user-friends {
			color: #ff6600;
		}
	}

	.live-games-button {
		color: #ffffff;
		background-color: transparent;
		&:not(.disabled) {
			border: 1px solid #ff8400;
		}

		&:hover {
			color: #ff8400;
		}

		&.join,
		&.open-lobby {
			color: #ffffff;
			border: none;
			background: transparent linear-gradient(90deg, #ff8400, #ff4e00) 0% 0% no-repeat padding-box;
			&:hover {
				background: transparent linear-gradient(90deg, #ff4e00 0%, #ff8400 100%) 0% 0% no-repeat padding-box;
			}
		}
	}
}

.verified-gather-top-row {
	border-bottom-color: transparent;
	background-image: url(../img/gathers/verifiedgather.jpg);
	background-size: cover;
	background-position: 50% 24.5%;
	height: 30px !important;
	line-height: 30px;

	td {
		height: 30px;
		line-height: 30px;
		font-size: 12px;
		font-weight: 700;
		background-color: rgba(0, 0, 0, 0.5);
	}

	.icon-verified {
		margin-right: 10px;
	}
	.verified-gather-top-row-left {
		float: left;
	}
	.verified-gather-top-row-right {
		float: right;
	}
}
.verified-gather {
	border-top-color: transparent;
	background-image: url(../img/gathers/verifiedgather.jpg);
	background-size: cover;
	background-position: 50% 31%;
}

.gather-table.home {
	.gather-country-column {
		// Collapse column
		width: 1%;
		white-space: nowrap;
	}

	.gather-creator-column {
		width: 21%;
	}

	.gather-table-name {
		max-width: 100%;

		&.microphone-required {
			max-width: calc(100% - 25px);
		}
	}

	.gather-friends-column {
		max-width: 7.5%;
	}

	.gather-twitch-column {
		max-width: 9%;
	}
}
.gather-table {
	@include until(1065px) {
		.gather-twitch-column,
		.gather-friends-column {
			display: none;
		}
	}
	@include until(1850px) {
		th.gather-friends-column span {
			display: none;
		}
	}

	@include until(870px) {
		.gather-country-column {
			display: none;
		}
	}

	@include mobile() {
		.gather-creator-column {
			width: 40%;
		}

		.gather-player-rank-column {
			display: none;
		}
	}
}
.gather-creator-and-status-section {
	align-items: center;
}

.gather-creator-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	background-color: #21232f;
	text-align: left;
	min-height: 110px;

	.creator-details-section {
		display: flex;
		flex-direction: column;
		padding: 15px 30px;
		border-right: 1px solid #3a3c48;
		height: 100%;

		.user-details {
			display: flex;
		}
	}
	.creator-stats-section {
		display: flex;
		height: 100%;
		align-items: center;

		.stats-block {
			display: flex;
			flex-direction: column;
			margin: 0 1.5rem;
			font-size: 16px;

			.info-item {
				display: flex;
				align-items: center;
				margin: 10px 0;

				.label {
					margin-left: 0.5em;
				}
				.value {
					display: flex;
					align-items: center;
					color: $primary-color;

					.player-card-wrapper {
						margin-right: 5px;
					}
				}
			}
		}
	}
	.info-group {
		display: flex;
		justify-content: space-between;

		.label {
			color: $primary-color;
		}
	}
	.info-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		color: #c0c6d1;

		.label {
			margin-left: 0.5em;
		}
		.value {
			margin-left: 0.5em;
			display: flex;
			align-items: center;

			.player-card-wrapper {
				margin-right: 5px;
			}
		}
	}
}

.quick-join-table {
	@include tablet-only() {
		.friends-column,
		.twitch-column {
			display: none;
		}
	}
}

;@import "sass-embedded-legacy-load-done:33";