.usp-wrapper {
	display: flex;
	align-items: center;
	margin-top: 1em;
	margin-bottom: 1em;
	flex-direction: column;

	@include widescreen() {
		flex-direction: row;
	}

	.usp-text {
		text-align: center;

		@include widescreen() {
			text-align: left;
		}
	}

	.usp {
		width: 120px;
		height: 150px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	.usp-anticheat {
		background-image: url(../img/USP/icon_cheat.png);
	}
	.usp-rank {
		background-image: url(../img/USP/icon_rank.png);
	}
	.usp-servers {
		background-image: url(../img/USP/icon_server.png);
	}
	.usp-admins {
		background-image: url(../img/USP/icon_support.png);
	}
	.usp-statistics {
		background-image: url(../img/USP/icon_stat.png);
	}
	.usp-giveaways {
		background-image: url(../img/USP/icon_challenge.png);
	}
	.usp-match {
		background-image: url(../img/USP/icon_match.png);
	}
	.usp-steam {
		background-image: url(../img/USP/icon_steam.png);
	}
}

.home-usp-wrapper {
	display: flex;
	flex-wrap: wrap;
	margin-top: 40px;
	padding: 12px;
	background-color: rgba(1, 5, 19, 0.25);
	justify-content: center;
	.usp-wrapper {
		width: 25%;
		margin-top: 0;
		margin-bottom: 0;
		min-width: 150px;
	}
}

.side-column-usp-wrapper {
	padding: 8px;
	background-color: rgba(1, 5, 19, 0.25);
	position: sticky;
	top: calc(#{$top-bar-height} + #{$top-bar-above-height} + 10px);
}

;@import "sass-embedded-legacy-load-done:65";