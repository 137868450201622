.tournaments-content {
	max-width: 1642px;
	margin: auto auto 0;
	.tournaments-main-content {
		display: flex;
		padding-left: 16px;

		.content-heading {
			@include mobile() {
				padding-left: 8px;
			}
		}

		th,
		.header {
			font-size: 16px;
			line-height: 40px;
			font-weight: bold;
			color: $grey-text-color;

			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		> .right-column {
			margin-top: 35px;
		}

		.top-10-teams-filter {
			margin-bottom: 0px;
			margin-top: 2px;
		}

		.top-10-teams-list {
			color: $grey-text-color;
			.name {
				color: #fff;
			}
			@include mobile() {
				th,
				td {
					padding: 0;
				}
			}
			td:first-child {
				padding-left: 16px;
				width: 20px;
			}
			td:last-child {
				padding-right: 40px;
				&.new-teams {
					padding-right: 8px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}

			tr:last-child td:first-child {
				border-bottom-left-radius: 10px;
			}
			tr:last-child td:last-child {
				border-bottom-right-radius: 10px;
			}
			tr:first-child td:last-child {
				border-top-right-radius: 10px;
			}
			tr:nth-child(even) {
				background-color: #1b1c26;
			}
			tbody > tr:nth-child(odd) {
				background-color: #272736;
			}
			.name-col {
				text-align: left;
				font-weight: 500;
				white-space: nowrap;
				@include mobile() {
					width: 65%;
					.table-avatar {
						margin-right: 10px;
					}
				}
			}
			.team-country {
				margin-right: 3px;
			}
		}

		.clickable {
			color: $button-color;
			cursor: pointer;
		}

		.centered-text {
			text-align: center;
		}

		.tournament-stats {
			.number-col {
				width: 16%;
				text-align: center;
			}
			.name-col {
				text-align: left;
				display: block;
				height: 41px;
				overflow: hidden;
			}

			.tournament-stats-avatar {
				vertical-align: middle;
				margin-right: 10px;
				width: 20px;
				height: 20px;
				border: $primary-color 1px solid;
			}

			.famfamfam-flag {
				margin-left: 5px;
			}
		}

		.tournament-latest-mvps {
			max-width: 800px;
			justify-content: left;
			.entry {
				margin-right: 8px;
			}
			.match-link {
				line-height: 23px;
				text-transform: uppercase;
				position: relative;
			}
		}
		.featured-tournament-or-ladder-header {
			margin-top: 35px;
			margin-bottom: 38px;
			padding: 0;
		}
	}

	.tournaments-categories {
		display: flex;
		background-color: #1d1e29;
		margin-bottom: 30px;

		.tab {
			padding: 1em 2em;
			border-right: 1px solid $dark-grey-border;
			font-weight: 600;
			font-size: 13px;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;

			&.active {
				border-bottom: 3px solid $primary-color;
			}
			&:last-child {
				border-right: 0;
			}
			@include mobile() {
				padding: 1em 1em;
				width: 100%;
			}
		}
		.tab-dropdown {
			position: relative;

			> .label {
				display: flex;
				align-items: center;
				justify-content: space-between;
				.fa-caret-down {
					margin-left: 1em;
				}
			}

			ul {
				position: absolute;
				top: 100%;
				left: 0;
				display: none;
				width: 100%;
				border-top: 1px solid $dark-grey-border;
				z-index: 1;

				li {
					display: block;
					width: 100%;
					background-color: #1d1e29;
				}
			}

			&:hover {
				ul {
					display: block;
				}
			}
		}
	}
}

.ladder-section {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	margin-bottom: 50px;

	.tournaments-tournament-card-list-wrapper {
		width: 100%;
	}

	.tournaments-tournament-card {
		margin: 0px 7px;
	}

	.tournaments-description {
		max-width: 25%;
		margin: 0px 7px;
	}

	.tournaments-tournament-card-padding {
		display: none;
	}
}

.featured-tournament-or-ladder-header {
	display: flex;

	.featured-tournaments-carousel {
		overflow: hidden;
		justify-content: space-around;
		flex-flow: wrap;
		align-items: center;
		width: 100%;
		height: auto;
		margin: 40px 0 0px 0;
		background-color: transparent;

		.carousel {
			position: relative;

			.navigation-button {
				position: absolute;
				border-radius: 50%;
				background-color: rgba(0, 0, 0, 0.5);
				width: 2em;
				height: 2em;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #b8b4b8;
				border: 0;
				margin: 0 1em;
				z-index: 1;
				top: 50%;

				&.left {
					left: 0;
				}
				&.right {
					right: 0;
				}
			}
		}

		.carousel-frame {
			flex-direction: column;
			justify-content: center;
			display: flex;
			height: 100%;

			.slider-dots {
				align-self: center;
				margin: 25px 0;
				.carousel-dot {
					background-color: #272736;
					border: 0 !important;
					height: 14px;
					width: 14px;
					border-radius: 12px;
					margin: 0 10px;
				}

				.carousel-dot:disabled {
					background-color: $primary-color !important;
				}
			}
		}
	}

	@include mobile() {
		flex-direction: column-reverse;
	}

	.featured-entry {
		display: flex;
		flex-direction: column;
		height: 100%;

		@include mobile() {
			padding-left: 8px;
		}

		.img-wrapper {
			position: relative;
			height: 100%;
			@include mobile() {
				height: auto;
			}

			.entry-banner {
				width: 100%;
				height: 100%;
				position: relative;
				border-radius: 4px 4px 0 0;
				background-size: cover;
				background-position: center;
			}
		}
		.text {
			width: 100%;
			height: 84px;
			text-align: center;
			background-color: #12121abb;
			border-radius: 0 0 24px 24px;

			@include mobile() {
				height: 112px;
			}

			.date-slots-wrapper {
				line-height: 84px;
				@include mobile() {
					line-height: 52px;
				}
				text-transform: uppercase;
				.start-date {
					color: $grey-text-color;
					padding-right: 45px;
				}
				.slots {
					color: $grey-text-color;
					padding-right: 45px;
				}
			}

			.button {
				margin-left: 45px;
				font-size: 13px;
				font-weight: 800;
				padding: 0 28px;
				@include mobile() {
					width: 80%;
					margin-left: 0;
				}
			}

			.flag-wrap {
				.region-flag,
				.country-flag {
					width: 23px;
					height: 16px;
					background-size: 23px 16px;
					background-position: 0px 50%;
					line-height: 16px;
					background-repeat: no-repeat;
					padding-left: 28px;
				}
			}
		}
	}
}

.filter-by-game-type {
	display: flex;
	align-items: center;

	&.float-right {
		margin-left: auto;
		padding-left: 8px;
		padding-right: 8px;
	}

	.filter-by-game-container {
		display: flex;
		flex-direction: row;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		.filter-button {
			text-align: center;
			line-height: 40px;
			background-color: #191a23;
			border-radius: 4px;
			width: 5em;
			height: 40px;
			margin: 4px;
			font-weight: 800;
			font-size: 13px;
			cursor: pointer;
			color: #fff;

			&.selected {
				background-color: #1f212c;
				color: $primary-color;
			}

			&.disabled {
				color: $dark-grey-text-color;
			}
		}
	}
}

.tournaments-list-header {
	display: flex;
	justify-content: space-between;
	align-items: center;

	@include mobile() {
		flex-direction: column;
	}
}

.tournaments-tournaments-column {
	width: 100%;
	padding: 0.75rem;

	@include from(1200px) {
		padding: 0.75rem 0;
	}

	@include from(1750px) {
		width: 77% !important;
	}
	@include from(1850px) {
		width: 76% !important;
	}
}

.tournaments-latest-results {
	width: 100%;

	.tournaments-latest-results-table {
		margin-bottom: 50px;
	}
	.top-10-teams-sort-by {
		font-weight: 600;
		text-transform: uppercase;
		display: inline-flex;
		justify-content: flex-start !important;
		width: 100%;

		&.region-filter {
			@include mobile() {
				display: flex;
				justify-content: left;
			}
		}

		@include mobile() {
			text-align: left;
		}

		@include tablet() {
			display: flex;
			justify-content: center;
			text-transform: none;

			.filter-label {
				display: none;
			}
		}

		div {
			background-color: #111219;
			border-radius: 10px 10px 0 0;
			color: $grey-text-color;
			font-size: 12px;
			padding: 12px 24px;
			text-transform: uppercase;

			@include mobile() {
				padding: 12px 14px;
			}

			@include tablet() {
				font-size: 14px;
				padding: 5px 8px;
			}

			@include widescreen() {
				white-space: nowrap;
			}

			.famfamfam-flag {
				padding: 0px 8px;
			}
		}

		.selected {
			background-color: #272736;
			color: $primary-color;
			text-transform: uppercase;
			padding: 13px;
			font-size: 10px;
		}

		.clickable {
			text-transform: uppercase;
			padding: 13px;
			font-size: 10px;
		}
	}
}

.tournaments-filter-selector {
	display: flex;
	margin-top: 8px;
	margin-bottom: 8px;
	font-size: 16px;

	@include mobile() {
		justify-content: center;
	}

	.tournament-country-filter-title {
		color: $grey-text-color;
		justify-content: center;
		vertical-align: middle;
		display: flex;
		align-items: center;
		padding-right: 8px;
		padding-left: 6px;
		font-size: 13px;
	}

	.tournament-country-filter {
		flex-grow: 1;
		display: flex;
		background-color: $table-header-background-color;

		div {
			color: $grey-text-color;
			font-size: 13px;
			padding: 10px 26px;
			line-height: 36px;
			@include mobile() {
				padding: 10px 12px;
			}
			@include tablet() {
				padding: 4px 9px;
			}
			@include widescreen() {
				white-space: nowrap;
			}
			.famfamfam-flag {
				padding: 0px 6px;
			}
		}
		.selected {
			background-color: #1a1a23;
			color: $primary-color;
			text-transform: uppercase;
		}
		.clickable {
			text-transform: uppercase;
		}
	}
}

.tournaments-tournament-list {
	tr {
		background-color: rgba(1, 5, 19, 0.4);
	}

	th {
		color: white;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.region-col {
		width: 10%;
		text-align: left;
		div {
			margin-left: 6px;

			&.famfamfam-flag {
				margin-left: 13px;
			}
		}
	}

	.name-col {
		width: 35%;
		text-align: left;
	}

	.teams-col {
		width: 10%;
		text-align: center;
	}

	.twitch-starting-at-col {
		width: 25%;
		text-align: center;
	}

	td.twitch-starting-at-col {
		text-transform: uppercase;
	}

	.tournament-sign-up,
	.tournament-view {
		color: white;
		padding: 5px 20px;
		text-transform: uppercase;
	}

	.tournament-full {
		padding: 0 20px;
		text-transform: uppercase;
	}

	.tournament-sign-up {
		background-color: $important-color;

		&:hover {
			background-color: $important-hover-color;
			cursor: pointer;
		}
	}

	.tournament-view {
		background-color: $button-color;

		&:hover {
			background-color: $button-hover-color;
			cursor: pointer;
		}
	}

	.fa-twitch {
		color: purple;
		font-size: 28px;
		padding-top: 3px;
		line-height: 53px;
		font-weight: 200;
	}
}

.tournaments-tournament-card-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;

	@include mobile() {
		flex-wrap: nowrap;
		overflow-x: auto;
	}

	&.completed {
		.tournaments-tournament-card .cover {
			opacity: 0.3;
		}
	}

	& + .content-footer {
		margin-top: 20px;

		button.more {
			min-width: 250px;
		}
	}
}

.tournaments-view-all-card {
	display: flex;
	@include mobile() {
		flex: 0 0 auto;
	}
	flex-direction: column;
	position: relative;
	width: 258px;
	margin: 10px 7px;
	border-radius: 16px;
	border: 1px solid #21232c;
	background-color: #14151c;

	.icon-trophy {
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		clear: both;
		margin: 20px auto 0;
		width: 125px;
		height: 125px;
		background-image: url(../img/esportalglobal/icon_trophy.png);
	}
	.message {
		color: $grey-text-color;
		align-self: center;
		text-align: center;
		padding: 0 50px 15px 50px;
	}
	.load-more-button {
		align-self: center;
		min-width: 140px;
	}
}

.tournaments-tournament-card {
	width: 258px;
	margin: 10px 0;

	@include mobile() {
		margin: 10px 7px;
		min-width: 200px;
	}

	.tournament-card-content {
		border-radius: 4px;
		border: 1px solid $dark-grey-border;
		display: flex;
		@include mobile() {
			flex: 0 0 auto;
		}
		flex-direction: column;
		position: relative;

		&.prime {
			border: solid 1px $prime-color;

			.meta {
				.name {
					color: $prime-color;
				}
			}
		}

		&.premium {
			border: solid 1px $premium-color;

			.meta {
				.name {
					color: $premium-color;
				}
			}
		}
	}

	.cover {
		width: 100%;
		border-radius: 4px 4px 0 0;
	}
	.entry {
		position: relative;
	}

	.date {
		color: #000;
		font-size: 14px;
		margin-top: 2px;
		margin-right: 22px;
	}

	.meta {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		background-color: #1d1e29;
		width: 100%;
		padding: 10px;
		border-top: 1px solid $dark-grey-border;

		.info-wrapper {
			min-width: 0;

			.title {
				font-size: 11px;
				color: $dark-grey-text-color;
			}
			.text {
				font-size: 12px;
				line-height: 16px;
				color: #fff;
				white-space: nowrap;
			}
		}
		.info-wrapper + .info-wrapper {
			margin-left: 5px;
		}
		.flag-wrap {
			float: right;
			margin-right: 4px;
			.region-flag,
			.country-flag {
				width: 23px;
				height: 16px;
				background-size: 23px 16px;
				background-position: 0px 50%;
				line-height: 16px;
				background-repeat: no-repeat;
				padding-left: 28px;
			}
		}
	}
	.name-section {
		background-color: #212230;
		border-top: 1px solid $dark-grey-border;
		padding: 10px;

		.name-wrap {
			height: 36px;
			display: flex;
			align-items: center;
		}
	}
	.prize-section {
		background-color: #28293a;
		position: relative;

		.prize-wrap {
			color: #212230;
			font-size: 10px;
			position: absolute;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: $primary-color;
			height: 18px;
			line-height: 18px;
			border-radius: 9px;
			padding: 0 6px;

			.trophy-icon {
				margin-right: 4px;
			}
		}
		.prize {
			color: #fff;
			line-height: 26px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			text-align: center;
			margin-top: 10px;
			font-size: 12px;
		}
	}

	.signup-button {
		border-radius: 0 0 4px 4px;
		height: 38px;
		width: 100%;
	}

	.clear-border {
		border-radius: 0px;
	}

	.view-ladder {
		background-color: #303144;
		color: #6ce900;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 35px;

		.icon {
			margin-right: 6px;
		}
	}
	.round-bottom-borders {
		border-radius: 0 0 5px 5px;
	}
}

.tournaments-tournament-card-padding {
	height: 2em;
	width: 100%;
}

.manage-tournaments-page {
	max-width: 1200px;
}

.manage-tournaments-heading {
	margin-bottom: 24px;
}
.create-tournament-button {
	margin-left: 26px;

	.fas {
		margin-right: 8px;
	}
}

.manage-tournaments-table {
	th:first-child,
	th:last-child,
	td:first-child,
	td:last-child {
		padding: 0 16px;
	}

	td,
	td:first-child,
	td:last-child {
		border: none;
	}

	tbody tr {
		height: 66px;
	}
}
.manage-tournament-status {
	text-transform: uppercase;
}
.edit-tournament-button {
	position: relative;
	top: 2px;
	font-size: 20px;
}
.tournaments-description {
	display: inline-block;
	position: relative;
	background-color: #1c1c26;
	border: 1px solid #262636;
	padding: 11px 22px;
	margin: 1em 0;

	.tournaments-description-header {
		display: inline-block;
		font-size: 16px;
		font-weight: 500;
		line-height: 40px;
	}

	.tournaments-description-content {
		display: inline-block;
		color: $grey-text-color;
		font-size: 15px;
		font-weight: 500;
		line-height: 24px;

		> div {
			margin: 1em 0;
		}
	}

	.tournaments-description-read-more {
		display: inline-block;
		color: $grey-text-color;
		font-size: 15px;
		font-weight: 500;
		line-height: 44px;
	}

	.tournaments-description-close-button {
		color: $grey-text-color;
		text-transform: uppercase;
		position: absolute;
		cursor: pointer;
		top: 18px;
		right: 20px;
		font-size: 13px;
		font-weight: 800;
		padding: 2px;
	}
}

.view-all {
	min-width: 155px;
}

;@import "sass-embedded-legacy-load-done:93";