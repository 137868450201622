.team-profile-achievements,
.user-profile-tournament-achievements {
	.header {
		height: 40px;
		background-color: $primary-color;
		font-size: 14px;
		line-height: 40px;
		text-align: center;
		font-weight: 700;
		color: #fff;
		margin-bottom: 5px;
	}

	.item:not(:last-child) {
		margin-bottom: 8px;
	}

	.logo {
		background-color: #fafafa;
		height: 100px;
		background-position: 50% 50%;
		background-image: url(../img/logo.png);
		background-size: 100%;
	}

	.achievements-info {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background-color: #fbfbfb;
		min-height: 75px;
		text-align: center;

		.placement {
			margin-top: 5px;
			font-size: 26px;

			&.is-gold {
				background-color: #fddfa9;
				color: #f2ba4c;
			}

			&.is-silver {
				background-color: #e6e6e6;
				color: #b3b3b3;
			}

			&.is-bronze {
				background-color: #e6c09b;
				color: #cd7f32;
			}

			.ordinal {
				font-size: 13px;
				text-transform: uppercase;
				vertical-align: super;
			}
		}

		.date {
			color: #545454;
			margin-bottom: 5px;
		}
	}
}

;@import "sass-embedded-legacy-load-done:63";