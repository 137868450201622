#snackbar {
	position: fixed;
	bottom: 0px;
	z-index: 9998;

	@include mobile() {
		display: none;
	}
}

.snack {
	color: #fff;
	position: relative;
	margin-bottom: 5px;
	margin-left: 5px;
	width: 350px;
	min-height: 80px;
	background-color: $table-header-background-color;
	border-radius: 4px;
	display: flex;
}
.snack-close {
	position: absolute;
	top: 10px;
	right: 5px;
	padding: 5px 5px;
	cursor: pointer;
	font-size: 16px;
	font-weight: 700;
	color: $grey-text-color;

	&:hover {
		color: #fff;
	}
}
.snack-logo {
	display: flex;
	align-items: center;
	justify-content: center;

	.default-snack-logo {
		min-width: 100px;
		height: 100%;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/logo.png);
	}

	.prime-queue {
		background-color: transparent;
		background-image: url(../img/prime.png);
		background-size: 40px 40px;
		background-position: 50% 50%;
		background-repeat: no-repeat;
	}

	.avatar {
		height: 80px;
		width: 80px;
		margin: 1rem;
		margin-right: 0;
	}

	.snack-gather-invite {
		height: 80px;
		width: 80px;
		margin: 1rem;
		margin-right: 0;
		background-color: #2d2e3e;
		border-radius: 50%;
		color: $primary-color;
		font-size: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.snack-thumbnail {
		min-width: 100px;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}
	.default-team-logo {
		min-width: 100px;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
	}
}
.snack-body {
	margin: 1rem;
}
.snack-title {
	margin-right: 20px;
	margin-bottom: 0.75rem;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 500;
}
.snack-content {
	margin-top: 5px;
	font-size: 14px;
	color: #98a0ae;

	.avatar {
		margin-top: 5px;
		margin-bottom: 5px;
	}

	a {
		color: #fff;
	}
}
.snack-admin-request-message {
	overflow-y: auto;
	max-height: 60px;
	word-break: break-all;
	padding-right: 5px;
}
.snack-friend-request-actions {
	margin-top: 0.75rem;
	display: flex;

	.button {
		color: $button-color;
		border-color: $button-color;
		min-width: 40%;

		&.decline {
			color: #fff;
			border-color: #3f4151;
		}
	}
}

.snack.wide {
	width: 550px;

	.snack-logo {
		max-width: 234px;

		&.drop-image-background {
			background-image: url('../img/drops/drop-item-background.png');
			background-size: contain;
			background-position: center;
		}
	}
}

.tournament-snack-check-in {
	flex-direction: column;

	.snack-thumbnail {
		border-bottom-left-radius: 0;
	}

	.snack-body {
		margin-bottom: 0;
	}

	.counter {
		margin-top: 0.75rem;
		margin-bottom: 5px;
		width: 100%;
		display: flex;
		justify-content: space-evenly;

		.sector {
			display: flex;
			flex-direction: column;
			align-items: center;

			.double-digits {
				display: flex;

				.digit {
					display: flex;
					align-items: center;
					justify-content: center;

					width: 17px;
					height: 21px;
					background-color: #191a24;
					border-radius: 3px;
					font-size: 18px;
					color: #98a0ae;
				}

				.digit + .digit {
					margin-left: 5px;
				}
			}
			.label {
				color: #5e6271;
				font-size: 11px;
				margin-top: 5px;
			}
		}

		.sector + .sector {
			margin-left: 10px;
		}
	}
	.snack-friend-request-actions {
		margin-bottom: 0.75rem;
		width: 100%;
		justify-content: center;
		align-items: center;
	}
}
.tournament-snack-check-in.wide {
	.counter .sector .double-digits .digit {
		width: 27px;
		height: 31px;
	}
}

;@import "sass-embedded-legacy-load-done:21";