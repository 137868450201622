.ql-toolbar.ql-snow {
	border: 0 !important;
	padding: 0 !important;
}
.ql-container.ql-snow {
	border: 1px solid #d5d5d5 !important;
}
.ql-picker {
	color: #fff !important;
}
.ql-stroke {
	stroke: #fff !important;
}
.ql-snow .ql-fill {
	fill: #fff !important;
}
.ql-picker-options {
	background-color: #0076a3 !important;
	color: #fff !important;
	border-color: #00678d !important;
}

.ql-twitch {
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	font-family: 'Font Awesome 5 Brands';
	color: white;
	&:hover {
		color: $primary-color;
	}
}
.ql-twitch:after {
	content: '\f1e8';
}

.ql-tooltip[data-mode='twitch']::before {
	content: 'Enter twitch username:';
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
	color: $primary-color;
}
.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
	fill: $primary-color;
}
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
	stroke: $primary-color;
}
.ql-active {
	color: $important-color !important;
	.ql-stroke {
		stroke: $important-color !important;
	}
	.ql-fill {
		fill: $important-color !important;
	}
}

.ql-picker-item:hover,
.ql-picker-label:hover {
	color: $important-color !important;
}

.ql-formats {
	background-color: #0076a3 !important;
	border-top: 1px solid #13aceb;
	border-left: 1px solid #13aceb;
	border-radius: 5px;
	padding: 5px 5px;
	margin-bottom: 10px;
}

.ql-container .ql-editor {
	min-height: 300px;
	max-height: 500px;
	padding: 0px;
}

.wysiwyg-content {
	margin-top: 40px;
	word-wrap: break-word;
	white-space: pre-line;
	line-height: 1.42;
	padding: 15px 20px;

	p {
		margin-bottom: 0;
		font-size: 18px;
		font-weight: 400;
		margin-top: 0px !important;
		color: #000;
		padding: 0;
	}

	img {
		width: auto;
		max-width: 100%;
	}

	iframe {
		width: 533.34px;
		height: 300px;
	}

	h1 {
		font-size: 36px !important;
	}

	h2 {
		font-size: 21px !important;
	}

	h3 {
		font-size: 19px !important;
	}

	h4 {
		font-size: 17px !important;
	}

	h5 {
		font-size: 15px !important;
	}

	h6 {
		font-size: 12px !important;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0px;
	}

	ul li {
		list-style-type: disc !important;
	}
	ol li {
		list-style-type: decimal !important;
	}

	ul,
	ol {
		li::before {
			content: '';
			display: none;
		}
		li {
			padding-left: 0 !important;
		}
		padding-left: 0 !important;
		margin-left: 1.5em !important;
	}

	.ql-align-right {
		text-align: right;
	}

	.ql-align-center {
		text-align: center;
	}

	.ql-align-left {
		text-align: left;
	}

	.ql-indent-1 {
		padding-left: 3em;
	}

	.ql-indent-2 {
		padding-left: 6em;
	}

	.ql-indent-3 {
		padding-left: 9em;
	}

	.ql-indent-4 {
		padding-left: 12em;
	}

	.ql-indent-5 {
		padding-left: 15em;
	}

	.ql-indent-6 {
		padding-left: 18em;
	}

	.ql-indent-7 {
		padding-left: 21em;
	}

	.ql-indent-8 {
		padding-left: 24em;
	}

	blockquote {
		border-left: 4px solid #ccc;
		margin-bottom: 5px;
		margin-top: 5px;
		margin-left: 0px;
		padding-left: 16px;
	}

	pre {
		border-radius: 3px;
		background-color: #dddddd !important;
		color: #181812 !important;
		white-space: pre-wrap;
		margin: 5px 0px;
		padding: 5px 10px;
	}
}
.wysiwyg-content-transparent {
	background-color: transparent !important;
	border: 0 !important;
	padding: 0 !important;
	margin: 0 !important;
}
.wysiwyg-editor {
	margin-top: 0px;
}

;@import "sass-embedded-legacy-load-done:64";