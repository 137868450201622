.ladder-page {
	max-width: 1642px;
	margin: 0px auto;

	.column {
		padding: 2.5rem;
	}

	.has-right-column {
		margin-right: 16px;
	}

	.content-heading {
		@include mobile() {
			padding-left: 8px;
		}
	}

	.view-all {
		line-height: 35px;
		text-transform: uppercase;
	}

	.featured-tournaments-carousel .carousel .navigation-button {
		top: 25%;
	}
}

.ladder-page-content {
	padding: 0 !important;

	.ladder-status {
		width: 100%;
		line-height: 75px;
		font-size: 22px;
		font-weight: 400;
		color: #c0c6d1;
		background-color: #161720;

		.icon-premium,
		.icon-prime {
			width: auto;
			background-position: 0px 50%;
			background-size: 32px 32px;
			padding-left: 40px;
			height: 75px;
			display: flex;
			align-items: center;
		}

		.ladder-title {
			max-width: 1200px;
			margin: auto;
			overflow: auto;
		}

		.ladder-title-status {
			font-size: 16px;
			font-weight: 400;
			text-transform: uppercase;
			white-space: nowrap;

			& > * {
				display: inline-block;
			}
		}

		.ladder-status-data {
			color: $primary-color;
			padding: 0px 10px;
		}

		.ladder-status-separator {
			padding-left: 30px;
		}

		@include mobile() {
			line-height: 1.2;
			padding: 25px 15px 10px;

			.ladder-title-status {
				display: flex;
				justify-content: space-between;
			}
			.ladder-status-separator {
				padding-left: 0px;
			}
		}
	}

	.ladder-banner {
		width: auto;
		max-width: 100%;
		@include mobile() {
			width: 100%;
		}
	}

	&.ladder-premium-only .ladder-wysiwyg-tab {
		color: $premium-color;
	}

	&.ladder-prime-only .ladder-wysiwyg-tab {
		color: $prime-color;
	}

	.ladder-wysiwyg-tab {
		display: inline-block;
		margin-right: 5px;
		cursor: pointer;
		padding: 10px 40px;
		font-weight: 400;
		text-transform: uppercase;

		&.active {
			color: #fff;
			background-color: #1a1d26;
			box-shadow: inset 0 -4px 0 0 $primary-color;
		}

		&.inactive {
			color: $primary-color;
		}
	}

	.ladder-details-table td:first-child {
		text-transform: uppercase;
		font-weight: 500;
	}

	.ladder-details-table tr {
		border-top: 1px solid #292a34;
		font-weight: 400;
		line-height: 55px;
		color: #c0c6d1;
		font-size: 14px;
	}

	.ladder-details-table tr:first-child {
		border-top: 0px;
	}

	&.ladder-premium-only .ladder-details-table td:first-child {
		color: $premium-color;
	}

	&.ladder-prime-only .ladder-details-table td:first-child {
		color: $prime-color;
	}

	.ladder-matches-buttons {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.ladder-queue {
		font-size: 16px;
		cursor: default;
		margin-bottom: 40px;
		margin-top: 20px;

		.ladder-sign-up {
			font-size: 14px;
			width: 100%;
		}

		.ladder-join-diagnostic {
			font-size: 11px;
			text-align: center;
			color: $grey-text-color;
			padding: 4px;
		}

		.section {
			color: $grey-text-color;
			background-color: $table-header-background-color;
			font-size: 13px;
			margin: 6px 0;
			padding: 6px 12px;
			border-radius: 4px;

			.subsection {
				background-color: #2a2b39;
				margin-top: 10px;
				margin-bottom: 5px;
				border-radius: 4px;
			}

			.section-header {
				font-size: 15px;
			}
		}

		.ladder-queue-player-counter {
			.count {
				color: $primary-color;
				font-size: 14px;
				width: 100%;
			}

			.text-display {
				display: flex;
				justify-content: space-between;

				.count {
					color: $primary-color;
				}
			}

			.party-selector {
				display: flex;
				justify-content: space-evenly;

				.choice {
					display: flex;
					align-items: center;
					padding: 5px;

					&:first-child {
						flex-basis: 30%;
					}
				}
			}
		}

		.ladder-invite-friends-list {
			max-height: 200px;
			display: block;
			overflow: auto;
			padding: 5px;

			div {
				display: flex;
				flex-direction: row;
				flex-shrink: 1;

				align-items: center;
				justify-content: space-between;

				background-color: #1b1c26;

				padding: 2px 3px;
			}

			.player-rank-icon {
				height: 30px;
				width: 30px;
				background-size: 30px 30px;
			}

			.friends-username {
				font-size: 13px;
			}

			.player-column {
				flex-grow: 1;
			}

			.invite-column {
				display: flex;
				align-items: center;
				justify-content: flex-end;

				.invite-button {
					padding: 10px;
					width: 40px;
				}
			}
		}

		.players-list {
			display: flex;
			flex-wrap: wrap;
			line-height: 2em;
			text-align: left;
			padding: 3px 0;

			.player {
				background-color: #191c22;
				margin: 2px;
				padding: 12px 14px;
				width: 48%;

				.famfamfam-flag {
					margin-right: 6px;
				}

				.name {
					padding-left: 4px;
					color: $primary-color;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.placeholder {
					color: #2c2e3a;
					font-size: 9px;
				}

				.avatar-placeholder {
					border: 2px solid #2c2e3a;

					.fa-user {
						display: block;
						padding: 5px;
						color: #2c2e3a;
						font-size: 12px;
					}
				}
			}
		}
	}

	.player-ladder-content {
		max-width: 1200px;
		margin: auto;

		.content-heading {
			font-weight: 400;
			padding-bottom: 5px;
		}

		.content-body {
			margin-top: 0;
			@include mobile() {
				padding: 25px 15px 10px;
			}
		}

		.ladder-mobile-nav {
			$height: 47px;
			font-size: 15px;
			position: relative;
			top: -3px;
			width: 100%;
			font-weight: 500;
			text-transform: uppercase;

			.mobile-nav-tab {
				color: white;
				height: $height;
				display: none;
				background-color: $secondary-color;
				align-items: center;
				justify-content: center;
			}

			&.collapsed {
				.mobile-nav-tab {
					display: flex;
					border-bottom: 1px solid $dark-grey-border;

					&:last-child {
						border-bottom: 0;
					}
				}
			}

			.mobile-nav-tab.active {
				display: flex;
				color: $primary-color;
			}

			.dropdown-icon {
				height: $height;
				display: flex;
				align-items: center;
				position: absolute;
				top: 0;
				right: 0;
				padding: 0 30px;
			}
		}

		.table-striped tr {
			height: 55px;
			background-color: #12121a;
		}

		.ladder-standings-table-container {
			.scroll-indicator {
				display: none;
			}

			@media (max-width: 600px) {
				th {
					line-height: 1.2;
				}

				.table-wrapper {
					width: 100%;
					overflow-x: scroll;
					overflow-y: hidden;
					scroll-behavior: smooth;
				}
				table {
					.scroll-indicator {
						width: 30px;
						display: inline-block;
						color: $primary-color;
						font-size: 20px;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						margin-right: 2px;
					}

					th.username-column {
						& > div.td-inner {
							width: 60vw;
						}
					}

					th.score-column {
						text-align: left;

						& > div.td-inner {
							width: 32vw;
							display: flex;
							align-items: center;
							justify-content: space-between;
						}

						.caret-container {
							white-space: nowrap;
						}
					}

					td.score-column {
						text-align: left;
					}
				}
			}
		}
	}

	&.ladder-prime-only,
	&.ladder-premium-only {
		@media (max-width: 600px) {
			.ladder-standings-table-container table .scroll-indicator {
				color: white;
			}
		}
	}

	&.ladder-premium-only th {
		background-color: #ff7500 !important;
		color: #fff;
	}

	&.ladder-prime-only th {
		background-color: $prime-color !important;
		color: #fff;
	}

	.ladder-standing-placement {
		padding-right: 4px;
	}

	.table-striped {
		color: $grey-text-color;

		td:first-child {
			padding-left: 18px !important;
		}

		.table-avatar {
			margin-right: 9px !important;
			margin-left: 14px !important;
		}

		.player-card-wrapper {
			padding-right: 6px;
		}

		.country-flag {
			width: 24px;
			height: 16.5px;
		}
	}

	#ranking-self-row {
		color: $grey-text-color !important;
		background-color: #282a39 !important;
		border-bottom: 0px !important;
	}

	.content-heading {
		display: flex;
		flex-direction: row;
	}

	.ladder-standing-filter-checkbox {
		font-size: 12px;
		font-weight: 500;
		display: flex;
		align-items: center;
		padding-left: 40px;
		text-transform: uppercase;

		input {
			margin-right: 10px;
		}
	}

	.ladder-country-flag {
		margin-right: 4px;
	}
}

;@import "sass-embedded-legacy-load-done:104";