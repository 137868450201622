.finnkampen-banner-root {
	@import '../../lib/bulma.scss';
	background-image: url('../../../img/finnkampen/lobbyheader_eng2.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	display: flex;
	background-color: transparent;
	height: 170px;
	width: 100%;
	position: relative;
	justify-content: flex-end;
	max-width: 100%;

	padding: 2% 11% 0 0;

	@include mobile() {
		padding: 2% 7% 0 0;
	}

	&.fi {
		background-image: url('../../../img/finnkampen/lobbyheader_fin2.png');
	}

	&.se {
		background-image: url('../../../img/finnkampen/lobbyheader_swe2.png');
	}

	.win-counter-caption-root {
		@include mobile() {
			transform: scale(0.6);
			padding: 0;
		}
	}

	.win-counter-caption {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 20px;

		img {
			width: 45px;
			max-width: 45px;
			margin-right: 10px;
		}
	}
}

.wins-counter {
	display: flex;

	.wins-counter__value {
		color: #ffda00;
		font-size: 25px;
		background-color: #006aa7;
		border-radius: 3px;
		margin: 0 2px;
	}

	p {
		line-height: normal;
		margin: 0;
		padding: 2px 7px;
	}
}
