.user-sign-up-source {
	width: 650px;
	padding: 0;

	.popover-content {
		padding: 0 0 15px 0;
	}

	@include mobile() {
		width: 90vw;
	}

	.bold {
		font-weight: 700;
	}

	.header {
		padding: 15px;
		width: 100%;
		font-size: 16px;
		margin-bottom: 30px;
		position: relative;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;

		.close-button {
			position: absolute;
			top: 0.5rem;
			right: 0.5rem;
			width: 20px;
			min-height: 20px;
		}
	}

	.user-sign-up-source-content {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.options {
		width: 600px;

		height: 360px;
		overflow-y: auto;

		@include mobile() {
			width: 100%;
		}
	}

	.option {
		width: 100%;
		display: flex;
		flex-direction: column;
		background-color: #1f202b;
		padding: 10px;
		border-radius: 4px;

		&:not(:last-child) {
			margin-bottom: 5px;
		}

		input[type='radio'] {
			margin: 0 10px;
			cursor: pointer;
		}
	}

	.option-info {
		display: flex;
		align-items: center;
		cursor: pointer;
		user-select: none;
	}

	.optional-comment-holder {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 65px;
		overflow: hidden;
		padding: 15px 0;

		input[type='text'] {
			width: 100%;
			caret-color: $primary-color;

			&::placeholder {
				color: #acacac;
			}
		}
	}

	.buttons {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 20px;

		.button.submit {
			width: 120px;
		}
	}
}

;@import "sass-embedded-legacy-load-done:130";