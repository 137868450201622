.level-popover-level {
	width: 254px;
	height: 240px;
	margin: 0 auto;
	background-size: 254px 240px;

	&.locked {
		filter: grayscale(1) opacity(0.5);
	}
}

.level-popover-progress-bar-container {
	width: 80%;
	height: 20px;
	margin: 0 auto;
	background-color: #cccccc;

	> .level-popover-progress-bar {
		height: 100%;
		background-color: $primary-hover-color;
	}

	.level-popover-progress-stat {
		position: relative;
		top: -20px;
		line-height: 20px;
		text-align: center;
		font-size: 16px;
	}
}

;@import "sass-embedded-legacy-load-done:117";