$primary-color: #daa200;

.ence-landing-root {
	background-color: #12151c;

	.first-screen {
		padding-bottom: 100px;

		.block-title {
			font-size: 48px;
		}

		@media only screen and (max-width: 1200px) {
			padding-bottom: 60px;
		}

		@media only screen and (max-width: 480px) {
			padding-bottom: 30px;
		}
	}
}

.sign-up-blocks-list {
	display: flex;
	justify-content: center;

	.sign-up-blocks-item {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 422px;
		max-width: 100%;
		margin: 0 30px;
		padding-top: 87px;
		padding-bottom: 58px;
		background-color: #171a22;
		border: 1px solid #1e212b;
		border-radius: 4px;

		.block-general-banner {
			position: absolute;
			top: -1px;
			left: -1px;
			width: calc(100% + 2px);
		}
	}

	.bullet-points {
		padding: 0 20px 24px;

		&-item {
			font-size: 14px;
			line-height: 18px;
		}
	}

	.sign-up-block-btn {
		position: absolute;
		bottom: 20px;
		left: calc(50% - 125px);
		width: 250px;
		max-width: 80%;
		margin: 0 auto;
		padding: 10px 0;
		text-align: center;
		text-transform: uppercase;
		color: #fff;
		border-radius: 4px;
		background-color: #d25c0c;

		&:hover {
			opacity: 0.9;
		}

		&-disabled {
			background-color: #464951;
			cursor: default;

			&:hover {
				background-color: #464951;
			}
		}
	}

	@media only screen and (max-width: 800px) {
		display: block;
		padding: 0 20px;

		.sign-up-blocks-item {
			display: block;
			margin: 0 auto 20px;
		}
	}

	@media only screen and (max-width: 480px) {
		.sign-up-blocks-item {
			padding-top: 60px;
		}

		.bullet-points {
			padding-right: 0;
			padding-left: 0;
		}
	}
}

.sign-up-section {
	padding-bottom: 100px;

	@media only screen and (max-width: 1200px) {
		padding-bottom: 60px;
	}

	@media only screen and (max-width: 480px) {
		padding-bottom: 30px;
	}
}
