.terms-popover {
	border-color: #444;
	background-color: #1f202b;
	width: 100%;
	max-width: 600px;
	padding: 0;
	border-radius: 10px;
	color: $white-text-color;

	.terms-header {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		margin-top: -15px;
		background-color: $button-color;
		padding: 12px;
	}

	.terms-body {
		text-align: left;
		margin: 20px;
		font-size: 14px;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}

	.terms-button-transparent {
		font-size: 12px;
		color: $primary-color;
		background: transparent !important;
		margin-top: 1em;
		border: none;
		cursor: pointer;
	}

	&.button {
		font-size: 14px;
	}
}

;@import "sass-embedded-legacy-load-done:119";