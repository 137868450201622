.popover.challenge-1v1-invite-popover {
	padding: 0;
	border-radius: 10px;
	width: 450px;
	height: 400px;
	background-image: url(../img/backgrounds/gathersback3.png);
	background-repeat: no-repeat;
	background-position: top;
	background-size: 300%;

	.popover-content {
		padding: 0;
	}

	.challenge-1v1-invite-container {
		width: 100%;

		.challenge-content {
			height: 330px;
			padding: 40px;
			position: relative;

			.header {
				font-size: 20px;
				color: $premium-color;

				.icon20 {
					width: 30px;
					height: 30px;
				}
			}

			.players-info {
				display: flex;
				justify-content: center;
				align-items: center;

				.player-info-holder {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					width: 100px;
					min-height: 95px;

					.player-avatar {
						width: 60px;
						height: 60px;
						background-repeat: no-repeat;
						background-size: cover;
						border: 2px solid $primary-hover-color;
						border-radius: 50%;
						margin-bottom: 0.5rem;

						&.premium {
							border-color: $premium-color;
						}
						&.prime {
							border-color: $prime-color;
						}
					}

					.player-details {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: center;

						.user-name {
							margin-left: 0.5em;
						}

						.live-games-rank-icon {
							width: 32px;
							height: 32px;
							background-size: 22px;
						}
					}
				}

				.vs-divider {
					width: 50px;
				}
			}

			.description {
				font-size: 18px;
			}

			.timer-notification {
				font-size: 13px;
			}
		}

		.buttons-holder {
			width: 100%;
			height: 70px;
			background-color: #292a38;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;

			.cancel-button {
				width: 150px;
			}

			.accept-button {
				width: 200px;
			}
		}
	}
}

.minimize-1v1-popup-button {
	position: absolute;
	top: 15px;
	right: 20px;

	.minimize-icon {
		background-image: url(../img/misc/minimize_1v1_popup_icon.svg);
		width: 20px;
		height: 3px;
		background-position: center;
		opacity: 0.8;
		cursor: pointer;
	}
}

.challenge-1v1-invite-snack {
	padding: 0;
	border: 1px solid #47485b;
	border-radius: 10px;
	width: 230px;
	height: 180px;
	background-image: url(../img/backgrounds/gathersback3.png);
	background-repeat: no-repeat;
	background-position: top;
	background-size: 300%;

	.content-section {
		width: 100%;
		height: 120px;
		padding: 15px;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		.header {
			font-size: 16px;
			color: $premium-color;

			.icon20 {
				width: 20px;
				height: 20px;
			}
		}

		.description {
			font-size: 14px;
		}
		.notification {
			font-size: 12px;
		}

		.description,
		.notification {
			width: 100%;
			text-align: center;
		}
	}

	.button-holder {
		width: 100%;
		height: 60px;

		background-color: #292a38;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;

		.open-window-button {
			width: 80%;
		}
	}
}

.popover.challenge-1v1-manager-popover {
	padding: 0;
	border-radius: 10px;
	width: 600px;
	height: 470px;
	background-image: url(../img/backgrounds/gathersback3.png);
	background-repeat: no-repeat;
	background-position: top;
	background-size: 300%;

	.popover-content {
		padding: 0;
	}

	.challenge-1v1-manager-container {
		width: 100%;

		.challenge-content {
			height: 420px;
			padding: 30px;
			position: relative;

			.header {
				font-size: 20px;
				color: $premium-color;

				.icon20 {
					width: 30px;
					height: 30px;
				}
			}

			.description {
				font-size: 18px;
			}

			.notification {
				font-size: 14px;
			}

			.invited-friend-list {
				max-height: 200px;
				overflow: auto;

				.profile-friend {
					justify-content: space-between;

					.friend-info {
						display: flex;
						align-items: center;
						width: 40%;

						.friend-list-username {
							flex-grow: 0;
							display: flex;
							align-items: center;
						}
					}

					.live-games-rank-icon {
						width: 25px;
						height: 40px;
						background-size: 25px;
					}

					.invite-buttons {
						display: flex;

						.button {
							width: 100px;

							&.narrow {
								width: 80px;
							}

							&.premium-color {
								color: $premium-color;
								border-color: $premium-color;
							}
							&.premium-color:hover {
								color: $premium-color-dark;
								border-color: $premium-color-dark;
							}
						}
					}
				}
			}
		}

		.buttons-holder {
			width: 100%;
			height: 70px;
			background-color: #292a38;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;

			.cancel-button,
			.accept-button {
				width: 30%;
			}
		}
	}
}

.top-bar-notification {
	width: 260px;
	height: 70px;
	background-color: #ff800017;

	padding: 10px;

	display: flex;
	justify-content: space-between;
	align-items: center;

	.text-holder {
		font-size: 12px;
	}
	.icon20 {
		width: 35px;
		height: 35px;
	}

	.button {
		min-height: 25px;
	}
}

;@import "sass-embedded-legacy-load-done:136";