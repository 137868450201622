.offers-banner {
	background-color: rgba(87, 0, 162, 0.8);
	border-radius: 20px;
	color: #fff;
	padding: 50px 25px;

	.offers-banner-title {
		text-align: center;
		text-transform: uppercase;
		font-size: 50px;
		font-weight: 700;
		font-family: 'Roboto Condensed';
	}

	@include mobile() {
		display: block;
	}
}
.offers-image-banner {
	height: 250px;
	background-size: cover;
	background-color: rgba(87, 0, 162, 0.8);
	border-radius: 20px;
}
.offers-title {
	clear: both;
	font-size: 40px;
	text-transform: uppercase;
	font-weight: 600;
	text-align: center;
	padding: 50px 0px 15px 0px;
	font-family: 'Roboto Condensed';
}
.offers-entries {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	color: #fff;

	.offers-entry {
		text-align: center;
		cursor: default;
		border-radius: 5px;
		margin-top: 20px;
		margin-left: 20px;
		max-width: 300px;
		transition: all 0.25s ease-in-out;

		&:first-of-type {
			margin-left: 0px;
		}

		@include mobile() {
			width: 100%;
			&:first-of-type {
				margin-left: 20px;
			}
		}
		@include tablet-only() {
			width: 48%;
			&:first-of-type {
				margin-left: 20px;
			}
		}
		@include desktop-only() {
			width: 48%;
			&:first-of-type {
				margin-left: 20px;
			}
		}
		&.featured {
			box-shadow: 0 0 20px 0 #8e4dff;
		}

		.offer-entry-title {
			background-color: #292937;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			padding: 20px 10px;
			font-size: 20px;
			position: relative;

			.best-deal {
				position: absolute;
				left: 50%;
				top: 0;
				transform: translate(-50%, -50%);
				background-color: #8e4dff;
				padding: 5px 20px;
				text-transform: uppercase;
				font-size: 10px;
				font-weight: 600;
				color: #292937;
				border-radius: 5px;
			}
		}
		.offer-entry-monthly-pricing {
			padding: 20px 20px;
			background-color: #1d1e2a;
			display: flex;
			flex-direction: column;
		}
		.offer-entry-price-value {
			color: #fff;
			font-size: 30px;
			font-weight: 700;
		}
		.offer-entry-price-period {
			font-size: 12px;
			color: $grey-text-color;
		}
		.offer-entry-savings {
			color: $prime-color;
			height: 24px;
		}
		.offer-entry-button {
			padding: 10px;
			border-radius: 0 0 5px 5px;
		}
		.offer-entry-billing {
			padding-bottom: 10px;

			.offer-entry-billing-price {
				font-size: 18px;
				font-weight: 700;
			}
			.offer-entry-billing-period {
				font-size: 12px;
				color: $grey-text-color;
			}
		}
		.offer-entry-description {
			padding: 20px 0px;

			.description-point {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.plp-check.fas {
					display: block;
				}
			}
			ul {
				text-align: left;
			}
		}
		.offer-entry-button {
			background-color: #151621;
		}

		&.offers-entry-selected {
			margin-top: 0px;
			background-color: #3c2080;

			.offer-entry-title {
				background-color: #0090ff;
			}
			.offer-entry-price-value {
				color: #0090ff;
			}
		}
	}
}
.offers-purchase-button {
	margin: 25px auto;
	background-color: #8522ff;
	border-radius: 4px;
	padding: 5px 5px;
	font-size: 17px;
	font-weight: 700;
	width: 300px;
	display: block;
}
.offers-purchase-button:hover {
	background-color: #8522ff;
}
.offers-payment-types-title {
	text-align: center;
	font-size: 20px;
	margin-top: 50px;
}
.offers-payment-types {
	border-radius: 78px;
	background-color: #fff;
	border: 1px solid #707070;
	width: 620px;
	padding: 0px 20px;
	margin: 10px auto;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-wrap: wrap;
}
.offers-payment-type {
	display: inline-block;
	width: 100px;
	margin: 5px 5px;
	height: 50px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: contain;
}
.offers-payment-type-visa {
	background-image: url(../img/payments/visa.svg);
	height: 20px;
	margin-left: 20px;
	margin-bottom: 3px;
}
.offers-payment-type-mastercard {
	background-image: url(../img/payments/mastercard.svg);
}
.offers-payment-type-amex {
	background-image: url(../img/payments/amex.svg);
}
.offers-payment-type-paypal {
	background-image: url(../img/payments/paypal.svg);
	height: 22px;
	margin-top: 10px;
	margin-right: 25px;
}
.offers-payment-type-swish {
	background-image: url(../img/payments/swish.svg);
	background-size: 200px 200px;
	margin-right: 40px;
}

.offer-page-premium {
	.offers-banner {
		background-color: rgba(0, 0, 0, 0.5);
		color: $premium-color;
	}
	.offers-image-banner {
		background-color: rgba(0, 0, 0, 0.5);
	}
	.offers-entry {
		background-color: #27293a !important;

		&.offers-entry-selected {
			background-color: #ee6000 !important;
		}
		&.featured {
			box-shadow: 0 0 20px 0 $premium-color;
		}

		.best-deal {
			background-color: $premium-color !important;
		}
		.plp-button {
			background-color: #ff6a00 !important;
		}
		.plp-button:hover,
		.plp-button:focus {
			background-color: $premium-hover-color !important;
		}
	}
	.offer-entry-price-value {
		color: #fff !important;
	}
	.offer-entry-savings {
		color: $premium-color;
	}
	.offers-purchase-button {
		background-color: $premium-hover-color !important;
	}
	.offers-purchase-button:hover {
		background-color: $premium-hover-color !important;
	}
	.plp-dashes {
		color: $premium-color-dark;

		&.plp-prime-color {
			color: $prime-color;
		}
	}

	.plp-content-list {
		display: flex;
		flex-direction: column;

		.plp-content-row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid $dark-grey-border;
			line-height: 3em;

			&:last-child {
				border-bottom: 0;
			}

			.fas.fa-check {
				padding-right: 1em;
			}
		}
	}
}

.premium-advertisement-popover {
	width: 100%;
	max-width: 1000px;
	padding: 0;
	border-radius: 5px;

	@include mobile() {
		width: 100%;
		max-height: 100vh;
		overflow-y: auto;
	}

	@include tablet-only() {
		width: 100%;
		max-height: 100vh;
		overflow-y: auto;
	}

	.close-button {
		position: absolute;
		right: 1em;
		top: 1em;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		border: 1px solid $dark-grey-text-color;
		color: $grey-text-color;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.plp-premium-section-1 {
		background-size: cover;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;

		.plp-premium-title {
			font-weight: 500;
		}
	}

	.popover-content {
		padding: 0;
	}

	.premium-advantages {
		min-width: 220px;
		height: 242px;
		display: flex;
		align-items: center;
		margin-top: 20px;
		margin-left: 3rem;

		@include tablet-only() {
			margin-left: 6rem;
		}

		ul {
			margin-left: 20px;

			li {
				text-align: left;
				margin: 10px 0;

				&::before {
					width: 8px;
					height: 8px;
					top: 59%;
				}
			}
		}
	}

	.offers-text {
		margin: auto;
		max-width: 80%;
	}

	.offers-entries {
		align-items: center;

		.offers-entry {
			min-width: 220px;
		}
	}

	.premium-advertisement-buttons-wrapper {
		width: 100%;
		min-height: 100px;
		background-color: #20212d;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;

		.button {
			height: 35px;
			width: 40%;
		}

		.button.cancel-button {
			margin: 0;
			background: transparent;
			color: $premium-color;
			font-weight: 400;
		}
	}
}

;@import "sass-embedded-legacy-load-done:99";