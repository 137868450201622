#esac-logo {
	width: 216px;
	height: 216px;
	margin: 0px auto;
	background-image: url(../img/esac.png);
}

.esac-btn {
	display: block;
	padding: 10px 30px;
	font-size: 22px;
	width: 290px;
	margin: 10px auto 0px auto;
	text-shadow: 1px 1px 1px #000;
}
.half-esac-btn {
	display: inline-block;
	padding: 10px 30px;
	font-size: 22px;
	width: 250px;
	margin: 10px auto 0px auto;
	text-shadow: 1px 1px 1px #000;
}

.esac-step {
	position: relative;
	overflow: hidden;
	font-size: 25px;
	font-weight: 300;
	max-width: 920px;
	width: 100%;
	margin: 5px auto;
}
.esac-step-num {
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	background-color: $primary-hover-color;
	width: 65px;
	height: 100%;
}
.esac-step-num > span {
	position: absolute;
	top: 50%;
	left: 0px;
	width: 100%;
	text-align: center;
	margin-top: -15px;
	color: #fff;
	font-weight: 400;
}
.esac-step-content {
	padding: 20px 0px;
	color: #000;
}
.esac-step-content-small {
	font-size: 12px;
}

.esac-ul > li:before {
	content: ' ';
	display: inline-block;
	height: 1px;
	width: 1px;
	vertical-align: middle;
	border: 5px solid $primary-hover-color;
	margin-right: 10px;
}
.esac-ul {
	font-size: 18px;
	font-weight: 300;
	margin: 15px 0px 20px 0px;
}
.esac-ul > li {
	margin-bottom: 10px;
}

.esac-heading-1 {
	color: $primary-hover-color;
	font-size: 34px;
	font-weight: 500;
}
.esac-heading-2 {
	font-size: 24px;
	font-weight: 300;
	line-height: 50px;
}

.esac-authentication-heading {
	color: $primary-hover-color;
	font-size: 34px;
	font-weight: 500;
	margin: 20px 0;
}

.esac-authentication-message {
	font-size: 24px;
	font-weight: 300;
	line-height: 36px;
	max-width: 480px;
	margin: 20px auto 40px auto;
}

.esac-authentication-fallback {
	line-height: 30px;
}

.download-client-button {
	font-size: 13px;
	font-weight: 600;
	text-transform: uppercase;
	color: $primary-color;

	.fa-download {
		margin-right: 0.5em;
	}

	&.launch-button {
		padding-top: 0px;
		margin-left: 5em;
		color: #fff;
	}
}

;@import "sass-embedded-legacy-load-done:87";