.user-profile-rank-elo__progress {
	height: 5px;
	width: 100%;
	margin-bottom: 10px;

	.progress_bar {
		background-color: #222330;
		height: 100%;
		margin-left: auto;
	}
}

.user-profile-rank-elo {
	width: 100%;
	background-color: #2a2a3a;
	color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 70px;

	p {
		line-height: unset;
		margin: 0;
		padding: 0;
	}

	.title {
		font-size: 15px;
	}

	.sub-title {
		font-size: 12px;
	}

	.sub-sub-title {
		font-size: 9px;
	}
}

.user-profile-rank-icon {
	width: 53px;
	position: relative;
}

.user-profile-rank-image {
	max-width: 100%;
}

.user-profile-rank-lock {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -60%);
	opacity: 0.85;
	width: 15px;
}
