.spoken-languages-selector {
	max-height: 15em;
	overflow: auto;
	background-color: $content-background-color;
	color: $grey-text-color;
	border: 2px solid #2d2f3b;
	border-radius: 5px;
	padding: 10px;
	line-height: normal;

	.entry {
		display: flex;
		align-items: center;
		padding: 5px 0;

		label {
			padding-left: 0.5em;
		}
	}
}

.settings-page {
	.spoken-languages-selector {
		label:not(.basic-text) {
			padding-bottom: 0;
		}
	}
}
.sign-up-page {
	.box-content {
		.spoken-languages-selector {
			label:not(.basic-text) {
				padding-bottom: 0;
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:82";