#sign-up-page {
	.Tipsy-inner {
		color: #c4c8e8;
		border: 1px solid #5155b2;
		background-color: #1f2154;
		font-size: 10px;
		text-align: left;
	}

	.Tipsy-arrow {
		visibility: hidden;
	}

	.es-text-h1 {
		font-size: 36px;
	}

	#main-content-background {
		background-image: url(@/../img/backgrounds/signup.jpg);
		background-size: cover;
	}

	#sign-up-page-logo {
		margin-top: 20px;
		margin-left: 30px;
	}

	#sign-up-page-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 10vh;

		> #sign-up-page-location {
			margin-top: -26px;
		}

		> #sign-up-page-skill {
			margin-top: 2px;
		}
	}

	#sign-up-page-step-0 {
		.sell-label {
			font-weight: normal;
			margin: 6px 0;
		}
		.email-label {
			font-size: 36px;
			margin-bottom: 16px;
		}
		.email-input {
			width: 330px;
			height: 50px;
			margin-bottom: 16px;
		}
		.terms-checkbox {
			width: 16px;
			height: 16px;
		}
		.terms-checkbox-label {
			font-size: 13px;
			font-weight: normal;
		}
	}

	#sign-up-page-verify-email {
		> div {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.email-label {
			font-size: 36px;
			margin-bottom: 54px;
		}

		.waiting-label {
			font-size: 13px;
			font-weight: normal;
			margin-top: 8px;
			margin-bottom: 0;
		}

		.resend-label {
			font-size: 11px;
			font-weight: 300;
			margin: 0;
		}

		.email-resend {
			width: 100%;
			margin-top: 8px;

			.resend-open {
				width: 100%;
				cursor: pointer;
				padding: 1em;
				text-align: center;

				.fas {
					margin-left: 1em;
				}
			}
			.check-email {
				color: #aeb6c4;
				font-weight: 400;
				padding: 1em;
				margin-bottom: 8px;
			}
			.form {
				width: 240px;
				display: flex;
				flex-direction: column;
				align-content: center;
				align-items: center;
				justify-content: center;
				margin: auto;

				.send-email-button {
					margin-top: 16px;
				}

				> p {
					margin-top: 8px;
					height: 14px;
				}
			}
		}
	}

	#sign-up-page-email-verified {
		.verify-check {
			width: 100%;
			height: 84px;
			font-size: 84px;
			color: $primary-hover-color;
			text-align: center;
		}

		.verify-label {
			font-size: 18px;
			font-weight: normal;
		}

		.redirect-label {
			margin-top: 32px;
			font-size: 11px;
			font-weight: normal;
			text-align: center;
		}

		.redirect-link {
			color: $primary-hover-color;
		}
	}

	#sign-up-page-username {
		> div {
			&:nth-child(2) {
				margin-bottom: 8px;
			}
		}

		.username-label {
			font-size: 36px;
			margin-top: 29px;
			margin-bottom: 6px;
		}

		.username-input {
			width: 325px;
			height: 50px;
			font-size: 20px;
			font-weight: normal;
			color: #fff;

			&.es-textfield-input-error {
				border: 2px solid #eb1338;
			}
		}

		.es-textfield-error {
			margin-top: 8px;
			font-size: 13px;
			font-weight: 400;
		}
	}

	#sign-up-page-location {
		.location-label {
			font-size: 36px;
			margin-bottom: 32px;
		}

		.location-input {
			width: 330px;
			height: 50px;
			font-size: 20px;

			&.es-dropdown-select {
				background-position-y: 10px;
			}
		}

		.location-button {
			margin-top: 65px;
		}
	}

	#sign-up-progress-bar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 284px;
		height: 42px;
		margin-bottom: 32px;

		.step {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 28px;
			font-size: 11px;
			font-weight: normal;
			white-space: nowrap;
		}

		.node {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 28px;
			height: 28px;
			margin-bottom: 7px;
			border-radius: 50%;
			background-color: $primary-hover-color;
			color: white;
			font-size: 12px;
			font-weight: medium;

			&.disabled {
				background-color: #1f2154;
			}
		}

		.bar {
			position: relative;
			align-self: start;
			top: 9.5px;
			width: 100%;
			height: 4px;
			background-color: #1f2154;

			.progress {
				height: 100%;
				background-color: $primary-hover-color;

				transition: width 0.5s ease-out;
			}
		}
	}

	#sign-up-friends {
		.friends-title {
			margin-bottom: 0;
		}

		.buttons {
			display: flex;
			flex-direction: row;
		}

		.skip-button {
			color: #fff;
			background: transparent;
			padding: 0;
			border: none;
		}
	}

	#sign-up-friend-selector {
		max-height: 250px;
		overflow: auto;
		margin-bottom: 46px;

		.sign-up-friend-selector-friend {
			display: flex;
			align-items: center;
			width: 300px;
			height: 43px;
			background-color: #030519;
			margin-bottom: 4px;
		}

		.sign-up-friend-selector-username {
			flex-grow: 1;
		}

		.sign-up-friend-selector-toggle {
			height: 100%;
			width: 43px;
			line-height: 43px;
			text-align: center;
			cursor: pointer;
		}

		.avatar {
			margin-left: 10px;
			margin-right: 6px;
			height: 25px;
			width: 25px;
			border: 2px solid #ff8400;
		}
	}

	li {
		text-align: left;
		line-height: 26px;
		font-size: 16px;
		font-weight: normal;

		&::before {
			position: relative;
			content: ' ';
			display: inline-block;
			bottom: 2px;
			width: 8px;
			height: 8px;
			margin-right: 12px;
			background-color: $primary-hover-color;
		}
	}
}

.skill-confirmation-popover {
	flex-direction: column;
	width: 100%;
	max-width: 484px;
	height: 176px;
	background-color: #20212d;
	text-align: center;
	border-radius: 4px;
	padding: 2em 0 0 0;

	.popover-content {
		padding: 0;
	}

	.skill-confirmation-title {
		font-size: 18px;
		font-weight: 500;
		margin-bottom: 1em;
	}

	.skill-confirmation-info {
		font-size: 13px;
		font-weight: 300;

		.emphasis {
			font-weight: 500;
		}
	}

	.skill-confirmation-buttons {
		position: relative;
		bottom: -22px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 70px;
		background-color: #262734;
		border-radius: 0 0 4px 4px;

		.es-button {
			height: 30px;
			font-size: 12px;
		}
	}

	.skill-edit {
		width: 138px;
		margin-right: 13px;
	}

	.skill-confirmation-confirm {
		width: 160px;
	}

	.close {
		position: relative;

		.far {
			position: absolute;
			width: 1em;
			height: 1em;
			top: -0.75em;
			right: 1em;
		}
	}
}

;@import "sass-embedded-legacy-load-done:105";