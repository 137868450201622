.stats-content-outside-header-image {
	max-width: 100%;
}
.stats-content {
	max-width: 1400px;
	.stats-top-players-caption {
		font-size: 22px;
		font-weight: 500;
		margin-top: 20px;
	}
	.top-players,
	.top-players-by-weapon {
		margin-bottom: 20px;
		width: 48%;
		@extend %dropdown-table;
		thead {
			background-color: $table-header-background-color;
		}
		tr th {
			color: #c0c6d1;
			font-weight: 500;
			font-size: 13px;
			padding: 0px 20px;
		}
		td:nth-child(2) {
			text-align: unset;
		}
		.famfamfam-flag {
			margin-right: 6px;
		}
		table {
			white-space: nowrap;
		}
		tr:last-child {
			border: none;
		}
		td:first-child,
		td:last-child {
			font-weight: 400;
			padding-right: 20px;
		}
		@include mobile() {
			width: 98%;
		}
	}
	.top-players-row {
		position: relative;
	}
	.top-players-self-row {
		background-color: #191a24b3;
		color: #fff;
		border-bottom: 1px solid #13aceb;
	}
	.top-players .content-heading,
	.top-players-by-weapon .content-heading {
		background-color: transparent;
		color: #fff;
		font-size: 16px;
		font-weight: 400;
		padding: 3px 0px;
		overflow: hidden;
		@include mobile() {
			border-bottom: 1px solid $dark-grey-border;
		}
	}

	.top-players-nav {
		background-color: $table-header-background-color;
		font-weight: 500;
		text-transform: uppercase;
		display: inline-flex;
		justify-content: flex-start !important;
		min-height: 30px;
		align-items: center;
		@include mobile() {
			text-align: left;
		}
		@include tablet() {
			display: flex;
			justify-content: center;
			text-transform: none;
			.filter-label {
				display: none;
			}
		}
		div {
			color: $grey-text-color;
			font-size: 13px;
			padding: 5px 20px;
			line-height: 40px;
			@include widescreen() {
				white-space: nowrap;
			}
			.famfamfam-flag {
				padding: 0px 8px;
			}
		}
		.selected {
			background-color: #191a24;
		}
		.clickable {
			text-transform: uppercase;
		}
	}
	.top-players-by-weapon-table-header {
		color: $grey-text-color;
		margin-left: auto;
		padding: 5px 6px !important;
	}
}
.global-stats-friend-checkbox-wrapper {
	color: $grey-text-color;
	justify-content: center;
	vertical-align: middle;
	display: flex;
	align-items: center;
	margin-left: 32px;

	.global-stats-friends-filter-checkbox {
		margin-right: 8px;
	}

	.global-stats-friends-filter-label {
		margin-right: 4px;
	}
}
.global-stats-country-filter-label {
	color: $grey-text-color;
	justify-content: center;
	vertical-align: middle;
	display: flex;
	align-items: center;
	margin-right: 12px;
	font-size: 13px;
}
.global-stats-filter-selector {
	display: flex;
	margin-top: 18px;
	margin-bottom: 20px;
	.top-players-nav {
		border-radius: 10px;
		background-color: #1c1e28;
	}
}

.top-players-rank-flag {
	width: 125px;
}
.toggle-stats-container {
	display: flex;
	background: #1d1e29;
	margin: 20px 0px;
	span {
		position: relative;
		cursor: pointer;
		text-align: center;
		font-weight: 500;
		padding: 4px 40px;
		line-height: 35px;
	}

	span:first-child {
		border-right: 2px solid #2a2c3a;
	}
	.selected {
		color: $primary-color;
		border-bottom: 2px solid $primary-color;
	}
}

;@import "sass-embedded-legacy-load-done:71";