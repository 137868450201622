.compromised-popover {
	border-color: #444;
	background-color: #1f202b;
	width: 100%;
	max-width: 800px;
	padding: 0;
	border-radius: 10px;
	min-height: 400px;

	.compromised-header {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		margin-top: -15px;
		background-color: #ffdd00;
		color: black;
		padding: 12px;
	}

	.compromised-body {
		font: normal normal normal 14px/22px Roboto;
		text-align: left;
		margin: 20px;
		font-size: 14px;
		max-height: calc(100vh - 200px);
		height: 100%;
		overflow: auto;

		.compromised-bullet {
			display: inline-block;
			color: #ffdd00;
			font-weight: 800;
			width: 50px;
			height: 24px;
			text-align: center;
			line-height: 24px;
			font-size: 14px;
			margin: 10px 10px 10px 0px;
			border-radius: 10px;
		}

		.compromised-button {
			margin: 10px auto;
			display: inline-block;
			height: 40px;
			line-height: 40px;
			font-size: 14px;
			font-weight: 600;
			padding: 0 20px;
			border: 1px solid #ffdd00;
			border-radius: 4px;
			color: #ffdd00;
			text-transform: uppercase;
		}
		.for-news {
			.compromised-button {
				border: 0px;
				float: right;
				color: #ff00a8;
				cursor: pointer;
			}
			.cta-button {
				border: 1px solid #ff00a8;
				margin-left: 20px;
			}
		}

		.compromised-subtext {
			margin: 10px 0px;
			width: 100%;
			background-color: #14151d;
			font-size: 13px;
			padding: 10px;
		}
	}

	.compromised-footer {
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		margin-bottom: -15px;
		background-color: #ffdd00;
		color: black;

		button {
			cursor: pointer;
			background: none;
			border: none;
			padding: 12px;
			width: 100%;
		}
	}

	.news-header {
		position: relative;
		width: 100%;
		height: 300px;
		background-image: url(../img/banners/news_header.png);
		background-position: center top;
		background-repeat: no-repeat;
		background-size: 100% 300px;
		margin-top: -15px;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		margin-bottom: -20px;
	}

	.news-desc {
		margin-block-start: 0em;
		margin-block-end: 0.5em;
		display: inline-block;
		width: 100%;
	}

	.news-content-list {
		font: normal normal normal 14px/22px Roboto;
		line-height: 22px;
		width: 100%;
		text-align: left;
	}

	.news-block {
		float: left;
		background-color: #ff00a8;
		width: 10px;
		height: 10px;
		margin-right: 15px;
		margin-top: 5px;
		margin-bottom: 5px;
		clear: left;
	}
}

;@import "sass-embedded-legacy-load-done:131";