#left-side-navigation {
	width: $left-sidebar-width;
	flex-shrink: 0;
	background-color: #191a21;

	#left-side-navigation-content {
		position: fixed;
		width: 240px;
		top: $top-bar-height;

		@include widescreen() {
			.scroll-area {
				overflow-y: auto;
				max-height: calc(100vh - 50px);
			}
		}
	}

	.left-side-premium-banner {
		display: flex;
		align-items: center;
		width: 100%;
		height: 45px;
		background: linear-gradient(0deg, rgba(210, 101, 34, 0.2) 0%, rgba(210, 101, 34, 0.01) 100%);
		color: #ffa400;

		padding-left: 20px;
	}
	.section-title {
		width: 100%;
		color: $primary-color;
		height: 30px;
		line-height: 30px;
		padding-left: 20px;
		font-size: 13px;
		margin-bottom: 10px;
	}
	.item {
		transition: all 0.15s linear;
		&.active,
		&:hover {
			box-shadow: inset 6px 0 0 $primary-hover-color;
			color: $primary-hover-color;

			.icon-ranking {
				filter: none;
			}
		}
		&.item-smaller-font {
			font-size: 14px;
		}
	}

	.left-side-refer-friend {
		color: #00d555 !important;
		height: 45px;
		background: transparent linear-gradient(90deg, #153229 0%, #1e2029 100%) 0 0 no-repeat padding-box;
		&.active,
		&:hover {
			box-shadow: inset 6px 0 0 #00d555 !important;
			color: #00ff66 !important;

			.icon-ranking {
				filter: none;
			}
		}
	}

	.main-menu .expanded {
		> ul li {
			&:hover {
				a {
					color: $primary-hover-color;
					box-shadow: inset 6px 0 0 $primary-hover-color;
				}
			}

			a.active-nav-link {
				color: $primary-hover-color;
				box-shadow: inset 6px 0 0 $primary-hover-color;
			}
		}
	}
}

.left-side-menu {
	padding-bottom: 20px;
	padding-top: 5px;
	background-color: $secondary-color;
	.main-menu {
		padding-bottom: 10px;

		.item {
			padding: 15px 0;
			color: #c6cfde;
			display: flex;
			align-items: center;
			position: relative;
			.new {
				min-width: 40px;
				min-height: 25px;
				margin-left: auto;
				margin-right: 5px;
				text-align: center;
				line-height: 25px;
				background-color: #f02849;
				border-radius: 15%;
				font-size: 10px;
				letter-spacing: 1px;
				color: #fef4f6;
				font-weight: 600;
			}
		}
	}
	hr {
		color: #2b2d3a;
		background-color: #2b2d3a;
		border-width: 0;
		height: 1px;
		width: 80%;
		margin-left: 20px;
	}
	.secondary-menu {
		.item {
			color: $grey-text-color;
			height: 40px;
			display: flex;
			align-items: center;
			font-size: 15px;
			font-weight: 400;

			.fas {
				margin: 0 15px;
				min-width: 20px;
				text-align: center;
			}
		}
		.news-unread-count {
			min-width: 20px;
			min-height: 20px;
			margin-left: 10px;
			text-align: center;
			line-height: 20px;
			background-color: #f02849;
			border-radius: 50%;
			font-size: 10px;
			color: #fef4f6;
			font-weight: 600;
		}

		.epos-logo {
			max-width: 75px;
		}
	}

	.expand-button {
		cursor: pointer;

		.button-sign {
			margin-left: auto;
			margin-right: 15px;
			display: inline-block;
			width: 12px;
			height: 12px;

			background-repeat: no-repeat;
			background-position-y: center;

			&.plus {
				background-image: url(../img/misc/plus.svg);
			}

			&.minus {
				height: 2px;
				background-image: url(../img/misc/minus.svg);
			}
		}
	}
}
.icon-ladders {
	width: 17px;
	height: 17px;
	margin: 0 18px;
}
.icon-refer-friend {
	width: 17px;
	height: 20px;
	margin: 0 18px;
	background-image: url(../img/referfriend/heart.svg);
	background-repeat: no-repeat;
	background-position: center;
}
.icon-public-servers {
	width: 18px;
	height: 18px;
	margin: 0 18px;
}
.icon-astralis-public-servers {
	width: 18px;
	height: 18px;
	margin: 0 18px;
	background-position: center;
	background-size: cover;
}
.icon-ranking {
	width: 20px;
	height: 18px;
	background-position: 0px -2px;
	background-size: cover;
	margin: 0 15px;
	filter: brightness(0) saturate(100%) invert(86%) sepia(10%) saturate(224%) hue-rotate(180deg)
		brightness(92%) contrast(90%);
}

.left-side-featured-tournaments {
	background-color: #171820;
	padding-bottom: 40px;
	padding-top: 10px;

	.item {
		color: $grey-text-color;
		display: flex;
		align-items: center;
		text-transform: capitalize;
		padding-left: 10px;
		font-size: 12px;

		+ .item {
			margin-top: 12px;
		}

		&.x-kom,
		&.pathofaninja,
		&.jkaem-nordic-ladder,
		&.skolkampen {
			text-transform: none;
		}
		.logo {
			width: 35px;
			height: 35px;
			margin: 0 10px;
			border-radius: 4px;

			background-size: contain;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			&.hyperx {
				background-image: url(../js/components/landing/hyperX/assets/featured.jpg);
			}
			&.esportal-global {
				background-image: url(../js/components/landing/Global/assets/global_featured.png);
			}
			&.nobe {
				background-image: url(../img/landing/nobealoevera/nobe.png);
			}
			&.heaton {
				background-image: url(../img/dragon/squarefeatured_heaton.png);
			}
			&.hallzerk {
				background-image: url(../img/dragon/squarefeatured_hallzerk.png);
			}
			&.pucko {
				background-image: url(../img/pucko/squarefeatured_pucko.png);
			}
			&.dreamhackbeyond {
				background-image: url(../img/landing/dreamhackbeyond/featured_dreamhack.png);
			}
			&.elisa {
				background-image: url(../img/elisa/lsn.png);
			}
			&.educationalmasters {
				background-image: url(../img/educationalmasters/edumasters_featured_icon.jpg);
			}
			&.secsgo {
				background-image: url(../img/nel/squarefeatured_elitserien.png);
			}
			&.atg {
				background-image: url(../img/atg/logo.png);
			}
			&.esml {
				background-image: url(../img/esml.png);
			}
			&.mimi {
				background-image: url(../img/logo.png);
			}
			&.rnp {
				background-image: url(../img/rnp/left_side_logo.png);
			}
			&.doritos {
				background-image: url(../img/doritos/squarefeatured_doritos.png);
			}
			&.nordicinvitational {
				background-image: url(../img/logo.png);
			}
			&.maxgaming {
				background-image: url(../img/maxgaming/maxgaming_featuredicon.png);
			}
			&.juliano {
				background-image: url(../img/dragon/featured_juliano.png);
			}
			&.atlantic {
				background-image: url(../img/dragon/atlantic.png);
			}
			&.deltacogaming {
				background-image: url(../img/deltacogaming/deltaconordic.png);
			}
			&.heatonhowl {
				background-image: url(../img/heatonhowl/heatonhowl_featuredicon.png);
			}
			&.nobespringchallendge {
				background-image: url(../img/nobespringchallendge/nobe_featured.jpg);
			}
			&.cubacolacup {
				background-image: url(../js/components/landing/cubacola/assets/featured.jpg);
			}
			&.skolkampen {
				background-image: url(../js/components/landing/skolkampen/2022/assets/featured.jpg);
			}
			&.skolkampen2021 {
				background-image: url(../js/components/landing/skolkampen/2021/skolkampen2021/se/icon_featured.png);

				&.dk {
					background-image: url(../js/components/landing/skolkampen/2021/skolkampen2021/dk/icon_featured.png);
				}
				&.fi {
					background-image: url(../js/components/landing/skolkampen/2021/skolkampen2021/fi/icon_featured.png);
				}
				&.no {
					background-image: url(../js/components/landing/skolkampen/2021/skolkampen2021/no/icon_featured.png);
				}
				&.se {
					background-image: url(../js/components/landing/skolkampen/2021/skolkampen2021/se/icon_featured.png);
				}
			}

			&.dr-pepper-2021 {
				background-image: url(../img/drpepper/2021/pepper-logo-small.png);
			}

			&.wingman-juissi {
				background-image: url(../img/wingmanjuissi/upd1/side-icon.png);
			}

			&.omen-wingman {
				background-image: url(../img/omen_wingman/omen.png);
			}

			&.kandiskampen {
				background-image: url(../img/kandiskampen/logo_small.png);
			}
			&.drpepper-highscore-se {
				background-image: url(../img/drpepper/navicons/drpepper_featured_se.png);
			}
			&.drpepper-highscore-fi {
				background-image: url(../img/drpepper/navicons/drpepper_featured_fi.png);
			}
			&.fragleague {
				background-image: url(../img/fragleague/fragleague-logo.png);
			}
			&.mental {
				background-image: url(../img/mentalplays/mental.png);
			}
			&.astralis {
				background-image: url(../js/components/landing/astralis/assets/astralis_featured.png);
			}
			&.wingmanpathofaninja {
				background-image: url(../img/pathofninja/logo_small.jpg);
			}
			&.omenwingmantournament {
				background-image: url(../img/omenwingmantournament/omen_amd_featured.png);
			}
			&.finnkampen {
				background-image: url(../img/finnkampen/featured.jpg) !important;
			}
			&.esportlovet-elgiganten {
				background-image: url(../img/esportlovet-elgiganten/esportlovet_elgiganten_featured.png);
			}
			&.omenastralis {
				background-image: url(../img/omenwingmantournament/omen_astralisfeatured.png);
			}
			&.philipsoneblade {
				background-image: url(../img/onebladeLanding/featured_oneblade.png);
			}
			&.gcs-cup-summer-2021 {
				background-image: url(../img/landing/gcscupsummer2021/featured_gcs.png);
			}
			// &.finnkampen {
			// 	background-image: url(../img/finnkampen/icon.png);
			// }
			// &.finnkampenfi {
			// 	background-image: url(../img/finnkampen/finland.png);
			// }
			&.komplett {
				background-image: url(../img/landing/komplett/komplet_nav.png);
			}
			&.anker {
				background-image: url(../img/landing/anker/anker_logo.png);
			}
			&.beefmode {
				background-image: url(../img/landing/beefmode/featured_beefmode.png);
			}
			&.red-bull-flick {
				background-image: url(../img/featured/redbullflick.png);
			}
			&.volt-dragon-lore {
				background-image: url(../img/landing/volt/leftsidenav.png);
			}
			&.noccowingman {
				background-image: url(../img/nocco/noccowingman_featuredicon.png);
			}
			&.elgiganten {
				background-image: url(../img/elgiganten/featured_elgiganten.png);
			}
			&.dragon-lore {
				background-image: url(../img/dragonLore/dragonlore_feat.png);
			}
			&.havu {
				background-image: url(../img/havu/landing/featured_havu.png);
			}
			&.truecaller {
				background-image: url(../img/landing/truecaller/navigation.jpg);
			}
			&.deltaco {
				background-image: url(../img/landing/deltaco/featured_deltacoseason2.png);
			}
			&.epos-wingman {
				background-image: url(../img/landing/epos/featured_epos.png);
			}
			&.dignitas {
				background-image: url(../img/landing/diginitas/featured_dignitas.png);
			}
			&.nordicchampionsladder {
				background-image: url(../img/landing/nordicChampionsLadder/icon.png);
			}
			&.infernoonlineopen {
				background-image: url(../img/landing/infernoOnline/icon.png);
			}
			&.rogwingman {
				background-image: url(../js/components/landing/ROGWingman/assets/featured.jpg);
			}
			&.corsairwingman {
				background-image: url(../js/components/landing/corsairWingman/assets/icon.png);
			}
			&.jkaem-nordic-ladder {
				background-image: url(../img/jkaem/icon.png);
				text-transform: none;
			}
			&.ago {
				background-image: url(../img/landing/ago/ago_lsn.png);
			}
			&.honoris {
				background-image: url(../img/landing/honoris/honoris_lsn.png);
			}
			&.voon {
				background-image: url(../img/landing/voon/featured-35x35.jpg);
			}
			&.pricerunner {
				background-image: url(../img/landing/pricerunner/featured-icon.jpg);
			}
			&.kniferaffle {
				background-image: url(../img/landing/kniferaffle/vs_lsn.png);
			}
			&.izako {
				background-image: url(../js/components/landing/izakoDragonLore/assets/featured.png);
			}
			&.drpepper {
				background-image: url(../img/landing/drpepper/featured.png);
			}
			&.player-ladder {
				background-image: url(../img/featured/player-ladder.jpg);
			}
			&.dach-1v1 {
				background-image: url(../img/landing/dach1v1/esportal-1v1-cups-landing-page-icon.png);
			}
			&.dach-2v2 {
				background-image: url(../img/landing/dach2v2/2v2-icon.png);
			}
			&.assembly {
				background-image: url(../img/landing/assembly/assembly-logo-white.jpg);
			}
			&.legendsleague {
				background-image: url(../js/components/landing/legendsLeague/assets/legendsleague_featured.png);
			}
			&.ence {
				background-image: url(../img/landing/ence/ence-featured.png);
			}
			&.warszawski-koks {
				background-image: url(../img/landing/warszawskiKoks/iconwk1.png);
			}
			&.cubacolaladder {
				background-image: url(../js/components/landing/cubacola/cubacolaladder/assets/featured.jpg);
			}
			&.scou7 {
				background-image: url(../img/landing/scou7/ikonka-scou7.png);
			}
			&.bybit {
				background-image: url(../img/landing/bybit/bybit-featured.jpg);
			}
			&.svenskacupen {
				background-image: url(../img/featured/svenskacupen.png);
			}
			&.gamerpay {
				background-image: url(../img/featured/gamerpay.jpg);
			}
			&.opus {
				background-image: url(../img/featured/opus.jpg);
			}
			&.bredband2 {
				background-image: url(../img/featured/bb-1.jpg);
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:52";