.home-latest-news {
	margin-top: 10px;
}

.home-latest-news,
.home-left-column-stickies,
.nel-latest-news,
.atg-latest-news {
	.cover {
		width: 100%;
		border-radius: 4px 4px 0 0;
		display: block;
	}

	.entry-icon {
		color: #00aae8;
	}

	.entry {
		display: block;
		position: relative;
	}

	.title {
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: 700;

		@include mobile() {
			display: inline;
			white-space: normal;
			font-weight: 400;
			font-size: 21px;
			margin: 0 11px;
		}
	}

	.date {
		color: #000;
		font-size: 14px;
		margin-top: 2px;
	}
	.comments {
		font-size: 14px;
		float: right;

		.fa-comment {
			margin-right: 5px;
		}
	}

	.meta {
		background-color: #20212d;
		height: 90px;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		line-height: 30px;

		&.right-side-news {
			line-height: 16px;
			height: 70px;

			.date,
			.comments {
				font-size: 12px;
			}
		}

		.fa-star {
			line-height: 30px;
		}
		.title {
			font-size: 15px;
			padding: 4px;
			color: #fff;

			.right-side-news {
				font-size: 16px;
			}
		}
		.date {
			padding: 4px;
		}
		.date,
		.comments {
			font-size: 14px;
			color: $dark-grey-text-color;
		}
		@include mobile() {
			background-color: #20212d;
			padding-top: 6px;
			padding-left: 6px;
			line-height: 33px;

			.title {
				margin: 0 0 !important;
			}

			.comments {
				margin-right: 8px;
			}
		}
		@include tablet() {
			background-color: #20212d;
			padding: 12px 10px 0px;
		}
	}
}

.home-latest-news {
	.meta {
		.title-wrap {
			line-height: 15px;
		}

		.title,
		.date {
			padding: 0;
		}
	}

	@include tablet() {
		flex-direction: column;
	}
}

.home-latest-news,
.nel-latest-news,
.atg-latest-news {
	@include tablet() {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.featured {
		.cover {
			border: 2px solid #0a425d;
		}
		.meta {
			display: inline-flex;
			flex: 1;
			flex-flow: column;
			margin-left: 16px;
			width: calc(55% - 16px);
		}
		.date {
			font-size: 17px;
			font-weight: normal;
			margin: 13px 0;
			order: 1;

			&::after {
				content: normal;
			}
		}
		.title {
			font-size: 31px;
			margin: 0;
			order: 2;
			white-space: normal;
			word-wrap: break-word;
			overflow: initial;
		}
		.comments {
			font-size: 17px;
			margin: 13px 0;
			order: 3;
		}
	}
}

.home-left-column-stickies {
	display: block;
	.entry {
		width: 100%;
	}
}

.home-latest-news .entry {
	width: 100%;
	margin-bottom: 20px;

	.meta {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		padding: 14px;
	}
}

;@import "sass-embedded-legacy-load-done:32";