.country-grayed {
	filter: grayscale(100%);
}

/* Europe */
.country-se {
	background-image: url(../img/countries/se.png);
}
.country-dk {
	background-image: url(../img/countries/dk.png);
}
.country-no {
	background-image: url(../img/countries/no.png);
}
.country-fi {
	background-image: url(../img/countries/fi.png);
}
.country-pl {
	background-image: url(../img/countries/pl.png);
}
.country-de {
	background-image: url(../img/countries/de.png);
}
.country-nl {
	background-image: url(../img/countries/nl.png);
}
.country-fr {
	background-image: url(../img/countries/fr.png);
}
.country-gb {
	background-image: url(../img/countries/gb.png);
}
.country-dach {
	background-image: url(../img/countries/dach.png);
}
.country-cz {
	background-image: url(../img/countries/cz.png);
}
.country-at {
	background-image: url(../img/countries/at.png);
}

/* South America */
.country-br {
	background-image: url(../img/countries/br.png);
}

/* CIS */
.country-ru {
	background-image: url(../img/countries/ru.png);
}
.country-ua {
	background-image: url(../img/countries/ua.png);
}
.country-kz {
	background-image: url(../img/countries/kz.png);
}

.country-flag {
	width: 31px;
	height: 22.5px;
	background-repeat: no-repeat;
	background-size: cover;
}

;@import "sass-embedded-legacy-load-done:54";