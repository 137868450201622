.box-header {
	min-height: 40px;
	position: relative;
	overflow: auto;
	line-height: 40px;
	background-color: $primary-color;
	color: #fff;
	padding: 0px 15px;
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 700;
}
.box-header .center {
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	text-align: center;
	z-index: 0;
}

.box-content {
	overflow: auto;
	padding: 15px 15px;
	background-color: #f3f3f3;
}
.box-content-no-padding {
	overflow: auto;
	padding: 1px 1px;
	background-color: #f3f3f3;
}
.box-header + .box-content {
	border-top: 0;
}

;@import "sass-embedded-legacy-load-done:22";