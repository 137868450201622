/* roboto-300 - cyrillic_latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	font-display: fallback;
	src: local('Roboto Light'), local('Roboto-Light'),
		url('../fonts/roboto-v18-latin-ext_cyrillic_latin-300.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+ */ url('../fonts/roboto-v18-latin-ext_cyrillic_latin-300.woff')
			format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-regular - cyrillic_latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: fallback;
	src: local('Roboto'), local('Roboto-Regular'),
		url('../fonts/roboto-v18-latin-ext_cyrillic_latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+ */ url('../fonts/roboto-v18-latin-ext_cyrillic_latin-regular.woff')
			format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - cyrillic_latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-display: fallback;
	src: local('Roboto Medium'), local('Roboto-Medium'),
		url('../fonts/roboto-v18-latin-ext_cyrillic_latin-500.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+ */ url('../fonts/roboto-v18-latin-ext_cyrillic_latin-500.woff')
			format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - cyrillic_latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-display: fallback;
	src: local('Roboto Bold'), local('Roboto-Bold'),
		url('../fonts/roboto-v18-latin-ext_cyrillic_latin-700.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+ */ url('../fonts/roboto-v18-latin-ext_cyrillic_latin-700.woff')
			format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-condensed-700 - cyrillic_latin */
@font-face {
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 700;
	font-display: fallback;
	src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'),
		url('../fonts/roboto-condensed-v16-latin-ext_cyrillic_latin-700.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+ */ url('../fonts/roboto-condensed-v16-latin-ext_cyrillic_latin-700.woff')
			format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
	font-family: 'Telenor';
	font-style: normal;
	font-weight: 300;
	font-display: fallback;
	src: local('Telenor'), local('Telenor'), url('../fonts/telenor/Telenor.woff2') format('woff2'),
		url('../fonts/telenor/Telenor.woff') format('woff');
}

@font-face {
	font-family: 'Telenor';
	font-style: normal;
	font-weight: 500;
	font-display: fallback;
	src: local('Telenor Medium'), local('Telenor-Medium'),
		url('../fonts/telenor/Telenor-Medium.woff2') format('woff2'),
		url('../fonts/telenor/Telenor-Medium.woff') format('woff');
}

@font-face {
	font-family: 'Telenor-headline';
	font-style: normal;
	font-weight: 500;
	font-display: fallback;
	src: local('TelenorHeadline Regular'), local('TelenorHeadline Regular'),
		url('../fonts/telenor/TelenorHeadline-Regular.woff2') format('woff2'),
		url('../fonts/telenor/TelenorHeadline-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Robofan Spark';
	src: url('../fonts/doritos/RobofanSpark-Bold.woff2') format('woff2'),
		url('../fonts/doritos/RobofanSpark-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Robofan Spark';
	src: url('../fonts/doritos/RobofanSpark-Regular.woff2') format('woff2'),
		url('../fonts/doritos/RobofanSpark-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Robofan Spark';
	src: url('../fonts/doritos/RobofanSpark-Light.woff2') format('woff2'),
		url('../fonts/doritos/RobofanSpark-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Khand';
	src: url('../fonts/ambush/Khand-Light.woff2') format('woff2'),
		url('../fonts/ambush/Khand-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Khand';
	src: url('../fonts/ambush/Khand-Medium.woff2') format('woff2'),
		url('../fonts/ambush/Khand-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Khand';
	src: url('../fonts/ambush/Khand-Bold.woff2') format('woff2'),
		url('../fonts/ambush/Khand-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Khand';
	src: url('../fonts/ambush/Khand-Regular.woff2') format('woff2'),
		url('../fonts/ambush/Khand-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Khand';
	src: url('../fonts/ambush/Khand-SemiBold.woff2') format('woff2'),
		url('../fonts/ambush/Khand-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Teko';
	src: url('../fonts/teko/Teko-Regular.woff2') format('woff2'),
		url('../fonts/teko/Teko-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Teko';
	src: url('../fonts/teko/Teko-Medium.woff2') format('woff2'),
		url('../fonts/teko/Teko-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Teko';
	src: url('../fonts/teko/Teko-Bold.woff2') format('woff2'),
		url('../fonts/teko/Teko-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Teko';
	src: url('../fonts/teko/Teko-Light.woff2') format('woff2'),
		url('../fonts/teko/Teko-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Teko';
	src: url('../fonts/teko/Teko-SemiBold.woff2') format('woff2'),
		url('../fonts/teko/Teko-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('../fonts/pucko/BebasNeue-Regular.woff2') format('woff2'),
		url('../fonts/pucko/BebasNeue-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Voyager';
	src: url('../fonts/voyager/VoyagerGrotesqueBold.woff2') format('woff2'),
		url('../fonts/voyager/VoyagerGrotesqueBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'TradeGothic LT';
	src: url('../fonts/tradegothic/TradeGothicLTPro-Bold.woff2') format('woff2'),
		url('../fonts/tradegothic/TradeGothicLTPro-Bold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
