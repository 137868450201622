.public-servers {
	background-color: #111219;
	background-image: url(../img/backgrounds/publicservers.jpg);
	background-position: right top;
	background-repeat: no-repeat;
	background-size: auto;

	font-weight: 400;

	@include mobile() {
		background-image: initial;
	}

	.button-new-style {
		&:hover {
			color: #1ac9ab;
			border-color: #1ac9ab;
		}
	}
	.rounded {
		border-radius: 4px;
	}

	.has-text-centered {
		text-align: center;
	}

	.bold {
		font-weight: 900;
	}
	.icon {
		display: inline-block;

		&.deathmatch {
			background-image: url(../img/publicservers/icons/deathmatch.svg);
		}
		&.retake {
			background-image: url(../img/publicservers/icons/retake.svg);
		}
		&.mostpoints {
			background-image: url(../img/publicservers/icons/mostpoints.svg);
		}
		&.mostpoints-grey {
			background-image: url(../img/publicservers/icons/mostpoints_grey.svg);
		}
		&.timespent {
			background-image: url(../img/publicservers/icons/timespent.svg);
		}
		&.timespent-grey {
			background-image: url(../img/publicservers/icons/timespent_grey.svg);
		}
		&.friend-icon {
			background-image: url(../img/publicservers/icons/friend.png);
			width: 14px;
			height: 15px;
			vertical-align: middle;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: 50% 50%;
		}
		&.double-arrow {
			background-image: url(../img/publicservers/icons/double_arrow.png);
			width: 30px;
			height: 12px;
		}
	}
	.button-simple {
		background-color: #1a1a23;
		font-weight: 700;
		line-height: 30px;

		&.info {
			text-transform: lowercase;
			padding: 0 0.8em;
		}
	}
	.button-new-style.inactive {
		cursor: initial;
		color: #5e5f68;

		&:hover {
			border-color: #3f4151;
		}
	}
}

.public-servers-container {
	max-width: 1050px;
	margin: auto;
}

.public-servers-header {
	text-align: center;
	margin: 8em 0;

	@include mobile() {
		margin-top: 0;
		margin-bottom: 3em;

		.image {
			background-color: #101118;
			background-image: url(../img/publicservers/publicservers_topheader2.png);
			background-position: center top;
			background-repeat: no-repeat;
			background-size: contain;
			height: 18em;
		}
	}

	.rank-title {
		font-size: 11px;
		font-weight: 900;
		width: 320px;
		margin: 0 auto;
		text-align: left;
		margin-top: 30px;
		margin-bottom: 5px;
		text-transform: uppercase;
	}
	.title {
		text-transform: uppercase;
		font-size: 36px;
		font-weight: 700;
		font-family: 'Roboto', sans-serif;
	}
	.user-details-and-quick-join {
		display: flex;
		flex-direction: column;
	}

	.user-info {
		background-color: #232632;
		display: inline-block;
		margin: 0 auto;
		width: 320px;

		display: flex;
		justify-content: space-between;
		height: 50px;

		@include desktop() {
			min-width: 40%;
		}

		.user-name {
			display: flex;
			align-items: center;

			.flag {
				margin: 0 10px;
			}

			a {
				color: #fff;
				font-weight: 700;
			}
		}

		.user-rank {
			width: 25%;
			background-color: #1e1f2a;
			height: inherit;
			border-bottom-right-radius: 4px;
			border-top-right-radius: 4px;
		}
	}
	.quick-join-box {
		background-color: #232632;
		margin: 1em auto;
		display: inline-block;
		padding: 1em 2em;
		width: 320px;

		@include desktop() {
			min-width: 40%;
		}

		.button {
			width: 230px;
			font-size: 14px;
			background: #1ac9ab;
		}
		.premium-filter {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 1em 0;

			&.inactive {
				filter: opacity(0.3);
			}

			.text {
				margin: 0 5px;
				font-size: 12px;
				font-weight: 500;
			}
		}
		.pings {
			width: auto;
			margin: auto;

			tr {
				height: 20px;
				line-height: 20px;
				font-size: 12px;

				.country-name {
					font-weight: 600;
				}
				.fa-check-circle {
					color: #00b9ff;
				}
			}
		}
	}
	.description-link {
		color: #1ac9ab;
		font-size: 13px;
		display: block;
	}
}
.servers-column-holder {
	width: 100%;
	display: flex;
	justify-content: center;

	&.with-top-margin {
		margin-top: 10rem;

		@include mobile() {
			margin-top: 0;
		}
	}
}

.servers-column {
	max-width: 1050px;
	.column-header {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;

		.title {
			font-size: 22px;
			font-weight: 900;
			text-transform: uppercase;
			display: flex;
			align-items: center;
			justify-content: center;

			.icon {
				width: 35px;
				height: 35px;
				margin-right: 10px;
			}
		}
		.subtext {
			padding: 10px;
			color: #c0c6d1;
			font-size: 12px;
			width: 70%;
			height: 110px;
		}
	}
	.servers {
		// Removed overflow
		// $max-visible-items: 5;
		$item-height: 88px;
		// max-height: $max-visible-items * $item-height + 30px;
		// overflow: auto;

		.server {
			height: $item-height;
			background-color: #232632;
			border-radius: 4px;
			padding: 15px;
			display: flex;
			justify-content: space-between;
			margin: 5px;

			background-position: center top;
			background-repeat: no-repeat;
			background-size: cover;

			i {
				font-size: 16px;
			}

			&.nip {
				background-image: url(../img/publicservers/ninjas_bg.png);
			}

			&.astralis {
				background-image: url(../img/publicservers/astralis_bg.png);
			}

			&.volt {
				background-image: url(../img/landing/volt/server_bg.png);
			}

			&:hover {
				box-shadow: 0 0 5px #1ac9abe0;
			}

			.server-details {
				display: flex;
			}
			.organizer-logo {
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				width: 40px;
				height: 40px;
				margin-right: 15px;

				&.nip {
					background-image: url(../img/publicservers/nip_brand.png);
				}

				&.astralis {
					background-image: url(../img/publicservers/astralis_brand.png);
				}

				&.volt {
					background-image: url(../img/landing/volt/1.png);
				}
			}
			.friend-and-verified-icons {
				display: flex;
			}
			.name-players-map {
				display: flex;
				flex-direction: column;

				.icon16 {
					width: 14px;
					height: 15px;
					background-size: contain;
					background-repeat: no-repeat;
				}
				.server-name {
					font-weight: 700;
					display: flex;
					align-items: center;
					margin-bottom: 0.3em;
				}
				.players {
					display: flex;
					align-items: center;

					font-size: 12px;
					margin-top: 5px;

					.players-count {
						margin-right: 1em;
						white-space: nowrap;

						.fa-users {
							color: #787990;
							margin-right: 5px;

							&.white {
								color: #fff;
							}
						}
					}
				}
				.map {
					display: flex;
					align-items: center;

					.fa-image {
						color: #777998;
						margin-right: 5px;

						&.white {
							color: #fff;
						}
					}
				}
			}
			.actions {
				display: flex;
				align-items: center;
				flex-direction: column;

				.server-icons {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: flex-end;

					margin-bottom: 9px;
				}

				.client-icon {
					background-image: url(../img/publicservers/icons/client_none.png);
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
					width: 18px;
					height: 18px;

					&.active {
						background-image: url(../img/publicservers/icons/client.png);
						width: 18px;
						height: 18px;
					}
				}

				.flag-wrapper {
					margin-left: 10px;
					margin-right: 5px;
					height: 14px;
					display: flex;
					align-items: center;
				}
			}
			.icon-wrapper {
				width: 15px;
				height: 15px;
				margin-right: 0.75em;
				position: relative;

				@include mobile() {
					width: 30px;
					height: 30px;
					margin-right: 0;
					margin-left: 5px;
				}

				.icon {
					position: absolute;
					margin: 0;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);

					@include mobile() {
						height: 14px;
						width: 14px;
					}
				}
			}
		}
	}

	.volt-section {
		margin: 40px auto 20px;
	}
}
.public-servers-prizes-holder {
	width: 100%;
	justify-content: center;
}

.public-servers-prizes {
	max-width: 1050px;
	width: 100%;
	overflow: auto;
	padding: 0.75rem;
	margin: auto;
}

.public-servers-prizes-description {
	@include mobile() {
		text-align: center;
	}
	.title {
		text-transform: uppercase;
		font-size: 26px;
		font-weight: 700;
		//font-family: 'Roboto', sans-serif;
		color: #1ac9ab;
	}
	.text {
		max-width: 600px;
		line-height: 24px;
		margin: 2em 0;
	}
	.premium-box {
		background-color: #1b1d26;
		text-align: center;
		font-weight: 500;
		padding: 1.5em;

		.premium-logo {
			height: 45px;
			background-repeat: no-repeat;
			background-image: url(../img/premium/premium_function_popover.png);
			background-size: contain;
			background-position: center;
		}
		.text {
			margin: 1.5em 0;
		}
		.button {
			font-weight: 600;
			padding: 0 2em;
		}
	}
}

.public-standings-holder {
	width: 100%;
	display: flex;
	justify-content: center;
}

.public-standings {
	max-width: 1050px;
	width: 100%;
	overflow: auto;
	padding: 0.75rem;
}
.public-standings-title {
	text-transform: uppercase;
	font-size: 26px;
	font-weight: 700;
	font-family: 'Roboto', sans-serif;

	@include mobile() {
		text-align: center;
	}
}
.public-servers-standings {
	.tabs {
		.tab {
			display: inline-block;
			background-color: #191a24;
			min-width: 15%;
			padding: 1em;
			text-align: center;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			margin-right: 2px;
			cursor: pointer;
			text-transform: uppercase;
			color: $grey-text-color;
			font-family: 'Roboto', sans-serif;
			font-size: 15px;

			&.active {
				background-color: #1f212d;
				color: #1ac9ab;
			}
			&.selector {
				background: #1f212d
					url("data:image/svg+xml;utf8,<svg fill='white' height='32' viewBox='0 0 32 32' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h32v32H0z' fill='none'/></svg>")
					no-repeat;
				background-position-x: 100%;
				background-position-y: 8px;
			}
			&.disabled {
				cursor: default;
				color: #4f525d;
			}
			@include mobile() {
				text-align: left;
				padding-left: 2em;
				width: 100%;
			}
		}
	}
	.standing-table {
		white-space: nowrap;

		a {
			color: #fff;
		}

		thead {
			background-color: #1f212d;
			font-weight: 700;
			color: $grey-text-color;
		}
		th:last-child,
		td:last-child {
			text-align: center;
		}

		tbody tr {
			height: 70px;
		}

		tbody > tr:nth-child(odd) {
			background-color: #1f212d;
		}

		tbody > tr:nth-child(even) {
			background-color: #191a24;
		}

		.inline-block {
			display: inline-block;
		}

		.with-cursor {
			cursor: pointer;

			&.sorted-by {
				color: #1ac9ab;
			}

			&:hover {
				color: #1ac9ab;
			}
		}

		.user-personal-ranking-row {
			border-bottom: 2px solid #1ac9ab;
		}
	}

	.pager {
		background-color: #191923;
		height: 4em;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-right: 2em;

		@include mobile() {
			justify-content: center;
			padding-right: 0;
		}
	}
}

.prizes-info {
	max-width: 1050px;
	width: 100%;
	margin: auto;
	.title {
		text-transform: uppercase;
		font-size: 20px;
		font-weight: 700;
		display: flex;
		align-items: center;

		.icon {
			width: 30px;
			height: 30px;
			margin: 10px;
		}
	}
	.prizes {
		.prize-box {
			display: flex;
			margin: 1em 0;

			.counter {
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
				background-color: #232431;
				width: 3em;
				padding-top: 1em;
				font-size: 20px;
				font-weight: 700;
				color: #1ac9ab;
				text-align: center;
			}
			.details {
				display: flex;
				flex-direction: column;
				width: 100%;

				.prize-details {
					background-color: #1d1e2a;
					border-top-right-radius: 4px;
					padding: 10px;
					display: flex;
					align-items: center;
					justify-content: left;

					> div {
						flex: 1;
					}
					.prize {
						max-width: 190px;
						height: 70px;
						background-repeat: no-repeat;
						background-position: 50% 50%;
						background-size: contain;
					}
				}
				.winner-details {
					background-color: #292937;
					border-bottom-right-radius: 4px;
					padding: 1em;

					.label {
						color: $grey-text-color;
						text-transform: uppercase;
						font-weight: 700;
						font-size: 12px;
						padding-bottom: 5px;
					}
					.winner {
						display: flex;
						justify-content: space-between;
						align-items: center;

						a {
							color: #fff;
						}

						.famfamfam-flag {
							vertical-align: middle;
							margin-bottom: 2px;
							margin-left: 5px;
						}
						.points {
							font-weight: 500;
							display: flex;
							align-items: center;

							.icon {
								width: 26px;
								height: 26px;
								margin-right: 5px;
							}
						}
					}
				}
			}
		}
	}
}
.friends-and-verified-holder {
	width: 100%;
	display: flex;
	justify-content: center;
}

.public-servers-friends {
	max-width: 1050px;
	.colored {
		color: #1ac9ab;
	}

	.table-striped {
		a {
			color: #fff;
		}
		.flag-column {
			text-align: left;

			.friend-icon {
				width: 34px;
				height: 18px;
			}
		}
		.server-details-column {
			.server-name {
				font-size: 14px;
				line-height: 14px;
			}
			.server-type {
				font-size: 13px;
				line-height: 14px;
			}
		}

		.buttons-column {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			text-align: center;

			height: inherit;

			.info {
				width: 25px;
			}
		}

		th,
		td {
			padding: 0px 16px;
		}

		thead tr {
			height: 0;
		}
		tbody tr {
			border-bottom: none;
			height: 70px;

			&:nth-child(even) {
				background-color: #1f212d !important;
			}
		}
	}
	.mobile-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 5em;
		padding: 5px;

		a {
			color: #fff;
		}

		&:nth-child(odd) {
			background-color: #191a24;
		}
		&:nth-child(even) {
			background-color: #1f212d;
		}
		&:first-child {
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
		}
		&:last-child {
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
		}

		.details {
			display: flex;
			flex-direction: column;

			.server-details {
				font-size: 12px;
				color: $grey-text-color;
				margin-top: 5px;
			}
		}
	}
}
.popover.public-servers-server-join-popover {
	padding: 0;
	width: 470px;
	height: 470px;
	border-radius: 10px;

	.popover-content {
		padding: 0;
	}

	.public-servers-server-join-popover-container {
		background-color: #181822;

		.friend-icon {
			background-image: url(../img/publicservers/icons/friend.png);
			width: 14px;
			height: 15px;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			margin: 0 0.1rem;
		}

		.header {
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			width: 100%;
			height: 65px;
			background-color: #242430;
			padding: 15px 30px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.user-icon {
				color: #1ac9ab;
			}
		}

		.server-name-and-organizer {
			display: flex;
			align-items: center;
		}

		.organizer-logo {
			background-position: center top;
			background-repeat: no-repeat;
			background-size: cover;
			width: 40px;
			height: 40px;

			&.nip {
				background-image: url(../img/publicservers/nip_brand.png);
			}

			&.astralis {
				background-image: url(../img/publicservers/astralis_brand.png);
			}

			&.volt {
				background-image: url(../img/landing/volt/1.png);
			}
		}
		.players-list {
			height: 330px;
			display: flex;
			justify-content: center;
			align-items: center;

			.players-container {
				height: 280px;
				overflow-y: auto;

				.player {
					width: 390px;
					height: 55px;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.player-info {
						display: flex;
						align-items: center;
					}

					padding: 10px;

					a {
						color: #fff;
					}

					&:nth-child(odd) {
						background-color: #1d1e29;
					}

					.live-games-rank-icon {
						top: 0;
					}

					&.bots {
						.flag-wrapper {
							margin-right: 5px;
							height: 14px;
							display: flex;
							align-items: center;

							&.euro-flag {
								margin-left: -5px;
							}

							.region-flag {
								width: 23px;
								height: 16px;
							}
						}

						.bot-icon {
							color: #1ac9ab;
							width: 28px;
						}

						.bots-label {
							flex: 1 1 auto;
							text-align: left;
							padding: 0 5px;
						}

						.bots-number {
							width: 42px;
							text-align: center;
							color: #1ac9ab;
							font-weight: 700;
						}
					}
				}
			}
		}

		.buttons {
			width: 100%;
			height: 75px;
			background-color: #242430;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			display: flex;
			justify-content: center;
			align-items: center;

			.button {
				width: 120px;
			}

			.button.disabled:hover {
				color: #fff;
			}

			.join-button {
				background: #1ac9ab;

				&:hover {
					border: 2px solid #3f4151;
					background: #17b49a;
					color: #fff;
				}
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:108";