.home-live-streamz,
.home-left-column-streams,
.home-youtube-videos {
	.media-entries {
		width: 100%;

		@include tablet() {
			display: flex;
			justify-content: space-between;
			flex-flow: wrap;
		}
		@include mobile() {
			display: flex;
			justify-content: space-between;
			flex-flow: wrap;
		}
	}
	.media-entries.float-left {
		justify-content: flex-start;
	}
	.stream {
		display: block;
		position: relative;
		border: 1px solid #292b39;
		background-color: #1b1b26;
		border-radius: 3px;
	}
	.preview {
		width: 100%;
	}
	.meta {
		margin-top: -4px;
		display: flex;
		align-items: center;
		font-weight: 400;
		padding: 8px;

		@include mobile() {
			padding: 12px;
		}
	}
	.meta .title {
		font-size: 13px;
		font-weight: 500;
		color: #fff;
		white-space: nowrap;
		overflow: hidden;
		margin-bottom: 5px;
		text-overflow: ellipsis;
		padding-right: 8px;
	}
	.meta .by-line {
		width: 70%;
		color: #c0c6d1;
		flex-grow: 1;
		font-size: 13px;
		font-weight: 500;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		.username {
			color: $primary-color;
		}

		@include mobile() {
			font-size: 12px;
		}
	}

	.meta .viewers {
		color: #fff;
		font-size: 13px;
		font-weight: 500;
		min-width: 50px;

		position: static;
		display: flex;
		align-items: center;
		flex-shrink: 1;

		> span {
			display: inline-block;
			position: static;
			top: 0;
		}
	}

	.twitch-icon {
		position: relative;
		display: inline-block;
		top: 8px;
		width: 24px;
		height: 24px;
		margin-right: 5px;
		background-size: 24px 24px;
		background-image: url(../img/misc/twitch_views.png);
		background-repeat: no-repeat;
		filter: invert(1);

		@include mobile() {
			background-size: 20px 20px;
			width: 20px;
			height: 20px;
		}
	}

	.stream.small {
		.meta {
			height: 35px;
		}
		.by-line {
			height: auto;
			margin: 0;
			overflow: auto;
		}
		.viewers {
			font-size: 11px;
			min-width: auto;
		}
		.twitch-icon {
			background-size: 16px 16px;
			height: 16px;
			width: 16px;
		}
	}
}
.home-left-column-streams {
	> a {
		margin-bottom: 1em;
	}
}
.home-live-streamz,
.home-youtube-videos {
	flex-wrap: wrap;
	justify-content: space-between;
	flex-direction: column;
	@include tablet-only() {
		flex-direction: row;
	}
	@include mobile() {
		flex-direction: row;
	}

	&.no-content {
		background-color: #ebebeb;
		padding: 40px 50px;
		justify-content: center;
	}
	.video {
		width: 100%;
		padding: 1em 0;
	}
	.stream {
		width: 32%;
		margin: 1em 0;

		@include tablet-only() {
			width: 49%;
		}

		&.small {
			margin-top: 10px;
			width: 23%;
		}

		@include mobile() {
			width: 100%;
			&:not(:last-child) {
				margin-bottom: 35px;
			}
		}
	}

	.stream.float-left:not(:last-of-type) {
		margin-right: 5px;
	}

	&.empty {
		background: #ccc url(../img/banners/home_twitch.jpg) no-repeat center/cover;
		padding-top: calc(100% * 168 / 668);
	}
}

.home-youtube-videos {
	.meta {
		position: relative;
		margin-top: -4px;
		background-color: #10101a;

		.title {
			font-size: 18px;
			color: $grey-text-color;
		}
		.by-line {
			font-size: 15px;
			color: $primary-color;
		}
	}
}

;@import "sass-embedded-legacy-load-done:35";