@import 'constants';
@import 'lib/bulma';

$orange-color: #f26722;

.fragbite-content {
	width: 100%;
	background-color: $content-background-color;

	.page-general-banner {
		width: calc(100% + 48px);
		margin: -20px -24px 0;
		pointer-events: none;

		@media only screen and (max-width: 770px) {
			width: 100%;
			margin: 0;
		}
	}

	/*.fragbite-header {
		padding: 5em;

		@include mobile() {
			height: auto;
			padding: 0;
		}
	}*/

	.title {
		font-size: 24px;
	}
	.subtitle {
		color: $orange-color;
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 1.19px;
	}

	.country-filter {
		background-color: $card-background-color;
		border-radius: 10px;
		display: flex;
		width: 100%;
		justify-content: space-evenly;
		align-items: center;

		.flag-wrapper {
			padding: 10px 0;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			&:first-child {
				border-bottom-left-radius: 10px;
				border-top-left-radius: 10px;
			}
			&:last-child {
				border-bottom-right-radius: 10px;
				border-top-right-radius: 10px;
			}

			&.active {
				background-color: $dark-grey-border;
			}

			.flag {
				border-radius: 4px;
				max-width: 46px;
				width: 100%;
				height: auto;
				margin: 5px;
				background-size: cover;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				padding-top: 50%;
				min-width: 20px;
			}
		}
	}
	.youtube {
		overflow: hidden;
		text-align: right;

		@include mobile() {
			text-align: center;
		}
	}
	.jumpers {
		text-align: center;
		text-transform: uppercase;

		.jumpers-title {
			color: $grey-text-color;
		}

		.jumper-buttons {
			display: flex;
			flex-direction: row;

			div {
				text-transform: uppercase;
				color: $orange-color;
				margin: 0.5em;
				width: 100%;
				cursor: pointer;
			}
		}
	}
	.fragbite-news {
		margin-top: 2em;
	}
}
.fragbite-header {
	.partner-title {
		padding-left: 20px;
		padding-bottom: 5px;
		text-transform: uppercase;
		font-size: 10px;

		@include mobile() {
			font-size: 12px;
		}
	}

	.logo {
		height: 100%;
		width: 100%;
		background-size: contain;
		background-position: 0 50%;
		background-repeat: no-repeat;
		padding: 20px;
		padding-top: 0;
		background-origin: content-box;

		@include mobile() {
			padding: 10px;
			padding-top: 0;
		}
	}

	.fragbite {
		margin-top: 17px;
		padding: 0;
		background-image: url(../img/fragbite/fragbite_logo.png);

		@include mobile() {
			margin-top: 0;
			height: 100px;
			padding: 1em;
		}
	}

	.logos {
		display: flex;
		height: 100%;

		.logo {
			height: 100px;
		}

		.philips {
			background-image: url(../img/fragbite/sponsors/oneblade.png);
		}
		.samsung {
			background-image: url(../img/fragbite/sponsors/samsung-odysseyg9.png);
		}
		.subway {
			background-image: url(../img/fragbite/sponsors/subway.png);
		}
		.prisjakt {
			background-image: url(../img/fragbite/sponsors/prisjakt.png);
		}
		.trocadero {
			background-image: url(../img/fragbite/sponsors/trocadero-logo.png);
		}
		.zmarta {
			background-image: url(../img/fragbite/sponsors/zmarta-logo.png);
		}
	}
}
.fragbite-qualifiers {
	text-align: center;

	.qualifier-tournament {
		display: flex;
		justify-content: space-around;
		align-items: center;
		padding: 1em 0;
		border-bottom: 1px solid $dark-grey-border;

		&:last-child {
			border: 0;
		}
		.button-new-style {
			text-transform: uppercase;
		}
	}
	.q-number {
		color: $orange-color;
	}
	.country-filter {
		height: 50px;

		.flag-wrapper {
			height: 100%;
			padding: 10px;

			.flag {
				@include mobile() {
					padding-top: 0;
					height: 30px;
				}
			}
		}
	}

	&.wide {
		.country-filter {
			max-width: 200px;
			height: 50px;
			margin-left: 2em;

			.flag-wrapper {
				padding: 10px 5px;
				height: 100%;
			}
		}
		.qualifier-tournaments {
			height: 90%;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
		}
	}
}
.prizepool-column {
	margin: auto;
}
.prizepool-image {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.prizepool {
		background-image: url(../img/fragbite/prize.png);
		height: 100px;
		width: 100%;
		background-size: contain;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		margin: 20px -10px 0px auto;

		@include mobile() {
			width: 100%;
			margin: 0;
		}
	}
	.find-out-more {
		padding: 1em 3em;
		color: $orange-color;
		text-transform: capitalize;
		font-size: 12px;
		background-color: $secondary-color;
		border-radius: 4px;
		cursor: pointer;
		margin-top: -1.5em;

		.fa-chevron-down {
			margin-left: 1em;
		}
	}
}
.prizepool-points {
	font-weight: 400;

	.point {
		display: flex;
		margin: 2em 0;

		@include mobile() {
			&:first-child {
				margin-top: 0;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.fa-caret-right {
		color: $orange-color;
		margin-right: 1em;
		font-size: 18px;
	}
}
.fragbite-prizes-wrapper {
	margin-top: -0.75em;
	margin-bottom: 4em;

	@include mobile() {
		margin-bottom: 1em;
	}

	.fragbite-prizes {
		border-radius: 10px;
		background-color: $card-background-color;

		.prizes-header {
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			background-color: #20212c;
			text-align: center;
			padding: 1em 0;

			.title {
				color: $orange-color;
				text-transform: uppercase;
				font-size: 14px;
				letter-spacing: 0.74px;
			}
		}

		.prizes-content {
			padding: 0 1em;
			font-size: 12px;

			.prize {
				padding: 1.2em 0;
				display: flex;
				border-bottom: 1px solid $dark-grey-border;
				font-size: 13px;

				&:last-child {
					border-bottom: 0;
				}

				.counter {
					color: $orange-color;
					margin: 0 1em;
					width: 10%;
				}
				.content {
					font-weight: 400;
				}
			}
		}
	}
}
.elisa-tournaments,
.fragbite-tournaments {
	align-content: flex-start;
	.filter {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.fa-caret-right {
			margin-left: 0.5em;
			margin-right: 1em;
		}
	}
	.country-filter {
		max-width: 300px;
		height: 50px;

		.top-division {
			margin: 5px;
			white-space: nowrap;
		}
		.flag-wrapper {
			padding: 10px 5px;
			height: 100%;
		}
	}
	.ladders {
		tr:first-child th:first-child {
			border-top-left-radius: 10px;
		}
		tr:first-child th:last-child {
			border-top-right-radius: 10px;
		}

		thead {
			text-transform: uppercase;
			th {
				background-color: #1a1b24;
			}
		}
		th:nth-child(2),
		td:nth-child(2) {
			text-align: left;
		}
		tbody {
			color: $grey-text-color;
		}
		.more-column {
			text-align: center;
		}
		.white-text {
			color: #fff;
		}
	}
	.team-name {
		display: flex;
		align-items: center;

		&.left {
			justify-content: end;
			float: right;
		}

		.name-n-country {
			line-height: 14px;

			.country {
				text-transform: uppercase;
				font-size: 10px;
				color: $grey-text-color;
			}
		}
	}
	.matches {
		.tabs {
			.tab {
				display: inline-block;
				background-color: #222431;
				min-width: 20%;
				padding: 1em;
				text-align: center;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
				margin-right: 2px;
				cursor: pointer;
				text-transform: uppercase;
				color: $grey-text-color;

				&.active {
					background-color: #191a24b3;
					color: $orange-color;
				}
			}

			@include mobile() {
				.tab {
					background-color: #1c1c27;
					&.active {
						background-color: #232330;
					}
				}
			}
		}
		.date {
			color: $grey-text-color;
		}
		.match-view {
			color: $orange-color;
		}
		.table-avatar {
			margin-left: 5px;
		}
	}
	.table-avatar {
		width: 30px;
		height: 30px;
	}
	tr {
		line-height: 68px;
	}
	.mobile-matches {
		margin-bottom: 2px;

		.match-header {
			display: flex;
			justify-content: space-between;
			background-color: #232330;
			font-size: 10px;

			div {
				margin: 0.5em 1em;
			}
		}
		.teams {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #1c1c27;

			> div {
				flex-grow: 1;
				flex-basis: 0;
			}
			.team-name {
				margin: 0.5em 1em;
			}
			.score {
				flex-grow: 0;
				min-width: 50px;
				text-align: center;
				color: $grey-text-color;
			}
		}
	}
}
.fragbite-team-popup {
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */

	.popup-content {
		z-index: 20;
		position: fixed;
		text-align: center;
		width: 500px;
		max-height: 550px;
		line-height: 30px;

		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background-image: url(../img/backgrounds/dark.png);
		background-repeat: no-repeat;
		background-position: 50% 0;
		background-color: #14151d;
		border-radius: 20px;
		overflow: auto;

		.popup-body {
			padding: 1em;
		}
		.popup-cancel {
			position: absolute;
			top: 15px;
			right: 20px;
			cursor: pointer;
		}
		.team-name {
			.name-n-substring {
				text-align: left;

				.substring {
					text-transform: uppercase;
					font-size: 10px;
					color: $grey-text-color;
					line-height: 10px;

					.famfamfam-flag {
						transform: translate(0, 2px);
					}
				}
			}
			.name {
				font-size: 26px;
				line-height: 26px;
			}
			.header-avatar {
				width: 50px;
				height: 50px;
			}
		}

		.players {
			overflow: auto;

			thead {
				text-transform: uppercase;
				font-size: 12px;
			}
			tr {
				border-bottom: 1px solid $dark-grey-border;
			}
			.team-name {
				justify-content: left;

				.name-n-substring {
					line-height: 10px;
				}
			}
		}
	}
	.team-name {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
