.shutdown-landing-page {
	color: #ffffff;
	height: 100%;
	text-align: center;
	padding-top: 10em;

	&.brazil {
		#main-content-background {
			background-image: url(../img/backgrounds/brbbrazil_back.jpg);
		}

		h2 {
			color: #f0d317;
		}
	}
	&.cis {
		#main-content-background {
			background-image: url(../img/backgrounds/brb_CIS.png);
		}

		h2 {
			color: #f20022;
		}
	}

	.content {
		width: 50%;
		margin-left: auto;
		margin-right: auto;

		@media only screen and (max-width: 768px) {
			width: 90%;
		}
	}

	.text-block {
		font-weight: bold;
		margin-bottom: 1em;
		line-height: 26px;
		font-size: 16px;
	}

	.stats {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin-top: 2em;

		.box {
			width: 25%;
			background-color: #00000062;
			padding: 20px;

			h2 {
				font-size: 2rem;
				margin-bottom: 1rem;
			}
		}
	}

	h1,
	h2 {
		margin: 0;
		text-transform: uppercase;
	}

	h1 {
		font-size: 3rem;
	}

	h2 {
		margin-bottom: 1em;
	}
}
