.flagcut-by {
	content: url(~img/getinpro/menu/flags/flagcut_BY.png);
}
.flagcut-de {
	content: url(~img/getinpro/menu/flags/flagcut_DE.png);
}
.flagcut-dk {
	content: url(~img/getinpro/menu/flags/flagcut_DK.png);
}
.flagcut-kz {
	content: url(~img/getinpro/menu/flags/flagcut_KZ.png);
}
.flagcut-pl {
	content: url(~img/getinpro/menu/flags/flagcut_PL.png);
}
.flagcut-ru {
	content: url(~img/getinpro/menu/flags/flagcut_RU.png);
}
.flagcut-se {
	content: url(~img/getinpro/menu/flags/flagcut_SE.png);
}
.flagcut-ua {
	content: url(~img/getinpro/menu/flags/flagcut_UA.png);
}

.getinpro-flag-by {
	content: url(~img/getinpro/menu/flags/flag_BY.png);
}
.getinpro-flag-de {
	content: url(~img/getinpro/menu/flags/flag_DE.png);
}
.getinpro-flag-dk {
	content: url(~img/getinpro/menu/flags/flag_DK.png);
}
.getinpro-flag-kz {
	content: url(~img/getinpro/menu/flags/flag_KZ.png);
}
.getinpro-flag-pl {
	content: url(~img/getinpro/menu/flags/flag_PL.png);
}
.getinpro-flag-ru {
	content: url(~img/getinpro/menu/flags/flag_RU.png);
}
.getinpro-flag-se {
	content: url(~img/getinpro/menu/flags/flag_SE.png);
}
.getinpro-flag-ua {
	content: url(~img/getinpro/menu/flags/flag_UA.png);
}

.getinpro-menu {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	a {
		display: inline-block;
		background-color: #530f76;
	}
	a.flag {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		.flag-img {
			width: 50px;
		}
		div {
			width: 70px;
			color: white;
			text-transform: uppercase;
			text-align: center;
		}
	}

	.button {
		font-size: inherit !important;
		padding: 10px 38px;
		background-color: #f227ff !important;
	}
}

.getinpro-menu-overview {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	a {
		display: inline-block;
		background-color: #530f76;
	}
	a.flag {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		.flag-img {
			width: 50px;
		}
		div {
			width: 55px;
			color: white;
			text-transform: uppercase;
			text-align: center;
		}
		&.active {
			background-color: $primary-hover-color;
		}
	}

	.button {
		font-size: inherit !important;
		padding: 11px 37px;
		background-color: #f227ff !important;

		&.overview {
			background-color: #20243a !important;
		}
		&.overview.active {
			background-color: $primary-hover-color !important;
		}
	}
}

.getinpro-content {
	width: inherit !important;
	background-color: #191c2e;
	font-size: 25px;
	padding-top: 0 !important;
	margin-bottom: -15px;

	.getinpro-inner-content {
		width: 1200px !important;
		margin: 0 auto;
		position: relative;
	}

	.getinpro-banner {
		width: 100%;
		content: url(~img/getinpro/landing/header/header_full_trans.png);
		margin: 0 auto;
	}
	.getinpro-banner-small {
		width: 100%;
		content: url(~img/getinpro/tournament_header.png);
		margin: 0 auto;
	}
	.prize-info {
		display: block;
		margin: 50px auto;
		content: url(~img/getinpro/landing/prize.png);
	}
	.find-more {
		color: #9201c2;
		text-align: center;
		position: relative;
		top: -20px;
	}
	.details {
		position: relative;

		.details-bg {
			width: 100%;
			content: url(~img/getinpro/landing/mid_bg.png);
		}

		.grid-container {
			display: flex;
			flex-flow: row wrap;
			height: 100%;
			padding-top: 30px;

			position: absolute;
			top: 0;
			left: 0;
			.grid {
				width: 50%;
				height: 50%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				text-align: center;
			}
			.grid-img {
				margin-bottom: 20px;
			}

			.grid-img.seasons {
				content: url(~img/getinpro/landing/icons_2seasons.png);
			}
			.grid-img.leagues {
				content: url(~img/getinpro/landing/icons_winners.png);
			}
			.grid-img.stages {
				content: url(~img/getinpro/landing/icons_money.png);
			}
			.grid-img.participants {
				content: url(~img/getinpro/landing/icons_travel.png);
			}
		}
	}

	.slot-highlight {
		position: absolute;
		bottom: 100px;
		left: 0;
		height: 100px;
		width: 100%;
		padding: 0 60px;
		.slot-highlight-inner {
			width: 100%;
			height: 100%;
			border: 2px solid #ed1343;
			color: #ed1343;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			text-transform: uppercase;
			font-size: 20px;
			font-weight: 900;
			font-family: quagmire;
		}
	}

	.blue-yellow-thunder-container {
		position: absolute;
		bottom: -245px;
		left: -7%;
		width: 113%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.blue-yellow-thunder {
		content: url(~img/getinpro/landing/summerwintercup_bg_overlap.png);
		margin: 0 auto;
		width: 120%;
	}

	.premier {
		position: relative;
		margin-top: 100px;
	}
	.premier-inner {
		position: relative;

		.premier-event-text {
			content: url(~img/getinpro/landing/title_premierevent.png);
			text-align: center;
			margin: 0 auto;
			margin-bottom: 100px;
			padding: 40px;
			font-size: 65px;
			text-transform: uppercase;
			font-family: quagmire;
		}
		.subheading {
			font-size: 23px;
			font-weight: 400 !important;
		}
	}
	.premier-bg {
		content: url(~img/getinpro/landing/premierevent_bg.png);
		width: 118%;
		position: absolute;
		left: -127px;
	}
	.premier-bottom-spacer {
		height: 200px;
	}

	.cups {
		display: flex;
		position: relative;
		z-index: 2;

		.yellow {
			color: #ffe900;
		}
		.blue {
			color: #01ddf5;
		}
		.pink {
			color: #f227ff;
		}

		.bold {
			font-weight: 600;
		}

		.summer-cup {
			content: url(~img/getinpro/landing/titleSummerCup.png);
			margin: 30px 0;
		}
		.winter-cup {
			content: url(~img/getinpro/landing/titleWinterCup.png);
			margin: 30px 0;
		}

		.bullet-yellow {
			content: url(~img/getinpro/landing/bullets_yellow.png);
		}
		.bullet-blue {
			content: url(~img/getinpro/landing/bullets_blue.png);
		}
		.bullet-pink {
			content: url(~img/getinpro/landing/bullets_pink.png);
		}

		.bullet-nil {
			width: 20px;
			height: 10px;
			background-color: transparent;
		}
		.bullet-nil,
		.bullet-yellow,
		.bullet-blue,
		.bullet-pink {
			margin-left: 50px;
			margin-right: 25px;
		}

		.bullet {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin-top: 20px;
			margin-bottom: 20px;
		}

		.place {
			min-width: 145px;
		}
		.place-dash {
			margin-right: 20px;
		}

		.subheading {
			text-transform: uppercase;
			font-weight: 500;
		}

		.cups-entry-inner {
			width: 480px;
		}

		.cups-entry {
			display: flex;
			align-items: center;
			flex-direction: column;
			width: 50%;
			font-size: 20px;

			.cups-header {
				display: flex;
				justify-content: space-between;
			}
		}
	}
}

.getinpro-country-qualifier-content {
	$bg-content: #191c2e;
	$bg-normal: #20243a;
	$bg-alternate: #191d2e;
	$bg-tab: #1f2339;
	$bg-table-header: #540f76;
	$bg-button: #20a7e0;

	$fg-heading: #f227ff;
	$fg-caret: #00a9e9;
	$fg-textbackground-color: $bg-content;
	$fg-blue: #068fc7;

	font-size: 25px;
	padding-top: 0 !important;
	background-color: #191c2e;
	width: inherit;
	min-height: 1300px;
	margin-bottom: -15px;

	.getinpro-country-qualifier-content-inner {
		width: 1200px !important;
		margin: 0 auto;
		position: relative;
		min-height: 1300px;
	}

	.getinpro-menu-overview {
		margin-top: -6px;
		margin-bottom: 40px;
	}

	.getinpro-banner {
		width: 100%;
		content: url(~img/getinpro/tournament_header.png);
		margin: 0 auto;
	}

	.getinpro-country-qualifier-inner-content {
		margin: 0 auto;
		position: relative;
		display: flex;
	}

	.main {
		flex-grow: 1;
	}
	.sidebar {
		width: 370px;
		& > * {
			margin-left: 20px;
		}
	}

	.heading {
		font-weight: 700;
		color: $fg-heading;
		font-size: 19px;
		padding: 15px 0;
		text-transform: uppercase;
	}

	.tabs > .tab {
		display: inline-block;
		background-color: $bg-normal;
		padding: 10px 0;
		&:last-child button {
			border-right: 0;
		}

		&.active button {
			color: white;
		}
	}
	.tabs button {
		background-color: $bg-normal;
		padding: 5px 20px;
		border: 0;
		font-size: 14px;
		border-right: 1px solid #2f2f2f;

		color: $fg-blue;

		&:hover {
			cursor: pointer;
		}
	}

	.sidebar .heading {
		text-align: center;
	}
	.my-team-details {
		background-color: $bg-normal;
		min-height: 180px;
		margin-bottom: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		.user-profile-avatar {
			width: 100px;
			height: 100px;
			margin-left: 0;
			border-width: 3px;
			margin-bottom: 10px;
		}
		.team-and-flag {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			a {
				margin-left: 10px;
			}
		}
	}
	.result-entry {
		font-size: 13px;
		background-color: $bg-normal;
		padding: 15px;
		margin-bottom: 2px;

		.kval-name {
			color: $fg-blue;
			font-size: 14px;
			font-weight: 700;
		}
		.flex-row {
			display: flex;
			justify-content: space-between;
			margin-top: 5px;
		}
		.team-info {
			& > * {
				margin-left: 15px;
			}
			text-align: right;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			.team-name {
				font-size: 14px;
				font-weight: 500;
				overflow-wrap: break-word;
				max-width: 130px;
			}
		}
		.score {
			display: inline-block;
			width: 15px;
		}
		.score-win {
			color: #96ea00;
		}
		.score-lose {
			color: #a61228;
		}

		.country-flag {
			width: 21px;
			height: auto;
			display: inline-block;
		}
		.view-summary {
			color: #f9dd16;
			text-transform: uppercase;
			font-size: 11px !important;
			font-weight: 700;
		}
	}

	table.kval {
		thead th {
			color: white;
			background-color: $bg-table-header;
		}

		tbody td {
			background-color: $bg-normal;
		}
		tbody td {
			padding: 10px 20px;
		}
		thead th {
			padding: 3px 20px;
		}
		th.signup,
		td.signup {
			width: 150px;
		}

		td.signup a {
			padding: 0px 50px !important;
			width: 150px;
			&.button-clear {
				border: 1px solid $primary-hover-color;
				background-color: transparent;
			}
		}

		td {
			border-bottom: 2px solid #191c2e;
		}
	}
	table.teams {
		thead th {
			color: white;
			background-color: $bg-table-header;
		}

		tbody td {
			background-color: $bg-alternate;
		}
		tbody tr:nth-child(odd) td {
			background-color: $bg-normal;
		}

		&.qualifiers > tbody > tr > td,
		&.qualifiers > thead > tr > th {
			padding: 2px 20px;

			&.team-name {
				padding-left: 40px;
			}
		}

		.getinpro-teams-expanded.team-details {
			padding-left: 0;
			padding-right: 0;
		}

		th.team-details,
		td.team-details {
			border-left: 3px solid $bg-content;
			width: 140px;
			text-align: center;
			user-select: none;

			.fa-caret-up,
			.fa-caret-down {
				font-size: 21px;
				color: $fg-caret;
			}
			&:hover {
				cursor: pointer;
			}
		}
		td.team-details .fas {
			font-size: 23px;
		}

		tr.team-details {
			background-color: green;
			border-top: 2px solid $bg-content;
		}

		td.country-flag {
			.country-flag {
				width: 21px;
				height: auto;
				display: inline-block;
			}
		}
		td.status {
			font-size: 13px;
			text-transform: capitalize;
			font-weight: 500;
		}
		td.status.status-0 {
			color: #96ea00;
		}
		td.status.status-1 {
			color: #a61228;
		}

		&.qualifiers th.team-name,
		&.qualifiers td.team-name {
			width: 350px;
		}
		td.team-name {
			display: flex;
			align-items: center;

			.num {
				min-width: 35px;
				margin-left: 5px;
			}

			.team-card-wrapper {
				overflow-wrap: break-word;
				line-height: 1.4em;
			}

			.default-team-logo {
				border-radius: 50%;
				border: 1px solid $primary-hover-color;
				width: 30px;
				height: 30px;
				background-position: center;
				background-size: contain;
				display: inline-block;
				margin-right: 20px;
			}
		}
		th.country-flag,
		th.team-score {
			width: 50px;
		}
	}
	table.matches {
		thead th {
			color: white;
			background-color: $bg-table-header;
		}

		tbody td {
			background-color: $bg-alternate;
		}
		tbody tr:nth-child(odd) td {
			background-color: $bg-normal;
		}
		.country-flag {
			width: 20px;
			height: auto;
			display: inline-block;
			margin-left: 4px;
		}
	}
}

.getinpro-teams-expanded {
	padding: 0px;
	background-color: #c4edff;
}
.getinpro-teams-stats {
	padding: 2px;
}
.getinpro-lws-lls {
	float: left;
}
.getinpro-teams-average-rating-value {
	font-size: 18px;
}
.getinpro-teams-average-rating {
	font-weight: 500;
	display: block;
	float: left;
	width: 200px;
	text-align: center;
	font-size: 16px;
	line-height: 28px;
}
.getinpro-teams-misc-names {
	font-weight: 500;
	display: block;
	width: 60px;
	float: right;
	text-align: left;
	line-height: 28px;
}
.getinpro-teams-misc-values {
	display: block;
	width: 100px;
	float: right;
	text-align: left;
	margin-left: 20px;
	line-height: 28px;
}
.getinpro-teams-players {
	margin-bottom: 5px;
	border: 0px;
}

.getinpro-team-box {
	padding: 7px;
	text-align: center;
	font-size: 12px;
	background-color: #20243a;
	color: white;
	padding: 40px 0px;
}
.getinpro-team-box-no-team {
	line-height: 30px;
}
.getinpro-team-box-signed-in {
	line-height: 25px;
}
.getinpro-team-box-obs {
	font-size: 50px;
}

.getinpro-team-box-shield {
	font-size: 65px;
	margin-bottom: 10px;
	color: #0076a3;
}
.getinpro-team-box-logo {
	display: inline-block;
	background-size: cover;
	background-repeat: no-repeat;

	width: 30px;
	height: 30px;

	vertical-align: middle;
	margin-right: 8px;
}
.getinpro-team-box-team-name {
	font-size: 14px;
	font-weight: 500;
}
.getinpro-team-box-region-name {
	font-size: 20px;
	text-transform: uppercase;
}

;@import "sass-embedded-legacy-load-done:61";