.qc-cmp-button {
	background-color: #00a9e9 !important;
	border-color: #00a9e9 !important;
}
.qc-cmp-button:hover {
	background-color: transparent !important;
	border-color: #00a9e9 !important;
}
.qc-cmp-alt-action,
.qc-cmp-link {
	color: #00a9e9 !important;
}
.qc-cmp-button.qc-cmp-secondary-button:hover {
	border-color: transparent !important;
	background-color: #00a9e9 !important;
}
.qc-cmp-button {
	color: #fff !important;
}
.qc-cmp-button.qc-cmp-secondary-button {
	color: #fff !important;
}
.qc-cmp-button.qc-cmp-button.qc-cmp-secondary-button:hover {
	color: #ffffff !important;
}
.qc-cmp-button.qc-cmp-secondary-button {
	border-color: #004989 !important;
	background-color: #004989 !important;
}
.qc-cmp-ui,
.qc-cmp-ui .qc-cmp-main-messaging,
.qc-cmp-ui .qc-cmp-messaging,
.qc-cmp-ui .qc-cmp-beta-messaging,
.qc-cmp-ui .qc-cmp-title,
.qc-cmp-ui .qc-cmp-sub-title,
.qc-cmp-ui .qc-cmp-purpose-info,
.qc-cmp-ui .qc-cmp-table,
.qc-cmp-ui .qc-cmp-table-header,
.qc-cmp-ui .qc-cmp-vendor-list,
.qc-cmp-ui .qc-cmp-vendor-list-title {
	color: #fff !important;
}
.qc-cmp-ui a,
.qc-cmp-ui .qc-cmp-alt-action,
.qc-cmp-toggle-status {
	color: #fff !important;
}
.qc-cmp-ui {
	background-color: #121212 !important;
}
.qc-cmp-publisher-purposes-table .qc-cmp-table-header {
	background-color: #121212 !important;
}
.qc-cmp-publisher-purposes-table .qc-cmp-table-row {
	background-color: #121212 !important;
}
.qc-cmp-vendor-list .qc-cmp-vendor-row {
	background-color: #121212 !important;
}
.qc-cmp-vendor-list .qc-cmp-vendor-row-header {
	background-color: #121212 !important;
}
.qc-cmp-small-toggle.qc-cmp-toggle-on,
.qc-cmp-toggle.qc-cmp-toggle-on {
	background-color: #00a9e9 !important;
	border-color: #00a9e9 !important;
}
.qc-cmp-close-icon {
	background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath d='M.5.5l23 23m0-23l-23 23' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10'/%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3C/svg%3E")
		50% no-repeat !important;
}
.qc-cmp-persistent-link {
	right: auto !important;
	left: 0px;
}
