.tournament-page-content {
	padding: 0 !important;

	@include mobile() {
		.tournament-content {
			padding: 0 15px;
			padding-top: 27px;
		}
	}

	.tournament-bracket-content {
		background-color: #15151d;
		overflow: auto;
		cursor: default;
		user-select: none;
		margin-top: -25px;
		padding-top: 25px;
		position: relative;
		scroll-behavior: smooth;

		@include mobile() {
			margin-top: 0;
		}

		.tournament-bracket-upper-phase-description {
			height: 30px;
			line-height: 30px;
			.tournament-bracket-phase-title {
				font-size: 16px;
				font-weight: bold;
				text-transform: uppercase;
				text-align: center;
				float: left;
			}
			color: white;
		}
		.tournament-bracket-scroll-indicator {
			color: $primary-color;
			background-color: $dark-grey-border;
			font-size: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			width: 30px;
			height: 70px;
			position: absolute;
			top: 12px;
			right: 0;
			transition: opacity 1s;

			&.invisible {
				opacity: 0;
			}
			.scroll-info {
				color: $grey-text-color;
				font-size: 13px;
				text-transform: capitalize;
				position: relative;
				& > div {
					text-transform: uppercase;
					text-align: center;
					background-color: $dark-grey-border;
					width: 110px;
					padding: 8px;
					position: absolute;
					right: 13px;
					bottom: -5px;
					display: none;
				}
			}
			&:hover .scroll-info > div {
				display: block;
			}
		}
		.tournament-bracket-lower-phase-description {
			height: 20px;
			line-height: 20px;
			.tournament-bracket-phase-misc {
				font-weight: 500;
				font-size: 14px;
				color: #c0c6d1;
				text-transform: uppercase;
				text-align: center;
				float: left;
				.tournament-bracket-spacing {
					display: inline-block;
					margin-left: 30px;
				}
			}
		}
		.tournament-bracket-root-layout {
			$line-color: #464650;
			$line-width: 1px;

			position: relative;

			.tournament-bracket-node {
				$node-height: 100px;

				position: absolute;
				width: 250px;
				height: $node-height + $line-width;

				.tournament-bracket-actions {
					position: absolute;
					width: 244px;
					top: 97px;
					background-color: rgba(0, 0, 0, 0.5);
					padding: 5px 0px;
					font-size: 12px;
					text-align: center;
					margin-left: 3px;

					a {
						color: #fff;
					}
				}

				.tournament-bracket-team-divider {
					height: calc($node-height / 2);
					line-height: calc($node-height / 2 - 6px);
					border: 3px solid #15151d;

					.tournament-bracket-team-data {
						display: inline-block;
						width: calc(250px - $node-height / 2);

						&.dragging-on {
							outline: 3px solid red;
						}
					}
					.tournament-bracket-team-score {
						float: right;

						width: calc($node-height / 2 - 6px);
						height: calc($node-height / 2 - 6px);

						background-color: #e0e0e0;
						font-size: 20px;
						color: #fff;
						display: flex;
						justify-content: center;
						align-items: center;
					}
					.tournament-bracket-winner {
						background-color: $button-color;
					}
					.tournament-bracket-team-logo {
						display: inline-block;
						vertical-align: middle;
						margin: 0px 5px 0px 15px;
						border: 2px solid $primary-color;
						width: 25px;
						height: 25px;
					}
					.tournament-bracket-team-country {
						margin-left: 5px;
						vertical-align: middle;
					}
					.tournament-bracket-team-name {
						vertical-align: middle;
						max-width: 120px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: inline-block;
						white-space: nowrap;
					}
				}
				.tournament-bracket-team-divider:first-child {
					margin-bottom: 3px;
				}
				.tournament-bracket-team-divider:last-child {
					margin-top: 3px;
				}
			}

			.tournament-bracket-horizontal-line {
				position: absolute;
				height: $line-width;
				background-color: $line-color;
			}
			.tournament-bracket-vertical-line {
				position: absolute;
				width: $line-width;
				background-color: $line-color;
			}
		}

		.tournament-no-brackets-container {
			text-align: center;
			width: 100%;
			border: 1px solid #dadada;
			background-color: $content-background-color;
			padding: 40px;
		}
	}

	.tournament-bracket-content.tournament-bracket-dragging {
		cursor: move;
	}
	.tournament-teams-content,
	.tournament-matches-content {
		max-width: 1200px;
		margin: auto;
		padding-bottom: 50px;

		.tournament-upcoming-matches-team-column {
			.team-column-inner {
				display: flex;
				align-items: center;
				line-height: 1.2;
				column-gap: 7px;

				.table-avatar {
					margin: 0;
				}

				&.inner-right {
					justify-content: flex-start;
				}
				&.inner-left {
					justify-content: flex-end;
				}

				@include mobile() {
					font-size: 13px;
					&.inner-right {
						text-align: left;
					}
					&.inner-left {
						text-align: right;
					}
				}
			}
		}
		.tournament-upcoming-matches-actions-column {
			.button + .Tipsy-inlineblock-wrapper {
				margin-left: 15px;
			}
		}

		.tournament-upcoming-matches-team-column,
		.tournament-upcoming-matches-starts-at-column,
		.tournament-upcoming-matches-actions-column,
		.tournament-upcoming-matches-map-column,
		.tournament-upcoming-matches-summary-column,
		.tournament-upcoming-matches-vs-column {
			width: 1px;
			white-space: nowrap;
		}
		.tournament-upcoming-matches-vs-column .sched {
			display: none;
		}
		.tournament-upcoming-matches-vs-column {
			.match-status-indicator {
				border-radius: 1em;
				border: 1px solid;
				display: inline-block;
				padding: 2px 1em;

				&.live {
					color: #abff00;
					border-color: #abff00;
				}
			}
		}
		@include mobile() {
			.tournament-upcoming-matches-vs-column {
				position: relative;

				.sched {
					display: flex;
					align-items: center;
					justify-content: center;
					top: 0;
					left: 50%;
					position: absolute;
					font-size: 10px;
					text-align: center;
					white-space: nowrap;
					width: 1px;

					& > span {
						display: inline-block;
					}
				}
			}
		}

		tr {
			background-color: #161720;
		}

		.tr-right {
			text-align: right;
			padding-right: 20px;
		}

		.tr-left {
			text-align: left;
			padding-left: 20px;
		}

		.tournament-schedule-mobile {
			td {
				position: relative;
				padding: 10px 0px;
			}
			td > * {
				padding: 1px;
				line-height: 1;
			}
			.sched {
				color: $grey-text-color;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 12px;
				line-height: 1;
				text-align: center;
			}
			.mid-row {
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				line-height: 1;
				& > * {
					flex-grow: 1;
					flex-basis: 0;
				}
				& > *:first-child {
					text-align: right;
				}
				.mid-col {
					display: flex;
					align-items: center;
					min-height: 28px;
					white-space: nowrap;
					padding: 0 20px;
					flex-grow: 0;
				}
			}
			.actions {
				display: flex;
				justify-content: center;
				.button {
					flex-grow: 1;
					&.lobby-button {
						max-width: unset;
					}
					font-size: 12px;
					padding-top: 0 !important;
					padding-bottom: 0 !important;
					display: inline-block !important;
					margin: 0;
					margin-left: 3px;
					max-width: 50px;
				}
			}
			.links {
				font-size: 12px;
				text-transform: uppercase;
				position: absolute;
				top: 10px;
				right: 10px;
				width: 75px;
				height: 100%;
				text-align: right;
				& > * {
					display: block;
				}
			}
		}
	}

	.tournament-rules-content {
		display: flex;
		margin-right: 0px;

		.tournament-rules-container {
			border: 1px #dadada solid;
			background-color: #fff;
			padding: 40px;
		}
	}

	.tournament-teams-content {
		.tournament-team-name-column {
			width: 28%;
			text-align: left;
			& > .tournament-team-name-inner {
				display: flex;
				align-items: center;
				.team-card-wrapper {
					line-height: 1.3;
				}
			}
		}
		.tournament-team-info-column {
			width: 12%;
			text-align: center;

			.fas {
				cursor: pointer;
			}
		}
		.tournament-team-rating-column {
			width: 12%;
			text-align: center;
		}
		.tournament-team-country-column {
			width: 12%;
			text-align: center;
			.famfamfam-flag {
				transform: scale(2);
			}
		}
		.tournament-team-p-column {
			width: 12%;
			text-align: center;
		}
		.tournament-team-wins-column {
			width: 12%;
			text-align: center;
		}
		.tournament-team-losses-column {
			width: 12%;
			text-align: center;
		}
		.tournament-team-checked-in-column {
			min-width: 82px;
		}
		td.tournament-team-decline-accept-column {
			align-items: center;
			display: flex;
			height: 40px;
		}

		.tournament-team-extended-row {
			border-bottom: 2px $dark-grey-text-color solid;
			border-top: 2px $dark-grey-text-color solid;
			vertical-align: top;

			td {
				padding: 0px;

				tr:last-child {
					border-bottom: none;
				}
			}

			td,
			th {
				text-align: center;
			}

			th {
				text-transform: uppercase;
			}

			tbody {
				background-color: $secondary-color;
			}

			.tournament-team-extended-name-column {
				text-align: left;
				padding-left: 10px;

				.player-card-wrapper {
					vertical-align: middle;
					line-height: 14px;

					.tournament-teams-players-username {
						overflow: hidden;
						text-overflow: ellipsis;
						width: 100px;
					}
				}
			}

			.tournament-team-player-favorite {
				vertical-align: middle;
				margin-top: 0px;
				display: inline-block;
				width: 39px;
				height: 25px;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: 50% 50%;
			}
		}

		.tournament-extended-header {
			tr {
				height: 48px;
				line-height: 20px;
				border-bottom: 2px black solid;
				background-color: #171721;
				font-size: 12px;
			}
		}

		.tournament-team-card {
			display: flex;
			flex-direction: row;
			align-items: center;

			.tournament-team-rating-name {
				font-size: 14px;
				text-align: center;
				margin: 15px;
			}

			.tournament-team-rating-value {
				height: 22px;
				font-size: 22px;
			}

			.tournament-team-view-profile {
				background-color: $button-color;
				color: white;
				padding: 10px;
				text-transform: uppercase;
				cursor: pointer;
			}

			.tournament-team-view-profile:hover {
				background-color: $button-hover-color;
			}
		}
	}
	.tournament-ladder-page,
	.tournament-page-content,
	.tournament-info-content {
		max-width: 1200px;
		display: flex;
		margin: auto;
		padding-bottom: 50px;

		@include mobile() {
			flex-direction: column-reverse;
		}

		.tournament-info-container {
			padding: 0px 40px;
			@include mobile() {
				padding: 0px;
				.wysiwyg-content {
					padding: 0px;
				}
				iframe {
					width: 100%;
				}
			}
		}

		.tournament-info-nel-logged-out-right-banner {
			background-image: url(../img/nel/banner_secsgo_kvalpage.png);
			padding-top: 170%;
			background-size: cover;
			width: 100%;
			display: block;
			margin-bottom: 8px;
		}
	}

	.tournament-ladder-content {
		width: 100%;

		@include mobile() {
			th {
				line-height: 1.2;
			}
			th.wl-column {
				white-space: nowrap;
			}
		}
	}

	.tournament-details-column {
		width: 40%;
	}

	.tournament-details-table td:first-child {
		text-transform: uppercase;
	}

	.tournament-details-table tr {
		border-top: 1px solid #292a34;
		font-weight: 400;
		line-height: 55px;
		color: #c0c6d1;
		font-size: 14px;
	}

	.tournament-details-table tr:last-child {
		border-bottom: 1px solid #292a34;
	}

	.tournament-header {
		position: relative;
	}

	.tournament-banner,
	.tournament-tabs {
		max-width: 1200px;
		width: 100%;
	}

	.tournament-1v1-status {
		width: 100%;
		line-height: 75px;
		font-size: 16px;
		font-weight: 400;
		text-transform: uppercase;
		color: #c0c6d1;

		.tournament-1v1-status-container {
			height: 40px;
			font-size: 14px;
			margin: 0 auto;
			margin-top: 20px;
			max-width: 1200px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			text-transform: uppercase;
			.tournament-status-separator {
				padding-left: 30px;
			}
			.left {
				.tournament-type-label {
					color: white;
					font-weight: 500;
					margin-right: 5px;
				}
				.tournament-type-text {
					color: $primary-color;
					font-weight: 500;
				}
			}
			.right {
				display: flex;
				justify-content: flex-end;
				color: $grey-text-color;
				font-weight: 500;
				font-size: 13px;
				.inner-right {
					display: flex;
					align-items: center;

					> * {
						margin-right: 5px;
						display: block;
					}
				}
				.status-label {
					font-weight: 600;
					margin-right: 8px;
				}
				.map-name {
					color: $primary-color;
				}
				.country-flag {
					width: 21.7px;
					height: 15.75px;
					margin: 0 5px;
				}
				.country-name {
					text-transform: capitalize;
				}
				.twitter-button {
					display: inline-block;
					position: relative;
					width: 22px;
					height: 22px;
					margin-left: 8px;
					margin-right: 8px;
					background-image: url(../img/misc/twitter-logo.png);
					background-size: 22px 22px;

					filter: brightness(0%) invert(100%);
				}
				.facebook-button {
					display: inline-block;
					position: relative;
					width: 22px;
					height: 22px;
					margin-left: 4px;
					margin-right: 4px;
					background-image: url(../img/misc/facebook-logo.png);
					background-size: 22px 22px;

					filter: brightness(0%) invert(100%);
				}
			}
		}

		.download-demo {
			cursor: pointer;
		}
	}
	.tournament-status {
		width: 100%;
		line-height: 75px;
		background-color: #161720;
		font-size: 16px;
		font-weight: 400;
		text-transform: uppercase;
		color: #c0c6d1;

		@include mobile() {
			line-height: 30px;
			font-size: 14px;
			padding: 25px 15px 10px;

			.time-status {
				display: flex;
				justify-content: space-between;
			}
		}

		.tournament-status-data {
			color: $primary-color;
			padding-left: 10px;
			padding-right: 10px;
		}

		.tournament-status-separator {
			padding-left: 30px;
		}

		.tournament-status-container {
			max-width: 1200px;
			margin: auto;
		}
		.tournament-title {
			margin-right: 1em;
		}
	}

	.tournament-title {
		font-size: 22px;

		@include mobile() {
			font-size: 18px;
			font-weight: 500;
		}
	}

	.tournament-settings-buttons {
		position: absolute;
		top: 100px;
		right: 36px;
		text-align: right;

		.button {
			width: 124px;
		}

		.publish {
			background-color: #739900;
		}

		.unpublish {
			background-color: #a80000;
		}
	}

	.header {
		background-color: $primary-color;
		font-size: 14px;
		line-height: 40px;
		font-weight: bold;
		padding: 0px 24px;
		color: #fff;

		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.tournament-tabs {
		background-color: #1b1d26;
		text-transform: capitalize;
		font-size: 17px;
		text-align: center;
		margin: 0px auto;

		@include tablet {
			margin-bottom: 25px;
		}

		@include mobile {
			margin-bottom: 0px;
		}

		.tournament-columns {
			margin: auto;
			max-width: 1200px;
		}

		.tournament-sign-up {
			color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $primary-color;
		}
		.tournament-tab {
			display: flex;
			height: 50px;
			align-items: center;
			justify-content: center;

			background-color: $secondary-color;
			color: white;
			border-left: 1px solid #292a34;
			border-right: 1px solid #292a34;
		}
		.tournament-tab.active {
			box-shadow: inset 0 -3px 0px 0px $primary-color;
		}
		.tournament-tab-start {
			border-left: 0px;
		}
		.tournament-tab-end {
			border-right: 0px;
		}
	}

	.tournament-mobile-nav {
		$height: 47px;
		font-size: 15px;
		position: relative;
		top: -3px;
		font-weight: 500;
		text-transform: uppercase;
		.mobile-nav-tab {
			color: white;
			display: none;
			height: $height;
			background-color: $secondary-color;
			align-items: center;
			justify-content: center;
		}
		&.collapsed {
			.mobile-nav-tab {
				display: flex;
				border-bottom: 1px solid $dark-grey-border;
				&:last-child {
					border-bottom: 0;
				}
			}
		}
		.mobile-nav-tab.active {
			display: flex;
			color: $primary-color;
		}
		.dropdown-icon {
			height: $height;
			display: flex;
			align-items: center;
			position: absolute;
			top: 0;
			right: 0;
			padding: 0 30px;
		}
	}

	.tournament-details-content {
		padding-bottom: 50px;
		.tournament-details-sign-up {
			background-color: $button-color;
			color: white;
			text-transform: uppercase;
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 25px;
			height: 40px;

			&.disabled {
				background-color: gray;
				cursor: default;
			}

			&:not(.disabled) {
				cursor: pointer;

				&:hover {
					background-color: $button-hover-color;
				}
			}
		}

		@include mobile() {
			padding-bottom: 0px;
			.tournament-details-sign-up-mobile {
				margin: 30px 0;
			}
		}

		.tournament-details-boxes {
			color: #c0c6d1;
			flex: 1;
			margin: 15px;
			margin-left: 25px;
			text-transform: uppercase;

			display: flex;
			align-items: center;
			justify-content: space-between;
			text-align: center;

			.tournament-details-box {
				height: 80px;
			}

			.tournament-box-name {
				font-size: 12px;
				padding: 8px 30px;
				background-color: #1b1d26;
				text-align: center;
			}

			.tournament-box-value {
				display: block;
				padding: 10px;
				font-size: 30px;
			}
		}
		.tournament-detail-name {
			color: #191919;
			//color: white;
			float: left;
			size: 20px;
		}
		.tournament-detail-value {
			color: #7a7a7a;
			float: right;
			text-align: right;
		}
		.tournament-detail {
			overflow: auto;
			padding: 10px;
			border-bottom: 1px gray solid;
		}

		.nel-tournament-overview-button {
			display: block;
			margin: 25px;
			line-height: 40px;
			font-weight: 400;
		}

		.tournament-checkin-content {
			margin: 1em 0;
		}
	}
}

;@import "sass-embedded-legacy-load-done:94";