.team-profile-update-logo {
	position: absolute;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 56px;
	height: 56px;
	bottom: -5px;
	right: -10px;
	line-height: 56px;
	text-align: center;
	background-color: #00ace9;
	cursor: pointer;

	&.is-confirm {
		background-color: #13b913;
	}

	&.is-danger {
		background-color: #790000;
	}

	&.is-left {
		left: -10px;
	}

	> .fas {
		line-height: 56px;
	}
}

.team-profile-nel-data {
	text-align: center;

	.nel-logo {
		display: inline-block;
		width: 35px;
		height: 35px;
		vertical-align: middle;
		background-image: url(../img/nel/nel_logo.png);
		background-size: 100% 100%;
	}

	.region-name {
		margin-left: 10px;
		font-weight: 700;
		font-size: 17px;
		vertical-align: middle;
	}

	.city-name {
		vertical-align: middle;
	}
}

.team-profile-team-meta {
	position: relative;
	font-size: 16px;
	font-weight: 500;
	text-align: center;
	margin: 1.5rem 0;
	padding: 1rem 0;

	.title {
		margin-right: 0.25em;
	}

	.data {
		color: $primary-color;
	}

	&::before,
	&::after {
		content: ' ';
		position: absolute;
		left: 25%;
		height: 1px;
		width: 50%;
		border-bottom: 1px solid #ccc;
	}

	&::before {
		top: 0;
	}

	&::after {
		bottom: 0;
	}
}

.team-profile-section:not(:first-child) {
	margin-top: 32px;
}

.team-profile-heading {
	font-size: 20px;
	margin-bottom: 8px;
}

.team-profile-leader-icon {
	color: $primary-color;
}

.team-manage-actions {
	.fa-angle-double-up {
		cursor: pointer;
		color: #00ccff;
	}

	.fa-angle-double-down,
	.fa-times {
		cursor: pointer;
		color: #f00;
	}
}

.team-manage-no-friends {
	text-align: left !important;
}

.team-manage-invite-friends {
	text-align: left;
}

.manage-team-action-button {
	font-size: 12px;
	border-radius: 10px;
	height: 20px;
	line-height: 20px;
	min-height: auto;
}

.team-profile-logo-preview {
	background-position: 50% 50%;
}

.manage-team-logo-input {
	display: none;
}

.team-profile-member.selected td {
	background-color: #45570d;
}

.team-profile-no-members {
	text-align: left !important;
}

.team-profile-select-button {
	width: 76px;
}

.manage-team-settings-header {
	height: 44px;
	color: #fff;
	font-size: 18px;
	text-align: center;
	line-height: 44px;
	background-color: $primary-color;
}

.manage-team-settings {
	display: block;
	background-color: $content-background-color;
	padding: 1.5rem;

	.field {
		width: 70%;

		@include mobile() {
			width: 100%;
		}

		&:not(:first-child) {
			margin-top: 1rem;
		}

		.label {
			display: block;
			line-height: 18px;
			font-weight: 600;
		}

		.control {
			display: block;
			width: 100%;
		}
	}

	.fields {
		margin-top: 1rem;

		@include tablet() {
			display: flex;
			width: 70%;
			justify-content: space-between;

			> .field {
				width: 45%;
				margin-top: 0;
			}
		}
	}

	.save-button {
		display: block;
		margin: 25px auto 0;
		height: 45px;
		padding: 0 55px;
	}
}

;@import "sass-embedded-legacy-load-done:96";