$text-color-grey: #c6cfde;
$text-color-white: #ffffff;
$text-color-blue: #13aceb;

$base-color-background: #14151d;
$base-color-border: #2d2f3b;
$base-color-box-contrast: #20212d;
$base-color-box-selected: #282a39;
$base-color-premium: #ff8400;
$base-color-prime: #8522ff;
$base-color-error: #f6003b;

$user-bar-progress-bar-background: #2d2f3b;
$user-bar-progress-bar-color: #014de0;
$navigation-submenu-background: #171820;
$navigation-icon-color: #424658;

;@import "sass-embedded-legacy-load-done:314";