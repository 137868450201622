.team-selector-suggestion {
	display: inline-block;
	margin-right: 10px;
	padding: 5px 10px;
	cursor: pointer;
	border-radius: 10px;
}
.team-selector-suggestion:hover {
	cursor: pointer;
	background-color: #333;
}

;@import "sass-embedded-legacy-load-done:69";