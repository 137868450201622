.latest-drops-list {
	height: 100%;
	.content-heading {
		display: flex;
		margin: 20px 32px;
		@include mobile() {
			flex-direction: column;
			text-align: center;
		}
		.input-form {
			padding-top: 0px;
			padding-bottom: 0px;
			border-radius: 0px;
			border: 1px solid #2d2f3b;
			background-color: #1b1d25;
			color: $dark-grey-text-color;
		}
		.latest-drops-list-show-count-dropdown {
			margin-left: auto;
			margin-right: 8px;
			min-width: 160px;
			@include mobile() {
				margin: 6px auto;
				width: 100%;
			}
		}
		.latest-drops-list-filter-by-type-dropdown {
			margin-right: 8px;
			min-width: 180px;
			@include mobile() {
				margin: 6px auto;
				width: 100%;
			}
		}
		.latest-drops-list-search-box {
			font-size: 14px;
			@include mobile() {
				margin: 6px auto;
				width: 100%;
			}
			.text-input {
				padding-top: 12px;
				padding-bottom: 12px;
				border-radius: 0px;
				background-color: #1b1d25;
				border: 1px solid #2d2f3b;
				@include mobile() {
					width: 100%;
				}
			}
			.icon-search {
				background-image: url(../img/menu/search.png);
				background-repeat: no-repeat !important;
				background-size: 18px 18px;
				background-position: right;
				background-position-x: 97%;
			}
		}
	}
	.latest-drops-list-heading-text {
		color: $premium-color;
	}
	.latest-drops-list-content {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		margin-left: auto;
		margin-right: auto;
		justify-content: center;
		background-color: transparent;

		.latest-drop {
			height: 225px;
			width: 185px;
			margin: 6px 3px;
			text-align: center;
			font-size: 12px;
			text-overflow: ellipsis;
			background-repeat: no-repeat;
			background-position: 50% 10px;
			background-size: 120px 80px;
			background-color: #171821;
		}
		.latest-drop-text {
			width: 90%;
			padding-bottom: 8px;
			font-weight: 600;
		}
		.latest-drop-user {
			line-height: 22px;
		}
		.latest-drop-user-avatar {
			width: 22px;
			height: 22px;
		}
		.latest-drop-match {
			font-size: 13px;
			font-weight: bold;
		}
		.match-link {
			align-items: center;
			background-color: #20222e;
			color: $grey-text-color;
			flex: 1;
			font-size: 10px;
			font-weight: 500;
			justify-content: center;
			line-height: 33px;
			text-transform: uppercase;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
		}
	}
	.latest-drops-list-load-more-button {
		display: flex;
		width: 98%;
		text-align: center;
		text-transform: uppercase;
		cursor: pointer;
		line-height: 50px;
		font-size: 13px;
		font-weight: 500;
		margin: 32px auto;
		align-items: center;
		justify-content: center;
		background-color: #20222e;
		color: $primary-color;
		border: 0;
		.disabled {
			cursor: default;
		}
	}
}

;@import "sass-embedded-legacy-load-done:41";