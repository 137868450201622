.tournament-lobby-container {
	max-width: 1400px;
	margin-left: auto;
	margin-right: auto;

	&.is-1v1 {
		max-width: 1200px;

		.gather-players-username {
			color: white;
		}
	}
	.gather-chat {
		float: none;
	}
}

.tournament-lobby-header {
	position: relative;
	background-color: $content-background-color;
	margin: 18px 0px;

	@include tablet() {
		display: flex;
		justify-content: space-between;
		height: 90px;
	}
}

.tournament-lobby-header-time {
	display: inline-block;
	line-height: 90px;
	font-size: 24px;
	margin-left: 32px;
}
.tournament-lobby-header-join-button {
	position: absolute;
	display: inline-block;
	height: 35px;
	right: 32px;
	top: 50%;
	transform: translateY(-50%);

	&.leave {
		background-color: #e4454b;
	}
}

.tournament-team-tables-header {
	width: 100%;
	height: 40px;
	padding-left: 20px;
	line-height: 40px;
	font-size: 16px;
	background-color: $primary-color;
	color: #fff;

	.stub-team-card {
		display: inline-block;
		width: 65px;
	}
	.team-card-wrapper {
		color: #fff;
	}
}

.tournament-roster-invite-column,
.tournament-roster-revoke-invite-column {
	width: 32px;
}
.tournament-roster-check {
	padding-right: 15px;
}

.tournament-team-ready-check-button {
	float: right;
	height: 40px;
	background-color: #88d307;

	&:hover,
	&:focus {
		background-color: #5ec76c;
	}
}

.tournament-team-ready-check {
	height: 40px;
	line-height: 40px;
	font-size: 20px;
	padding: 0 16px;
	background: none;

	&.active {
		background-color: #88d307;
	}

	&.countdown {
		text-align: right;
	}
}

.tournament-team-ready-check-label {
	margin-right: 16px;
	font-weight: 300;
}

.tournament-lobby-popover-ready-button {
	width: 50%;
	margin-left: auto;
	background-color: #88d307;

	&:hover,
	&:focus {
		background-color: #5ec76c;
	}
}

.tournament-lobby-team-player {
	text-align: left;

	&.ready td {
		background-color: #94da81;
	}
}

.tournament-lobby-no-invite-players {
	width: 100%;
	height: 40px;
	background-color: $content-background-color;
	text-align: center;
	line-height: 40px;
}

.tournament-lobby-footer {
	position: relative;
	background-color: $content-background-color;
	margin: 18px 0px;
	padding: 16px;

	*:not(:first-child) {
		margin-top: 8px;
	}
}

.tournament-lobby-map-ballot {
	position: relative;
	background-color: $content-background-color;
	margin: 18px 0px;
	width: 100%;
}
.tournament-lobby-map-ballot-header {
	width: 100%;
	height: 64px;
	font-size: 24px;
	line-height: 64px;
	padding-left: 32px;

	.state {
		text-transform: uppercase;
	}
}
.tournament-lobby-picked-maps {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
	padding: 0px 32px 0 32px;
}
.tournament-lobby-map-vote {
	display: inline-block;
	width: 124px;
	height: 108px;
	color: #739900;
	background-color: $primary-color;
	line-height: 28px;
	font-size: 14px;
	text-align: center;
	border: 1px solid #739900;
	margin: 0 8px 16px 8px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&.banning {
		color: #a80000;
		border-color: #a80000;
	}

	&.randomizing {
		filter: sepia(100%) opacity(75%);
	}

	.question-mark {
		position: absolute;
		width: 100%;
		height: 80px;
		text-align: center;
		font-size: 42px;
		font-weight: bolder;
		line-height: 80px;
		color: #000;
	}
}
.tournament-lobby-map-vote-label {
	position: absolute;
	width: 36px;
	height: 20px;
	line-height: 20px;
	text-align: center;
	text-transform: uppercase;
	font-weight: bolder;
	background-color: $secondary-color;
}
.tournament-lobby-map-choices {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
	margin-top: 32px;
	padding-bottom: 32px;
}
.tournament-lobby-map-choices.disabled .tournament-lobby-map-choice {
	filter: sepia(100%) opacity(75%);
	cursor: default;
}
.tournament-lobby-map-choice {
	display: inline-block;
	width: 124px;
	height: 108px;
	font-size: 14px;
	color: #13aceb;
	background-color: $primary-color;
	border: 1px solid #383838;
	padding: 0;
	margin: 0px 8px 8px 8px;
	cursor: pointer;
	line-height: 28px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	text-align: center;

	&.banned {
		color: #383838;
		cursor: default;
		.tournament-lobby-map-choice-image {
			filter: sepia(100%) opacity(75%);
		}
	}

	&.empty {
		background: none;
	}

	&.cursor-default {
		cursor: default;
	}
}
.tournament-lobby-map-choice-image {
	width: 122px;
	height: 80px;
	background-size: cover;
}
.tournament-team-ready-button {
	float: left;
	margin-top: 3px;
	background-color: #94da81;
}

.tournament-team-extend-walkover {
	width: 100%;
	background-color: $button-color;
	text-transform: uppercase;
	padding: 0px 10px;
	text-align: center;
	line-height: 35px;
	min-height: 35px;
	font-weight: 500;
	font-size: 14px;
	color: #fff;
	display: inline-block;
}

.popover.tournament-lobby-created {
	padding: 0px;

	.popover-title {
		color: white;
		background-color: $button-hover-color;
		padding: 10px 0;
		text-transform: none;
	}

	.popover-content > div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		> * {
			margin: 15px 0;
		}

		.view-lobby {
			padding: 2px 30px;
		}
	}
}

.tournament-lobby-1v1 {
	max-width: 1200px;

	.button {
		border-radius: 18px !important;
	}
	.match-lobby-chat {
		.box-header {
			display: none;
		}
		.box-content {
			border-radius: 10px;
			height: unset;
		}
		.match-lobby-chat-messages {
			color: #c0c6d1;
			background-color: #1b1b27;
			min-height: 200px;
		}
		.match-lobby-chat-input-field {
			width: 100%;
			padding: 20px !important;
			background-color: #191924;
		}
		.match-lobby-chat-input-toggle,
		.match-lobby-chat-input-button {
			display: none;
		}
		.match-lobby-chat-message-type {
			display: none;
		}
	}
}

.is-1v1 {
	thead {
		background-color: #212230;
	}

	tbody {
		tr {
			background-color: #191a24 !important;
		}
	}

	.tournament-team-ready-check-button {
		border-radius: 10px;
	}
}

;@import "sass-embedded-legacy-load-done:95";