.receipt-container {
	padding: 10px;
	color: black !important;
	background-color: white !important;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;

	.receipt-header {
		width: 800px;

		.receipt-logo {
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			background-image: url(../img/logo_black.png);
			float: right;
			margin: 10px 10px;
			min-width: 273px;
			width: 273px;
			height: 75px;
		}
	}

	.receipt-footer {
		margin-top: 40px;
		width: 800px;
		text-align: center;

		.receipt-print {
			font-size: 16px;
			line-height: 28px;
			cursor: pointer;
			color: #fff;
			background-color: #00a9e9;
			padding: 10px;
		}
	}

	.content-heading {
		color: black !important;
	}

	table {
		max-width: 800px;
	}

	table,
	thead,
	tbody,
	tr,
	td,
	th {
		color: black;
		background-color: white;
	}
	th {
		text-align: left;
		border-bottom: 2px solid #000;
	}
	.vat-computation {
		border-top: 1px solid #333;
	}
	td {
		height: inherit !important;
		text-align: left;
	}

	.receipt-vat {
		display: flex;
		justify-content: space-between;
	}
	.text-right {
		text-align: right;
	}
}

@media print {
	.receipt-footer {
		display: none;
	}
}

;@import "sass-embedded-legacy-load-done:102";