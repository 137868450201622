.tournament-checkin-popover {
	width: 640px;
	max-width: 100%;
	padding: 0px;

	.popover-title {
		color: white;
		background-color: $button-hover-color;
		padding: 10px 0;
		text-transform: none;
	}

	.popover-content {
		padding: 0;
	}

	.tournament-checkin-content {
		padding: 20px 64px;
	}

	.tournament-banner {
		width: 100%;
		height: 200px;
		background-size: contain;
	}

	.actions {
		padding-bottom: 20px;
	}

	button {
		padding: 0px 30px;
	}

	.button-decline {
		background-color: #f4555b;
	}

	.button-close {
		background: rgb(81, 81, 81);
	}
}

;@import "sass-embedded-legacy-load-done:120";