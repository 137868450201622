@import '../styles/colors';
@import '../styles/fonts';

.es-textfield {
	.block {
		width: 100%;
		.es-textfield-input {
			display: flex;
			width: 100%;
		}
	}
	.has-icons-left {
		.es-textfield-input {
			padding-left: 2.5em;
			.icon {
				color: $text-color-grey;
			}
		}
	}
	.has-icons-right {
		.es-textfield-input {
			padding-right: 2.5em;
			.icon {
				color: $text-color-grey;
			}
		}
	}
}
.es-textfield-label {
	display: block;
	margin-bottom: 9px;
	font-size: 14px;
	line-height: 19px;
	text-align: left;
}
.es-textfield-input {
	background: $base-color-background;
	border: 1px solid $base-color-border;
	outline: none;
	color: $text-color-grey;
	height: 40px;
	padding: 0px 14px;
	border-radius: 4px;
	width: 242px;
	font-size: 14px;
	font-family: #{$font-family};
}
.es-textfield-input.es-textfield-input-error {
	border-color: $base-color-error;
}
.es-textfield-input:focus {
	border-color: $text-color-blue !important;
	background: $base-color-box-contrast !important;
}
.es-textfield-error {
	margin-top: 7px;
	color: $base-color-error;
	font-size: 11px;
}
