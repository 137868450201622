.footer {
	width: 100%;
	height: 190px;
	background-color: #161720;
	position: absolute;
	bottom: 0;

	@include mobile() {
		max-height: 170px;
	}
	@include tablet() {
		height: 300px;
	}
	@include desktop() {
		height: 260px;
	}
	@include widescreen() {
		height: 230px;
	}
}

.footer-logo {
	text-align: center;
	width: 130px;
	height: 80px;
	background-image: url(../img/logo.png);
	background-size: cover;
	margin-right: 1em;
}

.footer-center {
	text-align: center;
	color: $grey-text-color;

	@include mobile() {
		&::before {
			/* Divider */
			content: ' ';
			background-color: #204a5b;
			border: none;
			display: block;
			height: 1px;
			margin-top: 1em;
			margin-bottom: 1em;
		}
		order: 3;
	}
}

.footer-links {
	line-height: 35px;
	min-height: 35px;
	text-transform: uppercase;
	padding-bottom: 2px;
	margin-bottom: 2px;
	border-bottom: 1px solid #373844;
	font-size: 13px;
}

.footer-link {
	display: inline-block;
	color: $grey-text-color;
	font-weight: 600;
	padding: 0px 15px;
	cursor: pointer;
	border-top: 5px solid transparent;
	transition: all 0.15s linear;
}

.footer-link:hover {
	border-top: 5px solid $primary-hover-color;
}

.footer-copyright {
	font-size: 15px;
	font-weight: 500;
	padding: 1em;
}

.footer-social {
	padding-top: 15px;

	a {
		display: inline-block;
		margin-left: 10px;
		width: 27px;
		height: 27px;
		transition: opacity 0.25s linear;
	}

	a:hover {
		opacity: 0.6;
	}

	@include mobile() {
		order: 2;
	}
}

@include mobile() {
	.footer-columns {
		display: flex;
		flex-flow: column;
	}

	.footer-mobile-links {
		margin-bottom: 1em;

		li {
			background-color: #062a38;
			color: #02aaef;
			margin-top: 0.1em;
			padding: 0.4em 0em 0.4em 1em;
			text-transform: uppercase;

			&.footer-more-info {
				color: #ccc;
				margin-top: 0em;
				padding-top: 0.5em;
				padding-bottom: 0.5em;
			}
		}

		.footer-caret {
			float: right;
			padding-right: 1em;
		}
	}
}

.icon-vk {
	background-image: url(../img/socialmedia/vk_icon.png);
}

.icon-insta {
	background-image: url(../img/socialmedia/insta_icon.png);
}

.icon-fb {
	background-image: url(../img/socialmedia/fb_icon.png);
}

.icon-steam {
	background-image: url(../img/socialmedia/steam_icon.png);
}

.icon-twitter {
	background-image: url(../img/socialmedia/twitter_icon.png);
}

.icon-youtube {
	background-image: url(../img/socialmedia/youtube_icon.png);
}

;@import "sass-embedded-legacy-load-done:47";