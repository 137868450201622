@import '../components/spokenlanguagesselector';

.settings-page {
	input:not([type='checkbox']),
	select {
		width: 100%;
	}

	.submit {
		@include mobile() {
			justify-content: center;
		}

		.submit-button {
			padding: 0.5em 1em;

			@include mobile() {
				width: 50%;
			}
		}
	}

	.day-selector {
		display: flex;
		justify-content: space-between;

		select {
			width: 30%;
		}
	}

	.elitserien {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 80px;

		.icon-secsgo {
			width: 45px;
			height: 45px;
			min-width: 45px;
			margin-right: 10px;
		}
	}

	.nel-city-selector {
		display: flex;
		justify-content: space-between;

		.nel-city-field {
			width: 48%;
		}
	}

	.verify-account {
		max-width: 600px;
		margin: auto;
		.box-header {
			background-color: transparent;
			margin: 0;
		}
		.box-content {
			border: 0;
		}

		.fa-exclamation-triangle {
			color: red;
			font-size: 18px;
			margin-right: 11px;
			vertical-align: middle;
		}

		input::placeholder {
			color: #acacac;
		}
	}

	button {
		margin-top: 10px;
	}

	.right-side {
		.button {
			min-width: 8em;
			font-size: 13px;
			font-weight: 600;
			margin-top: 10px;
			margin-bottom: 10px;

			&.button-premium {
				width: auto;
				border-radius: 0;
			}
		}
	}

	.reset-stats-premium {
		display: flex;
		align-items: center;

		.button {
			margin-right: 10px;

			.icon-premium {
				margin-right: 10px;
			}
		}
	}

	.subtext {
		font-size: 12px;
		padding: 5px;
		padding-left: 0;
	}
	.premium-color {
		color: $premium-color;
	}
	.find-out-more {
		background-color: $premium-color;
		line-height: 20px;
		height: 20px;
		min-height: auto;
		font-size: 9px;
		font-weight: 600;
		margin: 5px;
	}

	.accept-name-conditions {
		display: flex;
		align-items: center;
		padding-top: 10px;
		padding-bottom: 10px;

		input {
			margin-right: 10px;
		}
	}
	.mute-sound-checkbox {
		display: flex;
		align-items: center;
		padding-top: 10px;
		padding-bottom: 10px;
		vertical-align: middle;

		input {
			margin-right: 10px;
		}
		label {
			padding-bottom: 0 !important;
		}
		#change-volume {
			font-size: 22px;
			cursor: pointer;
			width: 34px;
			height: 34px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	label:not(.basic-text) {
		display: block;
		font-weight: 500;
		padding-bottom: 10px;
		text-transform: capitalize;
	}

	.connect-company {
		font-weight: 600;
		font-size: 16px;
		display: flex;
		align-items: center;
		padding-bottom: 10px;

		.logo {
			height: 30px;
			background-size: contain;
			width: 30px;
			background-repeat: no-repeat;
			margin-right: 10px;
			background-position: center;
		}
		.lemondogs {
			background-image: url(../img/logo/lemondogs_logo_nooutline.png);
		}
		.twitch {
			background-image: url(../img/logo/TwitchGlitchPurple.png);
		}
		.steam {
			background-image: url(../img/logo/steam.png);
		}
		.twitter {
			background-image: url(../img/logo/twitter.png);
		}
	}

	.field {
		padding: 3em;
		padding-top: 0;
	}
	.field:last-child {
		padding-bottom: 0;
	}

	.tabs {
		font-size: 14px;
		font-weight: 500;
		background-color: transparent;
		border: none;
		display: flex;
	}
	.tab {
		display: inline-block;
		margin: 0;
		margin-right: 0.2em;
		font-size: 16px;
		padding: 30px 10px;
		cursor: pointer;
		color: #fff;
		transition: background-color 0.25s ease-in-out;
		background-color: $secondary-inactive-color;
		border-radius: 0;

		@include tablet() {
			padding: 30px;
		}
		@include mobile() {
			flex: 1;
		}
	}
	.tab:hover {
		background-color: #1e1f27;
	}
	.tab.active {
		background-color: $secondary-color;
		color: $primary-color;
	}
	.tab-content {
		background-color: $secondary-color;
		padding: 3em 1em;

		.columns {
			margin-top: 0;
		}

		.column {
			padding-top: 0;
		}

		.column:first-child {
			border-right: 0;

			@include tablet() {
				border-right: 1px solid #353841;
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:81";