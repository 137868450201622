@import '../../../../../css/responsiveness.scss';

.downloadClientContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	h1 {
		margin-top: 0;
		margin-bottom: 32px;
	}

	h3 {
		margin-bottom: 16px;
	}
}

.downloadClientAfter {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 2em;
}

.downloadClientSteps {
	border-radius: 4px;
	display: flex;
	padding: 0;
	max-width: 65%;
	justify-content: space-around;
	margin-bottom: 32px;

	@include isMobile() {
		max-width: 100%;
	}
}

.downloadClientStep {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 25%;

	> i {
		width: 60px;
		height: 60px;
		border-radius: 50%;
		background-color: #1f2154;
		color: #00a9e9;
		font-size: 24px;
		line-height: 60px;
		text-align: center;
	}

	> div {
		&:nth-child(2) {
			font-size: 16px;
			margin: 16px 0px;
		}

		&:nth-child(3) {
			color: #ffffff;
			font-weight: 300;
			font-size: 12px;
			line-height: 150%;
			text-align: center;

			> a {
				cursor: pointer;
				color: #13aceb;
				font-weight: 400;
			}
		}
	}
}

.downloadClientStepArrow {
	position: relative;
	top: 25px;
	width: 0px;
	height: 0px;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-left: 15px solid #fff;
}

.downloadClientRetry {
	font-size: 12px;
	font-weight: normal;

	> span {
		cursor: pointer;
		color: #13aceb;
	}
}

.downloadClientIntro {
	display: flex;
	flex-direction: row;
	height: 182px;
	align-items: center;
	justify-content: center;
	margin-bottom: 32px;
	margin-left: 16px;
	margin-right: 16px;

	> ul {
		flex-shrink: 1;
		max-width: 272px;

		> li {
			font-weight: 300;

			+ li {
				margin-top: 16px;
			}
		}
	}
}

.downloadClientLogo {
	background-image: url(../../../../../img/esac.png);
	width: 125px;
	height: 125px;
	background-size: cover;
	background-repeat: no-repeat;
	margin-right: 24px;

	@include isMobile() {
		display: none;
	}
}

.downloadClientIntroButtons {
	display: flex;
	flex-direction: column;

	> button {
		&:first-child {
			width: 200px;
		}

		&:nth-child(2) {
			width: 200px;
		}

		+ button {
			margin-top: 16px;
		}
	}
}

.downloadClientSkipButtonHide {
	visibility: hidden;
}
