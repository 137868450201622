.premium-drops-counter {
	.premium-drops-counter-title {
		font-size: 40px;
		font-weight: 300;
		text-align: center;
		.premium {
			color: $premium-color;
		}
		.drops {
			color: #fff;
		}
	}

	.premium-drops-container {
		background-image: url(../img/drops/premiumdropbox.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		margin: auto;
		width: 260px;
		height: 165px;
		.premium-drops-container-counter {
			color: #f2943c;

			background: linear-gradient(0deg, #292929 63%, #353535 63%);
			padding: 10px;
			margin-left: auto;
			margin-right: auto;
			position: relative;
			top: 49px;
			font-size: 52px;
			font-weight: 400;
			width: 75%;
			border-radius: 10px;
		}
	}

	.premium-drops-breakdown-container {
		margin: 18px auto 8px auto;
		width: 250px;
		.premium-drops-breakdown {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin: 12px auto 12px auto;
			.premium-drops-breakdown-counter {
				color: #f2943c;

				background-color: #35353594;
				line-height: 50px;
				border-radius: 10px;
				min-width: 135px;
				font-weight: 400;
				font-size: 27px;
				margin-right: 16px;
			}
			.premium-drops-breakdown-highlight {
				color: #f2943c;
				margin: 4px;
			}
		}
	}
	.premium-drops-this-month {
		font-size: 20px;
	}

	.premium-drops-counter-overlay {
		position: relative;
		width: 100%;
		height: 50%;
		background-color: #00000062;
	}
}

;@import "sass-embedded-legacy-load-done:66";