$primary-color: #ed9521;

.legends-league-root {
	&.landing-page-wrapper {
		padding: 0 65px;
		background-color: #000;

		@media only screen and (max-width: 710px) {
			padding: 0 20px;
		}
	}

	.first-screen {
		margin: 0 -65px;
		padding-top: 53px;
		background-image: url(./assets/landingheader2.jpg);
		background-position: center bottom;
		background-size: cover;

		.big-logo-picture {
			display: block;
			width: 610px;
			max-width: 50%;
			margin: 0 auto 53px;
		}

		@media only screen and (max-width: 710px) {
			margin: 0 -20px;
		}
	}

	.logo-pictures-list {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 25px -15px 0;

		.logo-picture {
			width: 90%;
			max-width: 300px;
			max-height: 205px;
		}

		@media only screen and (max-width: 840px) {
			flex-wrap: wrap;
			text-align: center;

			.logo-picture-item {
				width: 50%;
				padding: 20px 0;
			}

			.logo-picture {
				width: auto;
				max-width: 100%;
			}
		}
	}

	.important-questions {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 85px;

		.question-item {
			width: calc(33% - 35px);
			padding: 26px 20px;
			border-radius: 6px;
			background-color: #101010;
		}

		.question-item-title {
			position: relative;
			margin-bottom: 44px;
			text-align: center;
			font-size: 24px;
			line-height: 30px;
			text-transform: uppercase;
			font-weight: 700;

			&:after {
				position: absolute;
				left: 35%;
				bottom: -21px;
				width: 30%;
				height: 2px;
				content: '';
				background-color: #494a57;
			}
		}

		.question-item-highlight {
			color: $primary-color;
			background-color: transparent;
		}

		.question-item-text {
			margin: 0;
			padding-bottom: 26px;
			font-size: 16px;
			line-height: 26px;
			color: #fff;
			font-weight: 300;
			text-align: center;
		}

		@media only screen and (max-width: 1800px) {
			.question-item {
				width: 32.5%;
			}
		}

		@media only screen and (max-width: 1550px) {
			.question-item-title {
				font-size: 20px;
				line-height: 24px;
			}

			.question-item-text {
				font-size: 14px;
				line-height: 20px;
			}
		}

		@media only screen and (max-width: 1550px) {
			display: block;

			.question-item {
				display: block;
				width: 300px;
				max-width: 100%;
				margin: 30px auto 0;
				padding: 15px;
			}
		}
	}

	.second-screen {
		.play-now-btn {
			display: block;
			width: 362px;
			max-width: 100%;
			margin: 36px auto 0;
			padding: 15px 0;
			font-weight: 400;
			font-size: 18px;
			line-height: 18px;
			text-align: center;
			text-transform: uppercase;
			background: transparent linear-gradient(180deg, #ed9521 0%, #c16508 100%) 0% 0% no-repeat;

			&:hover {
				background: transparent linear-gradient(180deg, #c16508 0%, #ed9521 100%) 0% 0% no-repeat;
			}
		}
	}

	.page-title {
		margin-top: 90px;
		text-align: center;
		font-size: 25px;
		color: $primary-color;
		text-transform: uppercase;

		.highlighted-text {
			color: #fff;
			background-color: transparent;
		}
	}

	.ambassadors-screen {
		.ambassadors-list {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			margin-top: 47px;
		}

		.ambassador-item {
			width: 184px;
			text-align: center;
		}

		.ambassador-picture {
			width: 120px;
			border-radius: 5px;
		}

		.ambassador-name {
			margin-top: 10px;
			color: $primary-color;
		}

		@media only screen and (max-width: 1880px) {
			.ambassadors-list {
				max-width: 550px;
				margin: 47px auto 0;
			}

			.ambassador-item {
				flex-basis: 33%;
				width: auto;
				max-width: 50%;
				margin-top: 30px;
			}
		}

		@media only screen and (max-width: 490px) {
			.ambassadors-list {
				width: 360px;
			}

			.ambassador-item {
				flex-basis: 100%;
			}
		}
	}

	.participants-table {
		.content-heading {
			display: none;
		}

		.column {
			padding: 0;
		}

		.button {
			font-weight: 400;
			text-align: center;
			text-transform: uppercase;
			background: transparent linear-gradient(180deg, #ed9521 0%, #c16508 100%) 0% 0% no-repeat;

			&:hover {
				background: transparent linear-gradient(180deg, #c16508 0%, #ed9521 100%) 0% 0% no-repeat;
			}
		}
	}

	.faq {
		&-screen {
			padding-bottom: 180px;

			@media only screen and (max-width: 710px) {
				padding-bottom: 20px;
			}
		}

		&-list {
			max-width: 735px;
			margin: 0 auto;
			padding-top: 15px;
		}

		&-item {
			margin-bottom: 14px;
			padding: 18px 24px;
			background-color: #101010;
			font-weight: 400;
			border-radius: 4px;
		}

		&-question {
			margin: 0;
			font-size: 16px;
			line-height: 25px;
			color: $primary-color;
			font-weight: 400;
		}

		&-answer {
			margin: 5px 0 0;
			font-size: 14px;
			color: #fff;
		}

		&-email-address {
			color: $primary-color;
		}
	}
}

.trial-period {
	position: fixed;
	top: 288px;
	z-index: 1;
	margin: auto 8.5%;
	max-width: 50%;
}

.trial-period-blurred {
	background: rgba(25, 26, 33, 0.5);
	backdrop-filter: blur(30px);
	background: rgba(25, 26, 33, 0.5);
	filter: blur(5px);
	-webkit-filter: blur(5px);
}

.player-section {
	display: flex;
}

.ladders-table-striped {
	thead {
		background-color: #191a24b3;
		* {
			color: #ed9521;
		}
		& > tr {
			th:first-child {
				padding-left: 22px;
				width: 30px;
			}

			th:last-child {
				padding-right: 8px;
				display: flex;
				justify-content: center;
			}

			th:nth-child(2) {
				width: 20px;
			}
		}
	}
	tbody > tr {
		td:first-child {
			padding-left: 22px;
			width: 30px;
		}

		td:last-child {
			padding-right: 8px;
			display: flex;
			justify-content: center;
		}

		td:nth-child(2) {
			max-width: 38px;
			.country-flag {
				transform: scale(0.5);
				margin: 0 8px;
			}
		}

		td.prizes-column {
			color: #ed9521;
		}
	}
	tbody > tr:nth-child(odd) {
		background-color: transparent;
	}

	tbody > tr {
		background-color: #191a24b3;
	}

	th {
		border: none;
	}
	td {
		border: none;
	}

	&.table-button-color th {
		border-left: none;
		border-right: none;
	}
}

.load-more-button {
	margin: 0 auto;
	padding: 12px 100px;
	background: linear-gradient(to bottom, #ed9521, #c16508);
	width: 280px;
	word-break: keep-all;
	font-size: 14px;
	font-weight: bold;
	border-radius: 4px;
}
