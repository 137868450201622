.action-notification-entry {
	position: relative;
	height: 75px;
	background-color: #fafafa;
	border-bottom: 1px solid #ccc;
	line-height: 14px;
	text-align: left;

	&.team-invite {
		height: 105px;
	}
}
.action-notification-entry-image {
	position: absolute;
	left: 7px;
	top: 7px;
	height: 25px;
	width: 25px;
	border: 2px solid $button-color;
}
.action-notification-entry-title {
	position: absolute;
	top: 13px;
	left: 40px;
	width: calc(100% - 195px);
	font-weight: 500;
}
.action-notification-entry-description {
	position: absolute;
	top: 38px;
	left: 7px;
	width: calc(100% - 120px);
	font-size: 12px;
}
.action-notification-entry-actions {
	position: absolute;
	right: 0px;
	top: 0px;
	bottom: 0px;
	height: 100%;
	width: 120px;
	&.team-invite {
		top: 15px;
	}
}
.action-notification-entry-action {
	color: #fff;
	margin: 7.5px 10px;
	height: 25px;
	line-height: 25px;
	border-radius: 5px;
	text-align: center;
	font-weight: 700;
	cursor: pointer;
	transition: background-color 0.15s linear;

	&.accept {
		background-color: #0c0;
	}
	&.accept:hover {
		background-color: #0a0;
	}
	&.decline {
		background-color: #c00;
	}
	&.decline:hover {
		background-color: #a00;
	}
}

.top-bar-activity-notifications-header {
	line-height: 40px;
	text-align: left;
	font-weight: 500;
	font-size: 12px;
	background-color: #1f212a;
	display: flex;
	align-items: center;

	.top-bar-activity-icon {
		display: inline-block;
		margin-left: 10px;
		margin-right: 5px;
	}
}
.activity-notification-entry {
	position: relative;
	min-height: 45px;
	background-color: #fafafa;
	border-bottom: 1px solid #ccc;
	line-height: 14px;
	text-align: left;
	display: flex;

	&.no-title {
		min-height: 0;
	}
}
.activity-notification-entry-type-3,
.activity-notification-entry-type-4 {
	.activity-notification-entry-image {
		background-image: url(../img/menu/challenges.png);
	}
}
.activity-notification-entry-image {
	flex-shrink: 0;
	margin: 10px 7px;
	height: 25px;
	width: 25px;
	font-size: 14px;
	text-align: center;
	line-height: 25px;
	background-size: cover;
	color: #6e7181;

	&.avatar {
		border: 2px solid $button-color;
	}
	.activity-notification-icon {
		width: 25px;
		height: 25px;
		border-radius: 50%;
		background-color: #32333e;
		line-height: 25px;
	}
}
.activity-notification-entry-content {
	display: flex;
	flex-direction: column;
	padding-left: 5px;
}
.activity-notification-entry-title {
	margin-top: 10px;
	font-weight: 500;
}
.activity-notification-entry-description {
	padding-top: 5px;
	padding-bottom: 5px;
	font-size: 12px;
}
.activity-notification-entry-description-only {
	padding-top: 10px;
	padding-bottom: 5px;
}
.activity-notification-entry-datetime {
	margin-bottom: 5px;
	font-size: 10px;

	.category {
		margin-right: 5px;
	}
}

.chat-notification-entry {
	position: relative;
	height: 75px;
	background-color: #fafafa;
	border-bottom: 1px solid #ccc;
	line-height: 14px;
	text-align: left;
	cursor: pointer;

	&.unseen {
		background-color: #e3f0f5;
	}
}
.chat-notification-entry-image {
	position: absolute;
	left: 7px;
	top: 38px;
	height: 25px;
	width: 25px;
	border: 2px solid $button-color;
}
.chat-notification-entry-title {
	position: absolute;
	top: 13px;
	left: 7px;
	font-weight: 500;
}
.chat-notification-entry-description {
	position: absolute;
	top: 42px;
	left: 40px;
	font-size: 12px;
	width: calc(100% - 40px);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.chat-notification-entry-datetime {
	position: absolute;
	bottom: 2px;
	right: 5px;
	font-size: 10px;
}

.notification-missing-trade-link {
	font-weight: 900;
	color: #f00;
	text-decoration: underline;
}

.activity-notification-super-match-drop {
	margin: 0 auto;
	.match-drop-super {
		width: 191px;
		height: 62px;
		margin: 15px auto 5px auto;
	}
	.match-drop-item-image {
		margin: 5px auto;
		width: 220px;
		height: 120px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: 50% 50%;
	}
	.match-drop-item-user {
		text-align: center;
		line-height: 20px;
	}
	.match-drop-item-description {
		text-align: center;
		font-size: 13px;
		line-height: 18px;
		padding: 5px 15px;

		.premium-color,
		a {
			font-weight: 500;
		}
	}
}

.activity-notification-rare-match-drop {
	margin: 0px 12px;

	.match-drop-activity-text {
		padding: 8px 0px 8px 45px;
		margin-bottom: 8px;
		background-size: 35px 20px;
		line-height: 24px;
		background-position: 0px 50%;
		border-bottom: 1px solid #2c2c2c;
		font-weight: 600;
		font-size: 12px;
	}
	.match-drop-item-image {
		float: left;
		margin-right: 12px;
		width: 67px;
		height: 58px;
		background-color: #1a1a1a;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: 50% 50%;
	}
	.match-drop-item-user {
		text-align: left;
		font-size: 12px;
		line-height: 20px;

		.table-avatar {
			width: 20px;
			height: 20px;
		}
		.table-rank {
			width: 30px;
			height: 30px;
		}
	}
	.match-drop-item-description {
		text-align: left;
		font-size: 13px;
		line-height: 18px;
		padding: 2px 15px;

		.premium-color,
		a {
			font-weight: 500;
		}
	}
}

;@import "sass-embedded-legacy-load-done:24";