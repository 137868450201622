body.theme-dark:not(.disable-theme-dark) {
	color: #fff;
	background-color: #121212;

	select,
	input,
	.file-input,
	textarea {
		background-color: $content-background-color;
		color: $grey-text-color;
		border-color: #2d2f3b;
	}
	input[type='checkbox'] {
		&:checked {
			border-color: $primary-color;
		}
		&:disabled {
			border-color: #2d2f3b;
		}
	}
	select::placeholder,
	input::placeholder,
	textarea::placeholder {
		color: $dark-grey-text-color;
	}
	select:focus,
	input:focus,
	textarea:focus,
	select:active,
	input:active,
	textarea:active {
		background-color: $secondary-inactive-color;
		//border-color: #888;
	}

	.content-heading {
		color: $white-text-color;

		&.lemondogs {
			color: #f5cb21;
		}
	}

	.button.disabled,
	button:disabled {
		background-color: #434343;
	}
	.button-two-tone {
		border-color: #00a9e9;
	}

	.table-striped {
		tr:nth-child(even) {
			background-color: rgba(25, 25, 25, 0);
		}
		tbody > tr:nth-child(odd) {
			background-color: #191a24b3;
		}

		th {
			border: none;
		}
		td {
			border: none;
		}

		&.table-button-color th {
			border-left: none;
			border-right: none;
		}
	}

	.table-separated {
		tr {
			background-color: transparent;
			border-bottom: 1px solid $dark-grey-border;
			border-left: none;
			border-right: none;
			border-top: none;
		}
	}
	.gather-table .table-separated tr {
		border-bottom: 2px solid #1d1f2a;

		&.star-gather-top-row,
		&.verified-gather-top-row,
		&:last-child {
			border-bottom: 0;
		}
		&.star-gather-top-row {
			border-bottom: none;
		}
	}
	.checkout-table {
		th {
			color: #fff;
		}
	}

	#main-content-background {
		//background-image: url(../img/backgrounds/dark.png);
		background-color: #14151d;
	}
	.home-live-streamz,
	.home-youtube-videos {
		&.no-content {
			background-color: $card-background-color;
		}
	}
	.stats-content .top-players-nav .selected,
	.gather-table-filter .selected {
		color: #fff;
		text-transform: uppercase;
	}

	.timeline-Widget {
		background: transparent;
	}

	#matchmaking-unranked-notice {
		background-color: $primary-color;
	}
	#matchmaking-map-selector-instructions {
		background-color: #212121;
	}

	.stats-bar-dropped-icon {
		filter: invert(1);
	}
	.stats-bar-username {
		color: $primary-hover-color;
	}

	#invite-friends-or-line {
		background-color: $primary-color;
	}
	#invite-friends-or-circle {
		color: $primary-color;
		background-color: $content-background-color;
	}

	.user-avatar,
	.user-profile-avatar,
	.user-profile-favorite-map,
	.user-profile-favorite-weapon,
	.user-profile-steam-link,
	.user-profile-lemondogs-link {
		border-color: $primary-hover-color;
	}
	.user-profile-username {
		color: #fff;
	}
	.user-profile-thumbs {
		color: #fff;

		> .fas {
			color: #0375a3;
		}

		.mvps {
			color: #fff;
		}
	}

	.user-profile-activity-list-stripe {
		filter: invert(1);
	}
	.user-profile-team-info > .content {
		background-color: $secondary-color;
	}

	#ranking-self-row {
		background-color: #191a24b3;
		color: #fff;
		border-bottom: 1px solid #13aceb;

		.player-card-wrapper {
			color: #13aceb;
		}
	}
	#ranking-find-player-input {
		background-color: $content-background-color;
		color: #fff;
		border-color: #14151d;
	}

	.support-ticket-chat .box-content {
		border-color: #646464;
		border: 1px solid #646464;
	}
	.support-ticket-chat-input-bar {
		border-color: #646464;
	}

	.match-lobby-chat-input-field {
		border: 0;
		border-radius: 0;
		background-color: #1f1f29;
	}

	.match-lobby-admin-options {
		background-color: $content-background-color;
	}

	.box-content,
	.box-content-no-padding {
		background-color: $card-background-color;
		border-color: #646464;
	}

	#top-bar-search-form::before {
		border-bottom-color: $content-background-color;
	}
	.top-bar-search-result {
		color: #fff;
		background-color: $content-background-color;
		border-color: #262833;

		&.hover,
		&:hover {
			background-color: #282a36;
		}
	}

	.top-bar-notification-dropdown,
	.activity-notification-entry,
	.action-notification-entry {
		color: #fff;
		background-color: $notifications-background-color;
		border-color: #2d2d38;

		&.unseen {
			background-color: #282a36;
		}
	}
	.chat-notification-entry {
		color: #fff;
		background-color: #15151d;
		border-color: #1b1d26;

		&.unseen {
			background-color: #282a36;
		}
	}

	.top-bar-notification-dropdown::before {
		border-bottom-color: #646464;
	}

	.match-summary-logo {
		filter: invert(1);
	}
	.match-summary-item {
		.label {
			@include mobile() {
				color: $button-color;
			}
		}
	}
	.match-summary-player-stats .player-stat {
		background-color: $table-header-background-color;

		@include mobile() {
			&:nth-child(3n),
			&:nth-child(3n-1) {
				background-color: #191a24;
			}
		}
	}
	//.match-summary-team-tables tr.highlight-self td {
	//border-bottom: 1px solid #13aceb;
	//border-top: 1px solid #13aceb;
	//}

	.consent {
		border-color: #444;
		background-color: $secondary-color;
	}

	.live-games-twitch-icon,
	.twitch-icon {
		filter: invert(1);
	}

	.queue-button {
		background-color: $primary-color;
	}
	.queue-side-column > .item {
		background-color: #14151da1;
	}

	.queue-side-column > .label {
		background-color: #14151da1;
	}

	.map-selector-item-label .button.disabled {
		color: #434343;
		background-color: #000 !important;
	}

	.level-popover-progress-stat {
		color: #000;
	}

	#rank-popover .rank-list {
		background-color: #1c1c1c;
	}

	.player-card {
		background-color: #232632;
	}
	.player-card-lower-bottom {
		background-color: #232632;
	}
	.player-card-label {
		color: $grey-text-color;
	}
	.player-card-lws > .fa-trophy {
		color: #5ec76c;
	}
	.player-card-lls > .fa-trophy {
		color: #e4454b;
	}

	.user-profile-ban-duration {
		color: #fff;
		background-color: #2f2f2f;
	}

	.player-card-wins,
	.player-card-win-streak,
	.player-card-losses,
	.player-card-lose-streak {
		color: #000;
	}

	.create-gather-map,
	.tournament-lobby-map-choice,
	.tournament-lobby-map-vote {
		color: $primary-hover-color;
		background-color: #000;

		&.selected {
			color: #fff;
			background-color: $primary-hover-color;
			border-color: $primary-hover-color;
		}

		&.disabled,
		&.banned {
			color: #383838;
		}

		&.empty {
			background: none;
		}
	}
	.create-gather-map,
	.tournament-lobby-map-choice {
		border: 1px solid #383838;
	}
	.solid-background {
		margin-top: 10px;
		background-color: $secondary-color;
	}
	.gather-creator-user-label {
		color: #fff;
	}
	.gather-status-icon.playing {
		filter: none;
	}

	.gather-no-waiting-players {
		background-color: $content-background-color;
	}

	.table-microphone {
		filter: invert(0);
	}
	.gather-table-microphone {
		filter: invert(0);
	}
	.gather-microphone {
		filter: invert(0);
	}

	.gather-group-dialog {
		background-color: $content-background-color;
	}

	.friend-open-chat {
		color: #fff;
	}
	.friend-column.col-chat .friend-open-chat {
		color: $primary-hover-color;
	}

	.gather-creator-row td {
		background-color: #010513;
	}

	.icon-gather-group {
		filter: brightness(1);
	}

	.gathers-player-table tr {
		&.accepted td {
			background-color: #45570d;
		}
		&.declined td {
			background-color: #570c23;
		}
	}

	.gather-group-friends-invite {
		background-color: $content-background-color;
	}

	.gather-swap-average-rank {
		border: 1px solid #2c2c2c;
	}

	.gather-chat-input-field {
		&:disabled {
			background-color: rgb(29, 29, 29);
		}
	}

	.gather-mute-cell button {
		color: #fff;
	}

	.esac-step-content {
		color: #fff;
	}

	.user-stream-page {
		background-color: #121212;

		.actions-section {
			background-color: #232323;
			border-top: 1px solid $content-background-color;
		}
	}

	.icon-minimize {
		filter: invert(1);
	}
	.icon-maximize {
		filter: invert(1);
	}

	.team-card {
		.team-card-rating-name,
		.team-card-lws,
		.team-card-lls {
			color: #fff;
		}

		.team-card-match-date {
			color: white;
			font-weight: 600;
		}
	}

	.create-tournament-info {
		color: #000;
		background-color: #fff;
	}
	.create-tournament-map-selection hr {
		background-color: #3b3b3b;
	}

	.team-profile-nel-data {
		color: #fff;
	}

	.team-profile-team-meta {
		color: #fff;

		&::before,
		&::after {
			border-bottom: 1px solid #414141;
		}
	}

	.team-profile-achievements,
	.user-profile-tournament-achievements {
		.logo {
			background-color: $content-background-color;
		}

		.achievements-info {
			background-color: #1a1a1a;

			.placement {
				background-color: #1a1a1a !important;
			}

			.date {
				color: #c7c7c7;
			}
		}
	}

	.manage-team-settings {
		background-color: $card-background-color;
	}

	.tournament-page-content {
		.tournament-bracket-content {
			.tournament-bracket-root-layout {
				$line-color: #464650;

				.tournament-bracket-node {
					.tournament-bracket-team-divider {
						background-color: #1a1d26;
						.tournament-bracket-team-score {
							background-color: #2a2a34;
							color: #fff;
						}
						.tournament-bracket-winner {
							background-color: $primary-color;
						}
					}
					.tournament-bracket-team-divider:first-child {
						margin-bottom: 0px;
					}
					.tournament-bracket-team-divider:last-child {
						margin-top: 0px;
					}
				}

				.tournament-bracket-horizontal-line {
					background-color: $line-color;
				}
				.tournament-bracket-vertical-line {
					background-color: $line-color;
				}
			}

			.tournament-no-brackets-container {
				border: none;
				background-color: $content-background-color;
			}
		}
		.tournament-rules-content {
			.tournament-rules-container {
				border: none;
				background-color: $content-background-color;
			}
		}
		.tournament-details-content {
			color: white;

			.tournament-details-box {
				.tournament-active-box-value {
					color: $button-color;
				}
			}

			.tournament-detail-name {
				color: white;
			}
		}
	}

	.tournament-lobby-header,
	.tournament-lobby-footer,
	.tournament-lobby-map-ballot {
		background-color: $content-background-color;
	}

	.top-bar-notifications-dropdown {
		&::before {
			border-bottom-color: $notifications-background-color;
		}
	}

	.levels-page {
		.level-info {
			.level-data {
				.progress-wrapper {
					.progress {
						@for $i from 1 through 20 {
							&.is-completed-#{$i} {
								$color: get-level-colors($i);

								.mission-completed {
									color: nth($color, 1);
								}
							}
						}
					}
				}
			}
		}
	}

	.notification-center {
		background-color: $content-background-color;

		.friend-entry {
			color: #fff;
		}

		.chat-section {
			.conversation-divider {
				background-color: #03262e;
			}

			.conversation {
				.item {
					&.is-self {
						.chat-content {
							.chat-bubble {
								background-color: #1c1c1c;
							}
						}
					}

					.chat-content {
						.chat-bubble {
							color: #fff;
							background-color: #1c1c1c;
						}
					}
				}
			}

			.chat-input {
				border-top: 3px solid #1c1c1c;

				input {
					color: #fff;
				}
			}
		}

		.notifications-section {
			.action-notification-entry,
			.activity-notification-entry {
				background-color: #191919;

				&:nth-child(even) {
					border-top: 1px solid #000;
					background-color: #1c1c1c;
				}
			}
		}
	}

	.team-stats-content {
		max-width: 1200px;

		.table-striped th,
		.table-striped .content-heading {
			color: #fff;
		}
		select.team-stats-grayed-out option:not(:first-child) {
			color: #fff;
		}
		.team-stats-heading {
			.team-stats-upper-heading {
				.team-stats-team-name {
					color: #fff;
				}
				.team-stats-rating {
					.team-stats-text {
						color: #fff;
					}
					.team-stats-value {
						color: $primary-color;
					}
				}
			}
			.team-stats-lower-heading {
				color: $button-color;

				.team-stats-stats-card {
					.team-stats-stats-card-text {
						color: #fff;
					}
					.team-stats-stats-card-value {
						color: #13aded;
					}
					.team-stats-wins-stat {
						color: #a7d101;
					}
					.team-stats-losses-stat {
						color: #a60000;
					}
				}
				.team-stats-stats-card:nth-child(odd) {
					background-color: $content-background-color;
				}
				.team-stats-stats-card:nth-child(even) {
					background-color: $secondary-color;
				}
			}
		}
		.team-stats-body {
			.team-stats-active {
				color: #fff;
			}
			.team-stats-latest-achievements-card {
				background-color: $content-background-color;

				.team-stats-achievement-image {
					background-color: $secondary-color;
					border: 0px;
				}
				.team-stats-achievement-body {
					background-color: $content-background-color;

					.team-stats-achievement-player a {
						color: $primary-color;
					}
				}
			}
		}
	}

	.lws-lls-teams-lws,
	.lws-lls-teams-lls {
		color: #ffffff;
	}
	.lws-lls-teams-stats,
	.lws-lls-teams-lws-lls,
	.lws-lls-teams-average-rating,
	.lws-lls-teams-misc-values,
	.lws-lls-teams-misc-names {
		background-color: #1e1e1e;
	}

	.nel-content {
		.nel-team-box {
			color: #8c8c8c;
			background-color: $content-background-color;
			border: 0px;
		}
		.nel-team-box-create-new-team {
			background-color: $button-color;
		}
		.nel-team-box-create-new-team:hover {
			background-color: $button-hover-color;
		}
		.nel-right-heading {
			background-color: $secondary-color;
		}
		.nel-center-heading {
			color: $button-color;
		}
		.nel-sign-up-popup {
			background-color: #1c1c1c;
			border: 1px solid #454545;
		}
		.nel-sign-up-popup-head {
			background-color: $content-background-color;
			color: $button-color;
		}
		.nel-sign-up-popup-body {
			background-color: #1c1c1c;
			border: 0px;
		}
		.nel-sign-up-popup-sign-up {
			background-color: $button-color;
		}
		.nel-sign-up-popup-sign-up:hover {
			background-color: $button-hover-color;
		}
		.nel-sign-up-popup-cancel {
			color: #af2828;
		}
		.nel-region-subtabs {
			background-color: $content-background-color;
		}
		.nel-region-subtab {
			color: $button-color;
		}
		.nel-region-subtab.active {
			color: #ffffff;
		}
		.nel-region-subtab:not(:first-child) {
			border-left-color: #2f2f2f;
		}
		.nel-qualifier-sign-up-container {
			background-color: $content-background-color;
		}
		.nel-team-box-shield {
			color: #8c8c8c;
		}
	}
	.user-profile-level-container {
		background-color: $content-background-color;
	}
	.blocked {
		background-color: rgba(25, 25, 25, 0);
		border-bottom: 1px solid $dark-grey-border;
	}
	.blocked:nth-child(odd) {
		background-color: #191a24;
	}

	// Dark NEL-specific rules.
	.nel-accepted {
		color: #94e900;
	}
	.nel-pending {
		color: #a51328;
	}
	.nel-teams-players {
		border-bottom-color: #000000;
		border: 0px;
		thead {
			border-left-color: #121212;
			border-right-color: #121212;
			background-color: $table-header-background-color;
		}
	}
	.nel-teams-player {
		border-color: #121212;
	}
	.nel-teams-extended-name-column {
		text-align: left;
		padding-left: 10px;

		.nel-teams-players-username {
			overflow: hidden;
			text-overflow: ellipsis;
			width: 100px;
		}
	}
	.nel-teams-toggle-col {
		text-align: center;
	}

	.admin-news-organizer-picker {
		background-color: $content-background-color;
	}
	.admin-news-organizer {
		color: #fff;
		background-color: #252525;

		&:nth-child(odd) {
			background-color: #1d1d1d;
		}
	}

	.tournament-team-replace-team {
		background-color: #252525;

		&:nth-child(odd) {
			background-color: #1d1d1d;
		}

		&.selected {
			background-color: $primary-color;
		}
	}

	.match-lobby-prime-info {
		background-color: rgba(28, 28, 28, 0.3);
		color: #fff;
	}

	.ql-container.ql-snow {
		border-color: #2a2a2a !important;
	}
	.ql-picker {
		color: #fff !important;
	}
	.ql-stroke {
		stroke: #fff !important;
	}
	.ql-snow .ql-fill {
		fill: #fff;
	}
	.ql-picker-options {
		background-color: #000 !important;
		color: #fff !important;
		border-color: #111 !important;
	}

	.ql-twitch {
		color: white;
		&:hover {
			color: $primary-color;
		}
	}

	.ql-snow.ql-toolbar button:hover,
	.ql-snow .ql-toolbar button:hover,
	.ql-snow.ql-toolbar button:focus,
	.ql-snow .ql-toolbar button:focus,
	.ql-snow.ql-toolbar button.ql-active,
	.ql-snow .ql-toolbar button.ql-active,
	.ql-snow.ql-toolbar .ql-picker-label:hover,
	.ql-snow .ql-toolbar .ql-picker-label:hover,
	.ql-snow.ql-toolbar .ql-picker-label.ql-active,
	.ql-snow .ql-toolbar .ql-picker-label.ql-active,
	.ql-snow.ql-toolbar .ql-picker-item:hover,
	.ql-snow .ql-toolbar .ql-picker-item:hover,
	.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
	.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
		color: $primary-color;
	}
	.ql-snow.ql-toolbar button:hover .ql-fill,
	.ql-snow .ql-toolbar button:hover .ql-fill,
	.ql-snow.ql-toolbar button:focus .ql-fill,
	.ql-snow .ql-toolbar button:focus .ql-fill,
	.ql-snow.ql-toolbar button.ql-active .ql-fill,
	.ql-snow .ql-toolbar button.ql-active .ql-fill,
	.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
	.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
	.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
	.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
	.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
	.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
	.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
	.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
	.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
	.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
	.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
	.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
	.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
	.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
	.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
	.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
	.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
	.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
	.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
	.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
	.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
	.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
		fill: $primary-color;
	}
	.ql-snow.ql-toolbar button:hover .ql-stroke,
	.ql-snow .ql-toolbar button:hover .ql-stroke,
	.ql-snow.ql-toolbar button:focus .ql-stroke,
	.ql-snow .ql-toolbar button:focus .ql-stroke,
	.ql-snow.ql-toolbar button.ql-active .ql-stroke,
	.ql-snow .ql-toolbar button.ql-active .ql-stroke,
	.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
	.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
	.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
	.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
	.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
	.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
	.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
	.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
	.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
	.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
	.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
	.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
	.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
	.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
	.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
	.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
	.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
	.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
	.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
	.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
	.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
	.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
		stroke: $primary-color;
	}
	.ql-active {
		color: $primary-color !important;
		.ql-stroke {
			stroke: $primary-color !important;
		}
		.ql-fill {
			fill: $primary-color !important;
		}
	}

	.ql-picker-item:hover,
	.ql-picker-label:hover {
		color: $primary-color !important;
	}

	.ql-formats {
		background-color: #2b2b2b !important;
		border-top-color: #414141;
		border-left-color: #414141;
	}

	.wysiwyg-content {
		p {
			color: #a7aaaf;
		}

		pre {
			background-color: #23241f !important;
			color: #f8f8f2 !important;
		}
	}
}

;@import "sass-embedded-legacy-load-done:138";