@import '../styles/colors';

.es-button {
	padding: 0px 10px;
	height: 35px;
	border-radius: 4px;
	cursor: pointer;
	font-size: 13px;
	border: none;

	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
}
.es-button > .es-text-button {
	display: inline-block;
}

.es-button-type-primary {
	color: $text-color-white;
	background-size: 200% auto;

	transition: 0.3s;

	&:hover {
		background-position: right center;
	}

	&.es-button-loader {
		> div {
			height: 24px;
			width: 24px;
			border-radius: 50%;

			animation: spin 1s linear infinite;

			@keyframes spin {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}
		}
	}

	&.es-button-loader:hover {
		background-position: 0% 0%;
	}
}

.es-button-type-secondary {
	color: $text-color-grey;
	background-color: transparent;
	border: 2px solid #2d2f3b;

	transition: 0.3s;
}

.es-button-type-secondary:hover {
	color: $text-color-white;
	border-color: $text-color-white;
}
.es-button-type-secondary.es-button-color-blue:hover {
	color: $text-color-blue;
	border-color: $text-color-blue;
}
.es-button-type-secondary.es-button-color-premium:hover {
	color: $base-color-premium;
	border-color: $base-color-premium;
}
.es-button-type-secondary.es-button-color-prime:hover {
	color: $base-color-prime;
	border-color: $base-color-prime;
}

.es-button-type-primary.es-button-color-blue {
	background-image: linear-gradient(
		to right,
		$text-color-blue 0%,
		#007ce9 51%,
		$text-color-blue 100%
	);

	&.es-button-loader {
		> div {
			border: 4px solid #007ce9;
			border-top: 4px solid $text-color-blue;
			border-right: 4px solid $text-color-blue;
		}
	}
}

.es-button-type-primary.es-button-color-premium {
	background-image: linear-gradient(
		to right,
		$base-color-premium 0%,
		#ff4e00 51%,
		$base-color-premium 100%
	);

	&.es-button-loader {
		> div {
			border: 4px solid #ff4e00;
			border-top: 4px solid $base-color-premium;
			border-right: 4px solid $base-color-premium;
		}
	}
}

.es-button-type-primary.es-button-color-prime {
	background-image: linear-gradient(
		to right,
		$base-color-prime 0%,
		#3900bf 51%,
		$base-color-prime 100%
	);

	&.es-button-loader {
		> div {
			border: 4px solid #3900bf;
			border-top: 4px solid $base-color-prime;
			border-right: 4px solid $base-color-prime;
		}
	}
}

.es-button-disabled {
	cursor: auto;
	background: #434343;
}

.es-button-type-primary.es-button-disabled,
.es-button-type-secondary.es-button-disabled,
.es-button-type-primary.es-button-disabled:hover,
.es-button-type-secondary.es-button-disabled:hover {
	background: #434343;
	color: #979797;

	&.es-button-loader {
		> div {
			border: 4px solid #535353;
			border-top: 4px solid #777777;
			border-right: 4px solid #777777;
		}
	}
}
