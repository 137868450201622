.create-tournament-page {
	max-width: 1200px;
}

.create-tournament-box {
	background-color: #14151d;
	font-size: 16px;
	padding: 48px;
	margin-bottom: 8px;
}

.create-tournament-input-group {
	text-align: right;
	margin-bottom: 40px;

	.create-tournament-input-label-group,
	.create-tournament-input-multiline-label-group {
		display: inline-block;
		width: 75px;
		text-align: right;
		margin-right: 14px;
	}

	.create-tournament-input-form-group {
		display: inline-block;
		width: 74%;

		input,
		select {
			width: 100%;
			min-height: 38px;
		}
	}
}
.create-tournament-input-group.long .create-tournament-input-form-group {
	width: 40%;
	margin-right: 32px;

	input,
	select {
		width: 100%;
	}
}
.create-tournament-input-group.left {
	text-align: left;

	.create-tournament-input-label-group {
		width: 150px;
	}

	.create-tournament-input-form-group {
		width: 50%;
	}
}
.create-tournament-input-group.map-selection-left {
	text-align: left;

	.create-tournament-input-label-group {
		width: 80px;
	}

	.create-tournament-input-form-group {
		width: 70%;
	}
}
.create-tournament-input-group.map-selection-bottom {
	margin-bottom: 0;
}
.create-tournament-input-group.game .create-tournament-input-form-group {
	width: 67%;
}
.create-tournament-input-group.mode {
	.create-tournament-input-label-group {
		width: 42px;
	}
	.create-tournament-input-form-group {
		width: 54%;
	}
}
.create-tournament-input-group.with-info .create-tournament-input-form-group {
	vertical-align: top;
}
.create-tournament-input-group.checkbox {
	text-align: left;

	.create-tournament-input-multiline-label-group {
		vertical-align: top;
		width: 168px;
	}
	.create-tournament-input-label-group {
		vertical-align: top;
		line-height: 42px;
		width: 168px;
	}
	.create-tournament-input-form-group {
		padding-top: 2px;
		width: 10%;
	}
}

.create-organizer {
	display: inline-block;
}
.create-tournament-info {
	display: inline-block;
	width: 16px;
	height: 16px;
	line-height: 16px;
	text-align: center;
	font-size: 12px;
	font-weight: 1000;
	margin-left: 6px;
	border-radius: 50%;
	background-color: #000;
	color: #fff;
	user-select: none;
}
.create-organizer-box {
	position: relative;
	padding: 36px;
	background-color: #ebebeb;
	border: 1px solid #dfdfdf;
	margin-bottom: 36px;

	.create-tournament-input-group {
		margin-bottom: 4px;
	}
}
.create-organizer-list {
	list-style-type: square;
	margin: 10px 0 10px 20px;
}
.create-organizer-close {
	position: absolute;
	right: 36px;
	color: $primary-hover-color;
	cursor: pointer;
	font-size: 13px;
}

.create-tournament-map-selection hr {
	border: 0;
	height: 1px;
	background-color: #bbbbbb;
	margin-top: 36px;
}
.create-tournament-map-selection-phase {
	font-size: 17px;
	font-weight: 600;
	margin: 36px 0;
}

.create-tournament-input-group.tournament-banner-input {
	vertical-align: top;

	.create-tournament-input-label-group {
		top: 50%;
		transform: translateY(-50%);
	}
}
input#tournament-banner {
	width: 300px;
	margin-bottom: 4px;
}

.create-tournament-streams {
	margin-top: 36px;
}
.create-tournament-streams-title {
	margin-bottom: 20px;

	.title {
		display: inline-block;
		font-weight: 600;
		margin-right: 24px;
	}
}
.create-tournament-description {
	display: inline-block;
	font-weight: 400;
	font-size: 14px;
}
.create-tournament-stream {
	width: 100%;
	height: 48px;
	line-height: 48px;
	padding: 0 16px;
	margin-bottom: 4px;

	input {
		margin-left: 26px;
		width: 50%;
	}

	.create-tournment-stream-action {
		float: right;
		margin-top: 6px;
	}

	.create-tournament-add-stream-input {
		margin-left: 42px;
	}
}

.create-tournament-rules-picker {
	font-weight: 400;
	margin-top: 34px;
}
.create-tournament-rules-picker-title {
	display: inline-block;
	vertical-align: top;
}
.create-tournament-rules-presets {
	display: inline-block;
	margin-left: 32px;

	input {
		width: auto;
	}

	label {
		position: relative;
		top: -1px;
	}
}
.create-tournament-rules-preset {
	position: relative;
	top: 1px;
}

.create-tournament-save-buttons {
	position: relative;
}

.create-tournament-save-button {
	position: absolute;
	width: 30%;
	right: 64px;
	top: 50%;
	transform: translateY(-50%);

	&.publish {
		background-color: #739900;
		left: 64px;
	}

	&.unpublish {
		background-color: #a80000;
		left: 64px;
	}
}

.create-tournament-organizer {
	position: relative;
}
.create-tournament-organizer-loading-icon {
	position: absolute;
	top: 11px;
	right: 20px;
}

.create-tournament-admin-picker {
	padding: 0 164px;
}
.create-tournament-admin-picker-section {
	vertical-align: top;
	display: inline-block;

	&.picked {
		float: right;
	}
}
.create-tournament-admin-picker-table {
	display: inline-block;
	width: 350px;
	height: 240px;

	tbody {
		display: block;
		max-height: 200px;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.player-column {
		width: 290px;
	}
	.pick-column {
		width: 60px;
	}

	th.pick-column {
		padding-right: 17px;
	}
}

;@import "sass-embedded-legacy-load-done:92";