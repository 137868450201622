.enterUsernameFormContainer {
	display: flex;
	flex-direction: column;
	max-width: 256px;

	> div {
		margin-bottom: 8px;
	}
}

.usernameInputError {
	margin-bottom: 12px;
	font-size: 12px;
	height: 12px;
	color: #f6003b;
	font-weight: 400;
}
