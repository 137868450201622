.checkout-processing-popover {
	width: 800px;
	padding: 10px;
	animation: 0.7s ease-out 0s 1 fadein;
	background-color: #0000;
	border: 0 !important;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

;@import "sass-embedded-legacy-load-done:123";