.email-pending {
	width: 150px;
	height: 10px;
	margin: auto;

	.dots {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 10px;

		.dot {
			background-color: #4a4d5e;
			height: 10px;
			width: 10px;
			border-radius: 50%;
			transition: background-color 0.5s ease;

			&.colored {
				background-color: $primary-color;
			}
		}
	}

	.waiting-text {
		color: $primary-color;
		font-size: 19px;
		margin: 5px;
	}

	.check-email {
		color: #aeb6c4;
		font-weight: 400;
	}
}

;@import "sass-embedded-legacy-load-done:67";