.multiple-select {
	width: 100%;
	min-height: 42px;
	font-size: 13px;
	padding: 5px 15px 15px;
	border: 1px solid #ccc;
	transition: border 0.25s linear;

	.multiple-select-search {
		width: 100%;
		padding: 0;
		height: 35px;
		border: 0;
		border-bottom: 1px solid #ccc;
	}

	.multiple-select-options {
		margin-top: 1em;
		max-height: 200px;
		overflow: auto;

		.multiple-select-option {
			margin-top: 0.5em;

			& > input[type='checkbox'] {
				vertical-align: middle;
			}

			& > span {
				margin-left: 5px;
			}

			&:hover,
			& > input[type='checkbox']:hover {
				cursor: pointer;
			}
		}
	}
}

body.theme-dark:not(.disable-theme-dark) {
	.multiple-select {
		background-color: #1c1c1c;
		color: #fff;
		border-color: #646464;
	}
	.multiple-select-search {
		border-color: #646464;
	}
}

;@import "sass-embedded-legacy-load-done:68";