@import 'constants';
@import 'lib/bulma';
@import 'helpers';
@import 'helpers/tailwind';

*,
*:before,
*:after {
	box-sizing: border-box;
}

* {
	outline: none;
}

div::-webkit-scrollbar {
	width: 25px;
}
div::-webkit-scrollbar-track {
	background: #191a24;
}
div::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb:hover {
	border: 4px solid rgba(0, 0, 0, 0);
	background-clip: padding-box;
	box-shadow:
		inset -1px -1px 0px rgba(0, 0, 0, 0.05),
		inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
div::-webkit-scrollbar-thumb {
	background-color: $scrollbar-thumb-color;
}
div::-webkit-scrollbar-thumb:hover {
	background-color: $scrollbar-thumb-hover-color;
}

audio {
	display: none;
}
input,
.file-input,
textarea {
	border-radius: 0;
}

textarea {
	resize: none;
	height: 100px;
	min-width: 350px;
	max-width: 100%;
}

.resizable {
	resize: both;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-overflow-scrolling: touch;
	background-color: #fff;
	color: #000;
	font-family:
		'Roboto',
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		Oxygen-Sans,
		Ubuntu,
		Cantarell,
		'Helvetica Neue',
		sans-serif;
	font-size: 14px;
	font-weight: 500;
	touch-action: manipulation;
	position: relative;
	min-height: 100vh;
}

html,
body,
#main {
	height: 100%;
}

#app {
	display: flex;
	flex-direction: row;
	min-height: 100vh;
	padding-top: $top-bar-height;

	@include mobile() {
		padding-top: calc(#{$top-bar-height} + #{$top-bar-above-height});
	}
}

#app-development-production-warning {
	display: flex;
	flex-direction: row;

	position: fixed;
	top: 0;
	left: 0;

	width: 100%;
	z-index: 133713371337;

	box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
	background-color: rgba(101, 0, 0, 0.9);

	color: #fff;
	font-weight: bold;

	&.top {
		top: 0;
		bottom: auto;
	}

	&.bottom {
		top: auto;
		bottom: 0;
	}

	.diagnostic {
		flex-grow: 1;
		text-align: center;
	}
}

#main-content {
	padding-bottom: 200px; // footer height + 10px

	@include tablet() {
		padding-bottom: 310px;
	}
	@include desktop() {
		padding-bottom: 270px;
	}
	@include widescreen() {
		padding-bottom: 240px;
	}
}

#main-content-background {
	display: block;
	position: fixed;
	z-index: -2;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-repeat: no-repeat;
	background-position: 50% 0px;
}

.unselectable,
.button {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-touch-callout: none;
	user-select: none;
}

a {
	color: $primary-hover-color;
}

select,
input,
.file-input,
textarea {
	background: #fff;
	color: #333;
	width: 240px;
	transition: border 0.25s linear;
	border: 2px solid;
	border-radius: 5px;
	padding: 10px;
}
select {
	padding-right: 25px; // for dropdown triangle
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	background: $content-background-color
		url("data:image/svg+xml;utf8,<svg fill='grey' height='32' viewBox='0 0 32 32' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h32v32H0z' fill='none'/></svg>")
		no-repeat;
	background-position-x: 100%;
	background-position-y: 8px;
}
select.disabled {
	background: #ddd;
	color: #555;
}
select.small {
	width: auto;
	padding: 2px;
	padding-right: 20px;
	background-position-x: 105%;
	background-position-y: 0;
}
input[type='checkbox'] {
	appearance: none;
	width: 12px;
	height: 12px;
	min-height: 12px;
	min-width: 12px;
	padding: 10px;

	&:checked {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='none' stroke='%2300a9e9' stroke-width='0.8' d='M 0.718,3.536 2.974,5.806 7.282,1.4715'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: center;

		&:disabled {
			filter: grayscale(60%);
		}
	}
}
select:focus,
textarea:focus,
input:focus,
.file-input:focus {
	border-color: #999;
}

p {
	line-height: 24px;
}

.block-link {
	display: block;
	width: 100%;
	height: 100%;
}
.clickable {
	cursor: pointer;
}

#content {
	position: relative;
	padding: 10px 0px;
}

.block {
	display: block;
	width: 100%;
}

.form-field {
	padding: 15px 0px 5px 0px;
	font-size: 13px;
}
.form-submit-field {
	padding: 10px 0px 15px 0px;
}
.form-input,
.form-select,
.form-textarea {
	display: block;
	border: 1px solid #b4b4b4;
	width: 100%;
	margin-top: 3px;
	font-size: 15px;
}
.form-input,
.form-select {
	padding: 0px 15px;
	height: 40px;
}
.form-textarea {
	padding: 10px 15px;
	height: 120px;
}
.form-textarea:focus,
.form-input:focus {
	border: 1px solid $primary-color;
}
.form-button {
	width: 100%;
}

.button.success {
	background-color: #01bb05;
}
.button.success:hover {
	background-color: #06c20a;
}
.button + .button {
	margin-left: 15px;
}
.top-button {
	margin-bottom: 10px;
}
.bottom-button {
	margin-top: 10px;
}
.highlight {
	color: $primary-hover-color;
}

div.right,
button.right {
	float: right;
}

i.right,
form.right {
	display: block;
	float: right;
}
th.right,
td.right {
	text-align: right;
}

.content-heading {
	font-size: 18px;
	font-weight: 500;
	line-height: 35px;
}

.content-heading-meta {
	padding-top: 0.5em;
	color: #c0c6d1;
}

.page-container {
	color: $primary-color;
	padding: 50px 10px;
	width: 100%;
	text-align: center;
}
.page-title {
	font-size: 24px;
	text-transform: uppercase;
}

.win {
	color: #739900;
}
.tie {
	color: #a89900;
}
.loss {
	color: #a80000;
}
.right-align {
	text-align: right;
}
.half {
	float: left;
	width: 49%;
}
.half + .half {
	margin-left: 2%;
}
.columns {
	margin-left: 0;
	margin-right: 0;
}
.columns.no-gap {
	margin-top: 0;

	&:last-child {
		margin-bottom: 0;
	}

	&:not(:last-child) {
		margin-bottom: 0rem;
	}

	> .column {
		padding: 0;
	}
}
.img {
	width: 100%;
}

.center {
	text-align: center;
}

::placeholder {
	color: #444445;
}

.input-group {
	width: 100%;
	max-width: 950px;
	margin: 5px auto;
	padding: 7.5px 0px 7.5px 0px;
	clear: both;
	overflow: auto;
}
.input-label-group {
	float: left;
	width: 38%;
}
.input-label {
	font-size: 15px;
	font-weight: 500;
}
.input-descr {
	font-size: 12px;
	color: #777778;
}
.input-form-group {
	float: right;
	width: 62%;
}
.input-form {
	width: 100%;
	min-height: 42px;
	font-size: 13px;
	padding: 5px 15px;
}

.banner {
	text-align: center;
	border-radius: 4px;
	line-height: 20px;
	margin-bottom: 10px;
	padding: 10px 15px;
}
.banner-danger {
	background-color: #790000;
}
.banner-success {
	background-color: $button-color;
}
.banner-link {
	text-decoration: underline;
}

.center-column {
	width: 100%;

	@include tablet() {
		width: calc(100% - 32px);

		&.has-left-column {
			width: calc(79.16% - 32px);
			margin-left: 16px;
		}

		&.has-right-column {
			width: calc(79.16% - 32px);
			margin-right: 16px;
		}

		&.has-left-column.has-right-column {
			width: calc(58.33% - 32px);
		}
	}
}

.left-column,
.right-column {
	width: 100%;

	@include tablet() {
		width: 20.833%;
	}
}

th.left,
td.left {
	float: none;
	text-align: left;
}

.icon16 {
	width: 16px;
	height: 16px;
	background-size: 16px 16px;
}
.icon20 {
	width: 20px;
	height: 20px;
	background-size: 20px 20px;
}
.icon32 {
	width: 32px;
	height: 32px;
	background-size: 32px 32px;
}
.icon40 {
	width: 40px;
	height: 40px;
	background-size: 40px 40px;
}

.icon-search {
	background-image: url(../img/menu/search.png);
}
.icon-admin {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/misc/admin.svg);
}
.icon-overwatcher {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/misc/overwatcher.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}
.icon-moderator {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/misc/moderator.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}
.icon-verified {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/misc/verified.png);
}
.icon-secsgo {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/nel/nel_logo_small.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}
.icon-fragleague {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/fragbite/fragbite_medal.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}
.icon-drpepper {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/drpepper/pepper-logo-small.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}
.icon-getinpro {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/getinpro/getinpro-logo.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}
.icon-telenor {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/telenor/telenor_tournamentlogo.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}
.icon-headset {
	width: 32px;
	height: 32px;
	background-image: url(../img/gathers/headset.png);
}
.icon-waves {
	width: 35px;
	height: 22px;
	background-image: url(../img/gathers/waves.png);
}
.icon-captain {
	color: #838384;
}
.icon-mvp {
	color: #f7cd1e;
	font-size: 15px;
}
.icon-ranking {
	background-image: url(../img/menu/ranking.png);
}
.icon-ladders {
	background-image: url(../img/menu/ladders.png);
}
.icon-public-servers {
	background-image: url(../img/menu/public_servers.png);
}
.icon-astralis-public-servers {
	background-image: url(../js/components/landing/astralis/assets/astralis_featured.png);
}
.icon-prime {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/prime.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}
.icon-premium {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/premium.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}
.icon-star-gather {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/gathers/stargather_star.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.icon-astralis {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/misc/icon_astralis.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.icon-minimize {
	background-image: url(../img/misc/icon_minimize.png);
}
.icon-maximize {
	background-image: url(../img/misc/icon_maximize.png);
}
.icon-minimize,
.icon-maximize {
	display: inline-block;
	width: 16px;
	height: 16px;
	background-size: cover;
}
.icon-first-star-gather {
	vertical-align: middle;
	display: inline-block;
	background-image: url(../img/misc/newtogathers.png);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.default-team-logo {
	background-image: url(../img/teams/default_logo.png);
}
.default-news-logo {
	background-image: url(../img/icon.png);
	background-size: cover;
}

.skill0,
.beginner {
	background-image: url(../img/skill-levels/beginner.png);
	background-size: 38px 20px;
}
.skill1,
.casual {
	background-image: url(../img/skill-levels/casual.png);
	background-size: 57px 21px;
}
.skill2,
.expert {
	background-image: url(../img/skill-levels/expert.png);
	background-size: 48px 28px;
}
.skill3,
.advanced {
	background-image: url(../img/skill-levels/advanced.png);
	background-size: 48px 28px;
}
.smurf-notification {
	width: 100%;
	background-color: #ffdd00;
	color: #000;
	padding: 15px;
	text-align: center;

	.link {
		text-decoration: underline;
		cursor: pointer;
		color: #000;
	}

	&.compromised {
		text-align: left;
		font-weight: 400;

		span {
			cursor: pointer;
		}

		.buttons {
			float: right;
		}
	}
}
.takeover-notification {
	text-transform: uppercase;
	width: 100%;
	background-color: #902908;
	padding: 15px;
	display: flex;

	.close {
		cursor: pointer;
		margin-left: auto;
	}
}
#route-content {
	position: relative;
	display: flex;
	flex-wrap: wrap;
}

#content-wrapper {
	width: 100%;

	@include tablet() {
		padding-top: 20px;
	}
}
#constant-width {
	//prevents sides from collapsing between page loads
	width: calc(100% - #{$left-sidebar-width} - #{$right-sidebar-fullhd-width} - 10px);
	position: relative;
	min-height: 100vh;
	flex-grow: 1;

	@include mobile() {
		width: 100%;
	}
}

#alert-message + #route-content {
	@include tablet() {
		padding-top: 50px;
	}

	@include mobile() {
		padding-top: 50px;
	}
}

%dropdown-table {
	.content-heading {
		background: transparent;
		color: $white-text-color;
		font-size: 18px;
		padding: 4px 0px;
		text-align: left;
		user-select: none;

		@include mobile() {
			font-size: 21px;
			padding: 8px 24px;
		}
	}
	.table-separated {
		border-collapse: collapse;
		border-spacing: 0;

		th,
		td {
			border: none;
		}
	}
	.content-footer {
		height: 60px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #1c1d29;
		border-radius: 0 0 5px 5px;

		@include mobile() {
			display: flex;
			justify-content: space-between;
		}

		.button {
			padding: 0.5em 0.5em;
			min-width: 90px;
			height: 100%;
			border-radius: 0 0 0 5px;
			background: #20212d;
			color: $primary-color;
			width: 14%;

			&.right {
				border-radius: 0 0 5px 0;
			}
			&.inactive {
				color: #343646;
				cursor: unset;
			}

			@include mobile() {
				min-width: 40%;
				font-size: 18px;
			}
		}
		.counter {
			color: $grey-text-color;
			font-size: 13px;
		}
	}
	.caret {
		float: right;
		font-size: 18px;
		cursor: pointer;
		width: 35px;
		text-align: center;
	}
}

@import 'components/frag';
@import 'components/button';
@import 'components/radio';
@import 'components/tipsy';
@import 'components/popover';
@import 'components/snackbar';
@import 'components/box';
@import 'components/table';
@import 'components/notification';
@import 'components/consent';
@import 'components/legal';

@import 'components/longestwinninglosingstreak';
@import 'components/playercard';
@import 'components/friendlist';
@import 'components/blockedlist';
@import 'components/activity';
@import 'components/latestnews';
@import 'components/livegames';
@import 'components/latestmvps';
@import 'components/livestreams';
@import 'components/statsbar';
@import 'components/invitefriends';
@import 'components/queue';
@import 'components/teamcard';
@import 'components/latestdropscarousel';
@import 'components/latestdropslist';
@import 'components/admin/overwatchcases';
@import 'components/admin/overwatch';
@import 'components/admin/user/permissions';

@import 'components/topbar';
@import 'components/notificationcenter';
@import 'components/footer';
@import 'components/chatbar';
@import 'components/gathergroup';
@import 'components/alerts';
@import 'components/flagbar';
@import 'components/leftsidenavigation';
@import 'components/rightsideinfo';

@import 'components/countries';
@import 'components/maps';
@import 'components/ranks';
@import 'components/regions';
@import 'components/weapons';
@import 'components/levels';
@import 'components/nel';
@import 'components/getinpro';
@import 'components/tournamentmatchesentry';
@import 'components/tournamentachievements';
@import 'components/editor';
@import 'components/usp';
@import 'components/premiumdropscounter';
@import 'components/emailpending';

@import 'components/multipleselect';

@import 'components/teamselector';

@import 'pages/home';
@import 'pages/globalstats';
@import 'pages/matchmaking';
@import 'pages/userprofile';
@import 'pages/userstats';
@import 'pages/userstream';
@import 'pages/ranking';
@import 'pages/news';
@import 'pages/challenges';
@import 'pages/match';
@import 'pages/adminlist';
@import 'pages/settings';
@import 'pages/adminstats';
@import 'pages/adminnews';
@import 'pages/adminsubscriptions';
@import 'pages/admintournaments';
@import 'pages/anticheat';
@import 'pages/gather';
@import 'pages/gathers';
@import 'pages/creategather';
@import 'pages/createteam';
@import 'pages/createtournament';
@import 'pages/tournaments';
@import 'pages/tournamentpage';
@import 'pages/tournamentlobby';
@import 'pages/teamprofile';
@import 'pages/teamstats';
@import 'pages/organizerpage';
@import 'pages/offers';
@import 'pages/checkoutOld';
@import 'pages/checkout';
@import 'pages/receipt';
@import 'pages/subscriptions';
@import 'pages/ladderpage';
@import 'pages/signup';
@import 'pages/loggedoutlanding';
@import 'pages/drops';
@import 'pages/publicservers';
@import 'pages/verificationpage';
@import 'pages/verifiedusers';

@import 'popovers/supportticket';
@import 'popovers/gameresult';
@import 'popovers/matchready';
@import 'popovers/report';
@import 'popovers/rank';
@import 'popovers/phoneverification';
@import 'popovers/level';
@import 'popovers/newsimageselector';
@import 'popovers/terms';
@import 'popovers/tournamentcheckin';
@import 'popovers/tournamentteamreplace';
@import 'popovers/prime';
@import 'popovers/checkoutprocessing';
@import 'popovers/unclaimedelo';
@import 'popovers/quickgather';
@import 'popovers/logoutbanned';
@import 'popovers/deactivation';
@import 'popovers/findyourfriends';
@import 'popovers/logoutoutpopover';
@import 'popovers/usersignupsource';
@import 'popovers/compromised';
@import 'popovers/onboarding';
@import 'popovers/adminban';
@import 'popovers/userrewind2021';
@import 'popovers/usersessionmanagement';
@import '1v1/1v1.scss';

@import 'primelandingpage';

@import 'themes/dark';

@import 'themes/prime';
@import 'themes/premium';

@import 'chat/messaging';
@import 'chat/chatdialog';

.primary-color {
	color: $primary-color;
}
.primary-hover-color {
	color: $primary-hover-color;
}
.button-color {
	color: $button-color;
}
.primary-background {
	background-color: $primary-color;
}
.prime-background {
	background-color: $prime-color;
}
.premium-background {
	background-color: $premium-color;
}
.premium-color {
	color: $premium-color;
}

.avatar {
	display: inline-block;
	background-size: cover;
	background-repeat: no-repeat;
}
.circle {
	border-radius: 50%;
}

.online-status-indicator {
	display: inline-block;
	margin-left: 5px;
	border-radius: 50%;
	height: 10px;
	width: 10px;
	vertical-align: middle;
}
.online0 {
	background-color: #717171;
}
.online1,
.online4 {
	background-color: #dc1519;
}
.online2 {
	background-color: #dcb519;
}
.online3 {
	background-color: #328dc6;
}
.online5 {
	background-color: #33c66b;
}
.online6 {
	background-color: $premium-color;
}

.records-headshot {
	background-image: url(../img/misc/records_icons_headshot.png);
}
.records-kills {
	background-image: url(../img/misc/records_icons_kills.png);
}
.records-deaths {
	background-image: url(../img/misc/records_icons_deaths.png);
}
.records-assists {
	background-image: url(../img/misc/records_icons_assists.png);
}
.records-drops {
	background-image: url(../img/gathers/running-icon-on-transparent-background-hi.png);
	filter: brightness(0%) invert(100%);
	-webkit-filter: brightness(0%) invert(100%);
}
.records-win-ratio {
	background-image: url(../img/misc/records_icons_win_ratio.png);
}
.records-kdr {
	background-image: url(../img/misc/records_icons_kdr.png);
}
.records-clutch {
	background-image: url(../img/misc/records_icons_headshot.png);
}

.match-table-map {
	vertical-align: middle;
	display: inline-block;
	height: 30px;
	width: 51px;
	background-size: cover;
	border-radius: 5px;
}

.icon-esportal {
	background-image: url(../img/icon.png);
}

.overflow-auto {
	overflow: auto;
}
.clear {
	clear: both;
}

.right-column {
	.top-players-username {
		display: inline-block;
		max-width: 80px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.strikethrough {
	text-decoration: line-through;
}

.fa-ban {
	color: #f00;
}

.quill-editor {
	min-height: 150px;
}
.quill-editor.disabled {
	background: #ddd;
	color: #555;
}

.ql-editor > *:not(:first-child) {
	margin-top: 1em;
}

.body-text {
	max-width: 1200px;
	ul {
		list-style-type: disc;
		padding: 0px 0px 10px 10px;
		margin: 0px 0px 10px 10px;
	}
}

.list-of-content {
	li {
		cursor: pointer;
	}
}

.famfamfam-flag.inactive {
	opacity: 0.6;
}

.uppercase {
	text-transform: uppercase;
}

.file-input {
	display: inline-block;
	min-width: 240px;
	width: auto;

	.button {
		width: 91px;
		height: 22px;
		min-height: 22px;
		line-height: 24px;
		font-size: 12px;
		padding: 0 4px;
	}
}
#twitterFeed {
	background-color: $secondary-color;
	width: 100%;
}
.is-fullheight {
	height: 100%;
}

.flex {
	display: flex;
}
.flex-grow {
	flex-grow: 1;
}
.flex-wrap {
	flex-wrap: wrap;
}
.flex-wrap-mobile {
	@include mobile() {
		flex-wrap: wrap;
	}
}
.flex-column {
	flex-direction: column;
}
.justify-start {
	justify-content: flex-start;
}
.justify-end {
	justify-content: flex-end;
}
.justify-center {
	justify-content: center;
}
.justify-between {
	justify-content: space-between;
}
.justify-around {
	justify-content: space-around;
}
.justify-evenly {
	justify-content: space-evenly;
}
.items-center {
	align-items: center;
}

/* Branding on the banner */
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
	display: none;
}

/* Branding on the widget */
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
	display: none;
}

.mw-full {
	max-width: 100%;
}

.inline-block {
	display: inline-block;
}
.inline-flex {
	display: inline-flex;
}
.hidden {
	visibility: hidden;
}
.named-data {
	&-block {
		display: inline-flex;
		font-size: 16px;
		text-transform: uppercase;
	}

	&-title {
		color: #73b0e4;
		font-weight: 400;
	}

	&-value {
		margin-left: 12px;
		color: #fff;
		font-weight: 300;
	}

	@media only screen and (max-width: 545px) {
		&-block {
			display: block;
		}

		&-value {
			margin-left: 0;
		}
	}
}

.geo-flags-selector {
	background-color: $content-background-color;
	padding: 1em;
	border-radius: 5px;

	ul {
		margin-left: 2em;
		line-height: normal;

		li {
			.entry {
				display: flex;
				align-items: center;

				label {
					margin-left: 1em;
				}
			}
			.divider {
				width: 100%;
				margin: 8px 0px;
				border-bottom: 1px solid #41455e;
			}

			margin: 5px 0;
		}
	}
}
