// Note: those are helpers for mobile viewports.

table.stackable {
	&.table-striped {
		td:first-child {
			border: none;
		}
	}

	tbody,
	tr,
	th,
	td {
		display: block;
		border: none;
		text-align: left;
	}

	thead {
		display: block;
	}

	tr {
		height: auto;
		line-height: 1;
	}

	th,
	td {
		padding: 0.5em;
	}
}

table.is-collapsing-mobile {
	td:not(.no-collapse) {
		white-space: nowrap;
		width: 1%;
	}
}

;@import "sass-embedded-legacy-load-done:14";