.user-stream-page {
	width: 100vw;
	height: calc(9 / 16 * 100vh);
	z-index: 2500;
	background-color: #fff;

	@include mobile() {
		height: calc(9 / 16 * 100vh);
	}

	.user-stream {
		height: 100%;

		@include tablet() {
			display: flex;
		}

		.stream-section {
			width: 100%;
			height: 100%;

			&.has-chat {
				width: calc(100% - 300px);
			}
		}

		.chat-section {
			width: 300px;
		}
	}

	.section {
		height: 100px;
		font-size: 18px;

		.section-content {
			display: flex;
			height: 100%;
			align-items: center;
		}

		.content-right {
			margin-left: auto;
		}
	}

	.title-section {
		.stream-title {
			max-width: 50%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.actions-section {
		background-color: #e8e8e8;
		border-top: 1px solid #c4c4c4;

		.avatar {
			width: 50px;
			height: 50px;
			margin-right: 10px;
		}

		.username {
			max-width: 35%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			margin-right: 20px;
		}
	}
}

;@import "sass-embedded-legacy-load-done:75";