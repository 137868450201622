@import '../styles/colors';
@import '../styles/fonts';

.es-checkbox {
	cursor: pointer;
	color: $text-color-white;
	font-size: 14px;
	display: flex;
	align-items: center;
}
.es-checkbox-input {
	cursor: pointer;
	background: $base-color-background;
	color: $base-color-border;
	transition: border 0.25s linear;
	border: 2px solid;
	border-radius: 4px;

	appearance: none;
	width: 20px;
	height: 20px;
	min-height: 20px;
	min-width: 20px;
	padding: 0;
	margin: 0;

	&:hover {
		cursor: pointer;
	}

	&:checked {
		border-color: $text-color-blue;
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='none' stroke='%2300a9e9' stroke-width='0.8' d='M 0.718,3.536 2.974,5.806 7.282,1.4715'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: center;
	}
}
.es-checkbox-label {
	cursor: pointer;
	padding-left: 10px;
}
