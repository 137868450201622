@mixin isSmallMobile {
	@media (max-width: 576px) {
		@content;
	}
}

@mixin isMobile {
	@media (max-width: 768px) {
		@content;
	}
}

@mixin isFullHD {
	@media (max-width: 1780px) {
		@content;
	}
}

;@import "sass-embedded-legacy-load-done:317";