.chatBarDialogBody {
	position: relative;
	height: 350px;
	overflow: auto;
	color: #fff;
	background-color: #191a24;

	.chatBarMessageList {
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
		overflow-y: auto;
		max-height: calc(100% - 64px);
		bottom: 64px;

		.padding-wrap {
			padding: 14px 14px 0;

			> div + div {
				margin-top: 4px;
			}
		}

		&::-webkit-scrollbar-track {
			padding: 0;
			margin: 0;
		}

		&::-webkit-scrollbar {
			background-color: transparent;
			width: 16px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: transparent;
			border-radius: 8px;
		}
	}
	.chatBarMessageList:hover {
		&::-webkit-scrollbar-thumb {
			background-color: $scrollbar-thumb-color;
		}
		&::-webkit-scrollbar-thumb:hover {
			background-color: $scrollbar-thumb-hover-color;
		}
	}
}

.chat-dialog-footer {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	width: 100%;
	height: 64px;
	border: 0;
	border-radius: 0;
	color: #c0c6d1;
	padding: 14px;

	i {
		color: #00b5ff;
		font-size: 17px;
		margin-left: 12px;
		cursor: pointer;
	}

	.chatBarMessageInput {
		background-color: #14151d;
		border: 1px solid #1e1f27;
		font-size: 12px;
		font-weight: 300;
		border-radius: 4px;
		flex-grow: 1;
	}
}

.userMessage {
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	.text {
		max-width: 75%;
		border-radius: 6px 6px 0 6px;
		padding: 10px;
		background-color: #1d9bf0;
		text-align: left;
		overflow-wrap: break-word;
	}
}

.text {
	font-size: 14px;
}

.details {
	font-size: 10px;
	color: #838a97;
}

.friendMessage {
	display: flex;
	width: 100%;

	.photo {
		margin-top: 9px;
	}

	div {
		max-width: 75%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.text {
			border-radius: 6px 6px 6px 0;
			padding: 10px;
			background-color: #242633;
			text-align: left;
			overflow-wrap: break-word;
		}
	}

	.details {
		span {
			margin-right: 4px;
			color: #fff;
		}
	}
}

p {
	margin: 0;
	padding: 0;
}

.user-chat-message-avatar {
	align-self: flex-end;
	width: 30px;
	height: 30px;
	flex-shrink: 0;
	margin: 8px 8px 32px 0px;
}

;@import "sass-embedded-legacy-load-done:142";