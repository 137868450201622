.admin-news-organizer-picker {
	vertical-align: top;
	display: inline-block;
	height: 320px;
	width: 300px;
	margin-right: 40px;
	background-color: $content-background-color;
	overflow-y: scroll;
}
.admin-news-organizer {
	height: 32px;
	line-height: 32px;
	color: #000;

	&:nth-child(odd) {
		background-color: #fafafa;
	}
}
.admin-news-organizer-name {
	display: inline-block;
	width: 90%;
	padding-left: 8px;
}
.admin-news-organizer-pick {
	display: inline-block;
	width: 10%;
	text-align: center;
}
.admin-news-language-tabs {
	border: 1px solid #ddd;
}
.admin-news-language-context-box {
	padding: 8px;
	border: 1px solid #ddd;
	border-top: 0;
}

;@import "sass-embedded-legacy-load-done:84";