.overwatch,
.admin-overwatch,
.admin-overwatch-case {
	.input-label,
	.input-descr {
		display: flex;
		flex-direction: row;
		align-items: center;
		text-align: left;

		input {
			margin-right: 0.2em;
		}
	}
}

.admin-overwatch {
	.cases-table {
		th,
		td {
			text-align: left;
		}

		input {
			display: block;
		}

		& > tbody > tr,
		& > thead > tr {
			&.hidden {
				visibility: collapse;
			}

			&:before {
				content: '\a';
				width: 0.5em;
				height: 0.5em;
				display: inline-block;
				border-radius: 50%;
				margin-left: 1em;
			}

			&.open:before {
				background-color: #236f1f;
			}

			&.draft:before {
				background-color: #5e3f30;
			}

			&.closed:before {
				background-color: #880510;
			}
		}
	}

	.cases-table-header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		background-color: #191a24b3;

		.actions {
			display: flex;
			flex-direction: row;

			.action-column > * {
				margin: 0.25em;
			}
		}
	}
}

.admin-overwatch-case {
	.table-left-aligned th {
		text-align: right;
	}

	.input-description-wrapper {
		vertical-align: middle;

		.input-description {
			width: 100%;
			line-height: normal;
		}
	}

	.input-save-wrapper {
		text-align: right;
		border-top: 1px solid $dark-grey-border;
		padding: 0.5em;
		display: flex;
		flex-wrap: wrap;

		.entity {
			flex: 50%;
			&.left {
				text-align: left;
			}
		}
	}

	.header {
		.input-description-wrapper > textarea {
			border: none;
		}

		.case-header {
			padding: 0 0.5em;

			display: flex;
			flex-direction: row;
			flex-direction: space-between;

			.loading-wrapper {
				display: table;
				margin-left: auto;

				& > * {
					display: table-cell;
					vertical-align: middle;
				}
			}

			&.open {
				background-color: #236f1f;
			}

			&.closed {
				background-color: #880510;
			}

			&.draft {
				background-color: #5e3f30;
			}
		}

		.status-bar {
			padding: 0 0.5em;
			display: flex;
			flex-direction: row;
			align-items: center;

			h3 {
				margin: 0;
				margin-right: 0.5em;
				text-transform: uppercase;
			}
		}
	}

	table.metadata {
		th,
		td {
			vertical-align: top;
		}
		select:disabled {
			appearance: none;
			background: unset; /* the drop-down arrow is.. background */
		}

		.overwatch-verdict-comment {
			color: #999;
			line-height: normal;
			white-space: pre-wrap;
			word-break: break-all;
		}
	}

	.affected-report-wrapper {
		display: flex;

		.left {
			flex: 0 0 35%;
			max-width: 35%;
			margin-right: 1em;
		}

		.right {
			flex-grow: 1;
		}
	}

	.condensed-reports {
		display: flex;
		flex-wrap: wrap;

		.condensed-report {
			min-width: 300px;

			flex-basis: 48%;
			flex-grow: 1;

			margin: 1%;
		}
	}

	.chat {
		.message-list {
			width: 100%;
			height: 20em;
			overflow: auto;

			.action {
				margin: 0.5em 0;
				color: #ccc;
				font-style: italic;
			}
		}

		.message-draft {
			display: flex;
			margin-top: 10px;

			input {
				flex-grow: 1;
			}

			.button {
				margin-left: 10px;
			}
		}
	}
}

.overwatch .condensed-report {
	table {
		border: 1px solid #333;
		th,
		td {
			line-height: normal;
			padding: 0.75em;
		}
		tr {
			height: auto;
			line-height: normal;
		}
	}
	.description {
		line-height: 1.8em;
	}

	.toolbar {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: right;

		.button {
			margin-left: 0.5em;
		}
	}

	border-left: 3px solid;

	&.bad_behavior {
		border-color: #f90;
	}
	&.suspected_cheating {
		border-color: #f90;
	}
	&.chat_abuse {
		border-color: #f09;
	}
	&.voice_abuse {
		border-color: #09f;
	}
}

;@import "sass-embedded-legacy-load-done:42";