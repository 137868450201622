.notification-center {
	position: fixed;
	top: calc(#{$top-bar-above-height} + #{$top-bar-height});
	left: 0;
	width: 100%;
	height: calc(100vh - #{$top-bar-above-height} - #{$top-bar-height});
	overflow: auto;
	background-color: #fff;
	color: #000;

	@include desktop() {
		top: $top-bar-height;
	}

	.tabs {
		display: flex;
		height: 50px;
		background-color: initial;
		border-bottom: initial;

		.tab {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $secondary-color;
			height: 50px;
			border-top: 1px solid #000;
			border-bottom: 1px solid #000;
			text-align: center;
			color: #fff;

			&:nth-child(even) {
				border-right: 1px solid #000;
				border-left: 1px solid #000;
			}

			&.is-active {
				background-color: #062a3a;
			}

			.unread-count {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: $primary-hover-color;
				border-radius: 50%;
				margin-left: 0.5em;
				min-width: 1.5em;
				height: 1.5em;
				color: #000;

				&.is-green {
					background-color: #5bb601;
				}
			}
		}
	}

	/* Used in chats tab (recent chats) and friends tab */
	.friend-entry {
		display: flex;
		align-items: center;
		height: 50px;
		padding: 0 1em;
		width: 100%;
		border-bottom: 1px solid #000;

		.item-right {
			justify-content: flex-end;
			margin-left: auto;
		}

		.entry-avatar {
			width: 35px;
			height: 35px;
			background-size: cover;
			border-radius: 50%;
		}

		.entry-username {
			margin-left: 0.5em;
			font-weight: 600;
		}

		.entry-flag {
			margin-left: 0.5em;
		}
	}

	.chat-section {
		/* 100% - (tabs) */
		height: calc(100% - 50px);

		.recent-chats {
			height: 150px;
			overflow: auto;

			.item {
				.chat-messages-indicator {
					font-size: 11px;

					&.is-true {
						color: $primary-hover-color;
					}
				}
			}
		}

		.conversation-divider {
			content: ' ';
			width: 100%;
			height: 5px;
			background-color: #dadada;
		}

		.conversation {
			/* 100% - (recent-chats) - (chat-input) - (conversation-divider) */
			height: calc(100% - 150px - 50px - 5px);
			padding-top: 5px;
			overflow: auto;

			.item {
				display: flex;
				width: 100%;
				min-height: 50px;
				padding: 1em;

				&.is-self {
					justify-content: flex-end;
					margin-left: auto;

					.chat-content {
						.chat-bubble {
							background-color: #8fdeff;
						}
					}
				}

				.chat-avatar {
					width: 50px;
					height: 50px;
					background-size: cover;
					border-radius: 50%;
					margin-right: 0.5em;
				}

				.chat-content {
					display: flex;
					flex-flow: column;

					.chat-bubble {
						width: 50vw;
						word-wrap: break-word;
						background-color: #20a7df;
						padding: 0.7em 1em;
						border-radius: 3%;
					}

					.chat-timestamp {
						margin-top: 0.4em;
						font-size: 10px;
						color: #909090;
					}
				}
			}
		}

		.chat-input {
			display: flex;
			align-items: center;
			width: 100%;
			height: 50px;
			border-top: 3px solid #dadada;
			padding: 0 1em;

			input {
				width: 100%;
				background-color: inherit;
				border: none;
				font-size: 16px;
				color: #000;
			}
		}

		.admin-symbol {
			margin-left: 0.5em;
		}
	}

	.friends-section {
		.item {
			.open-chat-indicator {
				font-size: 20px;
				color: #1fa8d6;

				.new-messages-indicator {
					position: absolute;
					width: 10px;
					height: 10px;
					background-color: #40fff6;
					border-radius: 50%;
					right: 28px;
				}
			}
		}

		.admin-symbol {
			margin-left: 0.5em;
		}
	}

	.notifications-section {
		.action-notification-entry {
			background-color: #fff;
			border: none;

			&:nth-child(even) {
				background-color: #f2f2f2;
			}
		}
	}

	/* When the mobile keyboard is open */
	&.is-keyboard-open {
		.tabs {
			display: none;
		}

		.chat-section {
			height: 100%;

			.recent-chats,
			.conversation-divider {
				display: none;
			}

			.conversation {
				/* 100% - (chat-input) */
				height: calc(100% - 50px);
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:46";