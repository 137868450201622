#alert-message {
	position: absolute;
	top: 120px;
	left: 0;
	width: 100%;
	height: 50px;
	text-align: center;
	line-height: 50px;
	white-space: nowrap;
	overflow-x: scroll;
	overflow: -moz-scrollbars-none;

	@include mobile() {
		top: 70px;
	}

	&::-webkit-scrollbar {
		display: none;
	}
}

.alert-notice {
	background-color: #641ae1;
	color: #fff;

	.icon {
		color: #ff7600;
	}
}

.alert-danger {
	background-color: rgb(184, 0, 0);
	color: #fff;
}

.alert-success {
	background-color: rgb(45, 150, 31);
	color: #fff;
}

;@import "sass-embedded-legacy-load-done:50";