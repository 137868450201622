.admin-time-selector {
	float: left;
	margin-right: 50px;
	width: 200px;
}

.admin-graph-grouping {
	vertical-align: top;
	margin-top: 18px;
}

.admin-graph-grouping-select,
.admin-graph-grouping-button {
	height: 42px;
}

;@import "sass-embedded-legacy-load-done:83";