.blocked {
	display: flex;
	background-color: #eee;
	align-items: center;
}
.blocked:nth-child(odd) {
	background-color: #fff;
}
.blocked-avatar {
	display: inline-flex;
	margin: 10px 10px;
	height: 30px;
	width: 30px;
	border: 2px solid $primary-color;
}

.blocked-country {
	display: inline-flex;
}
.blocked-username {
	margin-left: 10px;
	margin-right: 5px;
	display: inline-flex;
}
.button-unblock-blocked {
	font-size: 20px;
	display: inline-flex;
	cursor: pointer;
	transition: background-color 0.25s;
	margin-left: auto;
	margin-right: 10px;
	color: #aa000b;
	border-radius: 10px;
	text-align: center;
	line-height: 24px;
	justify-content: center;
}

.button-unblock-blocked:hover {
	color: #df000b;
}

;@import "sass-embedded-legacy-load-done:30";