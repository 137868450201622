.checkout-box {
	max-width: 740px;
	margin: 0px auto;
	padding: 45px 80px;

	@include mobile() {
		padding: 10px 15px;
	}

	.wysiwyg-content.wysiwyg-content-transparent {
		text-align: left;
		display: flex;
		align-items: center;
		flex-direction: column;
	}
}
.checkout-table {
	white-space: normal;
	th {
		color: #000;
		text-transform: uppercase;
		font-size: 12px;
	}
	tr,
	th {
		background-color: transparent;
	}
	tr {
		border-bottom: 1px solid #e1e1e1;
	}
	.checkout-table-recurring {
		font-size: 12px;
		line-height: normal;
		font-style: italic;
	}
}
.checkout-box-title {
	text-align: center;
	margin: 20px 0px;
	font-size: 30px;
	font-weight: 300;

	.fa-check-circle {
		color: #0c0;
	}
}
.checkout-box-vat {
	clear: both;
	float: right;
	margin-top: 15px;

	.checkout-box-vat-label {
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
	}
	.checkout-box-vat-value {
		font-size: 14px;
	}
}

.checkout-box-total {
	clear: both;
	float: right;
	margin-top: 15px;

	.checkout-box-total-label {
		text-transform: uppercase;
		font-weight: 700;
		font-size: 14px;
	}
	.checkout-box-total-value {
		font-size: 16px;
	}
}

.checkout-tos {
	float: left;
	width: 100%;
	margin-top: 16px;
	text-align: center;
}

.checkout-provider {
	font-size: 12px;
	text-align: center;
	margin-top: 10px;
}

.checkout-wrapper {
	max-width: 950px;
	margin: 0 auto;
	display: flex;

	.checkout-box {
		margin: 0;
		padding: 40px 80px;
		max-width: 640px;
	}
}

.checkout-snack {
	flex-grow: 1;
	margin-left: 20px;

	.offers-entries {
		.offers-entry {
			margin-top: 0;
			width: 100%;

			.offer-entry-motto {
				font-size: 26px;
				font-weight: 600;
				margin-bottom: 12px;
			}
		}
	}
	.bottom-image {
		margin-bottom: -20px;
		position: absolute;
		bottom: 0;
		z-index: -1;
		width: 370px;
	}
}

#adyen-payment-form {
	overflow: auto;

	.checkout-service-agreement {
		margin-bottom: 15px;
	}

	.adyen-checkout__payment-method {
		background: #191a24;
		border: 0;
		border-radius: 0;
	}
	.adyen-checkout__payment-method__header__title {
		color: #fff;
	}
	.adyen-checkout__label__text {
		color: #fff;
		font-size: 13px;
	}
	.adyen-checkout__error-text {
		font-size: 12px;
	}
	.adyen-checkout__input {
		background: #14151d;
		border: 1px solid #2d2f3b;
		border-radius: 4px;
		color: #fff;
		font-size: 13px;
	}
	.adyen-checkout__payment-method--selected {
		background: #1c1e29;
		padding-bottom: 1px;
	}
	.adyen-checkout__button--pay {
		background: transparent linear-gradient(90deg, #ff8400 0%, #ff6600 100%) 0% 0% no-repeat padding-box;
	}
	.adyen-checkout__threeds2__challenge {
		background-color: #e3e3e3;
		margin: 0px auto;
	}
}

;@import "sass-embedded-legacy-load-done:100";