.team-stats-heading {
	width: 80%;
	margin-left: 10%;
}

.team-stats-team-name {
	font-size: 17px;
	display: inline-block;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 80%;
	white-space: nowrap;

	.team-stats-logo {
		vertical-align: middle;
		display: inline-block;
		border: 1px solid $primary-color;
		width: 50px;
		height: 50px;
		margin-right: 10px;
		background-size: cover;
	}

	.team-stats-team-country-flag {
		margin-left: 10px;
	}
}

.team-stats-rating {
	float: right;
	line-height: 50px;

	.team-stats-text {
		font-weight: 500;
		font-size: 17px;
	}

	.team-stats-value {
		margin-left: 5px;
		font-size: 17px;
	}
}

.team-stats-lower-heading {
	margin-top: 10px;
}

.team-stats-stats-card {
	display: flex;
	position: relative;
	margin: 0px 10px;
	text-align: center;
	background-color: #67d4fd;
}

.team-stats-meta {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	flex: 1;
}

.team-stats-stats-card-value {
	font-size: 20px;
	z-index: 2;
	position: relative;
	color: #ffffff;
}

.team-stats-stats-card-text {
	font-size: 12px;
	z-index: 2;
	position: relative;
	color: #000000;
}

.team-stats-wins-stat {
	width: 30%;
	font-size: 40px;
	color: #a7d101;
}

.team-stats-losses-stat {
	width: 30%;
	font-size: 40px;
	color: #a60000;
}

.team-stats-player-row {
	position: relative;
}

.team-stats-body {
	margin-top: 15px;

	th {
		background-color: #00a9ea;
	}
	th:first-child,
	th:last-child {
		border: 0px;
	}

	.stats-content .column .content-heading {
		background-color: #00a9ea;
	}

	tbody {
		max-width: 384px;
		position: relative;

		td {
			width: initial;
			overflow: hidden;

			&.table-index {
				width: 30px;
			}

			.player-card-wrapper {
				max-width: 60%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}

	.top-players tr th,
	.top-players-by-weapon tr th {
		background-color: #005a7e;
	}

	option:first-child {
		color: #ccc;
	}

	select.team-stats-grayed-out {
		color: #ccc;
	}

	select.team-stats-grayed-out option:not(:first-child) {
		color: black;
	}
}

.team-stats-global-stats-heading {
	display: inline-block;
	vertical-align: middle;
}

.team-stats-global-stats-heading-separator {
	margin: 0px 10px;
}

.team-stats-latest-achievements-heading {
	display: inline-block;
	margin-right: 15px;
}

.team-stats-latest-achievements-filter {
	margin: 0px 15px 0px 15px;
	text-transform: uppercase;
	color: $primary-color;
	cursor: pointer;
}

.team-stats-active {
	color: #000;
}

.team-stats-latest-achievements-card {
	height: 150px;
}

.team-stats-achievement-body {
	display: inline-block;
	width: 50%;
	height: 100%;
	background-color: #006991;
	color: #ffffff;

	.team-stats-achievement-player a {
		color: #ffffff;
	}
}

.team-stats-achievement-player {
	text-align: center;
	margin-bottom: 10px;
	font-weight: 500;
	font-size: 14px;
	text-overflow: ellipsis;
	overflow: hidden;
}
.team-stats-achievement-avatar {
	width: 50px;
	height: 50px;
	border: 2px solid $primary-color;
	vertical-align: middle;
	margin-right: 5px;
}
.team-stats-achievement-text {
	text-align: center;
	font-size: 15px;
	text-transform: uppercase;
}
.team-stats-achievement-date {
	text-align: center;
	color: #968d8e;
}
.team-stats-achievement-place {
	text-align: center;
	font-size: 20px;
	text-transform: uppercase;
	margin-bottom: 10px;

	&.team-stats-achievement-first-place {
		color: #f1b94c;
	}
	&.team-stats-achievement-second-place {
		color: #c0c0c0;
	}
	&.team-stats-achievement-third-place {
		color: #c87533;
	}
	.team-stats-achievement-super {
		vertical-align: super;
		font-size: 10px;
	}
}

.team-stats-achievement-image {
	float: right;
	width: 50%;
	height: 100%;
	background-color: #f5f5f5;
	line-height: 150px;
	text-align: center;
	overflow: hidden;
	border: 2px solid #e4e4e4;
	border-left: 0px;
}

.team-stats-achievement-trophy {
	font-size: 104px;
	vertical-align: middle;
}
.team-stats-achievement-first-place {
	color: #f1b94c;
}
.team-stats-achievement-second-place {
	color: #c0c0c0;
}
.team-stats-achievement-third-place {
	color: #c87533;
}
.team-stats-achievement-level-up {
	white-space: nowrap;
	transform-origin: center;
}
.team-stats-achievement-level {
	height: 70px;
	width: 74px;
	margin-top: 30px;
	background-size: cover;
	background-repeat: no-repeat;
	display: inline-block;
	vertical-align: top;
}
.team-stats-achievement-rank {
	display: inline-block;
	width: 80.725px;
	height: 62.5px;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	vertical-align: middle;
}
.team-stats-achievement-arrow {
	color: $button-color;
	font-size: 40px;
	line-height: 150px;
	vertical-align: middle;
	margin: 0px 20px 0px 20px;
}

;@import "sass-embedded-legacy-load-done:97";