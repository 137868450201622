$primary-color: #00a9e9;
$primary-hover-color: #13aceb;

$secondary-color: #1b1d26;
$secondary-hover-color: #132d38;
$secondary-inactive-color: #24252e;

$card-background-color: #1a1d25;

$important-color: #ff8400;
$important-hover-color: #e57803;

$button-color: #00a9ea;
$button-hover-color: #00678d;
$gold-color: #f1bc1b;

$content-background-color: #14151d;

$prime-color: #8522ff;
$prime-color-dark: #5700a2;
$prime-hover-color: #8522ff;
$prime-color-transparent: #8522ff63;

$premium-color: #ff8020;
$premium-color-dark: #d26522;
$premium-hover-color: #ff8020;

$grey-text-color: #c0c6d1;
$white-text-color: #ffffff;
$dark-grey-text-color: #727784;
$light-grey-text-color: #c0c6d1;
$dark-grey-border: #292a34;
$chat-bar-background-color: #17181e;
$top-bar-height: 70px;
$top-bar-above-height: 70px;
$left-sidebar-width: 240px;
$right-sidebar-width: 240px;
$right-sidebar-wide-width: 300px;
$right-sidebar-fullhd-width: 390px;
$right-side-narrow-breakpoint: 1500px;
$right-side-wide-breakpoint: 1600px;
$chat-bar-height: 40px;

$content-padding-horizontal: 24px;

$table-header-background-color: #20212d;

$notifications-background-color: #262730;

$content-header-font-family: 'Roboto', serif;
$content-header-font-weight: 500;
$content-header-1-font-size: 38px;
$content-header-2-font-size: 26px;
$content-header-3-font-size: 18px;
$content-header-font-color: #ffffff;

$scrollbar-thumb-color: #292a34;
$scrollbar-thumb-hover-color: #555;

;@import "sass-embedded-legacy-load-done:19";