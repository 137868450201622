#admin-content {
	.group-role-selector .flex {
		align-items: center;
	}

	.admin-user-permissions > .permission-groups .header {
		display: flex;
		flex-direction: row;
		align-items: center;

		margin: 0 0 10px 0;
		padding: 0 10px;

		color: $premium-color;
		font-weight: bold;

		box-shadow: inset 6px 0 0 $premium-color;
		background: $secondary-color;

		&:hover {
			color: $prime-color;
			background: $secondary-hover-color;
			cursor: pointer;
		}

		.fas {
			width: 20px;
		}

		.indicator {
			flex-grow: 1;
			text-align: right;
			color: $premium-color;
		}

		.summary {
			color: #ddd;
			display: flex;
			align-items: center;

			span {
				display: block;
				margin-right: 5px;
			}

			.faded {
				color: #ccc;
			}
		}
	}

	.admin-user-permissions > .permission-groups .permission-group {
		display: flex;
		flex-direction: column;

		&.collapsed {
			max-height: 0;
			overflow: hidden;
		}

		&.expanded {
			max-height: none;
		}
	}
}

;@import "sass-embedded-legacy-load-done:44";