.news-latest-news-entry {
	display: block;
	position: relative;
	width: 100%;
	height: 207px;
	margin-bottom: 32px;
	background-image: url(../img/logo.png);
	background-color: #0072bc;
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.news-latest-entry-title {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-weight: 600;
}
.news-latest-entry-date {
	font-size: 12px;
	color: #fff;
}
.news-latest-entry-comments {
	font-size: 12px;
}

.news-article {
	padding: 5px 10px;

	.news-article-title {
		font-size: 34px;
	}
	.news-article-meta {
		padding-top: 1em;

		.date-comments {
			color: $dark-grey-text-color;
			font-size: 16px;
			display: flex;
			justify-content: space-between;

			.date {
				margin-right: 1em;
			}
			.fa-comment {
				margin-right: 5px;
			}
		}
	}
}
.news-article-image {
	width: 100%;
	border-radius: 4px;
	display: block;
}
.news-article-content {
	> * {
		margin-top: 1em;
		margin-bottom: 0;
	}

	ul {
		list-style-type: disc;
		list-style-position: inside;

		> li {
			line-height: 21px;
		}
	}
}
.news-article-content.wysiwyg-content-transparent {
	margin-top: 3em !important;
}
.news-thumb-image {
	max-width: 330px;
}

.news-user-comments {
	margin-top: 30px;

	.new-comment-input {
		width: 100%;
		border-radius: 20px;
		margin-bottom: 1em;
		font-size: 16px;
		padding: 20px;
	}
	.submit-wrapper {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 4em;
	}
	.reply-button-wrapper {
		display: block;
		text-align: right;
	}
}

.fa-comment {
	transform: scaleX(-1);
}

.latest-news,
.featured-news {
	.news-thumb-image {
		width: auto;
		height: auto;
		position: relative;
		background-image: url(../img/logo.png);
		background-color: rgba(0, 0, 0, 0.5);
		background-size: contain;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		border-radius: 4px;
	}
	.news-latest-entry-title {
		color: #fff;
		font-size: 20px;
		padding: 10px 0;
	}
	.cut {
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: 400;
		line-height: 1.5em;
		height: 3em;

		//show ellipsis at the end of 2 lines
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}
	.news-latest-entry-overlay {
		font-size: 14px;
		align-self: center;
		width: 100%;
	}
	.news-latest-entry-comments,
	.news-latest-entry-date,
	.cut {
		color: #6d788a;
		font-size: 14px;
	}
	.news-latest-entry-comments {
		padding-left: 4em;

		.fa-comment {
			margin-right: 5px;
		}
	}
	.news-entry {
		display: flex;

		.thumb-column {
			flex-grow: 0;
		}
		.title-column {
			display: flex;
			align-content: center;
		}
	}
	.heading {
		color: $grey-text-color;
		font-size: 18px;
		text-transform: capitalize;
		padding: 0.75rem;
	}

	.read-more {
		text-transform: capitalize;
		padding-top: 5px;
	}
}

.featured-news {
	.heading {
		color: #fff;

		.fa-star {
			color: $primary-color;
			padding-right: 5px;
		}
	}
	.home-left-column-stickies {
		display: flex;
		justify-content: space-between;
		overflow: auto;

		.entry {
			&:not(:first-child) {
				display: none;
			}

			.meta {
				height: 100px;
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
				padding-left: 25px;
				padding-top: 18px;

				.title-wrap {
					display: flex;
					align-items: center;
					margin-top: 7px;
					font-size: 22px;

					.fa-star {
						padding-right: 5px;
					}

					.title {
						color: $primary-color;
						padding: 0px;
						font-size: 20px;

						@include mobile() {
							font-weight: 700;
						}
					}
				}

				.date,
				.comments {
					font-size: 15px;
					margin-right: 30px;
					color: #6d788a;
				}
			}

			@include fullhd() {
				margin: 0 1em;

				&:not(:first-child) {
					display: block;
				}
				&:first-child {
					margin-left: 0;
				}
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}
@include mobile() {
	.latest-news {
		max-width: 100%;
		overflow: hidden;
		.news-entry {
			flex-direction: column;
			flex-wrap: wrap;
			background-color: #10101a;

			.overflow-auto {
				overflow: unset;
			}
			.thumb-column {
				text-align: center;
				padding: 0;
			}
			.read-more,
			.cut {
				display: none;
			}
			.news-latest-entry-title {
				font-size: 20px;
			}
		}
	}
}

.lemondogs-news {
	.content-heading {
		display: flex;
		align-items: center;

		.logo.lemondogs {
			height: 40px;
			width: 40px;
			margin-left: 10px;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			background-image: url(../img/logo/lemondogs_logo_nooutline.png);
		}
	}

	.card .meta .title {
		color: #fff;
		padding-top: 5px;
	}
}

.news-article-page {
	display: flex;
}
.news-article-left-column {
	min-width: 330px;
	width: 330px;
	margin-right: 15px;
}
.news-article-center-column {
	flex-grow: 1;
}
.news-article-right-column {
	min-width: 250px;
	width: 250px;
	margin-left: 15px;
}

.news-youtube-section {
	.home-youtube-videos {
		@include tablet() {
			flex-direction: row;
			justify-content: space-evenly;

			.video {
				width: 49%;
			}
		}
		@include desktop() {
			.video {
				width: 32%;
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:77";