.gathers-header {
	min-height: 248px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-image: url(../img/banners/matchmaking_gathers_header.png);
	background-position-y: top;

	.gather-header-left-column {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: center;
		margin-left: 20px;
	}
	.gather-header-right-column {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: center;

		margin-top: 55px;
		margin-bottom: 55px;

		.buttons {
			display: flex;
			margin-top: 18px;
		}
	}
	.gathers-header-content {
		.matchmaking-login-instructions {
			margin-left: 30px;
		}
	}
}

.gather-header-buttons-title {
	font-size: 18px;
}

.gathers-header-gather-button {
	font-weight: 550;
	text-transform: capitalize;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	&.create {
		padding-left: 50px;
		padding-right: 50px;
	}
	&.join {
		padding-left: 45px;
		padding-right: 45px;
	}
}
.gathers-banner-title {
	font-size: 36px;
	font-weight: 550;
	color: #fff;
	display: flex;
	align-items: center;

	.fa-crosshairs {
		color: $primary-color;
		margin-right: 10px;
		font-size: 40px;
	}
}
.gathers-banner-subtitle {
	margin-top: 16px;
	font-size: 18px;
	color: #fff;
}
.gathers-column-header {
	font-size: 18px;
	font-weight: 500;
	color: $white-text-color;
	padding-top: 16px;
	padding-bottom: 20px;
}

.gathers-content-footer {
	min-height: 35px;
	display: flex;
	justify-content: center;
	align-items: center;

	@include mobile() {
		display: flex;
		justify-content: space-between;
	}

	.button {
		padding: 0.5em 0.5em;
		min-width: 90px;
		height: 100%;

		&.disabled {
			background-color: transparent !important;
			color: #c0c6d1;
			cursor: unset;
		}

		@include mobile() {
			min-width: 40%;
			font-size: 18px;
		}
	}
	.counter {
		color: $grey-text-color;
		font-size: 13px;
	}
}

;@import "sass-embedded-legacy-load-done:89";