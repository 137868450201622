#invite-friends-or {
	position: relative;
	height: 60px;
	margin-bottom: 10px;
}
#invite-friends-or-line {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -350px;
	margin-top: -0.5px;
	height: 1px;
	background-color: #d7d7d7;
	width: 700px;
}
#invite-friends-or-circle {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -22.5px;
	margin-left: -22.5px;
	border-radius: 50%;
	background-color: #d7d7d7;
	width: 45px;
	height: 45px;
	line-height: 45px;
	text-align: center;
	font-weight: 500;
	font-size: 12px;
}
#invite-friends-left {
	display: inline-block;
}
#invite-friends-right {
	display: inline-block;
	vertical-align: top;
	margin-left: 33px;
}
.invite-friends {
	max-width: 920px;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 15px;

	.content-heading.centered-heading {
		margin-top: 40px;
		text-align: center;
		font-size: 20px;
	}
}
.invite-friends-table {
	table-layout: fixed;

	th {
		background-color: #20212d;
		height: inherit;
	}
	& th:first-child {
		border-radius: 10px 0 0 0;
	}

	& th:last-child {
		border-radius: 0 10px 0 0;
	}
}

.invite-friends-table > thead,
.invite-friends-table > tbody {
	display: block;

	> tr > .rank-column {
		min-width: 52px;
	}

	> tr > .player-column {
		width: 50%;
		text-align: left;
	}

	> tr > .status-column {
		width: 25%;
	}

	> tr > .select-column {
		width: 4.5%;
	}

	> tr > .scroll-bar {
		width: 3.5%;
	}

	> tr > .kick-column {
		width: 14.5%;
	}

	> tr > .non-scroll-bar-select-column {
		width: 14.5%;
	}
}

.invite-friends-table > thead {
	background-color: transparent;
	> tr {
		height: 48px;
		line-height: 28px;
	}

	> tr > th {
		text-align: center;
		font-weight: 400;
	}
}
.invite-friends-table > tbody {
	height: 260px;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: #191b24;

	tr {
		display: table-row;
		height: 50px;
		border-bottom: 1px solid #1e1f29;
	}
	td {
		border: none;
		text-align: center;
	}
	.player-column {
		line-height: 50px;
		text-align: left;
	}
	tr:nth-child(odd) {
		background-color: #191a24;
	}
	tr:nth-child(even) {
		background-color: #14151d;
	}
	.kick-button {
		font-weight: 700;
	}
	tr:nth-child(odd) .kick-button {
		background-color: $content-background-color;
		color: #000;
	}
	tr:nth-child(even) .kick-button {
		background-color: #fff;
		color: #000;
	}
}
.friends-user-avatar {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	width: 32px;
	height: 32px;
	background-size: cover;
	background-repeat: no-repeat;
	border: 2px solid $primary-hover-color;
	border-radius: 50%;
	margin: 0 10px;
}
.friends-username {
	display: inline-block;
	vertical-align: middle;
	max-width: 120px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.invite-friends-table-header {
	width: 100%;
	height: 45px;
	line-height: 45px;
	margin-bottom: 4px;
	text-align: left;
	font-weight: 700;
	font-size: 17px;
	color: white;
}
.queue-button {
	width: 100%;
	max-width: 450px;
	height: 45px;
	margin-bottom: 4px;
	font-weight: 700;
	font-size: 16px;
	background-color: #5ec76c;
}
.queue-button:hover,
.queue-button:focus {
	background-color: #6ed77c;
}
.queue-button-solo {
	display: block;
	height: 45px;
	margin: 10px auto;
}

.queue-invite-button,
.queue-leave-button {
	text-align: right;
	margin: 16px 0 8px 0;
}
.queue-leave-button {
	> button {
		background-color: #e4454b;
		min-width: 120px;
	}
	> button:hover,
	> button:focus {
		background-color: #f4555b;
	}
}

.invite-no-friends {
	width: 446px;
}

button.button-new-style.pre-invite-button,
.button.button-new-style.chat-button {
	position: relative;
	width: 40px;
	padding: 0px;
	margin: 0 2px;

	&:hover {
		color: #ffffff;
	}
}

button.button-new-style.pre-invite-button {
	padding: 10px;
}

;@import "sass-embedded-legacy-load-done:37";