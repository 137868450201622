.theme-prime {
	.top-bar-below-item.active,
	.top-bar-below-item:hover {
		box-shadow: inset 0 -5px 0 $prime-hover-color;
		color: #fff;
	}
	.top-bar-volume {
		color: $prime-hover-color;
	}
	.top-bar-caret {
		color: $prime-hover-color;
	}
	.top-bar-dropdown-icon {
		color: $prime-hover-color;
	}
	.top-bar-notifications-dropdown::before {
		border-bottom-color: $prime-hover-color;
	}
	.top-bar-notifications-content {
		border-top-color: $prime-hover-color;
	}
	.top-bar-search-result {
		&.hover,
		&:hover {
			background-color: $prime-hover-color;
			color: #fff;
		}
	}

	.top-bar-matchmaking {
		&:hover {
			box-shadow: inset 0 -5px 0 $prime-hover-color;
		}

		span {
			color: $prime-hover-color;
		}

		&.active {
			background-color: $prime-hover-color;
			color: #fff;
		}
	}

	.top-bar-below-item > i {
		color: $prime-hover-color;
	}
	.top-bar-below-icon.icon-ranking {
		background-image: url(../img/menu/ranking_prime.png);
	}

	@include mobile() {
		.top-bar-dropdown .top-bar-item.active {
			box-shadow: inset 0 -5px 0 $prime-hover-color;
		}
	}
	#left-side-navigation {
		.main-menu .item {
			.fas {
				color: $prime-hover-color;
			}
			.icon-ladders,
			.icon-public-servers {
				filter: brightness(0) saturate(100%) invert(23%) sepia(96%) saturate(5787%) hue-rotate(265deg)
					brightness(99%) contrast(104%);
			}
		}
		.main-menu .expanded {
			> ul li {
				&:hover {
					a {
						color: $prime-hover-color;
						box-shadow: inset 6px 0 0 $prime-hover-color;
					}
				}

				a.active-nav-link {
					color: $prime-hover-color;
					box-shadow: inset 6px 0 0 $prime-hover-color;
				}
			}
		}
		.item {
			&.active,
			&:hover {
				color: $prime-hover-color;
				box-shadow: inset 6px 0 0 $prime-hover-color;
				.fas {
					color: $prime-hover-color;
				}
				.icon-ranking {
					filter: brightness(0) saturate(100%) invert(13%) sepia(100%) saturate(5913%) hue-rotate(267deg)
						brightness(109%) contrast(100%);
				}
			}
		}
	}
}

.subscriptions-prime-status-star {
	font-size: 26px;
	line-height: 40px;
	font-weight: 700;
	text-transform: uppercase;

	.icon-prime {
		margin-top: -15px;
	}
}
.prime-color {
	color: $prime-color;
}

.prime {
	.avatar,
	&.avatar,
	&.friends-user-avatar,
	&.stats-bar-user-avatar,
	&.top-bar-avatar,
	&.activity-icon,
	&.stats-bar,
	.friends-user-avatar {
		border-color: $prime-color !important;
	}
	&.button:not([disabled]) {
		background-color: $prime-color !important;
	}
	&.stats-bar-games-ratio-bar,
	&.box-header {
		background-color: $prime-color !important;
	}
}

.prime-transparent {
	&.box-header {
		background-color: $prime-color-transparent !important;
	}
}

.user-profile-stats-boxes.prime,
.user-stats-page.prime,
.user-stats-page-only.prime {
	.stat-box {
		background-color: $prime-color !important;
	}
	.stat-box:nth-child(even) {
		background-color: $prime-color-dark !important;
	}
}

.invite-friends-table > tbody {
	tr.prime .friends-user-avatar {
		border-color: #fff !important;
	}

	tr.prime {
		&,
		.kick-button,
		.player-card-wrapper {
			color: #fff !important;
		}
	}
	tr.prime:nth-child(odd) {
		&,
		.kick-button {
			background-color: $prime-hover-color !important;
		}
	}

	tr.prime:nth-child(even) {
		&,
		.kick-button {
			background-color: $prime-color-dark !important;
		}
	}
}

.snack-logo {
	.prime-queue {
		background-color: transparent;
		background-image: url(../img/prime.png);
		background-size: 40px 40px;
		background-position: 50% 50%;
		background-repeat: no-repeat;
	}
	.prime-queue-low-hours {
		background-color: transparent;
		background-image: url(../img/misc/records_icons_headshot.png);
		background-size: 40px 40px;
		background-position: 50% 50%;
		background-repeat: no-repeat;
	}
}

;@import "sass-embedded-legacy-load-done:139";