.game-result-popover {
	width: 640px;
	max-width: 100%;
	padding: 0 0 20px 0;
	border-radius: 5px;

	.popover-content {
		padding: 0;
		position: relative;
		background-color: #20212d;

		.current-rank {
			padding: 0 50px;

			&.rank-up {
				.ranks-view {
					.current-rank {
						background: none;
					}

					.side-rank,
					.line-right,
					.line-left {
						display: none;
					}
				}
			}

			.subtitle {
				font-size: 16px;
			}

			p {
				font-size: 20px;
				text-align: center;
				font-weight: 400;
			}

			.ranks-view {
				display: flex;
				justify-content: space-around;
				align-items: center;
				position: relative;
				width: 400px;
				margin: 0 auto;

				.line-left {
					top: 39%;
					width: 40px;
					left: 83px;
					height: 3px;
					position: absolute;
					z-index: 1;
				}

				.line-right {
					top: 39%;
					width: 40px;
					left: 276px;
					height: 3px;
					position: absolute;
					z-index: 1;
				}

				img {
					max-width: 69px;
				}

				.side-rank {
					z-index: 1;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					&.empty {
						width: 70px;
					}

					p {
						padding: 0 10px;
						border-radius: 10px;
						font-size: 10px;
						width: max-content;
						line-height: 20px;
						background-color: #3a3b4b;
					}
				}

				.current-rank {
					z-index: 1;
					background: #292a38 0% 0% no-repeat padding-box;
					border-radius: 4px;
					width: 152px;
					height: 165px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					img {
						max-width: 112px;
					}

					p {
						padding: 0 10px;
						border-radius: 10px;
						font-size: 10px;
						width: max-content;
						line-height: 20px;
						background-color: #3a3b4b;
					}
				}
			}
		}

		.elo-progress {
			background-color: #14141c;
			font-size: 14px;
			padding: 18px 0;
			margin: 20px 0;
			line-height: unset;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			p {
				margin: 0;
			}

			.progress-bar-wrapper {
				display: flex;
				flex: 1;
				align-items: center;
				justify-content: center;
				width: 300px;

				img {
					max-width: 29px;
					width: 29px;
					z-index: 1;

					&.left {
						margin-right: 0;
					}

					&.right {
						margin-right: 0px;
					}
				}

				.progress-bar-bg {
					position: relative;
					height: 7px;
					width: calc(100% - 60px);

					z-index: 0;
				}

				.progress-bar {
					position: absolute;
					background-color: #292a38;
					height: 7px;
					right: 0;
				}
			}

			&.locked {
				.rank-image {
					height: 79px;
					margin: 10px;
				}

				.rank-footer {
					width: 100%;
					background-size: 200% 3px;
					background-repeat: no-repeat;
					background-position: center;
				}

				.rank-name {
					padding: 0 10px;
					border-radius: 10px;
					background-color: #3a3b4b;
					color: #c6cfde;
				}

				.rank-display {
					display: flex;
					flex-flow: row;
				}

				.rank-wrapper {
					display: flex;
					flex-flow: column;
					align-items: center;
					justify-content: center;

					* {
						margin: 10px;
					}

					&.focused {
						background-color: #292a38;

						&.first {
							border-radius: 4px 0 0 4px;
						}

						&.last {
							border-radius: 0 4px 4px 0;
						}
					}

					&:not(.focused) {
						opacity: 0.34;
					}
				}
			}
		}

		.close-button {
			position: absolute;
			top: 15px;
			right: 15px;
			cursor: pointer;
		}

		.header {
			padding: 30px 0 0px 0;
			display: flex;
			justify-content: center;
			align-items: center;

			h1 {
				color: #ffffff;
				font-size: 22px;
				margin: 0 10px;
			}

			p {
				font-size: 12px;
				color: #20212d;
				padding: 0 13px;
				border-radius: 15px;

				&.win {
					background-color: #acde21;
				}

				&.loose {
					background-color: #fb0741;
				}
			}
		}

		.locked-rank-indicator {
			margin: 0 auto;
			max-width: 400px;

			&,
			.indicator-wrapper {
				padding: 0.5em 0;
			}
		}

		.button-wrapper {
			background-color: #292a38;
			border-radius: 0 0 5px 5px;

			.button {
				color: white;
				font-size: 13px;
				padding: 8px 57px;
				margin: 20px 0;
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:112";