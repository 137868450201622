.cards {
	display: flex;
	width: 100%;
	justify-content: center;
	padding: 0px 24px 24px;
	flex: 1;

	@include mobile() {
		flex-wrap: wrap;
	}

	.card {
		width: 390px;
		color: white;
		margin: 10px;
		opacity: 0.8;

		.visual {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			height: 220px;
			border-radius: 4px;

			.home-image {
				width: 100%;
				height: 100%;
				background-position: center bottom;
				background-repeat: no-repeat;
				background-size: contain;

				&.gathers {
					background-image: url(../img/home/gathers.jpg);
					&.lang-se {
						background-image: url(../img/home/gathers-SE.jpg);
					}
					&.sr-pl {
						background-image: url(../img/home/pl_gathers.png);
					}
					&.sr-dach {
						background-image: url(../img/home/dach_gathers.png);
					}
				}

				&.public {
					background-image: url(../img/home/public.jpg);
					&.lang-se {
						background-image: url(../img/home/public-SE.jpg);
					}
					&.sr-pl {
						background-image: url(../img/home/pl_public.png);
					}
					&.sr-dach {
						background-image: url(../img/home/dach_public.png);
					}
				}
			}

			h1 {
				margin: 0;
				line-height: inherit;
			}
		}

		.info {
			background: #1d1e29 0% 0% no-repeat padding-box;
			border-radius: 4px;
			padding: 14px 16px;

			font-weight: 400;
			font-size: 11px;
			line-height: inherit;

			.subtitle {
				margin: 0;
				font-size: 14px;
			}

			.description {
				display: flex;
				justify-content: space-between;
				p {
					margin: 0;
				}
			}
		}
	}

	.card:hover {
		opacity: 1;
		box-shadow: 0px 3px 6px #00000085;
	}
}

.home-main-banner {
	margin-bottom: -60px;
	background-image: url(../img/home/homebackground_blue.png);
	&.nordic-masters-dk,
	&.nordic-masters-dk-live,
	&.nordic-masters-se,
	&.nordic-masters-se-live {
		background-size: contain;
		background-position: left top;
	}

	&.nordic-masters-dk {
		background-image: url(../img/home/nordicmastersDK.jpg);
	}
	&.nordic-masters-se {
		background-image: url(../img/home/nordicmastersSV.jpg);
	}
	&.nordic-masters-dk-live {
		background-image: url(../img/home/nordicmastersDKlive.jpg);
	}
	&.nordic-masters-se-live {
		background-image: url(../img/home/nordicmastersSVlive.jpg);
	}

	&.super-days {
		background-image: url(../img/home/homebackground-super-days.jpg);
		background-size: contain;
		background-position: left top;

		@media only screen and (max-width: 1880px) {
			background-size: 110%;
		}
	}

	&.prime {
		background-image: url(../img/home/homebackground_purple.png);
	}

	&.premium {
		background-image: url(../img/home/homebackground_orange.png);
	}

	&.sr-pl {
		background-image: url(../img/home/pl_banner.png);
		background-size: contain;
	}

	&.sr-pl-new {
		background-image: url(../img/home/esportal_poland.jpg);
		background-size: contain;
		@media only screen and (max-width: 800px) {
			padding-top: 50%;
		}
	}

	&.sr-dach {
		background-image: url(../img/home/dach_banner.png);
		background-size: contain;
	}

	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;

	.banner-mobile-picture {
		display: none;
		width: 100%;
	}

	.home-welcome {
		width: 100%;
		background-color: #00000057;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		font-size: 20px;
		padding: 18px;
		text-align: center;

		.highlight-color {
			color: #00a9e9;
		}

		.secondary-text {
			font-size: 16px;
		}
	}

	.content {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		text-align: center;
		padding: 5% 5% 3%;

		h1 {
			font-size: 48px;
			font-weight: 600;
			margin: 70px 0 0;
		}

		p {
			font-size: 16px;
			font-weight: 400;
		}

		.description {
			text-align: left;
			max-width: 500px;
		}

		.join-button {
			color: white;
			padding: 14px 56px;
			font-size: 15px;
			font-weight: 500;
			background: transparent linear-gradient(90deg, #13aceb 0%, #007ce9 100%) 0% 0% no-repeat padding-box;
			box-shadow: 0px 3px 6px #00000085;
			border-radius: 4px;
		}

		.matchmaking-stats {
			text-align: left;
			font-size: 13px;
			color: #888;
			margin-block-end: 0em;
		}
	}

	@media only screen and (max-width: 800px) {
		.banner-mobile-picture {
			display: block;
		}

		&.super-days {
			background-image: none !important;
		}

		.content h1 {
			margin-top: 0;
		}
	}

	@include mobile() {
		margin-bottom: 20px;

		.secondary-text {
			display: none;
		}

		.content {
			h1 {
				width: 100%;
				margin: 0;
				text-align: center;
			}

			p,
			.join-button {
				margin: 10px auto;

				.description {
					text-align: center;
				}
			}
		}

		.cards {
			padding: 0px;

			.card {
				margin: 0px;
			}
		}
	}
}

.home-content {
	display: flex;
	padding-top: 20px !important;

	.home-left-column {
		min-width: 300px;
		width: 300px;
		margin-right: 25px;
	}
	.home-center-column {
		flex-grow: 1;
		padding: 15px 10px 0px 10px;
		max-width: 100%;
		background-position: center top;
		background-repeat: no-repeat;
	}
	.home-right-column {
		min-width: 330px;
		width: 330px;
		margin-left: 25px;
	}

	@include mobile() {
		padding-top: 0px !important;
	}
	.content-heading {
		@include mobile() {
			padding: 0px !important;
		}
	}
	.live-games,
	.gather-table,
	.latest-drops-carousel,
	.latest-mvps,
	.live-streamz,
	.youtube-videos,
	.top-players,
	.quick-join-table {
		@extend %dropdown-table;
	}

	.top-players {
		td:nth-child(2) {
			text-align: unset;
		}
	}
	.home-stats-column-container {
		display: flex;
		flex-direction: column;

		.view-more-button {
			padding: 2em 4em;
			margin: 1em;
			margin-right: 0;
			margin-top: 5px;
			align-self: flex-end;

			@include mobile() {
				align-self: unset;
				margin: 0;
				margin-bottom: 2em;
			}
		}
	}
	.top-players {
		overflow: auto;
		text-align: left;
		width: 100%;
		margin-bottom: 0;
		@include tablet() {
			padding-left: 8px;
			padding-right: 8px;
		}
		@include widescreen() {
			padding-left: 0;
			padding-right: 0;
		}

		.challenges {
			width: 100%;
			margin-bottom: 5px;
		}
		.famfamfam-flag {
			margin-right: 6px;
		}

		table {
			white-space: nowrap;

			.table-index {
				padding-left: 22px;
				padding-right: 21px;
			}

			.table-avatar {
				margin-left: 9px;
			}

			.top-players-username {
				padding-left: 8px;
			}

			.top-players-sorting-parameter {
				padding-right: 19px;
			}
		}
	}
	.top-players-nav {
		background-color: #20212d;
		font-weight: 400;
		text-transform: uppercase;
		display: inline-flex;
		justify-content: flex-start !important;
		width: 100%;

		&.region-filter {
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;

			div:first-child.selected {
				border-top-left-radius: 4px;
			}

			@include mobile() {
				display: flex;
				justify-content: left;
			}
		}

		&.game-achievements-sorting {
			margin-top: 2px;

			.game-achievements-sorting-item {
				flex-grow: 1;
				text-transform: capitalize;
			}
		}

		@include mobile() {
			text-align: left;
		}

		@include tablet() {
			display: flex;
			justify-content: center;
			text-transform: none;

			.filter-label {
				display: none;
			}
		}

		div {
			color: $grey-text-color;
			font-size: 12px;
			padding: 12px 24px;
			text-transform: uppercase;

			@include mobile() {
				padding: 12px 14px;
			}

			@include tablet() {
				font-size: 14px;
				padding: 5px 8px;
			}

			@include widescreen() {
				white-space: nowrap;
			}

			.famfamfam-flag {
				padding: 0px 8px;
			}
		}

		.selected {
			background-color: #191a24;
			color: $primary-color;
			padding: 13px;
			font-size: 10px;
			text-transform: uppercase;
		}

		.clickable {
			text-transform: uppercase;
			font-size: 10px;
			padding: 13px;
		}
		.fa-caret-left,
		.fa-caret-right {
			font-size: 14px;
		}
		.disabled {
			color: $dark-grey-text-color;
			padding: 13px;
		}
	}

	.home-top-players-friend-checkbox-wrapper {
		text-transform: uppercase;
		color: $grey-text-color;
		justify-content: center;
		vertical-align: middle;
		display: flex;
		align-items: center;
		font-size: 12px;
		float: right;

		.top-players-friends-filter-checkbox {
			margin-right: 8px;
		}

		.top-players-friends-filter-label {
			margin-right: 4px;
		}
	}

	.gather-table {
		margin-top: 2em;
		overflow: auto;

		.content-heading {
			display: inline-block;
			margin-right: 2em;
			.fas {
				color: $primary-color;
				margin-right: 10px;
			}
		}
		.gather-table-filter-wrapper {
			display: inline-block;
		}
		tbody {
			background-color: #191a21;
		}
	}

	section {
		margin-bottom: 50px;
	}

	.lemondogs-news .content {
		@include mobile() {
			.card:nth-child(n + 2) {
				display: none;
			}
		}
		@include tablet() {
			.card:nth-child(-n + 2) {
				display: block;
			}
			.card:nth-child(n + 3) {
				display: none;
			}
		}
		@include desktop() {
			.card:nth-child(-n + 3) {
				display: block;
			}
			.card:nth-child(n + 4) {
				display: none;
			}
		}
	}

	.home-quick-matches {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 50px;

		.mobile-divider {
			display: flex;
			flex-direction: column;

			@include mobile() {
				height: 2em;
			}

			.home-welcome-players {
				display: inline-block;
				height: 3em;
				line-height: 3em;
				font-size: 18px;
				padding: 0 24px;
				background-color: #2a2c3991;
				border-radius: 10px;
				margin-bottom: 2em;

				@include mobile() {
					margin-left: 0;
					margin-top: 14px;
				}
			}
		}
		@include mobile() {
			flex-direction: column;
		}
	}
	.home-quick-match {
		display: inline-block;
		position: relative;
		text-align: center;
		vertical-align: top;
		width: 30%;

		&.gather {
			min-width: 300px;
		}

		@include mobile() {
			&.gather {
				margin-right: 0;
				margin-bottom: 12px;
			}
			display: block;
			width: 100%;
		}

		.home-quick-match-body {
			padding: 24px;
			background-color: #191a24;
			border-radius: 4px;
		}

		.home-quick-match-title {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			font-weight: 600;
			color: #fff;
			margin-bottom: 1em;

			i {
				font-size: 26px;
				color: $primary-color;
				margin-right: 10px;
			}
		}

		.home-quick-match-join {
			display: flex;
			align-items: center;
		}

		.home-quick-match-button {
			width: 100%;

			i,
			div {
				display: inline-block;
				vertical-align: middle;
				margin-right: 8px;
			}

			div {
				position: relative;
				top: -1px;
			}

			&.button-new-style-rounded {
				line-height: 36px;
			}
		}

		.home-quick-match-button-tipsy {
			display: block;
			width: 100%;

			&.join.gather {
				margin-top: 0;
				margin-left: 12px;
				margin-right: 12px;
				white-space: nowrap;
			}

			.home-quick-match-button.join.gather {
				//tipsy already has margin
				margin-left: 0;
			}
		}

		.home-quick-match-button.leave {
			background-color: #e4454b;
		}

		.home-quick-match-button.join.gather {
			margin-left: 12px;
		}

		.home-quick-match-button.join.gather.disabled {
			background: #434343;
		}

		.home-quick-match-text {
			line-height: 2em;
		}
		.disabled {
			color: #777778;
		}

		.home-quick-matches-played {
			font-size: 13px;
			padding-top: 2em;
		}
	}
	.frag .home-center-banner {
		margin-bottom: 0;
	}

	.upcoming-tournaments,
	.upcoming-ladders {
		.tournaments-list-header {
			justify-content: flex-start;

			@include mobile() {
				flex-direction: row;
				justify-content: space-between;
			}

			.view-all {
				margin-left: 2em;
			}
		}
		.tournaments-tournament-card-list {
			flex-wrap: nowrap;

			.tournaments-tournament-card + .tournaments-tournament-card {
				margin-left: 1.5rem; //2 column paddings
			}
		}
		.tournaments-tournament-card {
			width: 47%;

			@include mobile() {
				width: 100%;
			}

			.meta {
				.signup-button {
					margin-bottom: 5px;
				}
			}
		}
	}
}
.sidebanner {
	display: flex;
	align-items: center;
	position: relative;
	height: 240px;
	justify-content: center;

	.home-center-iframe {
		position: absolute;
		z-index: 2;
		height: 100%;
	}
}
.home-vertical-iframe {
	position: relative;
	display: block;
	height: 600px;
	margin-bottom: 15px;
	// removing default body margin
	margin-left: -8px;
	margin-top: -8px;
}

.home-center-banner {
	display: block;
	width: auto;
	margin: 10px auto;
	max-width: 100%;
	width: 980px;
	height: 240px;
}

#home-center-banner-container {
	margin: 20px 0px;
}
#home-center-banner-header {
	height: 32px;
	line-height: 32px;
	text-align: right;
	user-select: none;

	.window-button {
		display: inline-block;
		height: 32px;
		width: 32px;
		text-align: center;
		cursor: pointer;
	}

	.icon-minimize,
	.icon-maximize {
		position: relative;
		top: 4px;
	}
}

.card {
	display: flex;
	flex-direction: column;
	margin: 1em 0;

	.thumb-image {
		width: 100%;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}
	.meta {
		background-color: #20212d;
		padding: 14px;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;

		.title {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: $grey-text-color;
			font-size: 15px;
		}
		.comments,
		.date {
			color: #6d788a;
			font-size: 13px;
		}
		.comments {
			float: right;

			.fa-comment {
				margin-right: 5px;
			}
		}
	}
}
.logged-out {
	padding-top: 20px !important;
}
.home-banner-doritos {
	background-image: url(../img/doritos/banner.jpg);
}

.ggbet-banner {
	width: 250px;
	height: 300px;
	margin: auto;
	margin-bottom: 20px;
	background-image: url(../img/ggbet/EN.png);

	&.lang-se {
		background-image: url(../img/ggbet/SE.png);
	}

	&.lang-de {
		background-image: url(../img/ggbet/DE.png);
	}

	&.lang-dk {
		background-image: url(../img/ggbet/DK.png);
	}

	&.lang-fi {
		background-image: url(../img/ggbet/FI.png);
	}

	&.lang-no {
		background-image: url(../img/ggbet/NO.png);
	}

	&.lang-pl {
		background-image: url(../img/ggbet/PL.png);
	}

	&.lang-es {
		background-image: url(../img/ggbet/ES.png);
	}

	&.lang-fr {
		background-image: url(../img/ggbet/FR.png);
	}
}
.top-bar-client {
	height: 45px;
	text-transform: uppercase;
	display: flex;
	justify-content: center;
	background-color: $primary-color;
	font-weight: 800;

	.button {
		margin-left: 5em;

		.fa-download {
			margin-right: 1em;
		}
	}
}

;@import "sass-embedded-legacy-load-done:70";