#chat-bar {
	position: fixed;
	bottom: 0;
	right: $right-sidebar-width;
	z-index: 2600;
	width: auto;

	@media (min-width: $right-side-narrow-breakpoint) {
		right: $right-sidebar-wide-width;
	}

	@media (min-width: $right-side-wide-breakpoint) {
		right: $right-sidebar-fullhd-width;
	}
}
.chat-bar-entry {
	float: right;
	color: #fff;
	position: relative;
	height: $chat-bar-height;
	margin-left: 6px;
}

@keyframes chat-bar-unseen {
	0% {
		//background: #334d58;
		background: #ff0000;
	}
	100% {
		//background: $secondary-hover-color
		background: #880000;
	}
}

.chat-bar-entry.unseen {
	.chat-bar-entry-title {
		animation: chat-bar-unseen 1s linear infinite alternate;
	}
}

.chat-bar-entry-close {
	display: none;
	position: absolute;
	right: 8px;
	top: 0px;
}
.chat-bar-entry:hover {
	.chat-bar-entry-close {
		display: block;
	}
}

.chat-bar-entry-title {
	width: 100%;
	background-color: #1e1f2a;
	cursor: pointer;
	padding: 0px 25px;
	height: 40px;
	line-height: 40px;
	font-size: 15px;
	font-weight: 600;
	float: right;
	text-align: left;

	i {
		margin-right: 5px;
	}
}

.chat-bar-dialog {
	.search {
		position: relative;
		.chat-bar-find-friends-icon {
			position: absolute;
			pointer-events: none;
			width: 16px;
			height: 16px;
			line-height: 16px;
			top: 11px;
			left: 12px;
			color: #767982;
			font-size: 12px;
		}
		input {
			border: 0;
			border-radius: 0;
			padding-left: 38px;
			height: 40px;
			width: 100%;
			background-color: #17181e;
		}
	}
}
.chat-bar-dialog {
	position: absolute;
	bottom: 100%;
	right: 0px;
	width: 100%;
	background-color: transparent;
	color: #000;

	.chat-bar-gather-settings-wrapper {
		position: relative;

		.chat-bar-gather-settings {
			position: absolute;
			z-index: 1;
			top: 0;
			right: 100%;
			margin-right: 5px;
			width: 100%;
			color: #fff;
			background-color: #21232d;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			padding: 0px 10px;

			.settings-header {
				line-height: 50px;
				padding: 10px 15px;
				font-size: 14px;
				text-align: center;
			}
			.settings-body {
				overflow: hidden;

				> .columns {
					margin-top: 0;

					label {
						display: block;
						color: #c0c6d1;
						margin-bottom: 8px;
						font-size: 13px;
					}
					.input-label {
						margin-bottom: 0;
					}

					.create-gather-name-input {
						width: 100%;
						margin: 0;
					}
					select {
						width: 100%;
					}
					.collapsable-title {
						display: flex;
						align-items: center;
						cursor: pointer;
						margin: 0.5em 0;
						color: #c0c6d1;

						.fas {
							font-size: 20px;
							margin-left: 1em;
						}
					}

					.checkbox-label {
						display: flex;
						align-items: center;
						margin: 0.5em 0;

						.input-label {
							margin-left: 0.5em;
						}
					}
				}

				.save {
					display: block;
					margin: 0px auto;
					width: 45%;
					margin: auto;
					display: block;
					border: 2px solid #3f4151;
					background-color: transparent;
					color: #fff;
					font-size: 11px;
					font-weight: 700;
					height: 42px;
					border-radius: 50px;
					transition: all 0.25s;
					margin-bottom: 20px;
				}
				.save.button:hover {
					background-color: $primary-color;
					border-color: $primary-color;
				}
			}
		}
	}
	.chat-bar-dialog-gather-matchmaking {
		background-color: #17181e;

		.buttons {
			display: flex;
			align-items: center;
			flex-direction: column;
			padding: 15px 0;

			> div {
				width: 80%;
				padding: 5px;

				.button {
					width: 100%;
					margin: auto;
					min-height: 42px;
					transition: all 0.25s;
				}
				.button:hover {
					background-color: $primary-color;
					border-color: $primary-color;
				}
			}
		}

		.existing-gather {
			text-align: center;
			color: #fff;
			width: 70%;
			margin: auto;
			padding: 1em 0;

			> div {
				margin: 1em 0;
			}
		}

		.chat-bar-group-party {
			position: relative;
			padding: 1em 0;

			.title {
				color: $primary-color;
				text-align: center;
				text-transform: uppercase;
			}
			.subtext {
				color: $grey-text-color;
				font-size: 12px;
				text-align: center;
			}
			.chat-bar-players {
				display: flex;
				justify-content: space-around;
				margin: 10px;

				.chat-bar-player {
					position: relative;
					height: 45px;
					width: 45px;
					border: 2px solid #00a9e9;
					background-image: url(../img/gathers/user_notready.png);

					&.removable:hover {
						background-color: gray;
						border: 2px solid #ff4136;
						cursor: pointer;

						.fa-times {
							display: block;
						}
					}
					&.empty {
						border-color: #fff;
					}

					.fa-times {
						position: absolute;
						color: #ff4136;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						display: none;
					}
				}
				.crown {
					position: relative;
					background-color: #262b2d;
					color: yellow;
					height: 20px;
					width: 20px;
					border-radius: 50%;
					font-size: 10px;
					text-align: center;
					margin: auto;
					margin-top: -15px;

					.fa-crown {
						line-height: 18px;
					}
				}
				.online-status {
					height: 10px;
					width: 10px;
					margin: auto;

					.friend-online-status {
						margin: auto;
						top: -10px;
					}
				}

				.group-open-chat {
					position: relative;
					margin-top: 12px;
					margin-left: 10px;
					width: 30px;
					height: 30px;

					.chat-icon {
						color: #fff;
						width: 24px;
						height: 24px;
						cursor: pointer;

						&:hover {
							filter: invert(42%) sepia(97%) saturate(1665%) hue-rotate(166deg) brightness(101%) contrast(101%);
						}
					}
				}
			}
			.queue-button {
				width: 90%;
				margin: auto;
				display: flex;
				align-items: center;
				height: auto;
				font-size: 12px;
			}
			.abort {
				position: absolute;
				color: $grey-text-color;
				right: 1em;
				top: 1em;
				height: 20px;
				width: 20px;
				border-radius: 50%;
				border: 1px solid $grey-text-color;
				cursor: pointer;
				text-align: center;

				&:hover {
					color: #fff;
					background-color: gray;
				}

				.fa-times {
					line-height: 18px;
				}
			}
			.columns {
				margin-bottom: 0;

				.column {
					padding-bottom: 0;
				}
			}
		}
	}
	.sidebar-suggested-friends {
		overflow: hidden;
		color: #fff;
		max-height: 165px;
		min-height: 165px;
		flex: 1 1 auto;

		// .filter-header {
		// 	display: flex;
		// 	background-color: $table-header-background-color;
		// 	padding-top: 1em;
		// 	padding-bottom: 1em;

		// 	.suggested-friends-header {
		// 		display: flex;
		// 		align-items: center;
		// 		font-weight: 500;
		// 	}
		// }
		.friend-list {
			.suggested-friend {
				position: relative;
				display: flex;
				flex-direction: row;
				background-color: #1d1e29;
				padding: 0.8em;
				padding-left: 0;
				margin: 5px;
				margin-bottom: 0;
				border-radius: 4px;
				transition: all 0.5s;

				&:last-child {
					margin-bottom: 0.5em;
				}
				.actions {
					display: flex;
					flex-direction: row;
					align-items: center;
					& > * {
						color: #c6cfde;
						cursor: pointer;
						border: 2px solid #3f4151;
						font-size: 12px;

						.fas {
							padding: 6px;
						}
						margin-left: 6px;
					}
				}
				.username {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					align-items: center;
					flex-grow: 1;

					.friend-source {
						margin-left: 5px;
						font-size: 13px;
					}
				}
				.platforms-and-add {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.platforms {
						display: flex;

						.platform {
							background-color: #2b2d3a;
							border-radius: 20px;
							padding: 5px 10px;
							font-size: 12px;
							display: flex;
							align-items: center;
							margin-left: 0.5em;
							margin-right: 0.5em;

							.steam-icon {
								background-repeat: no-repeat;
								background-image: url(../img/steam.svg);
								background-position: center;
								width: 20px;
								height: 15px;
								background-size: contain;
								margin-right: 2px;
							}
							.faceit-icon {
								background-repeat: no-repeat;
								background-image: url(../img/logo/faceit.svg);
								background-position: center;
								width: 18px;
								height: 12px;
								background-size: contain;
								margin-right: 2px;
							}
						}
					}
				}

				&.hidden {
					height: 0;
					margin: 0;
					padding: 0;
					overflow: hidden;
				}
			}
		}
	}
}
.chat-bar-dialog-body {
	position: relative;
	height: 350px;
	overflow: auto;
	color: #fff;
	background-color: #21242d;

	&.friend-list {
		height: 250px;
	}
}

.chat-bar-entry.active {
	width: 365px;
}
.chat-bar-friend-list.active {
	width: 435px;

	.chat-bar-friend-list-dialog {
		width: 100%;
	}
}

.chat-bar-dialog-header {
	background-color: #000;
	color: #fff;
	line-height: 50px;
	font-size: 16px;
	font-weight: 500;
	padding: 0px 10px;
	text-align: left;
	cursor: pointer;

	> a {
		color: #fff;
	}
	> a:hover {
		text-decoration: underline;
	}
}

.chat-bar-friend-list .chat-bar-dialog-header {
	border-top-left-radius: 8px;
	background-color: #21232d;
	text-align: center;
	border-bottom: 1px solid #000;

	.fa-minus {
		line-height: 50px;
	}
}

.chat-bar-close {
	float: right;
	line-height: 50px;
}

.user-chat-message {
	margin: 12px 0px;
	min-height: 50px;
	overflow: auto;
}
.user-chat-message-sent {
	.user-chat-message-avatar {
		float: right;
	}
	.user-chat-message-content {
		background-color: #2a2c38;
		color: $grey-text-color;
		margin-right: 10px;
	}
	.user-chat-message-content:after {
		right: 0;
		border-left-color: #2a2c38;
		border-right: 0;
		margin-right: -10px;
	}
}
.user-chat-message-received {
	display: flex;

	.user-chat-message-avatar {
		float: left;
	}
	.user-chat-message-content {
		float: left;
		background-color: #3f4150;
		color: $grey-text-color;
		margin-left: 10px;
	}
	.user-chat-message-content:after {
		left: 0;
		border-right-color: #3f4150;
		border-left: 0;
		margin-top: -10px;
		margin-left: -10px;
	}
}
.user-chat-message-avatar {
	margin: 5px 5px;
	width: 40px;
	height: 40px;
	border: 2px solid $primary-color;
}
.user-chat-message-content {
	position: relative;
	width: 245px;
	border-radius: 15px;
	padding: 15px 15px 10px;
	white-space: pre-line;
	word-wrap: break-word;
}
.user-chat-message-timestamp {
	margin-top: 8px;
	font-size: 10px;
	color: $dark-grey-text-color;
}
.user-chat-message-content:after {
	content: '';
	position: absolute;
	top: 25px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 10px 15px 10px;
	border-color: transparent;
	margin-top: -10px;
}

.chat-bar-message-list {
	position: absolute;
	left: 0px;
	right: 0px;
	width: 100%;
	overflow-y: auto;
	max-height: calc(100% - 35px);
	bottom: 35px;
}
.chat-bar-message-input {
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;
	width: 100%;
	height: 35px;
	border: 0;
	border-radius: 0;
	background-color: #1f1f29;
	color: #c0c6d1;
}
.chat-bar-friend-list .chat-bar-message-input {
	border-left: 1px solid #ccc;
}

.padding-wrap {
	padding: 20px;
}

.friend-list-queue-timer {
	display: block;
	color: #fff;
	text-align: center;
	margin-bottom: 10px;
}

;@import "sass-embedded-legacy-load-done:48";