.home-latest-mvps {
	height: 200px;
	margin-bottom: 48px;
}

.home-latest-mvps,
.tournament-latest-mvps {
	flex-wrap: wrap;
	justify-content: space-between;
	overflow: hidden;
	&.no-content {
		padding: 24px;
		justify-content: center;
	}

	.entry {
		background-color: #171821;
		text-align: center;
		height: 200px;
	}
	.home-latest-mvp-slide-content {
		overflow: visible;
		position: relative;
		height: 200px;
		margin: 2px;
		font-size: 12px;
		text-overflow: ellipsis;
	}
	.user {
		overflow: visible;
		justify-content: space-between;

		padding: 14px 0;

		.famfamfam-flag,
		.player-card-wrapper {
			display: inline-block;
			vertical-align: middle;
		}

		.player-card-wrapper {
			text-overflow: ellipsis;
			white-space: nowrap;
			line-height: 24px;
			max-width: 100px;
		}
	}
	.user-avatar {
		border: 3px solid $button-color;
		margin: 0 auto;
		height: 70px;
		width: 70px;
	}
	.details {
		flex: 1;
		background-color: #1d1e29;

		@include mobile() {
			flex-flow: column;
		}
	}
	.stats {
		display: flex;
	}
	.kdr {
		flex: 0.9;
		padding: 12px 0;
	}
	.kdr-icon {
		width: 15px;
		height: 14px;
		background-size: cover;
		background-repeat: no-repeat;
		margin-right: 2px;
		text-align: center;
		line-height: 16px;
		color: $primary-color;
	}
	.kdr-label-container {
		display: inline-flex;
		color: $primary-color;
	}
	.kdr-label {
		font-size: 10px;
		line-height: 16px;
		font-weight: 400;
		width: calc(100% - 70px);
		text-align: center;
	}
	.kdr-value {
		font-size: 21px;
		font-weight: 500;
	}
	.weapon {
		border-left: 1px solid $dark-grey-border;
		flex: 1;
		position: relative;
	}
	.weapon-img {
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: contain;
		margin: 8px;
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}
	.match-link {
		align-items: center;
		background-color: #20222e;
		color: $grey-text-color;
		flex: 1;
		font-size: 10px;
		font-weight: 500;
		justify-content: center;
		line-height: 33px;
		text-transform: uppercase;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}
}

;@import "sass-embedded-legacy-load-done:34";