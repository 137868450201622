.queue-side-holder {
	display: flex;
	align-items: center;
	margin-top: 20px;
}
.queue-side-holder-spaced {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;
}
.queue-side-column {
	display: flex;
	align-items: center;
	//height: 40px;

	@include mobile() {
		flex-direction: row;
	}
	> .label {
		padding: 7px;
		font-weight: 700;
		height: 36px;
		background-color: $content-background-color;
		@include mobile() {
			align-self: center;
		}
	}

	> .item {
		padding: 7px;
		height: 36px;
		background-color: $content-background-color;
	}
	.queue-side-region {
		vertical-align: top;
	}
}

.queue-side-column:not(:first-of-type) {
	margin-left: 15px;
}

.queue-side-column-region {
	@include mobile() {
		padding-top: 0px;
		margin-left: 12px;
		margin-right: auto;
	}

	@include tablet-only() {
		padding-top: 0px;
		margin-left: 12px;
		margin-right: auto;
	}
}
.queue-players-region-container {
	display: flex;
	flex-direction: row;
}
.queue-players-region-container-spaced {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.queue-searching-column {
	width: 100%;
	display: inline-block;
	text-align: center;
	padding-bottom: 16px;

	> .label {
		font-size: 18px;
		padding: 16px 0;
	}

	> .leave-button {
		width: 205px;
		background-color: #46a8e5;
	}

	> .leave-button:hover,
	> .leave-button:focus {
		background-color: #66a8e5;
	}

	#queue-player-count-timer {
		font-size: 24px;
	}

	.leave-queue-confirmation-popup {
		$confirmation-popup-width: 600px;
		position: absolute;
		width: $confirmation-popup-width;
		width: $confirmation-popup-width;
		height: 260px;
		background-color: aqua;
		z-index: 1;
		left: calc(50% - $confirmation-popup-width / 2);
		top: 110px;

		background-color: #20212d;
		border-radius: 5px;
		border: 1px solid #292a38;

		.confirmation-popup-content {
			.matchmaking-header-title {
				margin-top: 1em;
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: left;
				font-size: 20px;
				font-weight: 500;
				padding-bottom: 12px;
				@include tablet() {
					padding-top: 0px;
				}

				.fa-crosshairs {
					color: $primary-color;
					margin-right: 10px;
					font-size: 26px;
				}
			}

			.popup-text {
				font-size: 24px;
			}

			.popup-subtext {
				font-size: 16px;
			}
		}

		.buttons-wrapper {
			width: 100%;
			height: 90px;
			background-color: #292a38;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			margin-top: 32px;

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-end;

			.button {
				width: 50%;
			}

			.button.leave-button {
				background: transparent;
				margin-left: 0;
				color: $button-color;
			}
		}

		.close-button {
			position: absolute;
			right: 1em;
			top: 1em;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			border: 1px solid $dark-grey-text-color;
			color: $grey-text-color;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
	}
}
.queue-time {
	position: relative;
	top: 4px;
	padding-left: 32px;
	font-weight: 600;
	font-size: 32px;
	color: $primary-hover-color;
}
.inPrimeQueue {
	margin-bottom: 5px;
	margin-right: 5px;
}
#queue-players {
	margin-top: 20px;
	margin-bottom: 35px;
	position: relative;
	height: 140px;

	.member {
		position: absolute;
		top: 22px;
		left: 0px;
		width: 90px;
		height: 90px;
		border-radius: 50%;
		background-image: url(../img/gathers/user_notready.png);
		border: 2px solid #7cb600;
	}
	.member:nth-child(2) {
		left: 50%;
		margin-left: -45px - 110px - 12px;
	}
	.member:nth-child(4) {
		left: 50%;
		margin-left: -45px - 110px - 12px - 90px - 12px;
	}
	.member:nth-child(3) {
		left: 50%;
		margin-left: -30px + 110px;
	}
	.member:nth-child(5) {
		left: 50%;
		margin-left: -30px + 110px + 90px + 12px;
	}

	.self {
		width: 120px;
		height: 120px;
		top: 10px;
		left: 50%;
		margin-left: -60px;
	}

	.empty {
		border-color: #56575d;
	}

	.playercard-with-ladders {
		position: absolute;
		top: 0;
		height: 140px;

		.player-ladders {
			position: absolute;
			bottom: -6px;
			height: 46px;
			width: 100%;
			justify-content: space-around;
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(10px, max-content));
			max-width: 120px;

			&.flex-end {
				justify-content: flex-end;
			}

			.Tipsy-inlineblock-wrapper {
				.player-ladder {
					position: relative;
					border-radius: 50%;
					width: 46px;
					height: 46px;
					border: 2px solid #7cb600;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: 50% 50%;

					&.inactive {
						opacity: 0.3;
					}
				}

				&:hover {
					z-index: 1;

					.player-ladder {
						transition-duration: 0.1s;
						margin-top: -2px;
						margin-right: -2px;
						width: 50px;
						height: 50px;
					}
				}
			}
		}
	}
}

.queue-icon {
	display: inline-block;
	position: relative;
	width: 33px;
	height: 15px;
	top: 1px;
	background-image: url(../img/gathers/queue_animate.gif);
	background-size: cover;

	&.prime {
		margin-top: -2px;
		margin-right: 5px;
		width: 25px;
		height: 17px;
		background-image: url(../img/gathers/queue_animate_prime.gif) !important;
	}
}
.queue-header-time {
	vertical-align: top;
	display: inline-block;
	font-size: 17px;
	font-weight: 700;
}
.queue-header-text {
	display: inline-block;
	vertical-align: top;
	text-transform: uppercase;
	font-size: 12px;
	margin: 0 18px;
}
.queue-header-button {
	vertical-align: top;
	min-height: 20px;
	height: 20px;
	line-height: 20px;
	border-radius: 10px;
	margin: 15px 0;
	font-size: 10px;
}

.queue-timer-container {
	flex-shrink: 0;
	margin-right: 2em;
}

;@import "sass-embedded-legacy-load-done:38";