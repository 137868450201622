#popover-manager {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 6000;
}
#popover-manager,
.popover-holder {
	background-color: rgba(0, 0, 0, 0.3);
}
.popover-holder {
	position: absolute;
	width: 100%;
	height: 100%;
}
.popover-helper {
	height: 100%;
	padding: 0;
}
.popover-helper,
.popover-wrapper {
	display: inline-block;
	vertical-align: middle;
	text-align: left;
}
.popover-wrapper {
	width: 100%;
}
.popover {
	margin: 0 auto;
	width: 320px;
	background-color: $secondary-color;
	padding: 20px 28px;
}
.popover-mm-no-margins {
	margin: 0;
	padding: 0;
	width: 0;
}
.popover-title {
	text-transform: uppercase;
	text-align: center;
	color: $primary-color;
	font-size: 16px;
}
.popover-content {
	text-align: center;
	padding: 15px 0 15px 0;
	font-size: 15px;
}
.popover-close-button {
	text-align: center;
	display: block;
	width: 130px;
	margin: 0px auto;
	line-height: 40px;
	height: 40px;
	font-size: 16px;
}

.popover-content select {
	width: 100%;
}

.popover-form {
	padding: 0px 0px 15px 0px;
	width: 600px;
}
.popover-form .popover-title {
	display: none;
}
.popover-form .popover-content {
	padding: 0px 0px;
}
.popover-form .box-header {
	color: $primary-color;
}
.popover-form .box-content {
	text-align: left;
}
.popover-form .popover-close-button {
	content: 'Close';
}
.popover-content select {
	width: 100%;
}

.confirmation-popover {
	padding: 0;
	width: 480px;
	border-radius: 4px;
	position: relative;
	box-shadow: 0px 3px 6px #00000042;

	.popover-content {
		padding: 0;
		font-size: 13px;
		font-weight: 300;
	}

	.popover-title {
		text-transform: none;
		color: #fff;
		font-size: 18px;
		padding: 1rem;
		padding-top: 2rem;
	}
	.popover-message {
		padding: 1rem;
		padding-top: 0;
	}

	.popover-close {
		position: absolute;
		top: 10px;
		right: 10px;
		padding: 5px 5px;
		cursor: pointer;
		font-size: 16px;
		font-weight: 700;
		color: $grey-text-color;

		&:hover {
			color: #fff;
		}
	}
	.popover-buttons {
		background-color: #262734;
		padding: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;

		.emphasis {
			color: $button-color;
			border-color: $button-color;
		}
	}
}

;@import "sass-embedded-legacy-load-done:20";