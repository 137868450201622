.admin-body {
	max-width: 1200px;
	margin: auto;
	padding-bottom: 40px;
}

.admins-header-amount {
	margin-left: 32px;
	font-size: 16px;

	.icon-admin,
	.icon-moderator,
	.icon-overwatcher {
		width: 23px;
		height: 23px;
		background-size: 23px 23px;
		margin-right: 8px;
	}

	.online-status-indicator {
		margin-left: 6px;
		margin-right: 3px;
	}

	@include mobile() {
		display: block;
		margin-left: 0;
	}
}

.admins-header {
	max-width: 1200px;
	margin: auto;
	padding: 0.75rem;
	font-size: 20px;
}

.admins-description {
	font-size: 14px;
	margin: 10px 0;
}

.admins-country-filters .famfamfam-flag {
	cursor: pointer;

	&:first-child {
		margin-left: 0.5rem;
	}

	&:not(:last-child) {
		margin-right: 0.5rem;
	}
}

.admins-support-link {
	color: $button-color;
	cursor: pointer;
}

.admins-icon-support-ticket {
	background-image: url(../img/reportbug.png);
	border-radius: 50%;
	cursor: pointer;
	display: inline-block;
	height: 29px;
	vertical-align: middle;
	width: 29px;
}

.admins-icon-open-chat {
	font-size: 17px;
	background-color: #006288;
	color: #fff;
	border-radius: 50%;
	width: 29px;
	height: 29px;
	line-height: 29px;
	cursor: pointer;

	&.gray {
		color: #bfbfbf;
		filter: grayscale(100%) brightness(75%);
	}
}

.admins-flag {
	width: 24px;
}

.admin-list-header {
	color: #fff;
	background-color: #00a9e9;

	th {
		color: #fff;
	}
}
.admin-right-header {
	display: flex;
	justify-content: center;
	height: 48px;
	line-height: 48px;
	font-size: 16px;
	font-weight: bold;
	color: #fff;
	background-color: #00a9e9;
}

.admin-right-text {
	color: #727784;
	border-bottom: 1px solid #2a2a32;
}

.admin-right-title {
	padding-top: 10px;
	font-size: 18px;
	font-weight: 500;
	color: #c0c6d1;
}

.admin-middle-text {
	text-align: center;
	padding-top: 10px;
	font-size: 18px;
	font-weight: 500;
	color: #c0c6d1;
}

.admin-requirement {
	padding: 10px 0px;
}

.admin-box {
	float: left;
	background-color: #727784;
	width: 10px;
	height: 10px;
	margin-right: 5px;
	margin-top: 3px;
	clear: left;

	&.box-green {
		background-color: #30a500;
	}

	&.box-red {
		background-color: #a5002c;
	}
}

;@import "sass-embedded-legacy-load-done:80";