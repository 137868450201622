.create-gather {
	max-width: 1400px;
}

.create-gather-button {
	position: relative;
	min-height: 21px;
	line-height: 21px;
	font-size: 11px;
	margin-left: 16px;
	top: -2px;
}

.create-gather-box {
	background-color: $content-background-color;
	font-size: 16px;
	padding: 20px;
	margin-bottom: 8px;

	> .checkbox-label {
		color: #c0c6d1;
		justify-content: center;
		vertical-align: middle;
		display: inline-flex;
		align-items: center;
	}

	& .input-label {
		padding-left: 10px;
	}
}
.create-gather-number {
	display: inline-block;
	width: 32px;
	height: 32px;
	text-align: center;
	line-height: 32px;
	margin-right: 20px;
	border-radius: 50%;
	background-color: $primary-hover-color;
}
.create-gather-subtext {
	color: #939393;
	font-size: 14px;

	&.indent {
		font-style: italic;
		margin-left: 52px;
	}
}
.create-gather-name-input {
	width: 330px;
	margin-left: 16px;
}
.create-gather-map-selector {
	width: 100%;
	margin: 24px 0;
}
.create-gather-map {
	display: inline-block;
	width: 124px;
	height: 108px;
	color: $primary-hover-color;
	background-color: #000;
	border: 1px solid #383838;
	margin-right: 4px;
	cursor: pointer;
	line-height: 28px;
	user-select: none;
	font-size: 14px;
	text-align: center;

	&.selected {
		color: #fff;
		background-color: $primary-hover-color;
		border-color: $primary-hover-color;
	}

	&.disabled {
		color: #383838;
		cursor: default;
		.create-gather-map-image {
			filter: sepia(100%) opacity(75%);
		}
	}

	&:last-child {
		margin-right: 0;
	}
}
.create-gather-map-image {
	width: 122px;
	height: 80px;
	background-size: cover;
}
.create-gather-flags-labels {
	vertical-align: top;
	display: inline-block;
	padding-top: 4px;
}
.create-gather-flags {
	display: inline-block;
	height: 40px;
	margin-left: 40px;
}
.create-gather-flag {
	display: inline-block;
	width: 55px;
	height: 40px;
	margin-left: 8px;
	background-size: cover;
	cursor: pointer;

	&.selected {
		border: 2px solid $primary-hover-color;
	}
}
.create-gather-checkbox {
	margin-left: 8px;
}
.create-gather-complete-setup {
	margin-top: 28px;
	margin-left: 52px;
}
.create-gather-complete-setup-selections {
	font-size: 14px;
	margin-top: 8px;
}
.create-gather-invite-friends {
	margin-top: 8px;
	width: 540px;
}

.create-gather-friends-table {
	tbody {
		max-height: 240px;
		overflow-x: hidden;
		overflow-y: auto;
		display: block;
	}
	thead {
		display: block;
	}
}
.create-gather-player-column {
	min-width: 237px;
}
.create-gather-drop-column {
	min-width: 94px;
}
.create-gather-rating-column {
	min-width: 80px;
}
.create-gather-kd-column {
	min-width: 60px;
}
.create-gather-pick-column {
	min-width: 52px;
	text-align: center !important;
}
.create-gather-blank-column {
	min-width: 17px;
}

.gather-mode-container {
	display: flex;
	margin: 24px 42px;
}
.gather-mode-choice {
	align-items: center;
	flex-grow: 1;
	margin: 0 8px;
	text-align: center;
	border: 1px solid rgba(0, 0, 0, 0);
	cursor: pointer;
}
.gather-mode-choice-header {
	margin-top: 44px;
	margin-bottom: 36px;
}
.gather-mode-choice-title {
	display: inline-block;
	vertical-align: top;
	line-height: 54px;
	font-size: 18px;
}
.gather-mode-choice-icon {
	display: inline-block;
	width: 54px;
	height: 54px;
	margin-right: 16px;
	background-size: 54px 54px;
	filter: grayscale(1);
}
.gather-mode-choice ul {
	text-align: left;
	font-size: 14px;
	margin: 0 48px;
	text-indent: -1em;

	li::before {
		content: '■ ';
		position: relative;
		color: $primary-hover-color;
		top: -2px;
	}
	li {
		margin: 8px 0;
	}
}

.gather-mode-choice.selected {
	border: 1px solid $primary-hover-color;

	.gather-mode-choice-icon {
		filter: grayscale(0);
	}
}

;@import "sass-embedded-legacy-load-done:90";