.latest-drops-carousel {
	.content-heading {
		display: flex;
		margin-bottom: 14px;
		line-height: 29px;
		.icon-star-gather {
			margin-right: 5px;
		}
		.icon29 {
			width: 29px;
			height: 29px;
			background-size: 29px 29px;
			margin-right: 10px;
		}
		.word-premium {
			color: #ff9100;
		}
	}

	.home-carousel-button {
		height: 234px;
		background-color: #20212d;
		border-radius: 4px;
		color: #ffffff;
	}

	.home-carousel-button:disabled {
		background-color: #191a21 !important;
		color: #c0c6d1 !important;
		opacity: 0.5;
	}
}
.latest-drops-carousel-heading-text {
	color: $premium-color;
	margin-left: 5px;
	margin-right: 5px;
}
.latest-drops-carousel-see-all-drops-link {
	color: #fff;
	height: 30px;
	text-transform: capitalize;
	font-size: 12px;
	margin-left: auto;
	width: 138px;
	background: transparent linear-gradient(270deg, #ff8000 0%, #ffaa00 100%) 0% 0% no-repeat
		padding-box;
	border-radius: 4px;
	text-align: center;
	cursor: pointer;
}
.latest-drops-carousel-super-drops-title {
	color: #fff;
	width: 95%;
	margin: auto;
	text-align: center;
	text-transform: uppercase;
	background: linear-gradient(to right, rgba(0, 0, 0, 0), #d26522, rgba(0, 0, 0, 0));
	.title {
		padding: 8px;
	}
	.large-star {
		font-size: 16px;
	}

	.small-star {
		font-size: 10px;
		padding: 1px;
	}
}
.latest-drops-carousel-content {
	overflow: hidden;
	justify-content: space-around;
	flex-flow: wrap;
	align-items: center;
	width: 100%;
	height: 234px;
	&.is-two-row {
		height: 472px;
	}
	&.gather-drop {
		height: 256px !important;
	}
	background-color: transparent;
}
.latest-drop {
	position: relative;
	height: 234px;
	min-width: 170px;
	margin: 0 2px;
	text-align: center;
	font-size: 12px;
	text-overflow: ellipsis;
	background-repeat: no-repeat;
	background-position: 50% 43px;
	background-size: 120px 80px;
	background-color: #171821;
	border-radius: 4px;

	&.super-drop {
		background-color: #171821b8;
		border: 2px solid #653400;
		border-radius: 4px;

		.drop-category-text {
			color: #14151d;
			background: transparent linear-gradient(270deg, #ff8000 0%, #ffaa00 100%) 0% 0% no-repeat
				padding-box;
			border-radius: 4px 4px 0 0;
			margin: -2px -2px 0;
		}

		.match-link {
			color: $important-color;
			bottom: 0;
			height: 32px;
			line-height: 34px;
		}
	}
	&.gather-drop {
		height: 254px !important;
		background-position: 50% 60px;
		padding: 56px 0 0 0;
		.latest-drop-gather-creator {
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: #20222e;
			padding-top: 7px;
			padding-bottom: 7px;
			.latest-drop-gather-creator-created-by {
				text-transform: uppercase;
				color: $grey-text-color;
				font-size: 10px;
				padding: 4px;
			}
		}
		.match-link {
			bottom: 2px;
		}
	}
	.famfamfam-flag {
		vertical-align: middle;
		margin-left: 2px;
	}
	.latest-drop-text {
		font-size: 13px;
	}

	.drop-image-block {
		height: 90px;
		background-image: url('../img/drops/drop-item-background.png');
		background-size: cover;
		background-position: center;

		img {
			margin-top: 15px;
			height: 70px;
		}

		&.no-background {
			background: none;
		}
	}
}

.drop-category-text {
	color: #ffffff;
	height: 33px;
	line-height: 33px;
	font-size: 13px;
	background-color: #20212d;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	position: relative;
}

.rare-drop-category-text {
	color: #ffffff;
}

.latest-drop-text {
	width: 90%;
	padding-bottom: 8px;
	font-weight: 600;
}
.latest-drop-user {
	line-height: 22px;
	font-size: 13px;
}
.latest-drop-user-avatar {
	width: 22px;
	height: 22px;
}
.latest-super-drop-user-avatar {
	border-width: 1px;
}
.latest-drop-match {
	font-size: 13px;
	font-weight: bold;
}
.match-link {
	align-items: center;
	background-color: #20212d;
	color: $grey-text-color;
	flex: 1;
	font-size: 13px;
	font-weight: 500;
	justify-content: center;
	height: 33px;
	line-height: 33px;
	text-transform: capitalize;
	cursor: pointer;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}
.carousel-frame {
	flex-direction: row;
	display: flex;
	height: 100%;
}
.two-row-carousel-wrapper {
	flex-direction: column;
	display: flex;
	width: 100%;
}
.home-carousel {
	flex: 1;
	margin: 0 2px;
	&.is-two-row {
		flex: unset;
		height: 234px;
		margin-bottom: 4px;
	}
}
.home-carousel-button {
	background-color: #1d1e29;
	border: 0;
	color: $premium-color;
	font-size: 22px;
	font-weight: 300;
	height: 202px;
	&.is-two-row {
		height: 472px;
	}
	&.gather-drop {
		height: 256px !important;
	}
}
.home-carousel-button.disabled,
.home-carousel-button:disabled {
	color: #686a73;
	background-color: #33354b81 !important;

	@include mobile() {
		display: none;
	}
}

.drop-external-link {
	color: #fff;
}

;@import "sass-embedded-legacy-load-done:40";