#share-social-link {
	display: flex;
	margin: 30px 0 10px 0;
	align-items: center;
	flex-direction: column;

	.icons {
		display: flex;
		align-items: center;
	}
	p:first-child {
		font-size: 24px;
		margin-bottom: 20px;
	}

	i,
	a {
		color: white;
		text-decoration: none;
		margin: 0 10px;
		font-size: 30px;
		cursor: pointer;
	}

	p {
		margin: 0 10px;
	}

	.share-icon {
		margin: 0 10px;
		width: 20px;
		height: 20px;
	}
}
