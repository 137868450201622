.user-session-management-popover {
	width: 100%;
	max-width: 640px;
	padding: 0;
	border: 0;

	.popover-content {
		padding: 0;

		.header {
			height: 4em;
			line-height: 4em;
			padding: 0 1em;
			background-color: $content-background-color;

			.slot-name {
				color: $primary-color;
			}
		}

		.monospace {
			font-family: monospace;
		}
		.user-sessions-table td,
		.user-sessions-table th {
			text-align: left;
		}

		.user-sessions-table tr:last-child {
			text-align: right;
		}
	}
}

;@import "sass-embedded-legacy-load-done:135";