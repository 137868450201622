@for $i from 0 through 41 {
	.weapon#{$i} {
		background-image: url(../img/weapons/#{$i}.png);
	}
}

.weapon19.weapon-skin {
	background-image: url(../img/weapons/19-skin.png);
}
.weapon22.weapon-skin {
	background-image: url(../img/weapons/22-skin.png);
}
.weapon25.weapon-skin {
	background-image: url(../img/weapons/25-skin.png);
}

;@import "sass-embedded-legacy-load-done:58";