.logged-out-landing-root {
	@import './lib/bulma.scss';

	width: 100%;
	margin-top: -70px;
	background-color: #14151d;
	color: #ffffff;

	@include mobile() {
		margin-top: -140px;
	}
	.header {
		height: 800px;
		width: 100%;
		position: relative;

		@include mobile() {
			height: 500px;
		}

		video {
			width: 100%;
			height: 100%;
			position: absolute;
			object-fit: cover;
			z-index: 0;
			opacity: 0.2;
			-webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
			mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
		}

		.overlay {
			width: 100%;
			height: 100%;
			position: absolute;
			object-fit: cover;
			background: linear-gradient(180deg, #b500b5 5%, blue 50%, #00a2ff2c 90%, #14151d);
			opacity: 0.2;
			z-index: 0;
		}

		.logo-container {
			position: absolute;
			width: 100%;
			top: 10px;
			left: 0;

			padding: 0 2rem;
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include mobile() {
				flex-direction: column;
				justify-content: center;
			}

			.logo {
				width: 128px;

				img {
					max-width: 100%;
				}
			}

			.home-link-container {
				color: #fff;
				display: flex;
				justify-content: center;

				span {
					line-height: 20px;
				}

				.fas.fa-caret-right {
					font-size: 20px;
					padding: 0 0.5em;
				}
			}
		}

		.content {
			position: absolute;
			width: 70%;
			top: 55%;
			left: 50%;
			z-index: 10;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 768px;
			transform: translate(-50%, -50%);

			@include mobile() {
				top: 65%;
				height: 310px;
			}

			h1,
			p {
				text-align: center;
			}

			h1 {
				margin: 0px;
				font-size: 77px;
				font-weight: 500;
				text-align: center;

				@include mobile() {
					text-align: center;
					font-size: 40px;
				}
			}

			.referral-user-block {
				display: flex;
				flex-direction: column;
				align-items: center;

				margin-top: 2rem;

				.table-avatar {
					width: 85px;
					height: 85px;
					margin-bottom: 1rem;

					@include mobile() {
						width: 60px;
						height: 60px;
					}
				}

				.referral-user-info {
					font-size: 28px;
					font-weight: 400;
					text-align: center;

					@include mobile() {
						font-size: 22px;
					}

					.user-name {
						font-weight: 700;
					}
				}

				.subheader {
					margin-bottom: 0;
				}
			}

			.subheader {
				font-size: 24px;
				font-weight: 500;
				text-align: center;
				margin: 2.1rem 0;

				@include mobile() {
					font-size: 20px;
				}
			}

			.button {
				font-size: 20px;
				font-weight: 500;
				padding: 11px 25px;
				width: 230px;
			}

			.login-text {
				font-size: 13px;
				font-weight: 400;
				-webkit-font-smoothing: subpixel-antialiased;
				-moz-osx-font-smoothing: grayscale;

				span {
					color: #00a9e9;
					cursor: pointer;
				}
			}

			.description {
				font-size: 26px;
				font-weight: 500;
				width: 55%;
				line-height: 32px;
				margin: 2rem 0 0 0;

				@include mobile() {
					width: 90%;
					font-size: 22px;
				}
			}
		}
	}

	.live-streamz {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		margin-bottom: 3rem;

		h1 {
			font-weight: 500;
			font-size: 43px;

			@include mobile() {
				text-align: center;
				font-size: 30px;
			}
		}

		.scrollable-container {
			height: 340px;
			width: 100%;
			overflow: hidden;
			//overflow-x: auto;
		}

		.stream-preview-list {
			width: fit-content;
			white-space: nowrap;
			margin: 0 auto;
		}

		.preview-wrapper {
			display: inline-block;
			position: relative;
			width: 540px;
			height: 310px;

			@include mobile() {
				width: 400px;
				height: 280px;
			}

			i {
				top: 50%;
				left: 50%;
				position: absolute;
				font-size: 120px;
				color: #ff9100;
				transform: translate(-50%, -50%);
				&:hover {
					color: #f3a847;
				}
			}
			.preview {
				width: 100%;
				height: 100%;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
			}
		}

		.empty-placeholder {
			width: 100%;
			height: 100%;
		}
	}

	.players-reviews-container {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		width: 100%;
		margin-bottom: 3.5rem;

		h1 {
			font-weight: 500;
			font-size: 43px;
			margin-bottom: 4rem;

			@include mobile() {
				text-align: center;
				font-size: 30px;
				margin-bottom: 1rem;
			}
		}

		.user-reviews {
			display: flex;
			justify-content: center;
			width: 100%;

			@include mobile() {
				flex-direction: column;
			}

			.review-wrapper {
				width: 380px;
				min-height: 220px;
				margin: 0 1rem;
				background-color: #1d1e29;
				border-radius: 4px;

				display: flex;
				flex-direction: column;
				padding: 1.5rem;

				@include mobile() {
					width: 90%;
					margin: 1rem;
				}

				.text-wrapper {
					display: flex;
					flex: 1;

					.icon-wrapper {
						width: 12%;
						padding: 0 10px 0 0;

						i {
							font-size: 26px;
							color: #ff9100;
						}
					}

					.text {
						width: 88%;
						color: #c9c8c8;
						font-size: 14px;
						font-style: italic;
					}
				}

				.user-data-wrapper {
					width: 100%;
					height: 50px;

					display: flex;
					align-items: center;
					margin-top: 0.5rem;

					.user-avatar {
						width: 50px;
						height: 50px;

						background-position: center;
						background-repeat: no-repeat;
						background-size: contain;
						border-radius: 50%;
						border: 2px solid #ff9100;

						margin-right: 1rem;

						// TODO: Implement all review images here instead of using URLs to find them
						&.taz {
							background-image: url(../img/loggedoutlanding/reviews/taz.jpg);
						}
					}

					.user-data {
						.user-name {
							font-size: 16px;
							color: #ff9100;
						}

						.user-status {
							font-size: 14px;
							color: #ffffff;
						}
					}
				}
			}
		}
		.description {
			font-weight: 500;
			width: 90%;
			font-size: 22px;
			line-height: 32px;
			margin: 2rem 0 0 0;
			padding: 0px 50px;
			text-align: center;
		}
	}
	.features {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		h1 {
			font-weight: 500;
			font-size: 43px;
			margin-bottom: 3rem;

			@include mobile() {
				text-align: center;
				font-size: 30px;
			}
		}

		.row {
			max-width: 80%;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			.card {
				margin: 2rem;
				padding: 0 10px;
				width: 320px;
				display: flex;
				flex-direction: column;
				align-items: center;

				.icon {
					width: 72px;
					height: 72px;
					background-color: #272936;
					border-radius: 4px;

					background-position: center;
					background-repeat: no-repeat;
					background-size: 70%;

					&.icon1 {
						background-image: url(../img/loggedoutlanding/icon1.png);
					}

					&.icon2 {
						background-image: url(../img/loggedoutlanding/icon2.png);
						background-size: 60%;
					}

					&.icon3 {
						background-image: url(../img/loggedoutlanding/icon3.png);
					}

					&.icon4 {
						background-image: url(../img/loggedoutlanding/icon4.png);
					}

					&.icon5 {
						background-image: url(../img/loggedoutlanding/icon5.png);
					}
				}

				.title {
					text-align: center;
					font-weight: 500;
					font-size: 22px;
					height: 100px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					margin: 1rem 0 0;
				}

				.description {
					text-align: center;
					font-weight: 300;
					font-size: 18px;
					min-height: 70px;
				}
			}
		}
	}

	.gathers-matchmaking {
		display: flex;
		justify-content: center;

		@include mobile() {
			flex-direction: column;
		}

		.card {
			width: 420px;
			height: 500px;
			margin: 50px;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;

			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;

			@include mobile() {
				width: 90%;
				margin: 20px auto;
			}

			&.matchmaking {
				background-image: url(../img/loggedoutlanding/matchmaking_bg.png);
			}

			&.gathers {
				background-image: url(../img/loggedoutlanding/gathers_bg.png);
			}

			.lol-button {
				width: 280px;
				font-size: 20px;
				font-weight: 500;
				color: #fff;
				border: 2px solid #00a6e9;

				padding: 24px 0;
				height: 40px;
				margin: 20px 0;
			}

			p {
				font-size: 18px;
				font-weight: 300;
				text-align: center;
				padding: 0 5%;
				margin-bottom: 100px;
			}
		}
	}

	.tournaments-block {
		width: 100%;
		padding: 6% 0;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url(../img/loggedoutlanding/tournaments_back.jpg);

		display: flex;
		flex-direction: column;
		align-items: center;

		.title {
			font-weight: 500;
			font-size: 43px;
			margin-bottom: 0.8rem;

			@include mobile() {
				text-align: center;
				font-size: 30px;
			}
		}
		.subtitle {
			font-weight: 300;
			font-size: 40px;
			margin-bottom: 3rem;

			@include mobile() {
				font-size: 26px;
			}
		}

		.bullets {
			padding: 0 15px;
			margin-bottom: 2rem;

			.bullet {
				text-align: left;
				font-size: 18px;
				font-weight: 300;
				position: relative;

				&:not(:last-child) {
					margin-bottom: 10px;
				}

				&::before {
					position: absolute;
					top: 0.4em;
					left: -1.5em;
					content: ' ';
					width: 8px;
					height: 8px;
					background-color: #5155b2;
				}
			}
		}

		.tournaments {
			width: 100%;
			display: flex;
			justify-content: center;

			@include mobile() {
				flex-direction: column;
				align-items: center;
			}

			.tournaments-tournament-card {
				margin: 1rem;
			}
		}
	}

	.premium-block {
		display: flex;
		width: 100%;
		justify-content: center;

		padding: 12% 10% 9%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url(../img/loggedoutlanding/premium_back.jpg);

		@include mobile() {
			flex-direction: column;
			padding: 4%;
		}

		.premium-logo {
			width: 320px;
			height: 65px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			background-image: url(../img/loggedoutlanding/premium_logo.png);

			@include mobile() {
				width: 230px;
				height: 55px;
				background-size: contain;
			}
		}
		h1 {
			font-size: 45px;

			@include mobile() {
				font-size: 30px;
			}

			span {
				color: #ff9100;
			}
		}
		.button {
			background: transparent linear-gradient(90deg, #ff8400 0%, #ff4e00 100%) 0% 0% no-repeat padding-box;
			padding: 13px 28px;
			border: none;
			margin: 20px 0;
			width: 200px;
			height: 50px;
			font-size: 16px;
		}

		.premium-ad-image {
			width: 660px;
			height: 660px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}

		.image-1 {
			background-image: url(../img/loggedoutlanding/premium_drops.png);
		}

		.image-2 {
			background-image: url(../img/loggedoutlanding/premium_mm.png);
		}

		.image-3 {
			background-image: url(../img/loggedoutlanding/premium_stats.png);
		}

		.col {
			width: 50%;
			position: relative;

			@include mobile() {
				width: 100%;
			}
		}

		.premium {
			color: #ff9100;
			font-size: 18px;
		}

		.slide {
			&.centered {
				justify-content: center;
				align-items: center;
				flex-direction: column;
			}

			&.hidden {
				display: none;
			}

			&.visible {
				display: flex;
			}

			&.fadeIn {
				-webkit-animation-name: fadeIn;
				-webkit-animation-duration: 1.5s;
				animation-name: fadeIn;
				animation-duration: 1.5s;
			}

			&.fadeOut {
				-webkit-animation-name: fadeOut;
				-webkit-animation-duration: 1.5s;
				animation-name: fadeOut;
				animation-duration: 1.5s;
			}

			@-webkit-keyframes fadeIn {
				from {
					opacity: 0;
				}
				to {
					opacity: 1;
				}
			}

			@keyframes fadeIn {
				from {
					opacity: 0;
				}
				to {
					opacity: 1;
				}
			}

			@-webkit-keyframes fadeOut {
				from {
					opacity: 1;
				}
				to {
					opacity: 0;
				}
			}

			@keyframes fadeOut {
				from {
					opacity: 1;
				}
				to {
					opacity: 0;
				}
			}
		}
	}
}
