.csgo-landing {
	margin: 0;
	margin-bottom: -1em;
	flex-grow: 1;
	color: #fff;
}

.csgo-landing-background {
	width: 100%;
	background-color: #15151d;
	padding-top: 50px;
	background-image: url(../img/backgrounds/sem_landing.jpg);
	background-position: center top;
	background-repeat: no-repeat;
	background-size: 1920px auto;

	@media (max-width: 1000px) {
		padding-top: 1px;

		.matchmaking-login-instructions {
			margin-top: 20px;
		}
	}
}

.csgo-landing-inner {
	max-width: 1000px;
	margin: auto;

	.inner-text {
		max-width: 600px;

		h1 {
			font-size: 36px;
			font-weight: 600;
		}
	}

	@media (max-width: 1000px) {
		margin: 20px;
	}
}

.landing-usp-section {
	padding: 20px 0 40px;

	.landing-usp-content {
		max-width: 1200px;
		margin: 0px auto;
	}
	.landing-usp-wrapper {
		display: flex;
		flex-wrap: wrap;
		.usp-wrapper {
			width: 25%;
			flex-direction: column;
			margin-top: 0;
			margin-bottom: 0;
			max-width: 220px;

			.usp-point {
				font-size: 18px;
				font-weight: 600;
				text-transform: uppercase;
				padding-bottom: 1em;
			}
			.usp-text {
				text-align: center;
			}
		}
	}
	.landing-usp-wrapper.top {
		justify-content: space-around;
	}
	.landing-usp-wrapper.bottom {
		width: 100%;
		flex-direction: row;
		justify-content: center;

		.usp-quarter {
			width: 25%;

			.usp-wrapper {
				width: auto;
				max-width: 150px;
				margin: auto;
			}

			@media (max-width: 1000px) {
				width: 50%;
			}
		}
	}
}
