.organizer-title {
	display: inline-block;
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 10px 20px 10px 30px;
	font-size: 30px;
}

.organizer-page-section {
	margin-top: 48px;
}

.organizer-settings-buttons {
	position: absolute;
	top: 20px;
	right: 36px;
	text-align: right;
}

.organizer-media-buttons {
	position: absolute;
	bottom: 16px;
	right: 34px;
}
.organizer-media-button {
	display: inline-block;
	margin-left: 12px;
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 20px;
	text-align: center;
	color: $primary-hover-color;
	background-color: $secondary-color;
	border-radius: 50%;
	cursor: pointer;
}

.organizer-matches {
	margin-bottom: 48px;
}
.organizer-matches-header {
	height: 54px;
	line-height: 54px;
	text-align: center;
	font-size: 16px;
	background-color: $secondary-color;
}
.organizer-matches-entry {
	position: relative;
	height: 70px;
	margin-bottom: 2px;
	background-color: #252525;
	padding: 10px;

	&.empty {
		line-height: 50px;
		text-align: center;
	}
}
.organizer-matches-entry:nth-child(even) {
	background-color: #1c1c1c;
}
.organizer-matches-entry-left {
	float: left;
	width: 100%;
	margin: 0 auto;
}
.organizer-matches-entry-right {
	position: absolute;
	float: right;
	width: 60%;
	bottom: 10px;
	right: 10px;

	.teams {
		float: right;
		text-align: right;
		margin-right: 16px;
	}
	.scores {
		float: right;
		width: 20px;
	}
}
.organizer-matches-entry-date,
.organizer-matches-entry-view {
	font-size: 10px;
	text-transform: uppercase;
}
.organizer-matches-entry-date {
	margin-top: 4px;
}

.organizer-page-center-column {
	margin-top: 35px;
}

;@import "sass-embedded-legacy-load-done:98";