.phone-verification-popover,
.phone-verification-embed {
	width: 500px;
	max-width: 100%;
	&.popover {
		padding: 0 0 15px 0;
	}
	.popover-title {
		display: none;
	}
	.verify-title {
		background-color: #161720;
		color: #fff;
		height: 48px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		.fa-exclamation-triangle {
			color: #a5010c;
			font-size: 18px;
			vertical-align: middle;
		}
		span {
			text-transform: uppercase;
			font-size: 16px;
			font-weight: 600;
			margin-left: 14px;
		}
	}
	.popover-content {
		padding: 0;
	}
	.verify-content {
		font-weight: 400;
		padding: 15px 23px 12px 23px;
	}
	input[type='tel'],
	input[type='text'],
	.button {
		width: 100%;
		&::placeholder {
			color: #acacac;
		}
	}
	.button.close {
		color: $grey-text-color;
		background-color: rgba(0, 0, 0, 0);
	}
	p {
		font-size: 18px;
		line-height: 22px;
	}
	.verification-helps {
		font-size: 13.5px;
		line-height: 18px;
	}
	.choose-buttons {
		display: flex;
		padding-bottom: 1em;

		.button {
			margin-top: 0;
		}

		.or {
			color: $grey-text-color;
			text-transform: uppercase;
			font-weight: 500;
			padding: 10px;
		}
	}
	.verification {
		border-top: 1px solid $dark-grey-border;
		color: $grey-text-color;

		.caption {
			color: $primary-color;
			padding: 1em 0;
			font-weight: 500;
			font-size: 16px;
		}
		.phone-group {
			align-items: center;
			display: flex;
			margin: 1em 0;

			input[type='tel'] {
				margin-left: 10px;
			}
		}
		.request-code {
			padding-bottom: 1em;
		}
		.check-code {
			border-top: 1px solid $dark-grey-border;
			padding-top: 1em;

			button {
				margin-top: 1em;
			}
		}

		li {
			padding-bottom: 1em;
		}
	}
}

;@import "sass-embedded-legacy-load-done:116";