.overwatch,
.admin-overwatch {
	&.shrink {
		width: 100%;
		max-width: 640px;
		margin: 0 auto;
	}

	> * {
		margin: 0.5em 0;
	}

	.hide-old-cases-checkbox {
		display: flex;
		align-items: center;

		label {
			margin-left: 0.5em;
		}
	}

	.header .statistics {
		display: flex;
		justify-content: center;
		align-items: baseline;

		.data-pair {
			padding: 1em;
			margin: 1em;
			text-align: center;
			align-self: flex-end;

			span {
				display: block;
				color: #fff;

				&.value {
					font-size: 4em;
				}

				&.key {
					color: #666;
				}
			}

			&.important span.value {
				font-size: 6em;
			}
		}
	}

	.menu {
		display: flex;
		flex-direction: row;

		> * {
			&:last-child {
				margin-left: auto;
			}
			&:not(:last-child) {
				color: #999;
			}
		}
	}

	.affected-users {
		.affected-user {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			.user-tools {
				display: flex;
				flex-direction: row;
			}
		}
	}
}

.overwatch-case-verdict-popover {
	width: 100%;
	max-width: 640px;

	.verdict-wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;

		label {
			flex-shrink: 1;
			padding: 0.5em;
		}

		select {
			flex-grow: 1;
		}
	}

	select,
	textarea {
		width: 100%;
		margin-bottom: 0.5em;
	}
}

.overwatch-create-investigation-popover {
	width: 100%;
	max-width: 640px;

	height: 100vh;
	max-height: 100vh;

	display: flex;
	flex-flow: column;

	.popover-content,
	.create-investigation-form {
		flex: 1;
		display: flex;
		flex-flow: column;
		max-height: 100%;
	}

	.controllers {
		max-height: 20vh;
		display: flex;
		flex-flow: row;
		justify-content: space-between;

		.toolbar {
			display: flex;
			flex-flow: row;
			align-items: left;

			* {
				margin-right: 0.5em;
			}
		}

		.actions {
			display: flex;
			flex-direction: row;
			justify-content: center;
		}
	}

	.report-list-wrapper {
		flex: 2;
		overflow: auto;

		margin: 1em 0;
		padding: 0 1em;

		.report-list .report {
			margin-bottom: 0.5em;
			display: flex;
			flex-direction: row;

			input {
				flex-shrink: 1;
				margin: 1em;
			}

			.condensed-report {
				flex-grow: 1;
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:43";