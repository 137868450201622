.match-lobby-page-header {
	display: flex;
	flex-direction: column;
	color: $grey-text-color;
	position: relative;
	font-size: 16px;
	text-align: center;
	margin-bottom: 20px;
	padding-bottom: 20px;

	.row {
		display: flex;
		align-items: center;
	}

	.region-flag,
	.country-flag {
		width: 23px;
		height: 16px;
		background-size: 23px 16px;
		background-position: 0px 50%;
		line-height: 16px;
		background-repeat: no-repeat;
		padding-left: 28px;
	}
}

.match-lobby-score-logo {
	flex: 1;
	min-width: 150px;

	.match-lobby-status {
		text-transform: uppercase;
		font-size: 13px;

		&.initializing,
		&.warmup,
		&.live {
			border-radius: 1em;
			border: 1px solid;
			display: inline-block;
			padding: 2px 1em;
		}

		&.live {
			color: #abff00;
			border-color: #abff00;
		}

		&.warmup {
			color: #fff;
			border-color: #fff;

			.time-remaining {
				font-family: monospace;
			}
		}

		&.initializing {
			color: $dark-grey-text-color;
			border-color: $dark-grey-text-color;
		}

		&.finished {
			color: $dark-grey-text-color;
		}
	}
}

.match-lobby-win-chance {
	width: 300px;
	margin: auto;
	font-size: 14px;
	margin-top: 40px;

	.match-lobby-win {
		display: flex;
		font-size: 18px;
		color: #fff;
		vertical-align: middle;
		line-height: 26px;

		.match-lobby-win-team {
			margin: 5px 2px;
			height: 16px;
			background: linear-gradient(270deg, #00a9e9 0%, #006de9 100%);

			&.team1 {
				background: linear-gradient(270deg, #006de9 0%, #00a9e9 100%);
			}
		}

		span {
			margin: 0px 5px;
		}
	}
}

.match-lobby-teams-score {
	margin-top: 35px;
}
.match-lobby-team-profile {
	font-size: 16px;
	width: 100%;
	display: flex;

	@include mobile() {
		display: none;
	}

	.avatar {
		height: 79px;
		width: 79px;
		border: 3px solid $button-color;
		margin: 0px 20px;
	}
}

.match-lobby-team-profile-align-left {
	text-align: left;

	.avatar {
		float: left;
	}
}
.match-lobby-team-profile-align-right {
	text-align: right;
	flex-direction: row-reverse;

	.avatar {
		float: right;
	}
}

.match-lobby-team-profile-team-stats {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.match-lobby-team-profile-label {
	font-size: 12px;
	color: $grey-text-color;
	text-transform: uppercase;

	.winner {
		color: #aad63f;
	}
}
.match-lobby-team-profile-team {
	font-size: 29px;
	color: #fff;
}

.match-lobby-team-profile-stats {
	font-weight: 400;
	div {
		padding-top: 6px;
		padding-right: 22px;
	}
	.wins {
		padding-right: 22px;
	}
	:last-child {
		padding-right: 0px;
	}
}

.match-lobby-team-tables {
	color: $grey-text-color;
	white-space: nowrap;
	tr {
		background-color: $table-header-background-color;
	}

	//tr.highlight-self td {
	//	border-bottom: 1px solid #13aceb;
	//	border-top: 1px solid #13aceb;
	//}

	td {
		height: 49px;
		line-height: 49px;
	}

	&.table-separated {
		border-spacing: 0;
	}
}
.match-lobby-team-user-rank {
	display: flex;
	flex-direction: column;
	line-height: 12px;
	font-size: 10px;

	.table-rank {
		margin: 0;
	}
}
.match-lobby-team-user {
	white-space: nowrap;

	.famfamfam-flag {
		margin: 0 3px;
	}
}
.match-lobby-team-username {
	max-width: 107px;
	height: 15px;
	text-overflow: ellipsis;
	overflow: hidden;
	vertical-align: middle;
	margin-right: 5px;

	&.with-skill {
		max-width: 105px;
	}
}
.match-lobby-kick-button {
	color: #f00;
	cursor: pointer;
}
.match-lobby-on-fire-gold {
	color: #eeb700;
}
.match-lobby-on-fire-red {
	color: #ac3333;
}
.match-lobby-on-fire-blue {
	color: #1386eb;
}

.match-lobby-chat {
	margin-top: 16px;
	@include mobile() {
		margin-top: 20px;
	}
	.box-header {
		background-color: #21273d;
		font-size: 12px;
		text-transform: none;
		text-align: left;
		margin: 0;

		.button {
			padding: 0px 5px;
			height: 25px;
			line-height: 25px;
			min-height: 25px;
			font-size: 12px;
			float: right;
		}
	}
	.box-content {
		height: 200px;
		padding: 0px 0px;
		overflow: hidden;
	}
}
.match-lobby-chat-messages {
	height: 160px;
	overflow: auto;
	padding: 20px;
}
.match-lobby-chat-message {
	margin: 5px 0px;
	word-wrap: break-word;
	align-items: center;

	.player-card-wrapper {
		font-weight: 500;
	}
}
.match-lobby-chat-input-bar {
	display: flex;
}
.match-lobby-chat-input-toggle {
	width: 80px;
}
.match-lobby-chat-input-toggle:hover {
	background-color: $primary-hover-color;
}
.match-lobby-chat-input-field {
	width: calc(100% - 140px);
}
.match-lobby-chat-input-field,
.match-lobby-chat-input-field:focus {
	border: 0;
}
.gather-chat-input-field + .match-lobby-chat-input-button {
	margin-top: 2px;
	margin-right: 10px;
}
.match-lobby-chat-input-toggle + .gather-chat-input-field {
	width: calc(100% - 170px);
}
.match-lobby-server {
	margin-top: 16px;
	@include mobile() {
		margin-top: 20px;
	}
	.box-content {
		height: 200px;
		text-align: center;
	}
}

.match-lobby-server-1v1 {
	.match-lobby-server {
		.box-header {
			background-color: #212230;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
		}
		.box-content {
			background-color: #191a24 !important;
			height: 220px;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
		}
	}
}

.match-lobby-server-timer {
	font-size: 22px;
	color: $primary-color;
	margin-bottom: 15px;
}
.match-lobby-server-vote-flags {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.match-lobby-server-vote-flag {
	position: relative;
	width: 78px;
	height: 57px;
	background-repeat: no-repeat;
	background-size: cover;
	display: inline-block;
	transition: filter 0.25s linear;
}
.match-lobby-server-vote-maps {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.match-lobby-server-vote-map {
	position: relative;
	width: 99px;
	height: 65px;
	background-repeat: no-repeat;
	background-size: cover;
	display: inline-block;
	transition: border 0.25s linear;
	border: 1px dashed #fff;

	&.map-banned {
		border: 1px solid #f00;
	}

	.fa-ban {
		line-height: 65px;
		text-align: center;
		font-size: 40px;
		color: #f00;
	}
}
.match-lobby-server-ping {
	position: absolute;
	bottom: 0;
	right: 0;
	color: #fff;
	padding: 2px 4px;
	background-color: rgba(0, 0, 0, 0.6);
	font-size: 12px;
	font-weight: 700;
	pointer-events: none;
}
.match-lobby-server-selected-flag {
	width: 36px;
	height: 26px;
	background-size: 36px 26px;
	background-repeat: no-repeat;
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;
}
.match-lobby-server-info {
	margin-top: 50px;
	font-size: 18px;

	.button {
		margin-left: 10px;
		background-color: #197b30;
		background-image: url(../img/misc/steam-logo.png);
		background-repeat: no-repeat;
		background-position: 10px 50%;
		padding-left: 60px;
	}
}
.match-lobby-scores {
	color: #fff;
	text-align: center;
	font-size: 36px;
	.winner {
		color: #aad63f;
	}
}
.match-lobby-scores-separator {
	color: $dark-grey-text-color;
}
.match-lobby-extend-warmup-button,
.match-lobby-request-admin-button,
.match-lobby-request-admin-reason {
	margin-top: 16px;
}

.match-lobby-admin-options {
	display: flex;
	align-items: center;
	width: 100%;
	height: 50px;
	margin-top: 16px;
	padding: 5px;
	background-color: #f2f2f2;

	&.is-summary {
		margin-top: 40px;
	}

	.title {
		text-transform: uppercase;
		font-weight: 700;
		font-size: 17px;
		margin-right: 10px;
	}
}

.match-summary-logo {
	width: 204px;
	max-width: 100%;
	height: 46px;
	background-image: url(../img/gathers/csgo_inverted.png);

	@include mobile() {
		display: none;
	}
}

.match-summary-gather-name {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 41px;
	margin: 1em;

	.icon-star-gather {
		margin-right: 0.5em;
		width: 52px;
		height: 52px;
	}
}
.match-summary-page-header {
	display: flex;
	flex-wrap: wrap;
	padding: 1em;
	background-color: $card-background-color;
	margin-bottom: 20px;

	@include tablet() {
		height: 150px;
	}

	.header-section {
		width: 33.333333%;

		@include mobile() {
			width: 50%;

			&:first-child {
				display: none;
			}
		}

		@include tablet() {
			&.is-centered {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				text-align: center;
			}
		}
	}

	.header-section.rematch-section {
		text-align: center;

		.rematch-timer {
			font-size: 24px;
			color: $primary-color;
		}

		.label {
			font-size: 15px;
			padding-bottom: 10px;
		}

		.buttons-wrapper {
			display: flex;
			justify-content: center;
			width: 100%;
		}

		.button {
			width: 100px;

			&.unselected,
			&.selected,
			&:active {
				cursor: default;
			}
		}

		.yes-button {
			background-color: #8dc67b;

			&.selected,
			&:active {
				background-color: #69945c;
			}

			&:hover {
				background-color: #a0e08b;

				&.unselected {
					background-color: #8dc67b;
				}

				&.selected {
					background-color: #69945c;
				}
			}
		}

		.no-button {
			background-color: darkred;

			&.selected,
			&:active {
				background-color: #400;
			}

			&:hover {
				background-color: red;

				&.unselected {
					background-color: darkred;
				}

				&.selected {
					background-color: #400;
				}
			}
		}
	}

	.logo {
		width: 64px;
		height: 64px;
		background-size: cover;
		background-repeat: no-repeat;
		border: 4px solid $primary-hover-color;
		border-radius: 50%;
		text-align: center;

		@include mobile() {
			display: none;
		}
	}

	.match-score {
		@include mobile() {
			display: block;
		}
	}

	.winner {
		font-size: 24px;

		@include mobile() {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			text-align: center;
			color: #fff;

			.team-name {
				color: #fff;
			}
		}

		@include tablet() {
			.team-name {
				color: $primary-color;
			}
		}
	}

	.labels-container {
		display: flex;

		.left-labels {
			@include tablet() {
				flex: 1;
				padding: 0 10px;
				font-weight: 500;
				text-align: right;

				> * {
					padding: 4px 0;
				}
			}
		}

		.right-labels {
			@include tablet() {
				text-transform: uppercase;
				font-weight: 500;

				> * {
					padding: 4px 0;
				}
			}
		}
	}

	&.tournament {
		height: 180px;
	}
}

.match-header-1v1 {
	.match-header-1v1-top {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		position: relative;
		margin-bottom: 40px;
	}

	.match-header-1v1-details {
		border-radius: 10px;
		padding: 1.5em;
		background-color: #1d1e2a;
		margin-bottom: 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100px;
		.label {
			display: block;
			font-size: 12px;
			color: $grey-text-color;
			margin-bottom: 5px;
		}
		.map-details {
			display: flex;
			& > div {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
			.match-table-map {
				border: 0;
				border-radius: 5px;
				margin-right: 15px;
				width: 75px;
				height: 50px;
			}
		}
		.server-location {
			display: flex;
			align-items: center;
			.Tipsy-inline-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 5px;
				.country-flag {
					width: 20px;
					background-size: contain;
					background-position: center;
				}
			}
		}
	}
	.match-logo {
		height: 46px;
		width: 130px;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url(../img/gathers/csgo_inverted.png);
		filter: invert(1);
	}
	.team-container {
		font-size: 25px;
		font-weight: 400;
		flex-grow: 1;
		flex-basis: 0;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		& > .logo {
			margin-left: 20px;
		}
		.team-name {
			text-align: right;
		}
		&.reverse {
			justify-content: flex-start;
			& > .logo {
				margin-right: 20px;
				margin-left: 0;
			}
			.team-name {
				text-align: left;
			}
		}
	}
	.winner-crown {
		color: gold;
		font-size: 25px;
		margin: 0 20px;
	}
	.score-container {
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		width: 100%;
		overflow: auto;
	}
	.score-status {
		color: gray;
		font-size: 13px;
	}
	.scores {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 30px;
		min-width: 160px;

		.score-values {
			display: flex;
			align-items: center;
		}
	}
	.score-value {
		color: white;
		margin: 0 1rem;
	}
	.score-sep {
		color: $grey-text-color;
		margin: 10px 8px;
	}
	.logo {
		width: 92px;
		height: 92px;
		background-size: cover;
		background-repeat: no-repeat;
		border-radius: 50%;
		text-align: center;

		border: 3px solid $primary-hover-color;
		&.premium {
			border-color: $premium-color;
		}

		@include mobile() {
			display: none;
		}
	}
}

.match-summary-item {
	margin: 10px 0;
	font-size: 12px;
	font-weight: 800;
	text-transform: uppercase;

	@include mobile() {
		color: #fff;

		a {
			color: #fff;
		}
	}

	.label {
		color: $primary-color;
		margin-right: 10px;

		@include mobile() {
			color: $secondary-color;
		}
	}
}
.match-summary-send-friend-request {
	cursor: pointer;
	color: #5ec76c;
}
.match-summary-add-friend-disabled {
	color: dimgray;
}
.match-summary-ratings > div:first-child {
	margin-right: 5px;
}
.match-summary-thumb {
	color: darkgray;
	padding-left: 8px;

	&.disabled,
	&.disabled:hover,
	&.disabled:active {
		color: dimgray;
	}
	&:hover {
		color: lightgray;
	}
	&:active,
	&.selected {
		color: slategray;
	}

	&.unselected:hover,
	&.unselected:active {
		color: darkgray;
	}
}

.match-summary-mvp {
	width: 100%;

	.mvp-summary {
		display: flex;
	}
	.title {
		font-size: 20px;
		padding: 8px;
	}
	.mvp-name-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.mvp-left {
	width: 25%;
	background-color: #20212d;
	color: #fff;
	float: left;
	text-align: center;

	.mvp-avatar-wrapper {
		margin: 1em;
	}
	.mvp-avatar {
		height: 61px;
		width: 61px;
		margin-top: 16px;
		border: 3px solid $button-color;
	}

	.mvp-name {
		font-size: 20px;
		margin-top: 4px;
		margin-bottom: 8px;
		margin-right: 5px;
	}
}
.mvp-right {
	width: 75%;
	background-color: #191a24;
	float: right;

	.mvp-stats {
		display: flex;
		justify-content: space-around;
		align-items: center;
		flex-wrap: wrap;
		height: 100%;

		.mvp-stat {
			font-weight: 500;
			font-size: 16px;
			margin: 1em;
			display: flex;
			flex-direction: column;
			text-align: center;

			.label {
				color: $grey-text-color;
				font-size: 14px;
			}
			.value {
				color: $primary-hover-color;
				font-size: 30px;
			}
		}
	}
}

.match-summary-comment-box {
	margin: 32px 0;

	.title {
		font-size: 20px;
		padding: 8px;
	}
	.comment-box {
		width: 100%;
		min-width: initial;
		border-color: #333;
		color: white !important;
		background: #1b1d26 !important;
	}
	.post-button {
		width: 140px;
		margin-top: 1em;
		margin-left: auto;
		display: block;
	}

	&.match-1v1 {
		height: 120px;
		margin-bottom: 50px;
		.post-button {
			width: 100px;
			float: right;
			min-width: 180px;
			border-radius: 20px;
			margin-top: 7px;
		}
		.comment-box {
			height: 100px;
		}
	}
}

.match-summary-1v1-team-tables {
	.match-summary-1v1-team-header,
	.match-summary-1v1-team-body {
		color: #c6cfde;
		display: flex;
		align-items: center;
		justify-content: space-between;
		& > * {
			flex-grow: 1;
			flex-basis: 0;
			margin: 1px;
			text-align: center;
		}
		.friend-request {
			white-space: nowrap;
		}
	}
	.match-summary-1v1-team-header {
		font-weight: 600;
		padding: 14px 10px;
		background-color: #212230;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		text-transform: uppercase;
	}
	.match-summary-1v1-team-body {
		padding: 19px 10px;
		background-color: #191a24;
		border-bottom-left-radius: 9px;
		border-bottom-right-radius: 9px;
	}
}

.match-summary-team-tables {
	white-space: nowrap;

	thead > tr {
		font-size: 13px;
		line-height: 25px;
		height: 25px;
		text-transform: uppercase;
	}

	button {
		cursor: pointer;
		background-color: transparent;
		border: none;
		outline: none;
	}

	button.match-summary-thumb {
		padding: 0 0 0 8px;
	}

	.friend-request {
		border-radius: 4px;
		margin: 0;
		color: #13aceb;
	}

	.friend-request:hover {
		background: none;
	}
}
.match-summary-team-username-cell {
	min-width: 120px;
}
.match-summary-team-plaque-cell {
	text-align: left;
	.summary-team {
		margin-left: 7px;
		margin-right: 4px;
	}
}
.match-summary-team-username {
	max-width: 120px;
	height: 17px;
	text-overflow: ellipsis;
	overflow: hidden;
	vertical-align: middle;

	&.with-skill {
		max-width: 82px;
	}

	/*Increase the max-width since in the 1v1 the username has its own row*/
	&.is-1v1 {
		max-width: 220px;
	}
}

.match-summary-twitter-button {
	display: inline-block;
	position: relative;
	width: 24px;
	height: 24px;
	top: -4px;
	margin-left: 4px;
	margin-right: 4px;
	background-image: url(../img/misc/twitter-logo.png);
	background-size: 24px 24px;

	@include mobile() {
		filter: brightness(0%) invert(100%);
	}
}
.match-summary-facebook-button {
	display: inline-block;
	position: relative;
	width: 24px;
	height: 24px;
	top: -4px;
	margin-left: 4px;
	margin-right: 4px;
	background-image: url(../img/misc/facebook-logo.png);
	background-size: 24px 24px;

	@include mobile() {
		filter: brightness(0%) invert(100%);
	}
}

.match-summary-teams {
	display: flex;
	flex-direction: column;

	.match-summary-team-wrapper + .match-summary-team-wrapper {
		margin-top: 4em;
	}
	.match-summary-team-wrapper {
		flex-grow: 1;
		overflow: auto;
	}
}

.match-teams-1v1 {
	display: flex;
	flex-direction: row;
	align-items: center;

	@include mobile() {
		flex-direction: column;
	}

	.match-teams-1v1-header {
		display: flex;
		align-items: center;
		margin-top: 15px;
		margin-bottom: 20px;
		> * {
			margin-right: 10px;
		}
		.player-card-wrapper > span {
			color: white;
			font-size: 17px;
			font-weight: 500;
			.table-avatar {
				width: 30px;
				height: 30px;
			}
		}
	}
	& > .spacing {
		width: 35px;
		flex-grow: unset;
		flex-basis: unset;
	}
	& > * {
		width: 100%;
		flex-grow: 1;
		flex-basis: 0;
	}
}

.match-summary-team-container {
	font-size: 16px;
	font-weight: 400;
	margin: 25px 0px;

	@include mobile() {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.avatar {
		float: left;
		margin-right: 20px;
		height: 61px;
		width: 61px;
		border: 3px solid $button-color;
	}
}

.match-summary-team-profile-label {
	font-size: 26px;
	font-weight: 500;
	margin-bottom: 7px;
}
.match-summary-team-profile-team-name {
	font-size: 26px;
	margin-bottom: 7px;
}

.match-summary-match-achievements {
	width: 100%;
	margin-top: 2em;

	.title {
		font-size: 20px;
		padding: 8px;
	}
	.match-drops-link {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		font-size: 16px;
		text-transform: capitalize;

		.match-drop-logo {
			height: 100%;
			width: 40px;
			background-size: contain;
			background-position: center;
			margin-right: 1em;
		}
	}
}

.match-summary-player-stats-wrapper {
	padding: 0;
	height: auto;
}

.match-summary-player-stats {
	display: flex;
	height: 100%;
	flex-wrap: wrap;

	.player-stat {
		display: flex;
		width: 20%;
		align-items: center;
		justify-content: center;
		color: #fff;
		background-color: $primary-color;
		text-align: left;
		line-height: 1;
		padding: 10px 0;

		@include tablet() {
			&:nth-child(even) {
				background-color: darken($primary-color, 10%);
			}
		}

		@include mobile() {
			width: 50%;

			&:nth-child(3n),
			&:nth-child(3n-1) {
				background-color: darken($primary-color, 10%);
			}

			// Odd number of stat boxes, so last one will be full-width.
			&:last-child {
				width: 100%;
			}
		}
	}

	.stat-icon {
		width: 50px;
		height: 50px;
		background-size: cover;
		margin-right: 10px;
		filter: brightness(0) saturate(100%) invert(72%) sepia(3%) saturate(282%) hue-rotate(202deg)
			brightness(87%) contrast(97%);

		@include mobile() {
			width: 30px;
			height: 30px;
		}

		&.score-icon {
			background-image: url(../img/misc/records_icons_kills.png);
		}
		&.headshots-icon {
			background-image: url(../img/misc/icon_headshot.png);
		}
		&.clutches-icon {
			background-image: url(../img/misc/icon_clutch.png);
		}
		&.opening-kills-icon {
			background-image: url(../img/misc/records_icons_deaths.png);
		}
		&.entry-frags-icon {
			background-image: url(../img/misc/records_icons_headshot.png);
		}
	}

	.stat-data {
		font-size: 27px;
		font-weight: 700;

		@include mobile() {
			font-size: 22px;
		}
	}

	.stat-title {
		font-size: 12px;
		color: $grey-text-color;
		word-wrap: break-word;
	}
}

.match-achievement-scroll {
	max-height: 250px;
	overflow: auto;
}

.match-achievement-scroll::-webkit-scrollbar-track {
	background: none;
}

.match-achievement-scroll::-webkit-scrollbar-thumb {
	background-color: #222330;
	border-radius: 20px;
	content: 'Reference ';
}

.match-achievement {
	display: flex;
	justify-content: space-between;
	height: 102px;
	background-color: #191a24;
	margin: 5px 0;
	border-radius: 10px;

	&.premium {
		border: 1px solid #f5791f;
		height: auto;

		.match-achievement-info {
			flex-direction: column;
		}
	}
	.player-wrapper {
		width: 30%;
		background-color: $table-header-background-color;
		border-radius: 10px 0 0 10px;
		display: flex;
		align-items: center;

		@include mobile() {
			flex-direction: column;
		}

		&.premium-drop {
			flex-direction: column;
			padding: 2em 0;
		}

		.match-drop-logo-left {
			margin-top: 5px;
			width: 100%;
			height: 62px;
		}
		.premium-player-wrapper {
			display: flex;
			align-items: center;

			@include mobile() {
				flex-direction: column;
			}
		}
	}
}

.match-achievement-avatar {
	display: inline-block;
	width: 66px;
	height: 66px;
	border: 2px solid $primary-hover-color;
	margin: 18px 20px 18px 26px;

	@include mobile() {
		width: 50px;
		height: 50px;
		margin: 13px 5px;
	}
}
.match-achievement-username {
	font-size: 20px;
}
.match-achievement-type-label {
	font-weight: 500;
}
.match-achievement-info {
	display: flex;
	justify-content: center;
	width: 70%;
	background-color: #1b1d26;
	border-radius: 0 10px 10px 0;

	.match-achievement-image {
		width: 30%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.match-achievement-type-label {
		width: 30%;
		display: flex;
		align-items: center;
	}
	.match-achievement-description {
		width: 40%;
		display: flex;
		align-items: center;
	}

	.match-achievement-prime-text {
		background-color: #1b1d26;
		text-transform: capitalize;
		height: 25%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 0 0 10px 0;
		margin: 0 5px;

		.button {
			margin-left: 1em;
		}
	}
}
.match-achievement-prime-info {
	display: flex;
	background-color: #191a24;
	height: 75%;
	border-radius: 0 10px 0 0;

	.match-achievement-type-label {
		width: 70%;
		border-radius: 0 10px 0 0;
	}
	.match-achievement-info-drop-name {
		color: $premium-color;
		font-size: 19px;
		padding: 1em 0;
	}
}
.match-achievement-rank {
	vertical-align: top;
	display: inline-block;
	width: 80px;
	height: 80px;
	margin: 11px 0;
	background-size: cover;

	@include mobile() {
		width: 56px;
		height: 56px;
		margin: 23px 0;
	}
}
.match-achievement-level {
	display: inline-block;
	width: 71px;
	height: 67px;
	background-size: cover;
	margin: 16px 0;

	&.from {
		width: 57px;
		height: 54px;
		margin: 22px 0;
	}
}
.match-achievement-arrow {
	vertical-align: top;
	display: inline-block;
	font-size: 22px;
	color: $primary-hover-color;
	line-height: 102px;
	margin-left: 12px;
	margin-right: 8px;

	&.red {
		color: #a80000;
	}
}
.match-achievement-info-drop-image {
	float: left;
	width: 100%;
	margin: 17.5px;
	height: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}
.match-achievement-info-drop-text {
	overflow: hidden;
	font-size: 13px;
}
.match-achievement-info-drop-name {
	font-weight: 700;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.match-achievement-info-drop-description {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.match-summary-bottom {
	margin-top: 2em;
	@include mobile() {
		display: flex;
		flex-direction: column;

		> .column:first-child {
			order: 2;
		}

		> .column:nth-child(2) {
			order: 1;
		}
	}
}
.match-lobby-skill {
	vertical-align: middle;
	display: inline-block;
	background-size: contain;
	height: 18px;
	background-position: 50% 50%;
	background-repeat: no-repeat;

	&.skill0 {
		width: 29px;
	}
	&.skill1 {
		width: 50px;
	}
	&.skill2 {
		width: 40px;
	}
}

.match-tournament-winner {
	margin: 12px 0;
}

.match-summary-lemondogs-background {
	@include tablet() {
		position: absolute;
		display: block;
		top: 50px;
		width: 260px;
		height: 700px;
		background-position: 50% 50%;
		background-repeat: no-repeat;

		&.is-left {
			left: 50%;
			margin-left: -900px;
			background-image: url(../img/backgrounds/lemondogs_left.jpg);
		}

		&.is-right {
			right: 50%;
			margin-right: -900px;
			background-image: url(../img/backgrounds/lemondogs_right.jpg);
		}
	}
}

.match-summary-premium-banner {
	background-color: rgba(222, 118, 32, 0.8);
	color: #fff;
	height: 50px;
	line-height: 50px;
	padding: 0px 25px;
	font-size: 25px;
	margin-bottom: 10px;

	.icon-premium {
		float: right;
		margin-top: 5px;
	}
}

.match-summary-prime-banner {
	background-color: rgba(87, 0, 162, 0.8);
	color: #fff;
	height: 50px;
	line-height: 50px;
	padding: 0px 25px;
	font-size: 25px;
	text-align: left;

	.icon-prime {
		float: right;
		margin-top: 5px;
	}

	.match-summary-prime-banner-esportal-logo {
		float: left;
		text-align: center;
		width: 190px;
		height: 80px;
		background-image: url(../img/logo.png);
		background-position: 0px -13px;
		background-size: cover;
	}
}

.match-lobby-prime-info {
	width: 100%;
	margin-top: 0px;
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid $prime-color;

	.title {
		text-transform: uppercase;
		font-weight: 700;
		font-size: 17px;

		.icon20 {
			margin-top: -8px;
			margin-right: 5px;
		}
	}

	ul {
		list-style-type: disc;
		padding: 0px 0px 10px 10px;
		margin: 0px 0px 10px 10px;
	}
}

.match-lobby-team-tables-drop-icon {
	color: $grey-text-color;
	display: inline-block;
	vertical-align: middle;
	width: 18px;
	height: 18px;
	margin-right: 8px;
	background-size: cover;
	background-repeat: no-repeat;
}

.match-lobby-team-tables-icon-headset {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 18px;
	height: 18px;
	margin-right: 8px;
	margin-left: 8px;
	background-image: url(../img/esportalglobal/icon_headset.svg);
}

.match-lobby-container {
	width: 100%;
	max-width: 1400px;
	margin: auto auto 0;
	background-image: url(../img/backgrounds/gathersback3.png);
	background-repeat: no-repeat;
	background-position: top;
	background-size: auto;
}

.gathers-lobby-container,
.match-summary-container {
	@extend .match-lobby-container;
	max-width: 100%;
}

.match-header-top-info {
	width: 100%;
}
.share-header {
	display: flex;
	justify-content: flex-end;
	color: $grey-text-color;

	> div {
		margin-left: 1em;
	}
	.match-summary-facebook-button,
	.match-summary-twitter-button {
		filter: brightness(0%) invert(100%);
	}
	a {
		cursor: pointer;
	}
}

.match-lobby-team-plaque-cell {
	text-align: left;
}

.ladder-match {
	width: 100%;
	max-width: 1200px;
	margin: auto;

	.ladder-banner,
	.ladder-tabs {
		width: 100%;
		max-width: 1200px;
	}
	.ladder-status {
		width: 100%;
		line-height: 75px;
		background-color: #161720;
		font-size: 16px;
		font-weight: 400;
		text-transform: uppercase;
		color: #c0c6d1;

		@include mobile() {
			line-height: 45px;
			font-size: 14px;
		}

		.ladder-status-data {
			color: $primary-color;
			padding-left: 10px;
			padding-right: 10px;
		}

		.ladder-status-separator {
			padding-left: 30px;
		}

		.ladder-status-container {
			max-width: 1200px;
			margin: auto;
		}
	}

	.ladder-title {
		font-size: 22px;

		@include mobile() {
			font-size: 18px;
			font-weight: 500;
		}
	}
	.ladder-tabs {
		background-color: #1b1d26;
		text-transform: uppercase;
		font-size: 20px;
		text-align: center;
		margin: 0px auto;

		@include tablet {
			margin-bottom: 25px;
		}

		@include mobile {
			margin-bottom: 0px;
		}

		.ladder-columns {
			margin: auto;
			max-width: 1200px;
		}

		.ladder-tab {
			display: flex;
			height: 75px;
			align-items: center;
			justify-content: center;

			background-color: $secondary-color;
			color: white;
			border-left: 1px solid #292a34;
			border-right: 1px solid #292a34;

			&:first-child {
				border-left: 0px;
			}
			&:last-child {
				border-right: 0px;
			}
		}
		.ladder-tab.active {
			color: $button-color;
		}
	}
}

.match-lobby-page-header {
	.match-lobby-and-gather-info {
		margin: 4rem 0.75rem 0 0.75rem;

		.match-lobby-info-star-gather {
			background-image: url(../img/gathers/stargather.jpg);
			background-size: cover;
			background-position: 50% 32.5%;
			text-align: left;
			display: flex;
			align-items: center;
			padding: 1em 2em;
			color: #ffa200;
			font-size: 17px;

			.icon-star-gather {
				margin-right: 5px;
				width: 30px;
				height: 30px;
			}
		}
		.match-lobby-info-verified-gather {
			text-align: left;
			padding: 1em 2em;
			font-size: 17px;
			background-image: url(../img/gathers/verifiedgather.jpg);
			background-size: cover;
			background-position: 50% 31%;
		}
	}
	.match-lobby-info {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		background-color: $secondary-color;
		padding: 0 1em;
		align-items: center;

		.info-item {
			margin: 1em;
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			color: #fff;

			label {
				color: $grey-text-color;
			}

			.match-twitch {
				align-items: center;

				.button,
				.remove,
				input {
					margin-left: 5px;
				}

				input {
					width: auto;
				}
			}
		}
		.info-item-map {
			display: flex;
			align-items: center;

			.match-lobby-info-map {
				width: 61px;
				height: 39px;
				background-size: cover;
				border-radius: 3px;
			}
		}
	}
}

.match-summary-1v1-container {
	max-width: 1200px;
	margin: 0 auto;
}

.info-match-sponsor {
	display: flex;
	margin: 5px;

	.info-match-sponsor-1 {
		background-image: url(../img/sponsors/1.png);
		width: 200px;
		height: 70px;
	}

	.info-match-sponsor-2 {
		background-image: url(../img/sponsors/2.png);
		width: 200px;
		height: 70px;
	}

	.info-match-sponsor-3 {
		background-image: url(../img/sponsors/3.jpg);
		width: 200px;
		height: 70px;
	}

	p {
		line-height: 48px;
		margin-right: 10px;
	}
}

;@import "sass-embedded-legacy-load-done:79";