#right-side-info {
	position: relative;
	width: $right-sidebar-width;
	flex-shrink: 0;
	font-weight: 400;
	background-color: #17181e;

	@include mobile() {
		position: fixed;
		height: 100%;
		top: 75px;
		right: 0;
		bottom: 0;
	}

	@media (min-width: $right-side-narrow-breakpoint) {
		width: $right-sidebar-wide-width;
	}

	@media (min-width: $right-side-wide-breakpoint) {
		width: $right-sidebar-fullhd-width;
	}

	#right-side-info-content {
		position: fixed;
		top: $top-bar-height;
		width: $right-sidebar-width;
		height: calc(100vh - #{$top-bar-height});
		display: flex;
		flex-direction: column;
		background-color: $chat-bar-background-color;

		@media (min-width: $right-side-narrow-breakpoint) {
			width: $right-sidebar-wide-width;
		}
		@media (min-width: $right-side-wide-breakpoint) {
			width: $right-sidebar-fullhd-width;
		}

		&.logged-in {
			max-height: calc(100vh - #{$top-bar-height});
		}
	}
}
#right-side-info-content {
	.home-left-column-stickies .cover {
		border-radius: 0;
	}

	.chat-bar-dialog {
		position: static;
		overflow: auto;
		display: flex;
		flex-direction: column;
	}
	.right-side-header {
		text-align: center;
		color: #fff;
		line-height: 40px;
		font-size: 14px;
		font-weight: 500;
		padding: 0 10px;
		background-color: #1e1f2a;
	}

	.button-inverted-colors {
		color: $primary-color;
		&:hover {
			color: #fff;
		}
	}
	.latest-drops-carousel {
		background-color: #1a1a22;

		.content-heading {
			margin-bottom: 0;
		}
		// hiding scroll button at beginning and end of the carousel
		.carousel__back-button:disabled,
		.carousel__next-button:disabled {
			background-color: #1a1a22 !important;
			color: #1a1a22;
			cursor: default;
		}
		.latest-drop {
			height: 209px;
			border: 2px solid #653400;
			border-radius: 4px;
			margin-left: 0;
			margin-right: 0;
		}

		.latest-drop.super-drop {
			.drop-category-text {
				display: none;
			}
		}

		.latest-drops-carousel-content {
			height: 209px;
		}

		.home-carousel-button {
			height: 209px;
		}

		.latest-drops-carousel-see-all-drops-link {
			display: none;
		}
		.content-heading {
			justify-content: center;
			margin-left: 0;
			font-size: 14px;
			color: #fff;
		}
	}

	.table-striped {
		tbody > tr {
			border-bottom: 0;
		}
		tbody > tr:nth-child(2n + 1) {
			background-color: #17181e;
		}
		tr:nth-child(2n) {
			background-color: #1b1c26;
		}
	}
	.chat-bar-dialog-body {
		height: auto;
		min-height: 150px;
		background-color: $chat-bar-background-color;
		flex: 1 1 auto;

		.friend-list {
			height: auto;
			max-height: 600px;
		}
	}
	.chat-bar-gather-settings-wrapper {
		position: absolute;
		right: $right-sidebar-width;
		// Temporary lef commented
		// bottom: $chat-bar-height;

		@media (min-width: $right-side-wide-breakpoint) {
			right: $right-sidebar-fullhd-width;
		}

		.chat-bar-gather-settings {
			position: static;
			width: 390px;
		}
	}
}
.verified-players-online,
.top-ranked-users {
	.famfamfam-flag {
		margin: 0 5px;
	}
}
.verified-players-online {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
}
.verified-players-table {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	overflow: hidden;
	height: auto;

	.player-row {
		display: flex;
		width: 100%;
		height: 49px;
		align-items: center;
		flex-shrink: 0;
		padding-left: 1em;
		border-bottom: 1px solid $dark-grey-border;
		white-space: nowrap;

		&:nth-child(odd) {
			background-color: #17181e;
		}
		&:nth-child(even) {
			background-color: #1b1c26;
		}

		.player-column {
			width: 70%;
		}
		.rank-column {
			width: 30%;
		}
	}
}

.challenging-1v1-right-side {
	width: 100%;
	height: 180px;
	color: #fff;

	font-weight: 500;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.button {
		width: 70%;
	}

	.button + .button {
		margin-left: 0;
	}
}

.daily-missions {
	margin: 20px;
	text-align: center;

	.mission-row {
		font-size: 12px;
		display: flex;
		width: 100%;
		height: 40px;
		align-items: center;
		padding: 1em;
		white-space: nowrap;
		background-color: #1b1c26;
		margin-bottom: 2px;
		border-radius: 5px;

		.mission-row-objective {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: center;
			flex-grow: 1;
		}

		.mission-row-reward {
			width: 52px;
			height: 22px;
			line-height: 22px;
			background-color: #252734;
			color: #00a9e9;
			border-radius: 20px;
		}

		&.completed {
			.mission-row-objective {
				color: #00a9e9;
				text-decoration: line-through;
			}
			.mission-row-reward {
				background-color: #00a9e9;
				color: #1b1c26;
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:53";