.logged-out-landing {
	width: 100%;
	background-image: url(../img/backgrounds/gathersback3.png);
	background-position: center top;
	background-repeat: no-repeat;
	background-size: contain;

	@include mobile() {
		margin-top: -$top-bar-height;
		background-size: 100%;
	}

	section {
		width: 100%;
		padding: 1.5em 0;

		.content {
			max-width: 1000px;
			margin: 0px auto;
		}

		button {
			padding: 0 2em;
		}
		@include mobile() {
			padding: 0.8em 0;
			button {
				width: 100%;
			}
		}
	}

	.title-and-usps {
		button {
			margin-top: 2em;
			margin-bottom: 1em;
		}

		@include mobile() {
			padding: 1em;

			.column {
				padding: 0;
			}
		}
	}
	.title {
		font-size: 28px;
		font-weight: 600;

		@include mobile() {
			font-size: 24px;
		}
	}

	.subtitle {
		font-size: 24px;
		font-weight: 600;

		@include mobile() {
			padding: 0 0.75rem;
			font-size: 22px;
		}
	}

	.subtitle-refer-friend {
		font-size: 20px;
		font-weight: 600;

		@include mobile() {
			font-size: 16px;
		}
	}

	.title-subtext {
		padding-top: 1.5em;
		font-size: 18px;
	}

	.emphasis {
		color: $primary-hover-color;
	}
	.usp-and-login {
		.usp-and-login-holder {
			@include mobile() {
				display: flex;
				flex-direction: column;
			}
		}
		.usp-image-container {
			border-radius: 50%;
			background-color: #20212d;
			width: 50px;
			height: 50px;
			display: flex;

			align-items: center;
			justify-content: center;
		}
		.usp {
			width: 40px;
			height: 40px;
		}

		.usp-wrapper {
			@include mobile() {
				flex-direction: row;
			}
			@include tablet() {
				flex-direction: row;
			}
		}

		.usp-text {
			font-size: 16px;
			margin-left: 1em;
		}

		.login-block {
			border-radius: 4px;
			width: 100%;
			height: 360px;
			background-color: #1b1c27ad;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			@include mobile() {
				height: 160px;
			}

			.top-bar-login {
				height: 50px;
				width: 250px;

				.steam-login-wrapper {
					.button {
						font-size: 16px;
						min-height: 50px;
					}
				}
			}
		}
	}

	.login-block-refer-friend {
		background-color: #1b1c27ad;
		border-radius: 4px;
		width: 100%;
		height: 360px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		@include mobile() {
			height: 260px;
		}
	}

	.photoWrapper {
		width: 100%;

		.photo {
			width: 68px;
			height: 68px;
			min-width: 68px;
			background: #292a34 0 0 no-repeat padding-box;
			border: 2px solid #13aceb;
			border-radius: 50%;
		}
	}

	.fin-out-more-holder {
		display: flex;
		justify-content: center;
		align-items: center;

		.arrow-icon {
			width: 50px;
			height: 50px;

			background-color: $primary-color;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.emphasis {
			font-size: 18px;
		}
	}

	.comparison-table {
		@include mobile() {
			margin-bottom: 40px;
		}
		.fas {
			font-size: 20px;
			transform: translate(0%, 15%);
		}
		.fa-times-circle {
			color: #505c71;
		}

		.fa-check-circle {
			color: $primary-color;
		}

		table {
			border-collapse: separate;
			border-spacing: 2px;
			width: 100%;
		}

		th,
		td {
			text-align: center;
			width: 33.33%;
			color: #fff;

			@include mobile() {
				width: 30%;
			}
		}

		th:first-child,
		td:first-child {
			padding-left: 15px;
			text-align: left;

			@include mobile() {
				width: 40%;
			}
		}

		tr {
			line-height: initial;
		}

		tr:nth-child(odd) {
			background-color: #1a1c26;
		}

		tr:nth-child(even) {
			background-color: #1b1d2a;
		}

		.comparison-table-section-heading {
			color: #fff !important;
			background-color: #282c39 !important;
		}

		.premium {
			color: #ff8020;
		}
	}

	.comparison-table-login-button {
		display: flex;
		justify-content: flex-end;
		margin-top: 1rem;
	}

	.why-play-text {
		text-align: center;
	}
	.landing-usp-wrapper {
		display: flex;
		flex-wrap: wrap;
		.usp-wrapper {
			width: 25%;
			flex-direction: column;
			margin-top: 0;
			margin-bottom: 0;
			max-width: 220px;

			.usp-point {
				font-size: 18px;
				font-weight: 600;
				text-transform: uppercase;
				padding-bottom: 1em;
				text-align: center;
			}
			.usp-text {
				text-align: center;
			}
		}
	}
	.landing-usp-wrapper.top {
		justify-content: space-around;

		@include mobile() {
			.usp-wrapper {
				width: 100%;
			}
		}
	}
	.landing-usp-wrapper.bottom {
		width: 100%;
		flex-direction: row;
		justify-content: center;

		.usp-quarter {
			width: 25%;

			.usp-wrapper {
				width: auto;
				max-width: 150px;
				margin: auto;
			}

			@include mobile() {
				width: 50%;
			}
		}
	}
	.two-pane-column {
		.column-title {
			font-size: 34px;
			font-weight: 600;

			@include mobile() {
				font-size: 28px;
			}
		}
		.column-text {
			line-height: 28px;
			padding: 1em 0;
		}
	}
	section .content.two-pane-column {
		@include mobile() {
			display: flex;
			text-align: center;
		}
	}
	section.odd {
		background-color: $content-background-color;

		.content {
			.text {
				padding-right: 2em;
			}
			@include mobile() {
				flex-direction: column;
			}
		}
	}
	section.even {
		background-color: $card-background-color;

		.content {
			.text {
				padding-left: 2em;
			}
			@include mobile() {
				flex-direction: column-reverse;
			}
		}
	}
	section.even,
	section.odd {
		@include mobile() {
			.content {
				.text {
					padding: 0 2em;
				}
			}
		}
	}

	.lol-image {
		width: 100%;
	}
	#lol-gather-image {
		content: url(../img/loggedoutlanding/english/stargathers_english.png);

		&.gathers-dk {
			content: url(../img/loggedoutlanding/danish/stargathers_danish.png);
		}
		&.gathers-pl {
			content: url(../img/loggedoutlanding/polish/stargathers_polish.png);
		}
		&.gathers-ru {
			content: url(../img/loggedoutlanding/russian/stargathers_russian.png);
		}
		&.gathers-ua {
			content: url(../img/loggedoutlanding/russian/stargathers_russian.png);
		}
		&.gathers-se {
			content: url(../img/loggedoutlanding/swedish/stargathers_swedish.png);
		}
		&.gathers-de {
			content: url(../img/loggedoutlanding/german/stargather_german.png);
		}
	}
	#lol-tournaments-image {
		content: url(../img/loggedoutlanding/english/tournaments_english.png);

		&.tournaments-dk {
			content: url(../img/loggedoutlanding/danish/tournaments_danish.png);
		}
		&.tournaments-pl {
			content: url(../img/loggedoutlanding/polish/tournaments_polish.png);
		}
		&.tournaments-ru {
			content: url(../img/loggedoutlanding/russian/tournaments_russian.png);
		}
		&.tournaments-ua {
			content: url(../img/loggedoutlanding/russian/tournaments_russian.png);
		}
		&.tournaments-se {
			content: url(../img/loggedoutlanding/swedish/tournaments_swedish.png);
		}
		&.tournaments-de {
			content: url(../img/loggedoutlanding/german/tournaments_german.png);
		}
	}
	#lol-stats-image {
		content: url(../img/loggedoutlanding/english/statsmedals_english.png);

		&.stats-dk {
			content: url(../img/loggedoutlanding/danish/statsmedals_danish.png);
		}
		&.stats-pl {
			content: url(../img/loggedoutlanding/polish/statsmedals_polish.png);
		}
		&.stats-ru {
			content: url(../img/loggedoutlanding/russian/statsmedals_russian.png);
		}
		&.stats-ua {
			content: url(../img/loggedoutlanding/russian/statsmedals_russian.png);
		}
		&.stats-se {
			content: url(../img/loggedoutlanding/swedish/statsmedals_swedish.png);
		}
		&.stats-de {
			content: url(../img/loggedoutlanding/german/statsmedals_german.png);
		}
	}
	#lol-support-image {
		content: url(../img/loggedoutlanding/english/support_english.png);

		&.support-dk {
			content: url(../img/loggedoutlanding/danish/support_danish.png);
		}
		&.support-pl {
			content: url(../img/loggedoutlanding/polish/support_polish.png);
		}
		&.support-ru {
			content: url(../img/loggedoutlanding/russian/support_russian.png);
		}
		&.support-ua {
			content: url(../img/loggedoutlanding/russian/support_russian.png);
		}
		&.support-se {
			content: url(../img/loggedoutlanding/swedish/support_swedish.png);
		}
		&.support-de {
			content: url(../img/loggedoutlanding/german/support_german.png);
		}
	}

	.premium-section {
		background-size: cover;
		background-position: center 20%;
		background-repeat: no-repeat;
		width: 100%;
		padding: 40px 0;

		.button {
			width: 200px;
		}
	}

	.premium-image {
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		width: 100%;
		height: 370px;
	}

	.premium-title {
		padding: 30px 0;
		font-size: 22px;
		text-align: left;
		font-weight: 700;

		.premium-logo {
			background-image: url(../img/premium/premium_function_popover.png);
			background-size: contain;
			width: 290px;
			height: 60px;
		}
	}

	.premium-bullets {
		padding: 0 16px;
		text-align: left;
		.bullet {
			position: relative;

			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
		.bullet::before {
			content: ' ';
			width: 7px;
			height: 7px;
			background-color: $premium-color;
			position: absolute;
			top: 0.5em;
			left: -16px;
		}
	}
}

.lol-elisa-header {
	width: 800px;
	height: 200px;
	margin: 50px auto;
	background-image: url(../img/elisa/banner.png);

	@media (max-width: 1200px) {
		display: none;
	}
}

;@import "sass-embedded-legacy-load-done:106";