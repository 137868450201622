.lws-lls-container {
	color: black;
	position: relative;
	width: 300px;
	height: 65px;
	display: grid;
	grid-template-columns: 1fr 2fr 2fr 1fr;
	grid-template-rows: 1fr 1fr;

	> div {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
/*
.lws-lls-teams-lws,
.lws-lls-teams-lls {
	position: absolute;
	top: 3px;
	width: 70px;
	font-size: 12px;
	text-align: center;
	line-height: 20px;
	font-weight: bold;

	> .fa-trophy {
		font-size: 2.7em;
	}
}
.lws-lls-teams-lws {
	left: 17px;

	> .fa-trophy {
		color: #80cd12;
	}
}
.lws-lls-teams-lls {
	right: 17px;

	> .fa-trophy {
		color: #c30000;
	}
}
.lws-lls-teams-wins,
.lws-lls-teams-losses,
.lws-lls-teams-win-streak,
.lws-lls-teams-lose-streak {
	position: absolute;
	width: 75px;
	text-align: center;
}
.lws-lls-teams-wins,
.lws-lls-teams-losses {
	height: 34px;
	line-height: 34px;
	font-weight: 500;
}
.lws-lls-teams-win-streak,
.lws-lls-teams-lose-streak {
	top: 34px;
	height: 23px;
	line-height: 23px;
}
.lws-lls-teams-wins {
	right: 50%;
	background-color: #80cd12;
}
.lws-lls-teams-losses {
	left: 50%;
	background-color: #c30000;
}
.lws-lls-teams-win-streak {
	right: 50%;
	background-color: #b7f55e;
}
.lws-lls-teams-lose-streak {
	left: 50%;
	background-color: #e82525;
}*/

.lws-lls-teams-lws,
.lws-lls-teams-lls {
	padding: 5px;
	font-size: 12px;
	text-align: center;
	line-height: 20px;
	font-weight: bold;
	background-color: #222330;

	.fa-trophy {
		font-size: 2em;
	}
}
.lws-lls-teams-lws {
	border-top-left-radius: 15px;
	.fa-trophy {
		color: #96b900;
	}
}
.lws-lls-teams-lls {
	border-top-right-radius: 15px;
	.fa-trophy {
		color: #cf0023;
	}
}
.lws-lls-teams-wins,
.lws-lls-teams-losses,
.lws-lls-teams-win-streak,
.lws-lls-teams-lose-streak {
	background-color: $secondary-color;
}
.lws-lls-teams-wins,
.lws-lls-teams-losses {
	color: #fff;
	font-weight: 500;
	font-size: 20px;
}
.lws-lls-teams-win-streak,
.lws-lls-teams-lose-streak {
	color: #9a9a9a;
}
.lws-lls-teams-wins,
.lws-lls-teams-win-streak {
	border-right: 1px solid #181922;
}
.lws-lls-abbreviation-win,
.lws-lls-abbreviation-lose {
	background-color: #2d2e3e;
	font-size: 12px;
	font-weight: 600;
}
.lws-lls-abbreviation-win {
	color: #96b900;
	border-bottom-left-radius: 15px;
}
.lws-lls-abbreviation-lose {
	color: #cf0023;
	border-bottom-right-radius: 15px;
}

/*
.lws-lls-teams-lws, .lws-lls-teams-lls {
	position: absolute;
	top: 30px;
	width: 60px;
	font-size: 11px;
	font-weight: 500
}
.lws-lls-teams-lws>.fa-trophy {
	color: #2877a4
}
.lws-lls-teams-lls>.fa-trophy {
	color: #b7d5e3
}
.lws-lls-teams-lls {
	right: 0;
}
.lws-lls-teams-wins, .lws-lls-teams-losses, .lws-lls-teams-win-streak, .lws-lls-teams-lose-streak {
	position: absolute;
	width: calc(50% - 60px)
}
.lws-lls-teams-wins, .lws-lls-teams-losses {
	top: 30px;
	height: 29px;
	line-height: 29px;
	font-weight: 500
}
.lws-lls-teams-win-streak, .lws-lls-teams-lose-streak {
	top: 59px;
	font-size: 10px;
	height: 19px;
	line-height: 19px;
	font-weight: 400
}
.lws-lls-teams-wins {
	left: 60px;
	background-color: #5ec76c
}
.lws-lls-teams-losses {
	right: 60px;
	background-color: #e4454b
}
.lws-lls-teams-win-streak {
	left: 60px;
	background-color: #63d487
}
.lws-lls-teams-lose-streak {
	right: 60px;
	background-color: #e96373
}
*/

;@import "sass-embedded-legacy-load-done:27";