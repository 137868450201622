$team-card-height: 210px;
$team-card-head-height: 60px;
$team-card-body-top-height: 100px;
$team-card-button-size: 46px;

.team-card-wrapper {
	position: relative;
	line-height: 12px;
}

.team-card {
	text-align: left;
	position: absolute;
	line-height: 12px;
	z-index: 9999;
	height: $team-card-height;
	width: 460px;
	background-color: #232632;
	box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.5);

	.team-card-loading {
		text-align: center;
		margin-top: 20px;
		line-height: 140px;
		height: 140px;
	}

	.team-card-head {
		height: $team-card-head-height;
		background-color: #0d0f1b;
		line-height: $team-card-head-height;
	}

	.team-card-logo {
		width: 40px;
		height: 40px;
		display: inline-block;
		background-size: cover;
		background-repeat: no-repeat;
		border: 2px solid $primary-color;
		vertical-align: middle;
		margin-right: 6px;
		margin-left: 10px;
	}

	.team-card-name {
		color: $primary-color;
		display: inline-block;
		vertical-align: middle;
		margin-right: 6px;
		font-size: 17px;
		max-width: 315px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		font-weight: 400;
	}

	.team-card-region {
		display: inline-block;
		vertical-align: middle;
	}

	.team-card-country {
		vertical-align: middle;
	}
}

.team-card-body-top {
	height: $team-card-body-top-height;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.team-card-rating {
		text-align: center;
		padding-left: 10px;
	}

	.team-card-rating-name {
		margin-top: 14px;
		text-transform: uppercase;
		color: black;
		font-weight: 400;
		text-align: center;
		font-size: 14px;
	}

	.team-card-rating-value {
		margin-top: 32px;
		font-size: 21px;
		text-align: center;
		font-weight: 400;
	}

	.team-card-results {
		height: initial;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.team-card-buttons {
		position: relative;
		float: right;
		width: $team-card-button-size;
	}

	.team-card-team-button {
		width: $team-card-button-size;
		height: $team-card-button-size;
		line-height: $team-card-button-size;
		text-align: center;
		color: white;
		font-size: 20px;
		background-color: $button-color;
		margin-bottom: 2px;
	}

	.team-card-team-button:hover {
		background-color: $button-hover-color;
	}

	.team-card-stats-button {
		width: $team-card-button-size;
		height: $team-card-button-size;
		line-height: $team-card-button-size;
		text-align: center;
		color: white;
		font-size: 20px;
		background-color: $button-color;
	}

	.team-card-stats-button:hover {
		background-color: $button-hover-color;
	}
}

.team-card-latest-matches {
	height: ($team-card-height - $team-card-head-height - $team-card-body-top-height);
	background-color: #191a23;
	display: block;
	width: 100%;

	tr {
		background-color: #191a23;
		line-height: 23px;
		height: 23px;
		font-size: 12px;
	}

	.team-card-match-won {
		font-weight: bold;
		color: #80cd12;
	}

	.team-card-match-lost {
		font-weight: bold;
		color: #c30000;
	}

	.team-card-view-match {
		text-transform: uppercase;
		font-weight: bold;
		text-align: center;
	}

	.team-card-other-team-name {
		display: inline-block;
		vertical-align: middle;
		max-width: 152px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		font-size: 14px;
		font-weight: 600;
	}

	.team-card-other-team-country-flag {
		margin-left: 5px;
	}

	.team-card-match-date {
		color: black;
		text-align: center;
		font-size: 13px;
	}
}

.team-card-wrapper.team-card-fixed {
	position: static;
	> .team-card {
		position: fixed;
		z-index: 9999;
	}
}

;@import "sass-embedded-legacy-load-done:39";