.matchmaking-content {
	.content-heading {
		background: transparent;
		font-size: $content-header-3-font-size;
		font-weight: $content-header-font-weight;
		font-family: $content-header-font-family;
		padding: 4px 0px;
		text-align: left;
		user-select: none;

		@include mobile() {
			font-size: 21px;
			padding: 8px 24px;
		}
	}
	.matchmaking-center-column {
		flex-grow: 1;
	}
	.frag-column {
		min-width: 300px;
	}

	.matchmaking-section {
		margin-bottom: 50px;
	}

	.live-ladders {
		.live-ladder {
			display: flex;
			height: 4em;
			align-items: center;
			border-bottom: 1px solid $dark-grey-border;

			&:last-child {
				border-bottom: 0;
			}

			.icon {
				width: 32px;
				height: 32px;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: 50% 50%;
				border: 2px solid #00a9ea;
				border-top-color: rgb(0, 169, 234);
				border-right-color: rgb(0, 169, 234);
				border-bottom-color: rgb(0, 169, 234);
				border-left-color: rgb(0, 169, 234);
				border-radius: 50%;
				margin-right: 10px;
				position: relative;
				flex-shrink: 0;

				.fa-check-circle {
					position: absolute;
					bottom: -6px;
					right: -6px;
					color: limegreen;
				}
			}
			.text {
				.name {
					color: #fff;
				}
				.join {
					text-transform: uppercase;
					font-size: 12px;
				}
			}
		}
	}
	.featured-ladder {
		margin-bottom: 50px;
		.featured-entry {
			display: inline-block;
			position: relative;
			width: 100%;

			.img-wrapper {
				position: relative;
				.entry-banner {
					width: 100%;
				}
			}
			.img-wrapper::after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-image: linear-gradient(to left, transparent 0%, $content-background-color 100%);
			}
			.text {
				position: absolute;
				top: 25%;
				left: 2em;

				@include mobile() {
					position: relative;
					left: 0;
					background-color: revert;
				}

				.description {
					max-width: 50%;
					color: #fff;
				}
				.button {
					margin-top: 15px;
					padding: 0 2em;
					border-radius: 20px;
				}
			}
		}
	}
	.featured-ladder-standings {
		.ladder-standing-filter-checkbox {
			display: flex;
			align-items: center;
			font-size: 14px;

			input {
				margin-right: 5px;
			}
		}
	}

	.ladder-section .tournaments-tournament-card {
		margin: 0;
	}
}
#matchmaking-unranked-notice {
	background-color: #92dfff;
	text-align: center;
	line-height: 35px;
	margin-bottom: 10px;
	font-weight: 500;
}

#matchmaking-maintenance-notice {
	background-color: #ff8400;
	text-align: center;
	line-height: 35px;
	margin-bottom: 10px;
	font-weight: 500;
}

#matchmaking-map-selector {
	width: 100%;
	overflow: auto;

	// Reset the margin 15px applied in the main.scss, because here buttons should follow in a column
	.button + .button {
		margin-left: 0px;
	}
}
#matchmaking-map-selector-title {
	font-size: 18px;
	font-weight: 400;

	> .subtitle {
		margin-left: 5px;
		font-size: 12px;
	}
}

.matchmaking-header-buttons {
	display: flex;
	flex-direction: column;
	width: 90%;
	@include from(1500px) {
		flex-direction: row;
	}
}

.matchmaking-map-selector-play {
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: flex-start;

	.checkbox {
		margin-top: 5px;
		margin-bottom: 20px;
		margin-right: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		float: left;
	}

	.button {
		display: block;
		margin-top: 8px;
		min-width: 60%;
		line-height: 33px;
		min-height: 33px;
		@include from(1500px) {
			width: 100%;
			min-width: 30%;
			max-width: 205px;
			&.prime {
				margin-left: 15px !important;
			}
		}
	}
}
.matchmaking-checkbox {
	margin-right: 8px;
}
.matchmaking-invite-checkbox {
	vertical-align: middle;
}
.check-prime {
	color: $prime-color;
}

.matchmaking-header {
	background-repeat: no-repeat;
	background-position: left;
	background-position-y: top;
	background-size: cover;
	background-image: url(../img/banners/matchmaking_gathers_header.png);
}

.matchmaking-header-content {
	min-height: 250px;
}

.matchmaking-header-description {
	display: flex;
	justify-content: center;
	align-items: start;
	flex-direction: column;
	margin-left: 20px;

	.matchmaking-header-title {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: left;
		font-size: 38px;
		font-weight: 500;
		padding-bottom: 12px;
		@include tablet() {
			padding-top: 0px;
		}

		.fa-crosshairs {
			color: $primary-color;
			margin-right: 10px;
			font-size: 40px;
		}
	}

	.matchmaking-header-message {
		padding-top: 12px;
		@include tablet() {
			padding-right: 70px;
			padding-bottom: 20px;
			text-align: left;
		}
	}
}
.queue-state-block {
	@include desktop() {
		padding-left: 14%;
	}
}
.matchmaking-login-instructions {
	margin-top: 55px;
	margin-bottom: 55px;
	max-width: 338px;
	margin-left: auto;
	margin-right: auto;
	background-color: #02031091;
	padding: 28px 32px;

	.matchmaking-login-instructions-start-playing {
		font-weight: bold;
		font-size: 18px;
		margin-bottom: 20px;
	}

	.matchmaking-login-ordering-number {
		display: inline-block;
		background-color: #242530;
		color: $primary-color;
		width: 24px;
		height: 24px;
		text-align: center;
		line-height: 24px;
		font-size: 14px;
		margin-right: 16px;
		border-radius: 50%;
	}
	.matchmaking-login-instructions-login {
		margin-bottom: 16px;
		.matchmaking-login-button {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			line-height: 1.5;
			flex-grow: 0;
			flex-shrink: 0;
			.matchmaking-login {
				height: 35px;
				width: 255px;
				min-width: 255px;

				margin-left: 38px;
				margin-top: 12px;
			}
		}
	}

	.matchmaking-login-instructions-download {
		.matchmaking-login-download-client {
			margin-left: 38px;
			margin-top: 12px;
		}
		.matchmaking-login-download-client-button {
			color: #fff;
			padding: 12px 8px 12px 8px;
		}
		.matchmaking-login-download-client-description {
			padding-left: 18px;
			.label {
				padding-left: 12px;
				color: $grey-text-color;
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:72";