.logged-out-popover-manager {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 6000;
	background-color: rgba(0, 0, 0, 0.75);
}
.logged-out-popover {
	padding: 0;
	width: 550px;
	height: 400px;

	.popover-content {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0;
		height: 100%;

		.close-button {
			position: absolute;
			right: 1em;
			top: 1em;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			border: 1px solid $dark-grey-text-color;
			color: $grey-text-color;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}

		.logo {
			background-image: url(../img/logo.png);
			width: 200px;
			height: 100px;
			background-size: cover;
			background-repeat: no-repeat;
			margin-top: 1em;
		}

		.text-content {
			flex: 1 auto;

			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		.main-text {
			font-size: 26px;
			line-height: 30px;
			margin: 0 3em 1em;
		}

		.sub-text {
			font-size: 18px;
			margin: 0 2em;
		}
		.sub-text.last {
			margin-bottom: 10px;
		}

		.login-footer {
			background-color: $dark-grey-border;
			width: 100%;
			height: 110px;
			padding: 2em;
			display: flex;
			justify-content: center;

			.top-bar-login {
				height: 50px;
				width: 250px;

				.steam-login-wrapper {
					.button {
						font-size: 16px;
						min-height: 50px;
					}
				}
			}
		}
	}
}

;@import "sass-embedded-legacy-load-done:129";