.find-your-friends-popover {
	width: unset;
	max-width: 470px;
	padding: 0;
	border-radius: 10px;

	.popover-content {
		padding-bottom: 0;
	}
	.info {
		padding: 1em 2em;
	}
	.steam-logo {
		background-image: url(../img/USP/icon_steam.png);
		width: 150px;
		height: 100px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		margin: auto;
	}
	.subtext {
		color: $grey-text-color;
		font-size: 13px;

		> span {
			color: #fff;
		}
	}
	.friend-list {
		margin-top: 1em;
		max-height: 250px;
		overflow: auto;

		.profile-friend {
			justify-content: space-between;

			.username {
				display: flex;
				align-items: center;
			}
			input {
				margin-right: 1em;
			}
		}
	}
	.buttons {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #171820;
		padding: 1em;
		border-radius: 10px;

		.skip {
			font-size: 13px;
		}
		.add-friends {
			display: flex;
			text-transform: uppercase;

			.friend-request:first-child {
				margin-right: 1em;
			}
		}
	}
}

.new-friend-popover {
	.fa-user {
		font-size: 52px;
		color: $primary-color;
	}
	.subtext {
		line-height: 2em;
		font-size: 14px;
		font-weight: 400;

		.avatar {
			vertical-align: middle;
		}
	}
	.space-top {
		padding-top: 0.5em;
	}
}
.friend-request-sent-popover {
	.fa-check-circle {
		font-size: 52px;
		color: $primary-color;
	}
	.buttons.single {
		justify-content: center;
	}
}

;@import "sass-embedded-legacy-load-done:128";