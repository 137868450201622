.inputContainer {
	display: flex;
	flex-direction: column;

	> div {
		&:first-child {
			margin-bottom: 16px;

			input {
				width: 100%;
			}
		}

		&:nth-child(3) {
			button {
				width: 100%;
				margin-top: 16px;
			}
		}
	}
}
