@import 'src/esportal-ui/styles/colors';

.userBarRankWrapper {
	display: flex;
	align-items: center;
	margin-left: 20px;
}

.imageContainer {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	:nth-child(2) {
		width: 12px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.userBarRankImage {
	max-width: 28px;

	> img {
		position: absolute;
		top: 50%;
		left: 50%;
		translate: transform(-50%, -50%);
	}
}

.progressBar {
	margin-left: -2px;
	height: 12px;
	width: 84px;
	background-color: $user-bar-progress-bar-background;
	border-radius: 0 2px 2px 0;

	div {
		background-color: $user-bar-progress-bar-color;
		height: 100%;

		transform-origin: left;
		animation-name: widthAnimation;
		animation-duration: 0.5s;

		@keyframes widthAnimation {
			0% {
				transform: scaleX(0);
			}
			100% {
				transform: scaleX(1);
			}
		}
	}
}
